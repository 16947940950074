import * as React from 'react'
import Box from '@mui/material/Box'
import { Tooltip } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import FormControl from '@mui/material/FormControl'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import FieldsetComponent from '../comun/FieldsetComponent'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { checkCompleteRoles, checkDuplicatesUser, rolDesriptionToRolCode } from './ProgramsFunctions'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'

export default function UsersProgramForm({ methodsForm, selected, roles, users }) {
	const {
		control,
		register,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = methodsForm

	const [rolesList] = React.useState(roles)
	const [usersList] = React.useState(users)
	const [dataSelected, setDataSelected] = React.useState([])

	const [disableAddButton, setDisableAddButton] = React.useState(true)

	const [t] = useTranslation('common')

	React.useEffect(() => {
		if (selected) {
			selected.forEach((value) => {
				const filterUser = users.find((user) => user.id === value.userId)
				const filterRol = roles.find((rol) => rol.id === value.emStaffRoleId)

				if (filterUser != null && filterRol != null) {
					var newRow = {
						user: { id: filterUser.id, userName: filterUser.userName },
						rol: {
							id: filterRol.id,
							code: rolDesriptionToRolCode(filterRol.id),
							description: filterRol.description,
						},
					}
					setDataSelected((prevState) => [...prevState, newRow])
				}
			})
		}
	}, [selected])

	React.useEffect(() => {
		setValue('selected_users', dataSelected)
	}, [dataSelected])

	React.useEffect(() => {
		const subscription = watch((value) => {
			setDisableAddButton(!value.users || value.users?.length == 0 || !value.roles)
		})
		return () => subscription.unsubscribe()
	}, [watch])

	const addRowTable = () => {
		var users = getValues('users')
		var roles = getValues('roles')
		var existeRolUsu = false
		const copyVessel = [...dataSelected]
		if (users == null || users?.length == 0 || roles == null) return
		copyVessel.forEach((element) => {
			if (element != null && element.user.id == users.id && element.rol.id == roles.id) {
				existeRolUsu = true
			}
		})

		if (!existeRolUsu) {
			var newRow = {
				user: { id: users.id, userName: users.userName },
				rol: { id: roles.id, code: rolDesriptionToRolCode(roles.id), description: roles.description },
			}
			errors.users = null
			errors.roles = null
			setValue('users', null)
			setValue('roles', null)
			setDataSelected((prevState) => [...prevState, newRow])
		} else {
			errors.users = { message: t('programs.form.user.error-user-rol') }
			errors.roles = { message: t('programs.form.user.error-user-rol') }
		}
	}

	const onDeleteUser = (row) => {
		const copyVessel = [...dataSelected]
		copyVessel.splice(
			copyVessel.findIndex((item) => item.user.userName === row.user.userName && item.rol.id === row.rol.id),
			1
		)

		setDataSelected(copyVessel)
	}

	const checkUsers = () => {
		var userRoles = getValues('selected_users')
		if (userRoles?.length != 0) {
			return !checkDuplicatesUser(userRoles) && checkCompleteRoles(userRoles)
		} else {
			return false
		}
	}

	return (
		<Box sx={{ width: '100%' }}>
			<FieldsetComponent title={t('programs.form.user.title')}>
				<Box
					sx={{
						width: '100%',
						m: 1,
						minHeight: '100px',
					}}>
					<Box sx={{ width: '100%' }}>
						<Box sx={{ display: 'flex' }}>
							<Box sx={{ width: '45%' }}>
								{usersList && usersList != null ? (
									<AutoCompleteComponent
										defaultValue={null}
										size='small'
										sx={{ minWidth: '100%', pr: 1 }}
										control={control}
										name='users'
										label={t('programs.form.user.username')}
										errors={errors?.users}
										optionsLabel={'userName'}
										keyProperty={'id'}
										options={usersList}
										rules={{
											validate: (v) => checkUsers() || t('programs.validations.user-role-field'),
										}}
									/>
								) : null}
							</Box>
							<Box sx={{ width: '45%' }}>
								<AutoCompleteComponent
									defaultValue={null}
									size='small'
									sx={{ minWidth: '100%' }}
									control={control}
									name='roles'
									label={t('programs.form.user.roles')}
									errors={errors?.roles}
									optionsLabel={'description'}
									keyProperty={'id'}
									options={rolesList}
									rules={{
										validate: (v) => checkUsers() || t('programs.validations.user-role-field'),
									}}
								/>
							</Box>
							<Box sx={{ display: 'flex', width: '10%', justifyContent: 'flex-end' }}>
								<FormControl
									variant='standard'
									size='small'
									sx={{ width: '40px', alignSelf: 'flex-start' }}>
									<Tooltip title={t('programs.form.vessel.add-button')}>
										<span>
											<IconButton
												disabled={disableAddButton}
												color='primary'
												onClick={() => addRowTable()}>
												<AddCircleIcon />
											</IconButton>
										</span>
									</Tooltip>
								</FormControl>
							</Box>
						</Box>
						<Box>
							<TableContainer component={Paper}>
								<Table {...register('selected_users')} sx={{ minWidth: 650 }} aria-label='simple table'>
									<TableBody>
										{dataSelected.map((row) => (
											<TableRow
												/* to avoid the same key that we receive from server */
												key={row.user.id + row.rol.id}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
												<TableCell component='th' scope='row'>
													{row.user.userName}
												</TableCell>
												<TableCell component='th' scope='row'>
													{row.rol.description}
												</TableCell>
												<TableCell component='th' scope='row' align='right'>
													<Tooltip title={t('users.table.content.delete')}>
														<span>
															<IconButton color='error' onClick={() => onDeleteUser(row)}>
																<DeleteIcon />
															</IconButton>
														</span>
													</Tooltip>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Box>
				</Box>
			</FieldsetComponent>
		</Box>
	)
}
