import { Box } from '@mui/material'
import {
	ArgumentAxis,
	Chart,
	CommonSeriesSettings,
	Crosshair,
	HorizontalLine,
	Legend,
	Margin,
	Pane,
	Point,
	Series,
	Tooltip,
	ValueAxis,
} from 'devextreme-react/chart'
import React, { useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import './Chart.css'
import { customizePoint, tooltipTemplate } from './ChartCustomTooltip'
import { datetimeGMTToLocaleInISOFormat, datetimeUTCtoString } from '../comun/DateUtils'

const iconOptions = [
	{ id: 1, name: 'circle' },
	{ id: 2, name: 'square' },
	{ id: 3, name: 'polygon' },
	{ id: 4, name: 'triangleDown' },
	{ id: 5, name: 'triangleUp' },
	{ id: 6, name: 'cross' },
]

function ChartGraph({ layers, data, updateCurrentTime, isLiveViewSelected }) {
	const chartRef = useRef()
	const [t] = useTranslation('common')
	const getIconName = (iconId) => {
		return iconOptions.find((i) => i.id == iconId).name
	}

	return (
		<Box sx={{ height: '100%', width: '100%' }}>
			<Chart
				disabled={isLiveViewSelected}
				height={'100%'}
				width={'100%'}
				dataSource={data}
				id='chart'
				ref={chartRef}
				customizePoint={(pointInfo) => customizePoint(pointInfo, layers)}
				onPointClick={(e) => updateCurrentTime(datetimeGMTToLocaleInISOFormat(e.target.data.date))}>
				<Margin top={0} bottom={0} />
				<CommonSeriesSettings argumentField='date' />
				{layers
					?.filter((l) => l.isHidden == false)
					.map((layer) => {
						return <Pane key={layer.layer} name={'layer' + layer.layer} width={'100%'} />
					})}

				{layers
					?.filter((l) => l.isHidden == false)
					.map((layer) => {
						return (
							<Series
								key={layer.layer}
								opacity={0.5}
								name={'Shade'}
								valueField={'sh' + layer.layer + 'rv'}
								pane={'layer' + layer.layer}
								type='area'
								color={layer.series[0].colorShaded}
							/>
						)
					})}
				<ValueAxis
					visible={false}
					valueMarginsEnabled={false}
					grid={{ visible: false }}
					name={'layers'}
					tick={{ visible: false }}
					label={{ visible: false }}
					maxValueMargin={1}
					minValueMargin={1}
					visualRange={{ endValue: 100, startValue: -2 }}
				/>

				<ArgumentAxis
					valueMarginsEnabled={false}
					endOnTick={false}
					wholeRange={true}
					argumentType='datetime'
					visible={false}
					label={{ visible: true, indentFromAxis: 1 }}
					tick={{ visible: false }}>
					{/* <DateTimeConstantLine value={time} /> */}
					{/* <ConstantLine value={time} label={{ visible: true }} extendAxis={true} /> */}
				</ArgumentAxis>
				{layers
					?.filter((l) => l.isHidden == false)
					.map((layer) => {
						return layer.series
							?.filter((s) => s.hidden == false)
							.map((serie, idx) => {
								if (serie.sensorSeatubeId == -10) {
									return (
										<Series
											key={serie.sensorSeatubeId + '-' + layer.layer + '-' + idx}
											valueField={serie.sensorNameGV}
											name={serie.name}
											pane={'layer' + layer.layer}
											axis={'layers'}
											width={1}
											type='scatter'
											color={serie.colorLine}></Series>
									)
								} else {
									return (
										<Series
											key={serie.sensorSeatubeId + '-' + layer.layer + '-' + idx}
											valueField={serie.sensorNameGV}
											name={serie.name}
											pane={'layer' + layer.layer}
											axis={'layers'}
											width={1}
											color={serie.colorLine}>
											<Point symbol={getIconName(serie.icon)} visible={serie.icon != null} />
										</Series>
									)
								}
							})
					})}
				<Legend visible={false} />
				<Crosshair enabled={true}>
					<HorizontalLine visible={false} />
				</Crosshair>

				<Tooltip
					enabled={true}
					interactive={true}
					paddingTopBottom={10}
					contentRender={(p) => tooltipTemplate(p, t, updateCurrentTime, layers)}
				/>
			</Chart>
		</Box>
	)
}

export default React.memo(ChartGraph)
