export const convertAnalysis = (selected) => {
	var array = []
	selected.map((sel) => {
		var analysis = { idFishingProfiles: sel.fishing.fishingProfileId, description: sel.description, programAnalysisTypeExports: sel.programAnalysisTypeExports }
		//programAnalysisTypeExports
		array.push(analysis)
	})
	return array
}

export const convertUser = (selected) => {
	var array = []
	selected.map((sel) => {
		var user = { userId: sel.user.id, emStaffRoleId: sel.rol.id }
		array.push(user)
	})
	return array
}

export const convertExports = (selected) => {
	var array = []
	selected.map((sel) => {
		var exports = { exportId: sel.exportId, description: sel.description }
		array.push(exports)
	})
	return array
}

export const checkCompleteRoles = (selected) => {
	var array = []
	selected.map((sel) => array.push(sel.rol.code))

	return array.includes('MAP') && array.includes('ANA') && array.includes('REV')
}

export const rolDesriptionToRolCode = (selected) => {
	switch (selected) {
		case 1:
			return 'MAP'
		case 2:
			return 'ANA'
		case 3:
			return 'REV'
		default:
			return ''
	}
}

export const checkDuplicatesUser = (selected) => {
	return new Set(selected).size !== selected.length
}
