import React, { useRef, useState } from 'react'
import { Box, MenuItem, Select } from '@mui/material'
import ReactHlsPlayer from 'react-hls-player'
import { useEffect } from 'react'
import { useChangeFootageCam } from '../../../../services/preview/useChangeFootageCam'
import useStore from '../../PreviewStore'
import MessageSnackBar from '../../../comun/MessageSnackBar'
import { messageDialogTypes } from '../../../../constants'
import { useShallow } from 'zustand/shallow'

function FootageTab({ footageInfo: { footage1VideoInfo, footage2VideoInfo }, cameraEnum, vesselApiId, projectId }) {
	const FootagePlayerTemplate = ({ data, idFootage }) => {
		const footageRef = useRef(null)
		const [footage, setFootage] = useState(data)

		const { isPlayingStore, currentTime, speed, mainVideoSeeked, setMainVideoSeeked } = useStore(
			useShallow((state) => ({
				isPlayingStore: state.isPlayingStore,
				currentTime: state.currentTime,
				speed: state.speed,
				mainVideoSeeked: state.mainVideoSeeked,
				setMainVideoSeeked: state.setMainVideoSeeked,
			}))
		)
		const { changeFootageCam, footageData, errorfootage } = useChangeFootageCam(vesselApiId, projectId)
		const [openError, setOpenError] = useState(false)
		const [errorMsg, setErrorMsg] = useState(null)

		useEffect(() => {
			if (errorfootage) {
				setOpenError(true)
				setErrorMsg(errorfootage)
			} else {
				setOpenError(false)
				setErrorMsg(null)
			}
		}, [errorfootage])

		const handleCloseError = () => {
			setOpenError(false)
			setErrorMsg(null)
		}

		useEffect(() => {
			if (footageData) {
				setFootage(footageData)
			}
		}, [footageData])

		useEffect(() => {
			if (speed && footageRef?.current) {
				footageRef.current.playbackRate = speed
			}
		}, [speed, footage])

		useEffect(() => {
			if (footageRef?.current) {
				if (footage?.offsetInSec != null) {
					footageRef.current.currentTime = footage.offsetInSec
					if (isPlayingStore && footageRef?.current.paused) {
						footageRef.current.play()
					} else if (!isPlayingStore && !footageRef?.current.paused) {
						footageRef.current.pause()
					}
				}
			}
		}, [footage?.startDate])

		useEffect(() => {
			if (isPlayingStore != null && footageRef?.current) {
				if (isPlayingStore && footageRef?.current.paused) {
					footageRef.current.play()
				} else if (!isPlayingStore && !footageRef?.current.paused) {
					footageRef.current.pause()
				}
			}
		}, [isPlayingStore])

		useEffect(() => {
			if (mainVideoSeeked && footage) {
				changeFootageCam('footage' + idFootage + '/cam', footage.cam, currentTime)
				setMainVideoSeeked(false)
			}
		}, [mainVideoSeeked])

		const onEnded = () => {
			changeFootageCam('footage' + idFootage + '/cam', footage.cam, currentTime)
		}

		const handleChangeFootageCam = (e) => {
			changeFootageCam('footage' + idFootage + '/cam', e.target.value, currentTime)
		}

		return (
			<>
				{footage && (
					<Box
						sx={{
							display: 'flex',
							position: 'relative',
							justifyContent: 'center',
							background: 'black',
							width: '100%',
							mb: 0.5,
						}}>
						<ReactHlsPlayer
							src={footage?.url}
							controls={false}
							autoPlay={false}
							style={{ width: '47.5%' }}
							muted
							playerRef={footageRef}
							onEnded={onEnded}
						/>
						<Select
							size='small'
							value={footage?.cam}
							onChange={handleChangeFootageCam}
							sx={{
								height: '35px',
								position: 'absolute',
								top: 10,
								right: 10,
								background: 'white',
							}}>
							{cameraEnum.map((c, idx) => (
								<MenuItem key={idx} value={c}>
									{c}
								</MenuItem>
							))}
						</Select>
					</Box>
				)}
				<MessageSnackBar
					openError={openError}
					errorMsg={errorMsg}
					typeMsg={messageDialogTypes.ERROR_MESSAGE}
					handleCloseError={handleCloseError}
				/>
			</>
		)
	}
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>
			<FootagePlayerTemplate data={footage1VideoInfo} idFootage={1} />
			<FootagePlayerTemplate data={footage2VideoInfo} idFootage={2} />
		</Box>
	)
}

export default React.memo(FootageTab)
