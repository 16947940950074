import axios from 'axios'
import * as React from 'react'
import { readCookie, setCookie } from '../utils/cookie'
import { baseUrl, jwtCookieName, jwtRefreshToken, refreshTokenUrl } from '../constants'

export const ApiClientContext = React.createContext({})

export const getApiClient = (token) => {
	const api = axios.create({
		headers: {
			Authorization: 'Bearer ' + token,
		},
	})
	// We get the token from the cookie everytime we start a request
	api.interceptors.request.use(
		(config) => {
			const token = readCookie(jwtCookieName)
			if (token) {
				config.headers.Authorization = `Bearer ${token}`
			}
			return config
		},
		(error) => Promise.reject(error)
	)

	// In case of a 401 response we get the accessToken and the refreshToken and send
	// a request to the refreshToken service, if we receive both tokens, we resend the previous request
	api.interceptors.response.use(
		(response) => response,
		async (error) => {
			const originalRequest = error.config
			console.log('intercepto error', error.response)
			if (error.response.status === 401 && !originalRequest._retry) {
				originalRequest._retry = true

				try {
					const refreshToken = readCookie(jwtRefreshToken)
					const accessToken = readCookie(jwtCookieName)
					const body = { refreshToken: refreshToken, accessToken: accessToken }
					const response = await axios.post(baseUrl + refreshTokenUrl, body)
					if (response.data.success) {
						const { token: newToken, refreshToken: newRefreshToken } = response.data.data

						setCookie(jwtRefreshToken, newRefreshToken, null)
						setCookie(jwtCookieName, newToken, null)

						// Retry the original request with the new token
						originalRequest.headers.Authorization = `Bearer ${newToken}`
						return axios(originalRequest)
					} else {
						console.log(response.data.errorMsg)
					}
				} catch (error) {
					// Handle refresh token error or redirect to login
				}
			}

			return Promise.reject(error)
		}
	)
	return api
}
