import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProjectFilterRequest = () => {
	const { client } = useContext(ApiClientContext)
	const [projectFilter, setProjectFilter] = useState(null)
	const [errorProjectFilter, setErrorProjectFilter] = useState(null)
	const [isLoadingProjectFilter, setIsLoadingProjectFilter] = useState(false)

	const projectFilterRequest = useCallback(() => {
		setIsLoadingProjectFilter(true)
		setProjectFilter(null)
		setErrorProjectFilter(null)

		client
			.get(baseUrl + 'horus/f3/project/filter')
			.then((result) => {
				setIsLoadingProjectFilter(false)

				if (result.data.success) {
					setProjectFilter(result.data.data)
				} else {
					setErrorProjectFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingProjectFilter(false)
				setErrorProjectFilter(err.response)
			})
	}, [client])
	return {
		projectFilterRequest,
		isLoadingProjectFilter,
		projectFilter,
		errorProjectFilter,
	}
}
