import { useCallback, useContext, useState } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProjectDeclarationRequest = () => {
	const [projectDeclarationData, setProjectDeclarationData] = useState(null)
	const [errorProjectDeclaration, setErrorProjectDeclaration] = useState(null)
	const [isLoadingProjectDeclaration, setIsLoadingProjectDeclaration] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectDeclarationRequest = useCallback(
		(id) => {
			const config = {
				params: {
					projectId: id,
				},
			}

			client
				.get(baseUrl + 'horus/f3/project/inform/declarations', config)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setProjectDeclarationData(result.data.data)
					} else {
						setErrorProjectDeclaration({ statusText: result.data.errorMsg })
					}
					setIsLoadingProjectDeclaration(false)
				})
				.catch((error) => {
					setErrorProjectDeclaration(error.response)
					setIsLoadingProjectDeclaration(false)
				})

			setProjectDeclarationData(null)
			setErrorProjectDeclaration(null)
			setIsLoadingProjectDeclaration(true)
		},
		[client]
	)

	return {
		projectDeclarationRequest,
		isLoadingProjectDeclaration,
		projectDeclarationData,
		errorProjectDeclaration,
	}
}
