import FilterAltIcon from '@mui/icons-material/FilterAlt'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'
import { Box, Collapse, IconButton } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { datetimeGMTToLocaleInISOFormat } from '../../../comun/DateUtils'

import { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import AnnotationFilter from './AnnotationFilter'
import AnnotationRow from './AnnotationRow'
import { getDeclarationIconAux } from '../../../comun/NotesIconHelper'
import { useProjectItemDelete } from '../../../../services/projectitem/useProjectItemDelete'
import { useEffect } from 'react'
import ConfirmationDialog from '../../../comun/ConfirmationDialog'
import CustomSnackBar from '../../../comun/CustomSnackBar'
import BackdropComponent from '../../../comun/utils/BackdropComponent'
import MessageSnackBar from '../../../comun/MessageSnackBar'

const pageSize = 20

export default function AnnotationTab({ annotationsInfo, fishingProfile, cameraEnum, updateCurrentTime }) {
	const [t] = useTranslation('common')
	const scrollRef = useRef(null)
	const parentRef = useRef(null)
	const [allAnnotations, setAllAnnotations] = useState(annotationsInfo)
	const [annotations, setAnnotations] = useState(annotationsInfo.slice(0, pageSize))
	const [hasMore, setHasMore] = useState(true)
	const [openDelete, setOpenDelete] = useState(false)
	const [showFilter, setShowFilter] = useState(false)
	const [annotationToDelete, setAnnotationToDelete] = useState(null)
	const [openSnack, setOpenSnack] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const { projectItemDelete, dataProjectItemDelete, errorProjectItemDelete, isLoadingProjectItemDelete } =
		useProjectItemDelete()

	const handleShowMore = () => {
		if (annotations.length >= allAnnotations.length - 1) {
			setHasMore(false)
		} else {
			setAnnotations((prevState) => [
				...prevState,
				...allAnnotations.slice(prevState.length + 1, annotations.length + pageSize + 1),
			])
		}
	}

	useEffect(() => {
		if (annotationsInfo) {
			setAllAnnotations(annotationsInfo)
		}
	}, [annotationsInfo])

	useEffect(() => {
		if (errorProjectItemDelete) {
			setOpenError(true)
			setErrorMsg(errorProjectItemDelete)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorProjectItemDelete])

	useEffect(() => {
		if (allAnnotations) {
			setAnnotations(allAnnotations.slice(0, pageSize))
		}
	}, [allAnnotations])

	useEffect(() => {
		if (dataProjectItemDelete) {
			let aux = [...allAnnotations]
			let idx = aux.findIndex((a) => a.projectItemId == dataProjectItemDelete)
			aux.splice(idx, 1)
			setAllAnnotations(aux)
			setOpenSnack(true)
		}
	}, [dataProjectItemDelete])

	const handleShowFilter = () => {
		setShowFilter(!showFilter)
	}

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const onFilter = (startDate, endDate, hasComments, itemType, camera) => {
		startDate = datetimeGMTToLocaleInISOFormat(startDate)
		endDate = datetimeGMTToLocaleInISOFormat(endDate)

		let filtered = annotationsInfo.filter((n) => {
			let result = true
			if (startDate != null && endDate != null) {
				result = startDate < n.date && endDate > n.date
			}
			if (startDate != null && endDate == null) {
				result = startDate < n.date
			}
			if (startDate == null && endDate != null) {
				result = endDate > n.date
			}
			if (hasComments != null && hasComments != '') {
				//TODO Búsqueda de comentarios o no comentarios
			}
			if (itemType != null) {
				result = n.itemType == itemType.itemType
			}
			if (camera != null && camera != '') {
				result = n.camNo == camera
			}
			return result
		})
		if (scrollRef.current) {
			scrollRef.current._infScroll.scrollTop = 0
		}
		setAllAnnotations(filtered)
		setAnnotations(filtered.slice(0, pageSize))
		setHasMore(true)
	}

	const handleCloseSnack = () => {
		setOpenSnack(false)
	}

	const handleDeleteAnnotation = (annotation) => {
		setAnnotationToDelete(annotation)
		setOpenDelete(true)
	}

	const onCloseDelete = (confirmed) => {
		setOpenDelete(false)
		if (confirmed) {
			//TODO Coger el listado de gráfica y borrar los elementos que correspondan
			projectItemDelete(annotationToDelete.projectItemId)
		}
	}

	const handleGoToMoment = (e, annotation) => {
		e.stopPropagation()
		updateCurrentTime(annotation.itemDateTime)
	}

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '99.7%',
				width: '100%',
				boxShadow:
					'0px 1px 2px 1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
			}}>
			<BackdropComponent open={isLoadingProjectItemDelete} />
			<MessageSnackBar openError={openError} errorMsg={errorMsg} handleCloseError={handleCloseError} />
			<CustomSnackBar
				message={t('preview.annotation.deleted')}
				open={openSnack}
				autoHideDuration={2000}
				handleClose={handleCloseSnack}
			/>
			<ConfirmationDialog
				itemToDelete={annotationToDelete}
				onOpen={openDelete}
				isDelete
				onClose={onCloseDelete}
				dialogTitle={t('preview.annotation.delete-title')}
				dialogContent={t('preview.annotation.delete-body')}
				dialogSaveButton={t('comun.actions.delete')}
				dialogCancelButton={t('comun.actions.close')}
			/>
			{/* Filter row */}
			<Collapse in={showFilter} timeout='auto' sx={{ width: '100%', p: 0, minHeight: 'auto !important' }}>
				<AnnotationFilter
					t={t}
					onFilter={onFilter}
					itemTypeList={fishingProfile?.itemTypeList}
					cameraEnum={cameraEnum}
				/>
			</Collapse>
			{/* Table */}
			<TableContainer sx={{ height: showFilter ? '79%' : '99%' }} ref={parentRef}>
				<InfiniteScroll
					style={{ width: '100%', minWidth: '100%' }}
					hasMore={hasMore}
					ref={scrollRef}
					dataLength={annotations.length}
					next={handleShowMore}
					scrollThreshold={0.95}
					// loader={<h4 style={{ marginLeft: '40%' }}>Loading...</h4>}
					height={showFilter ? 330 : 420}>
					<Table sx={{ width: '100%' }} size='small' stickyHeader>
						<TableHead sx={{ height: '4vh' }}>
							<TableRow>
								<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
									{t('preview.annotation.date')}
								</TableCell>
								<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
									{t('preview.annotation.camera')}
								</TableCell>
								<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
									{t('preview.annotation.type')}
								</TableCell>
								<TableCell sx={{ backgroundColor: '#1976d2', color: 'white' }} align='center'>
									{/* <Tooltip
									placement='top'
									title={
										showFilter
											? t('preview.notification.hide-filter')
											: t('preview.notification.show-filter')
									}> */}
									<IconButton
										size='small'
										sx={{ height: '20px' }}
										color='inherit'
										onClick={handleShowFilter}>
										{showFilter ? <FilterAltOffIcon /> : <FilterAltIcon />}
									</IconButton>
									{/* </Tooltip> */}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody sx={{ width: '100%' }}>
							{annotations.map((annotation) => {
								let icon = getDeclarationIconAux(annotation.itemType, false)
								return (
									<AnnotationRow
										annotation={annotation}
										key={annotation.projectItemId}
										fishingProfile={fishingProfile}
										handleDeleteAnnotation={handleDeleteAnnotation}
										handleGoToMoment={handleGoToMoment}
										icon={icon}
									/>
								)
							})}
						</TableBody>
					</Table>
				</InfiniteScroll>
			</TableContainer>
		</Box>
	)
}
