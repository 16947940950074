import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { messageDialogTypes } from '../../constants'
import { usePreview } from '../../services/preview/usePreview'
import { useUpdatePreview } from '../../services/preview/useUpdatePreview'
import { useUpdateTimeRange } from '../../services/preview/useUpdateTimeRange'
import ChartContainer from '../chart/ChartContainer'
import { datetimeGMTToLocaleInISOFormat } from '../comun/DateUtils'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import useStore from './PreviewStore'
import Controls from './controls/Controls'
import MainPanel from './main_panel/MainPanel'
import SecondaryPanel from './secondary_panel/SecondaryPanel'
import TimeControl from './bottom_panel/TimeControl'
import VideosTimeline from './bottom_panel/VideosTimeline'
import TimeRangeDisplay from './bottom_panel/TimeRangeDisplay'
import { useUpdateCurrentTime } from '../../services/preview/useUpdateCurrentTime'
import { useShallow } from 'zustand/shallow'

function NewPreviewContainer({ vesselApiId, vessel, project, projectId, jumpTime }) {
	//Errors control
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [visualizationData, setVisualizationData] = useState(null)
	const [zoomedIn, setZoomedIn] = useState(false)
	const { setVessel, setSpeed, setCurrentTime, setProject, getCurrentTime } = useStore(
		useShallow((state) => ({
			setVessel: state.setVessel,
			setCurrentTime: state.setCurrentTime,
			setSpeed: state.setSpeed,
			setProject: state.setProject,
			getCurrentTime: state.getCurrentTime,
		}))
	)

	const { preview, dataPreview, errorPreview, isLoadingPreview, setDataPreview } = usePreview()

	const {
		updatePreview,
		visualizationData: newVisualizationData,
		errorUpdatePreview,
		isLoadingUpdatePreview,
	} = useUpdatePreview(vesselApiId, projectId)
	const { updateTimeRange, dataUpdateTimeRange, errorUpdateTimeRange, isLoadingUpdateTimeRange } = useUpdateTimeRange(
		vesselApiId,
		projectId
	)
	const { updateTimeData, errorUpdateTime, isLoadingUpdateTime, updateCurrentTime } = useUpdateCurrentTime(
		vesselApiId,
		projectId
	)

	useEffect(() => {
		if (dataPreview && dataPreview.visualizationData) {
			setVisualizationData(dataPreview.visualizationData)
			setZoomedIn(dataPreview.controlsData.isZooming)
			setSpeed(dataPreview.controlsData.playSpeed)
			setCurrentTime(dataPreview.timeData.currentVisualizationTime)
		} else {
			if (dataPreview) {
				alert('Error en el servicio de actualización, se recarga la página')
			}
			if (vesselApiId) {
				preview('horus/f3/dashboard/preview?vesselApiId=', vesselApiId, jumpTime)
			} else if (projectId) {
				preview('horus/f3/project/preview?projectId=', projectId, jumpTime)
			}
		}
	}, [dataPreview])

	useEffect(() => {
		if (dataUpdateTimeRange) {
			setDataPreview(dataUpdateTimeRange)
		}
	}, [dataUpdateTimeRange])

	useEffect(() => {
		if (updateTimeData) {
			setDataPreview(updateTimeData)
		}
	}, [updateTimeData])

	useEffect(() => {
		if (newVisualizationData) {
			if (Object.hasOwn(newVisualizationData, 'controlsData')) {
				setDataPreview(newVisualizationData)
			} else {
				setVisualizationData(newVisualizationData)
			}
		}
	}, [newVisualizationData])

	useEffect(() => {
		if (vessel) {
			setProject(null)
			setVessel(vessel)
		} else if (project) {
			setVessel(null)
			setProject(project)
		}
		return () => {
			updateCurrentTime(datetimeGMTToLocaleInISOFormat(getCurrentTime()))
		}
	}, [])

	const handleZoomChanged = () => {
		setZoomedIn(!zoomedIn)
	}

	useEffect(() => {
		if (errorPreview) {
			setOpenError(true)
			setErrorMsg(errorPreview)
		} else if (errorUpdatePreview) {
			setOpenError(true)
			setErrorMsg(errorUpdatePreview)
		} else if (errorUpdateTimeRange) {
			setOpenError(true)
			setErrorMsg(errorUpdateTimeRange)
		} else if (errorUpdateTime) {
			setOpenError(true)
			setErrorMsg(errorUpdateTime)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPreview, errorUpdatePreview, errorUpdateTimeRange, errorUpdateTime])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<>
			{dataPreview && dataPreview.visualizationData ? (
				<Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
					{/* Main and secondary panel */}
					<Box sx={{ display: 'flex', height: '53vh', width: '99.5%' }}>
						<MainPanel
							dataPreview={dataPreview}
							updatePreview={updatePreview}
							zoomedIn={zoomedIn}
							projectId={projectId}
							vesselApiId={vesselApiId}
						/>
						{visualizationData && (
							<SecondaryPanel
								visualizationData={visualizationData}
								updatePreview={updatePreview}
								dataPreview={dataPreview}
								vesselApiId={vesselApiId}
								projectId={projectId}
								updateCurrentTime={updateCurrentTime}
							/>
						)}
					</Box>
					{/* Video and declaration controls */}
					<Box sx={{ display: 'flex', height: '4vh', width: '100%', mb: 0.5 }}>
						<Controls
							dataPreview={dataPreview}
							vesselApiId={vesselApiId}
							projectId={projectId}
							updatePreview={updatePreview}
							handleZoomChanged={handleZoomChanged}
							updateTimeRange={updateTimeRange}
						/>
					</Box>
					{/* Chart and time control */}
					<Box sx={{ display: 'flex', flexDirection: 'column', height: '35vh', width: '99.5%' }}>
						<TimeRangeDisplay
							currentRangeEnd={dataPreview.timeData?.currentRangeEnd}
							currentRangeStart={dataPreview.timeData?.currentRangeStart}
						/>
						<ChartContainer
							vesselApiId={vesselApiId}
							projectId={projectId}
							dataPreview={dataPreview}
							updateCurrentTime={updateCurrentTime}
						/>

						{dataPreview?.graphicData?.infoDownloadVideos && (
							<VideosTimeline
								timeData={dataPreview?.timeData}
								infoDownloadVideos={dataPreview?.graphicData?.infoDownloadVideos}
							/>
						)}
						<TimeControl
							updateTimeRange={updateTimeRange}
							vesselApiId={vesselApiId}
							timeData={dataPreview.timeData}
							isLiveViewSelected={dataPreview.visualizationData?.isLiveViewSelected}
						/>
					</Box>
				</Box>
			) : (
				<BackdropComponent open={isLoadingPreview || isLoadingUpdateTimeRange || isLoadingUpdateTime} />
			)}
			<BackdropComponent
				open={isLoadingPreview || isLoadingUpdateTimeRange || isLoadingUpdateTime || isLoadingUpdatePreview}
			/>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
				handleCloseError={handleCloseError}
			/>
		</>
	)
}

export default React.memo(NewPreviewContainer)
