import React from 'react'
import { useTranslation } from 'react-i18next'
import '../../index.css'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

export default function DownloadsTable({ downloadList }) {
	const [t] = useTranslation('common')


	const columns = [
		{
			field: 'vesselName',
			width: 200,
			// headerName: t('downloads.vessel'),
			headerName: <span sx={{ fontWeight: 'bold' }}>{t('downloads.vessel')}</span>,
		},
		{
			field: 'vesselApiId',
			headerName: t('downloads.id'),
		},
		{
			field: 'macAddr',
			width: 160,
			headerName: t('downloads.mac'),
		},
		{
			field: 'from',
			width: 200,
			headerName: t('downloads.from'),
			valueGetter: (params) => {
				const date = new Date(params);
				return date.toLocaleString();
			},
		},
		{
			field: 'to',
			width: 200,
			headerName: t('downloads.to'),
			valueGetter: (params) => {
				const date = new Date(params);
				return date.toLocaleString();
			},
		},
		{
			field: 'cam',
			headerName: t('downloads.cam'),
		},
		{
			field: 'sentDateSentBySeaTube',
			width: 200,
			headerName: t('downloads.date'),
			valueGetter: (params) => {
				const date = new Date(params);
				return params === "0001-01-01T00:00:00" ? "-" : date.toLocaleString();
			},
		},		  
		{
			field: 'vcReqId',
			headerName: t('downloads.req'),
		},
		{
			field: 'sentFiles',
			headerName: t('downloads.sentfiles'),
		},
		{
			field: 'transcodedFiles',
			headerName: t('downloads.transfiles'),
		},
		{
			field: 'updated',
			headerName: t('downloads.updated'),
		}
	]

	return (
		
			downloadList ? (
				<DataGrid
                    className='m-3'
                    autoHeight
                    disableDensitySelector
                    rows={downloadList.data}
                    columns={columns}
					slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                      csvOptions: { disableToolbarButton: true },
                      printOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 250 },
                      },
                    }}
                    getRowId={(row) => row.vesselName + Math.random()}
                    disableRowSelectionOnClick 
                    // getRowClassName={getRowClassName}
					getRowClassName={(params) =>
						params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
					}
                  />
			) : 
			(<>...</>)
	)
}
