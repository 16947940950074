import React from 'react'
import { MapContainer, Polygon, TileLayer } from 'react-leaflet'
import BasicDialog from '../../comun/BasicDialog'
import './AlertZonesMapPopup.css'

export default function AlertZonesMapPopup({ zone, open, onClose }) {
	return (
		<BasicDialog title={zone?.name} onOpen={open} onClose={onClose} noactions>
			<MapContainer
				center={[zone?.polygons[0].coordinates[0].lat, zone?.polygons[0].coordinates[0].lon]}
				zoom={2}
				scrollWheelZoom={true}
				minZoom={2}
				attributionControl={false}
				id='alert-zone-popup'>
				<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='' />

				{zone?.polygons.map((zonep) => (
					<Polygon key={zonep} positions={zonep.coordinates}></Polygon>
				))}
			</MapContainer>
		</BasicDialog>
	)
}
