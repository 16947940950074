import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUpdateCurrentTime = (vesselApiId, projectId) => {
	const [updateTimeData, setUpdateTimeData] = useState(null)
	const [errorUpdateTime, setErrorUpdateTime] = useState(null)
	const [isLoadingUpdateTime, setIsLoadingUpdateTime] = useState(false)
	const { client } = React.useContext(ApiClientContext)
	const computedUrl = vesselApiId
		? 'horus/f3/dashboard/preview/time/current?vesselApiId=' + vesselApiId
		: 'horus/f3/project/preview/time/current?projectId=' + projectId

	const updateCurrentTime = useCallback(
		(currentTime) => {
			client
				.put(baseUrl + computedUrl + '&currentvisualtime=' + currentTime)
				.then((result) => {
					setIsLoadingUpdateTime(false)
					if (result.data.success) {
						setUpdateTimeData(result.data)
					} else {
						setErrorUpdateTime({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setErrorUpdateTime(err.response)
					setIsLoadingUpdateTime(false)
				})
			setUpdateTimeData(null)
			setIsLoadingUpdateTime(true)
			setErrorUpdateTime(null)
		},
		[client]
	)
	return {
		updateTimeData,
		errorUpdateTime,
		isLoadingUpdateTime,
		updateCurrentTime,
	}
}
