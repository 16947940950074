import React, { useEffect, useRef, useState } from 'react'
import { trackTransforms } from '../measurement/CanvasFunctions'

var dragStart, dragged, lastX, lastY

export default function CountCanvas({ image, rowSelected, dataSelected, setDataSelected }) {
	const canvas = useRef(null)
	const [updated, setUpdated] = useState(false)
	const [ctx, setCtx] = useState(null)

	useEffect(() => {
		if (updated) {
			redraw()
			setUpdated(false)
		}
	}, [updated])

	useEffect(() => {
		setUpdated(true)
	}, [dataSelected])

	useEffect(() => {
		setTimeout(() => {
			handleZoom(-1, 853, 355)
		}, 1000)
	}, [])

	useEffect(() => {
		if (image.complete) {
			// La imagen ya está cargada, dibuja en el canvas
			redraw()
		} else {
			// Escucha el evento onload de la imagen para dibujar en el canvas una vez que esté cargada
			image.onload = () => {
				redraw()
			}
		}
	}, [image, ctx])

	useEffect(() => {
		if (canvas.current) {
			canvas.current.width = canvas.current.offsetWidth
			canvas.current.height = canvas.current.offsetHeight
			lastX = canvas.current.width / 2
			lastY = canvas.current.height / 2
			if (ctx == null) {
				let ctxAux = canvas.current.getContext('2d')
				trackTransforms(ctxAux)
				setCtx(ctxAux)
				setUpdated(true)
			}
		}
	}, [canvas?.current])

	const handleMouseDown = (evt) => {
		lastX = evt.offsetX || evt.pageX - canvas.current.offsetLeft
		lastY = evt.offsetY || evt.pageY - canvas.current.offsetTop
		dragStart = ctx.transformedPoint(lastX, lastY)
		dragged = false
	}

	const handleMouseMove = (evt) => {
		lastX = evt.offsetX || evt.pageX - canvas.current.offsetLeft
		lastY = evt.offsetY || evt.pageY - canvas.current.offsetTop
		dragged = true
		if (dragStart) {
			var pt = ctx.transformedPoint(lastX, lastY)
			ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y)
			setUpdated(true)
		}
	}

	const handleScroll = (evt) => {
		//evt.preventDefault()
		var delta = evt.nativeEvent.wheelDelta ? evt.nativeEvent.wheelDelta / 40 : evt.detail ? -evt.detail : 0
		if (delta && delta != 0) handleZoom(delta)

		return false
	}

	const handleZoom = (clicks, auxX, auxY) => {
		if (ctx == null) return
		var scaleFactor = 1.05
		var pt
		if (auxX && auxY) {
			pt = ctx.transformedPoint(auxX, auxY)
		} else {
			pt = ctx.transformedPoint(lastX, lastY)
		}
		ctx.translate(pt.x, pt.y)
		var factor = Math.pow(scaleFactor, clicks)
		ctx.scale(factor, factor)
		ctx.translate(-pt.x, -pt.y)
		setUpdated(true)
	}

	const handleMouseUp = (evt) => {
		dragStart = null
		if (!dragged) {
			handleSetPoint(evt)
		}
	}

	const handleSetPoint = (evt) => {
		if (rowSelected) {
			var x = evt.pageX - canvas.current.offsetLeft
			var y = evt.pageY - canvas.current.offsetTop
			let point = ctx.transformedPoint(x, y)
			let auxDots = [...rowSelected.dots]
			auxDots.push(point)
			let auxDataSelected = [...dataSelected]
			let idx = auxDataSelected.findIndex((d) => d.description == rowSelected.description)
			auxDataSelected[idx].dots = auxDots
			auxDataSelected[idx].count = auxDots.length
			setDataSelected(auxDataSelected)
		}
	}
	const redraw = () => {
		redrawImage()
		redrawAllRows()
	}

	const redrawImage = () => {
		if (ctx == null) return
		ctx.save()
		ctx.setTransform(1, 0, 0, 1, 0, 0)
		ctx.clearRect(0, 0, canvas.current.width, canvas.current.height)
		ctx.restore()
		ctx.drawImage(image, 0, 0)
	}

	const redrawAllRows = () => {
		if (ctx == null) return
		dataSelected?.forEach((row) => {
			row.dots.forEach((dot) => {
				drawPoint(dot, row.color)
			})
		})
	}

	const drawPoint = (aux, color) => {
		//Border black
		ctx.fillStyle = '#000000'
		ctx.beginPath()
		ctx.arc(aux.x, aux.y, 4, 0, Math.PI * 2)
		ctx.fill()
		//Actual point with its color
		ctx.fillStyle = color
		ctx.beginPath()
		ctx.arc(aux.x, aux.y, 3, 0, Math.PI * 2)
		ctx.fill()
	}
	return (
		<canvas
			id='canvas'
			ref={canvas}
			style={{
				width: '100%',
				height: '100%',
				cursor: 'crosshair',
				border: '1px solid black',
				backgroundColor: '#00000054',
			}}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onWheel={handleScroll}
			onMouseMove={handleMouseMove}></canvas>
	)
}
