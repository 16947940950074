import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useInformChangePreview = (vesselApiId, projectId) => {
	const [errorInformChange, setErrorInformChange] = useState(null)
	const { client } = React.useContext(ApiClientContext)
	const computedUrl = vesselApiId ? 'horus/f3/dashboard/preview/control/' : 'horus/f3/project/preview/control/'
	const computedArgument = vesselApiId ? '?vesselApiId=' + vesselApiId : '?projectId=' + projectId
	const informChange = useCallback(
		(url, speed) => {
			client
				.put(baseUrl + computedUrl + url + computedArgument + (speed ? '&speed=' + speed : ''))
				.then((result) => {
					if (!result.data.success) {
						setErrorInformChange({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setErrorInformChange(err.response)
				})
			setErrorInformChange(null)
		},
		[client]
	)
	return {
		informChange,
		errorInformChange,
		setErrorInformChange,
	}
}
