import { Box } from '@mui/material'
import React from 'react'
import ComplexAlertForm from './ComplexAlertForm'
import AutoCompleteComponent from '../../comun/form_fields/AutoCompleteComponent'

export default function AlertConfigFormOptions({ typeSelected, methodsForm, t, zones, sensors, alertConfig }) {
	const {
		control,
		formState: { errors },
	} = methodsForm

	switch (typeSelected?.typeId) {
		case '0': {
			//Alert type entry
			return (
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
					<AutoCompleteComponent
						size='small'
						sx={{ width: '100%' }}
						control={control}
						name='zoneId'
						label={t('alert.config.form.zone')}
						defaultValue={null}
						optionsLabel={'description'}
						keyProperty={'id'}
						options={zones}
						errors={errors?.zoneId}
						rules={{
							required: {
								value: true,
								message: t('comun.form-errors.required'),
							},
						}}
					/>
				</Box>
			)
		}
		case '2': {
			//Alert type exit
			return (
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', mb: 1 }}>
					<AutoCompleteComponent
						size='small'
						sx={{ width: '100%' }}
						control={control}
						name='zoneId'
						label={t('alert.config.form.zone')}
						defaultValue={null}
						optionsLabel={'description'}
						keyProperty={'id'}
						options={zones}
						errors={errors?.zoneId}
						rules={{
							required: {
								value: true,
								message: t('comun.form-errors.required'),
							},
						}}
					/>
				</Box>
			)
		}
		case '1': {
			//Alert type complex
			return (
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%' }}>
					<ComplexAlertForm
						methodsForm={methodsForm}
						zones={zones}
						sensors={sensors}
						alertConfig={alertConfig}></ComplexAlertForm>
				</Box>
			)
		}

		default:
			return null
	}
}
