import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useCalibrationInsert = () => {
	const [calibrationDataInsert, setCalibrationDataInsert] = useState(null)
	const [errorCalibrationInsert, setErrorCalibrationInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const calibrationInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/vessel/camera/calibrations', props)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setCalibrationDataInsert(result.data.data)
					} else {
						setErrorCalibrationInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorCalibrationInsert(error.response)
					setIsLoadingInsert(false)
				})

			setCalibrationDataInsert(null)
			setErrorCalibrationInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		calibrationInsert,
		isLoadingInsert,
		calibrationDataInsert,
		errorCalibrationInsert,
		setCalibrationDataInsert,
	}
}
