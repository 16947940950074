import * as React from 'react'
import DialogContent from '@mui/material/DialogContent'
import { Button, DialogContentText } from '@mui/material'
import '../../index.css'
import { CustomDialog, Transition } from './StyleComponentUtils'
import Draggable from 'react-draggable'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import logoImg from '../../assets/horus-azul.png'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

export default function CookieDialog({  onOpen, onSave, onLink , changeLanguage, language}) {
	const [t] = useTranslation('common')
	return (
		<CustomDialog
			open={onOpen}
			aria-describedby='alert-dialog-description'
			maxWidth='md'
			TransitionComponent={Transition}
			PaperComponent={PaperComponent}>
			<Box >
				<DialogContent >
					<DialogContentText
						id='alert-dialog-description'
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							
						}}>
						<Box component='div' >
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', my: 1.5 }}>
									<img  src={logoImg} />
									
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', my: 1.5 }}>
									<Typography variant='body1' sx={{width: '80%'}}>{t('cookie-policy.message')} <a onClick={onLink} ><u><b>{t('cookie-policy.link-message')}</b></u></a></Typography>
									<TextField
										select
										onChange={changeLanguage}
										value={language}>
										<MenuItem value={'en'}>En</MenuItem>
										<MenuItem value={'es'}>Es</MenuItem>
									</TextField>
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', my: 1.5 }}>
								<Button onClick={onSave} variant='outlined'>
									{t('cookie-policy.accepts-button')}
								</Button>
								</Box>
							</Box>
						</Box>
					</DialogContentText>
				</DialogContent>
			</Box>
		</CustomDialog>
	)
}

function PaperComponent(props) {
	return (
		<Draggable handle='#alert-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	)
}
