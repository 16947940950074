import { useCallback, useState } from 'react'
import { useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useSendMessage = () => {
	const [dataSend, setDataSend] = useState(null)
	const [errorSend, setErrorSend] = useState(null)
	const [isLoadingSend, setIsLoadingSend] = useState(false)
	const { client } = useContext(ApiClientContext)

	const sendMessage = useCallback(
		(vesselApiId, message) => {
			let params = '?vesselApiId=' + vesselApiId + '&message=' + message
			client
				.get(baseUrl + 'horus/f3/chat/send' + params)
				.then((result) => {
					setIsLoadingSend(false)
					if (result.data.success) {
						setDataSend(result.data.data)
					} else {
						setErrorSend({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingSend(false)
					setErrorSend(err.response)
				})
			setDataSend(null)
			setErrorSend(null)
			setIsLoadingSend(true)
		},
		[client]
	)
	return {
		sendMessage,
		isLoadingSend,
		dataSend,
		errorSend,
		setErrorSend,
	}
}
