import { useTranslation } from 'react-i18next'

export function datetimeUTCtoLocale(dateUTC) {
	var result
	if (dateUTC) {
		var date = new Date(dateUTC)
		var offset = date.getTimezoneOffset()
		date.setMinutes(date.getMinutes() - offset)
		result = date
	} else {
		result = null
	}
	return result
}

export function datetime2Str(date) {
	if (!date) {
		return ''
	}
	const mes = ('' + (date.getMonth() + 1)).padStart(2, '0')
	const dia = ('' + date.getDate()).padStart(2, '0')
	const hora = ('' + date.getHours()).padStart(2, '0')
	const minuto = ('' + date.getMinutes()).padStart(2, '0')
	const segundos = ('' + date.getSeconds()).padStart(2, '0')
	const dateStr = `${dia}/${mes}/${date.getFullYear()} ${hora}:${minuto}:${segundos}`

	return dateStr
}
export function datetime2String2Lines(date) {
	if (!date) {
		return ''
	}
	const mes = ('' + (date.getMonth() + 1)).padStart(2, '0')
	const dia = ('' + date.getDate()).padStart(2, '0')
	const hora = ('' + date.getHours()).padStart(2, '0')
	const minuto = ('' + date.getMinutes()).padStart(2, '0')
	const segundos = ('' + date.getSeconds()).padStart(2, '0')
	const dateStr = `${dia}/${mes}/${date.getFullYear()}  ${hora}:${minuto}:${segundos}`

	return dateStr
}

export function datetimeToStringMinutes(date) {
	if (!date) {
		return ''
	}
	const mes = ('' + (date.getMonth() + 1)).padStart(2, '0')
	const dia = ('' + date.getDate()).padStart(2, '0')
	const hora = ('' + date.getHours()).padStart(2, '0')
	const minuto = ('' + date.getMinutes()).padStart(2, '0')
	const dateStr = `${dia}/${mes}/${date.getFullYear()} ${hora}:${minuto}`

	return dateStr
}

export function datetimeUTCtoString(dateUTC, milis, minutes, twoLines) {
	var result
	if (dateUTC) {
		var date = new Date(dateUTC)
		result = date
	} else {
		result = null
	}
	if (milis) {
		return datetime2StrWithMilis(result)
	} else if (minutes) {
		return datetimeToStringMinutes(result)
	} else if (twoLines) {
		return datetime2String2Lines(result)
	} else {
		return datetime2Str(result)
	}
}

export function datetimeGMTToLocaleInISOFormat(date) {
	var localISOTime = null
	if (date != null) {
		var newDate = new Date(date)

		var tzoffset = newDate.getTimezoneOffset() * 60000 //offset in milliseconds
		localISOTime = new Date(newDate - tzoffset).toISOString().slice(0, -1)
	}

	return localISOTime
}

export function dateSubstractDays(date, days) {
	var result
	if (date) {
		result = new Date(date)
		result.setDate(result.getDate() - days)
	} else result = null

	return result
}

export function daysDifference(startDate, endDate) {
	let timeDiff = endDate.getTime() - startDate.getTime()
	let daysDiff = timeDiff / (1000 * 3600 * 24)

	return daysDiff
}

export function addMinutes(date, minutes) {
	return new Date(date.getTime() + minutes * 60000)
}

export function addSeconds(date, seconds) {
	let aux = new Date(date)
	return new Date(aux.getTime() + seconds * 1000)
}

export function dateDiffInSeconds(first, last) {
	let firstDate = new Date(first)
	let lastDate = new Date(last)
	let diff = firstDate.getTime() - lastDate.getTime()
	let diffInSeconds = diff / 1000
	return Math.abs(diffInSeconds)
}
export function dateDiffInSecondsAux(first, last) {
	let firstDate = new Date(first)
	let lastDate = new Date(last)
	let diff = firstDate - lastDate
	let diffInSeconds = diff / 1000
	return diffInSeconds
}

export function datetime2StrWithMilis(date) {
	if (!date) {
		return ''
	}
	const mes = ('' + (date.getMonth() + 1)).padStart(2, '0')
	const dia = ('' + date.getDate()).padStart(2, '0')
	const hora = ('' + date.getHours()).padStart(2, '0')
	const minuto = ('' + date.getMinutes()).padStart(2, '0')
	const segundos = ('' + date.getSeconds()).padStart(2, '0')
	const milis = ('' + date.getMilliseconds()).padStart(3, '0')
	const dateStr = `${dia}/${mes}/${date.getFullYear()} ${hora}:${minuto}:${segundos}.${milis}`

	return dateStr
}

export function isStartDateBefore(startDateValue, endDateValue) {
	if (startDateValue && endDateValue) {
		return datetimeGMTToLocaleInISOFormat(endDateValue) >= datetimeGMTToLocaleInISOFormat(startDateValue)
			? true
			: false
	}
	return true
}

export function isBetweenDates(startDateValue, endDateValue, date) {
	if (startDateValue && endDateValue && date) {
		date = datetimeGMTToLocaleInISOFormat(date).slice(0, 16)
		startDateValue = datetimeGMTToLocaleInISOFormat(startDateValue).slice(0, 16)
		endDateValue = datetimeGMTToLocaleInISOFormat(endDateValue).slice(0, 16)

		return startDateValue <= date && date <= endDateValue ? true : false
	}
	return false
}

export function nowDateToUTC() {
	var date = new Date()
	var now_utc = Date.UTC(
		date.getUTCFullYear(),
		date.getUTCMonth(),
		date.getUTCDate(),
		date.getUTCHours(),
		date.getUTCMinutes(),
		date.getUTCSeconds()
	)

	return new Date(now_utc)
}

export function formatTimeTimer(days, hours, minutes, seconds) {
	const [t] = useTranslation('common')
	if (!(days || hours || minutes || seconds)) {
		return '-'
	}
	let date = new Date(0, 0, 0, hours, minutes, seconds)
	return days ? `${days} ${t('timer.time.days')} ${date.toTimeString().slice(0, 8)}` : date.toTimeString().slice(0, 8)
}

export function formatTimeTimerDetails(days, hours, minutes, seconds) {
	const [t] = useTranslation('common')
	if (!(days || hours || minutes || seconds)) {
		return '-'
	}
	const newDays = days ? `${days} ${t('timer.time.days')} ` : ''
	const newHours = hours ? hours + ' h ' : ''
	const newMinutes = minutes ? minutes + ' min ' : ''
	const newSeconds = seconds ? `${seconds} ${t('timer.time.seconds')}` : '0 ' + t('timer.time.seconds')

	return newDays + newHours + newMinutes + newSeconds
}
