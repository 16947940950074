import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../../constants'
import { ApiClientContext } from '../../../ApiClientProvider'

export const usePreviewVideoRequest = () => {
	const [dataVideoRequest, setDataVideoRequest] = useState(null)
	const [isLoadingVideoRequest, setIsLoadingVideoRequest] = useState(false)
	const [errorVideoRequest, setErrorVideoRequest] = useState(null)

	const { client } = useContext(ApiClientContext)

	const videoRequest = useCallback(
		(filter, cams) => {
			const headers = {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			}
			let url = filter.projectId
				? 'horus/f3/project/preview/videorequest?projectId=' + filter.projectId
				: 'horus/f3/dashboard/preview/videorequest?vesselApiId=' + filter.vesselApiId
			url = url + '&from=' + filter.from + '&to=' + filter.to + '&isDone=' + filter.isDone
			url = url + cams.map((c) => '&cam=' + c)
			url = url.replaceAll(',', '')
			client
				.get(baseUrl + url, headers)
				.then((result) => {
					setIsLoadingVideoRequest(false)
					if (result.data.success) {
						setDataVideoRequest(result.data.data)
					} else {
						setErrorVideoRequest({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingVideoRequest(false)
					setErrorVideoRequest(err.response)
				})
			setIsLoadingVideoRequest(true)
			setDataVideoRequest(null)
			setErrorVideoRequest(null)
		},
		[client]
	)

	return {
		videoRequest,
		dataVideoRequest,
		isLoadingVideoRequest,
		errorVideoRequest,
	}
}
