import DeleteIcon from '@mui/icons-material/Delete'
import GradeIcon from '@mui/icons-material/Grade'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Box, Button, FormControl, IconButton, InputBase, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from './ConfirmationDialog'
import { SignalCellularNull } from '@mui/icons-material'

export default function SavedFiltersComponent({ filters, filterSelected, onClick, onDelete, setOpen, favSelected }) {
	const [t] = useTranslation('common')

	const [openDialog, setOpenDialog] = useState(false)
	const [filterToDelete, setFilterToDelete] = useState(null)
	const [openSelect, setOpenSelect] = useState(false)

	const handleChange = (event) => {
		const selectedFilter = filters.find((filter) => filter.filterName === event.target.value)
		onClick(selectedFilter)
	}

	const deleteFilter = (event, filterName) => {
		event.stopPropagation()
		setFilterToDelete(filterName)
		setOpenDialog(true)
	}

	const handleCloseDialog = (success) => {
		if (success) {
			onDelete(null, filterToDelete)
		}
		setOpenDialog(false)
		setFilterToDelete(null)
	}

	const handleOpenSelect = () => {
		setOpenSelect(true)
	}

	const handleCloseSelect = () => {
		setOpenSelect(false)
	}

	return (
		<Box sx={{ display: 'flex', width: '100%' }}>
			<Button onClick={handleOpenSelect} color='primary'>
				<MenuOpenIcon />
			</Button>
			<FormControl variant='outlined' sx={{ width: '0%' }} size='small'>
				<ConfirmationDialog
					onOpen={openDialog}
					dialogTitle={t('comun.filter.confirm-delete-title')}
					dialogContent={t('comun.filter.confirm-delete-content')}
					onClose={handleCloseDialog}
					dialogSaveButton={t('comun.actions.delete')}
					dialogCancelButton={t('comun.actions.cancel')}
					isDelete
				/>
				{/* <InputLabel id='saved-filters'>{t('comun.filter.saved-filters')}</InputLabel> */}

				<Select
					input={<InputBase sx={{ width: '0px', color: 'white' }} />}
					open={openSelect}
					onOpen={handleOpenSelect}
					onClose={handleCloseSelect}
					labelId='saved-filters'
					id='saved-filters'
					renderValue={(value) => ''}
					value={filterSelected ? filterSelected.filterName : ''}
					size='small'
					onChange={handleChange}
					IconComponent={() => null}>
					{filters && filters.length > 0 ? (
						filters.map((filter, index) => (
							<MenuItem
								key={index}
								value={filter.filterName}
								sx={{
									width: '100%',
									minHeight: '52px !important',
									// display: filter.filterName !== filterSelected.filterName ? '' : 'none',
								}}>
								<Typography
									sx={{
										textOverflow: 'ellipsis',
										overflow: 'hidden',
										width: '7vw',
										display: 'block',
										whiteSpace: 'nowrap',
									}}>
									{filter.filterName}
								</Typography>

								{filter.filterName !== filterSelected.filterName && (
									<IconButton color='error' onClick={(e) => deleteFilter(e, filter.filterName)}>
										<DeleteIcon />
									</IconButton>
								)}
							</MenuItem>
						))
					) : (
						<MenuItem key={null} value={''}>
							<Typography>{t('comun.filter.no-filters')}</Typography>
						</MenuItem>
					)}
				</Select>
			</FormControl>
			<Tooltip title={t('comun.actions.save-filter')}>
				<Button onClick={() => setOpen(true)} sx={{ height: '40px', mr: 1, width: '33%' }}>
					{favSelected ? <GradeIcon /> : <StarBorderIcon />}
				</Button>
			</Tooltip>
		</Box>
	)
}
