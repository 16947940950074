import { Box, Divider, FormControlLabel, Checkbox } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from '../../../constants'
import BasicDialog from '../../comun/BasicDialog'
import FieldsetComponent from '../../comun/FieldsetComponent'
import DatePickerComponent from '../../comun/form_fields/DatePickerComponent'
import SelectComponent from '../../comun/form_fields/SelectComponent'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'
import VesselListComponent from '../../comun/VesselListComponent'
import AlertConfigFormOptions from './AlertConfigFormOptions'
import { isStartDateBefore } from '../../comun/DateUtils'

const parseSensors = (row) => {
	return {
		...row,
		sensorId: row.id,
		onOff: row.minValue != null || row.maxValue != null ? null : false,
		minValue: row.minValue != null ? row.minValue : '',
		maxValue: row.maxValue != null ? row.maxValue : '',
	}
}

export default function AlertConfigForm({
	mode,
	onClose,
	open,
	emailInterval,
	vessels,
	zones,
	sensorsArr,
	alertConfig,
	onSave,
}) {
	const [t] = useTranslation('common')

	const alertTypes = [
		{ typeId: '0', name: t('alert.config.form.entry-option') },
		{ typeId: '1', name: t('alert.config.form.complex-option') },
		{ typeId: '2', name: t('alert.config.form.exit-option') },
	]

	const [checked, setChecked] = useState(alertConfig ? alertConfig.isEmailNotificationActive : false)

	const [sensors] = useState(sensorsArr.map((s) => parseSensors(s)))

	const getAlertType = (id) => {
		return alertTypes.find((t) => t.typeId == id) ? alertTypes.find((t) => t.typeId == id) : alertTypes[0]
	}

	const getZone = (zoneId) => {
		return zones?.find((z) => z.id == zoneId)
	}

	const getSendInterval = (minSendInterval) => {
		return emailInterval?.find((s) => s.sendInterval == minSendInterval)
	}

	const methodsForm = useForm({
		mode: 'onSubmit',
		defaultValues: {
			name: alertConfig ? alertConfig.name : '',
			description: alertConfig ? alertConfig.description : '',
			vesselAutocomplete: null,
			vessels: alertConfig?.vessels,
			type: getAlertType(alertConfig?.type),
			sensorAutoComplete: null,
			startDate: alertConfig ? alertConfig.startDate : null,
			endDate: alertConfig ? alertConfig.endDate : null,
			zoneId: alertConfig ? getZone(alertConfig.zoneId) : null,
			minSpeed: alertConfig ? alertConfig.minSpeed : '',
			maxSpeed: alertConfig ? alertConfig.maxSpeed : '',
			speedEnduranceMinutes: alertConfig ? alertConfig.speedEnduranceMinutes : '',
			sensors: alertConfig ? alertConfig.sensors : [],
			isEmailNotificationActive: alertConfig ? alertConfig.isEmailNotificationActive : false,
			email: alertConfig ? alertConfig.email : '',
			emailInterval: alertConfig ? getSendInterval(alertConfig?.minSendInterval) : null,
		},
	})

	const {
		control,
		watch,
		getValues,
		handleSubmit,
		formState: { errors },
	} = methodsForm

	const nameMaxLength = 500
	const descriptionMaxLength = 1000

	const typeSelected = watch('type')

	const getTitle = () => {
		if (modeTypesCRUD.UPDATE_MODE === mode) {
			return t('alert.config.form.title-update')
		} else if (modeTypesCRUD.INSERT_MODE === mode) {
			return t('alert.config.form.title-new')
		}
	}

	const isUpdateMode = () => {
		return modeTypesCRUD.UPDATE_MODE === mode
	}

	return (
		<Box>
			{open ? (
				<Box component='form' onSubmit={handleSubmit((data) => onSave(data, checked))}>
					<BasicDialog
						title={getTitle()}
						onOpen={open}
						saveTxt={t('alert.config.form.save')}
						onClose={onClose}>
						<Box sx={{ minWidth: '1300px', maxHeight: '600px', width: '100%' }}>
							<Box sx={{ display: 'flex', flexDirection: 'row' }}>
								<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
									<TextFieldComponent
										sx={{ width: '100%', m: 1 }}
										name='name'
										label={t('alert.config.form.name')}
										control={control}
										errors={errors?.name}
										size='small'
										rules={{
											required: {
												value: true,
												message: t('comun.form-errors.required'),
											},
											maxLength: {
												value: nameMaxLength,
												message: `${t('comun.form-errors.max-length')} ${nameMaxLength}`,
											},
										}}
									/>
									<TextFieldComponent
										sx={{ width: '100%', m: 1 }}
										name='description'
										multiline
										rows={2}
										label={t('alert.config.form.description')}
										errors={errors?.description}
										control={control}
										rules={{
											required: {
												value: true,
												message: t('comun.form-errors.required'),
											},
											maxLength: {
												value: descriptionMaxLength,
												message: `${t('comun.form-errors.max-length')} ${descriptionMaxLength}`,
											},
										}}
										size='small'
									/>

									{/* we do it to align "vessels" and "sensors" in 1 line in case we have "sensors"*/}
									{/* <>
										{typeSelected?.typeId == "1" ? (
											<Box sx={{ display: 'flex', mb: 5 }}>
												<Box sx={{ width: '50%' }}>
													<DatePickerComponent
														size='small'
														sx={{ width: '100%', m: 1, pr: 1 }}
														control={methodsForm.control}
														name='startDate'
														label={t('programs.filter.start-date')}
														errors={errors?.startDate}
														rules={{
															required: {
																value: true,
																message: t('comun.form-errors.required'),
															},
															validate: (startDateValue) =>
																isStartDateBefore(
																	startDateValue,
																	getValues('endDate')
																) || t('programs.validations.start-date'),
														}}></DatePickerComponent>
												</Box>
												<Box sx={{ width: '50%' }}>
													<DatePickerComponent
														size='small'
														sx={{ width: '100%', m: 1 }}
														control={control}
														name='endDate'
														label={t('programs.filter.end-date')}
														errors={errors?.endDate}
														rules={{
															required: {
																value: true,
																message: t('comun.form-errors.required'),
															},
														}}></DatePickerComponent>
												</Box>
											</Box>
										) : ( */}
									<Box sx={{ display: 'flex' }}>
										<Box sx={{ width: '50%' }}>
											<DatePickerComponent
												size='small'
												sx={{ width: '100%', m: 1, pr: 1 }}
												control={methodsForm.control}
												name='startDate'
												label={t('programs.filter.start-date')}
												errors={errors?.startDate}
												rules={{
													required: {
														value: true,
														message: t('comun.form-errors.required'),
													},
													validate: (startDateValue) =>
														isStartDateBefore(startDateValue, getValues('endDate')) ||
														t('programs.validations.start-date'),
												}}></DatePickerComponent>
										</Box>
										<Box sx={{ width: '50%' }}>
											<DatePickerComponent
												size='small'
												sx={{ width: '100%', m: 1 }}
												control={control}
												name='endDate'
												label={t('programs.filter.end-date')}
												errors={errors?.endDate}
												rules={{
													required: {
														value: true,
														message: t('comun.form-errors.required'),
													},
												}}></DatePickerComponent>
										</Box>
									</Box>
									{/* )}
									</> */}
									<Box sx={{ width: '100%' }}>
										<FormControlLabel
											label={t('alert.config.form.isEmailNotificationActive')}
											labelPlacement='end'
											sx={{ marginLeft: '0' }}
											control={
												<Checkbox
													id='isEmailNotificationActive'
													checked={checked}
													name='isEmailNotificationActive'
													onChange={(e) => setChecked(e.target.checked)}
												/>
											}></FormControlLabel>
										<Box
											id='emailNotificationBox'
											sx={{ display: checked ? 'flex' : 'none', width: '100%' }}>
											<Box sx={{ width: '50%' }}>
												<TextFieldComponent
													sx={{ width: '100%', m: 1, pr: 1 }}
													name='email'
													label={t('alert.config.form.email')}
													control={control}
													errors={errors?.email}
													size='small'
													rules={{
														required: {
															value: checked,
															message: t('comun.form-errors.required'),
														},
														pattern: {
															value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, //eslint-disable-line
															message: 'Email no válido',
														},
													}}
												/>
											</Box>
											<Box sx={{ width: '50%' }}>
												<SelectComponent
													sx={{ width: '100%', m: 1 }}
													size='small'
													name='emailInterval'
													control={control}
													errors={errors?.emailInterval}
													label={t('alert.config.form.emailInterval')}
													optionsLabel={'description'}
													optionsValue={'sendInterval'}
													options={emailInterval}
													defaultValue={[]}
													rules={{
														required: {
															value: checked,
															message: t('comun.form-errors.required'),
														},
													}}
												/>
											</Box>
										</Box>
									</Box>
									<FieldsetComponent
										title={t('alert.config.form.vessels')}
										style={{ width: '100%', marginLeft: '8px', flex: '1 1 auto', mt: 1 }}>
										<Box
											sx={{
												width: '100%',
											}}>
											<VesselListComponent
												methodsForm={methodsForm}
												selected={alertConfig ? alertConfig.vessels : null}
												vessels={vessels}></VesselListComponent>
										</Box>
									</FieldsetComponent>
								</Box>
								<Divider orientation='vertical' />
								<Box sx={{ display: 'flex', ml: 3, mt: 1, flexDirection: 'column', width: '100%' }}>
									<SelectComponent
										label={t('alert.config.form.type')}
										sx={{ width: '100%', mb: 2 }}
										name='type'
										control={control}
										disabled={isUpdateMode()}
										errors={errors?.type}
										size='small'
										options={alertTypes}
										optionsLabel={'name'}
										optionsValue={'typeId'}
										rules={{
											required: {
												value: true,
												message: t('comun.form-errors.required'),
											},
										}}
									/>
									<AlertConfigFormOptions
										typeSelected={typeSelected}
										methodsForm={methodsForm}
										zones={zones}
										sensors={sensors}
										alertConfig={alertConfig}
										t={t}></AlertConfigFormOptions>
								</Box>
							</Box>
						</Box>
					</BasicDialog>
				</Box>
			) : null}
		</Box>
	)
}
