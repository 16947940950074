import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useTimerDetails = () => {
	const [timerDetails, setTimerDetails] = useState(null)
	const [errorTimerDetails, setErrorTimerDetails] = useState(null)
	const [isLoadingTimerDetails, setIsLoadingTimerDetails] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerDetailsRequest = useCallback(
		(props) => {
			client
				.get(baseUrl + 'horus/f3/timer/projects/n3?' + prepareParams(props))
				.then((result) => {
					setIsLoadingTimerDetails(false)
					setTimerDetails(result.data.data)
					if (result.data.success) {
						setTimerDetails(result.data.data)
					} else {
						setErrorTimerDetails({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingTimerDetails(false)
					setErrorTimerDetails(err.response)
				})

			setTimerDetails(null)
			setErrorTimerDetails(null)
			setIsLoadingTimerDetails(true)
		},
		[client]
	)
	return {
		timerDetailsRequest,
		isLoadingTimerDetails,
		timerDetails,
		errorTimerDetails,
	}
}
