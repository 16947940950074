import { Box, Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import BasicDialog from '../comun/BasicDialog'
import ListNotesFilter from './ListNotesFilter'
import ListNotesTable from './ListNotesTable'
import { setLocalStorageItem } from '../../utils/cookie'
import { previousState } from '../../constants'

export default function ListNotesContainer({
	listNotesOptions,
	project,
	filteredListNotesData,
	open,
	onClose,
	onFilter,
	filterListNotes,
	filter,
	actionsProject,
}) {
	const history = useHistory()

	const [t] = useTranslation('common')

	const handleListNote = (e, row) => {
		e.stopPropagation()
		let prevState = {
			url: '/project',
			filter: filter,
			project: project,
			action: actionsProject.LIST_NOTES,
			filterNotes: filterListNotes,
		}
		setLocalStorageItem(previousState, JSON.stringify(prevState))

		history.push({ pathname: '/preview', state: { vessel: null, project: project, jumpTime: row.date } })
	}

	return (
		<Box>
			{open && (
				<BasicDialog
					title={t('project.list-note.title') + ' ' + project.projectRef + ' - ' + project.description}
					onOpen={open}
					onClose={onClose}>
					<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '180vh', overflow: 'hidden' }}>
						<Box sx={{ width: '100%', minHeight: '110px' }}>
							<ListNotesFilter
								listNotesOptions={listNotesOptions}
								onFilter={onFilter}
								filterListNotes={filterListNotes}
							/>
						</Box>

						<Divider sx={{ my: 2 }} variant='middle' />

						<Box>
							<ListNotesTable listNotesData={filteredListNotesData} handleListNote={handleListNote} />
						</Box>
					</Box>
				</BasicDialog>
			)}
		</Box>
	)
}
