import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../../services/ApiClientProvider'
import { prepareParams } from '../../ServicesUtils'

export const useProjectItemCommentDelete = () => {
	const [dataProjectItemCommentDelete, setDataProjectItemCommentDelete] = useState(null)
	const [errorProjectItemCommentDelete, setErrorProjectItemCommentDelete] = useState(null)
	const [isLoadingProjectItemCommentDelete, setIsLoadingProjectItemCommentDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemCommentDelete = useCallback(
		(params) => {
			client
				.delete(baseUrl + 'horus/f3/project/item/comment?' + prepareParams(params))
				.then((result) => {
					setIsLoadingProjectItemCommentDelete(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemCommentDelete({ data: result.data })
					} else {
						setErrorProjectItemCommentDelete({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemCommentDelete(error.response)
					setIsLoadingProjectItemCommentDelete(false)
				})

			setIsLoadingProjectItemCommentDelete(true)
			setDataProjectItemCommentDelete(null)
			setErrorProjectItemCommentDelete(null)
		},
		[client]
	)

	return {
		projectItemCommentDelete,
		dataProjectItemCommentDelete,
		errorProjectItemCommentDelete,
		isLoadingProjectItemCommentDelete,
	}
}
