import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../../services/ApiClientProvider'

export const useProjectItemReadComments = () => {
	const [dataReadComment, setDataReadComment] = useState(null)
	const [errorReadComment, setErrorReadComment] = useState(null)
	const [isLoadingReadComment, setIsLoadingReadComment] = useState(false)

	const { client } = useContext(ApiClientContext)

	const readComment = useCallback(
		(projectId, projectItemId, lineNo) => {
			client
				.put(
					baseUrl +
						'horus/f3/project/preview/view/readcomments?projectId=' +
						projectId +
						'&projectItemId=' +
						projectItemId +
						'&lineNo=' +
						lineNo
				)
				.then((result) => {
					setIsLoadingReadComment(false)
					if (result.status === 200 && result.data.success) {
						setDataReadComment(result.data.data)
					} else {
						setErrorReadComment({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorReadComment(error.response)
					setIsLoadingReadComment(false)
				})

			setIsLoadingReadComment(true)
			setDataReadComment(null)
			setErrorReadComment(null)
		},
		[client]
	)

	return {
		readComment,
		dataReadComment,
		errorReadComment,
		isLoadingReadComment,
	}
}
