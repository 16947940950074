import { Badge, Box, Button, CircularProgress, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Popup } from 'react-leaflet'

import NoteIcon from '@mui/icons-material/Note'
import NotificationsIcon from '@mui/icons-material/Notifications'
import RedoIcon from '@mui/icons-material/Redo'
import SensorsIcon from '@mui/icons-material/Sensors'
import { styled } from '@mui/material/styles'
import 'leaflet-rotatedmarker'
import { datetimeUTCtoString } from '../../../comun/DateUtils'
import { getDeclarationIconAux } from '../../../comun/NotesIconHelper'
import './MapTab.css'
import { useTranslation } from 'react-i18next'

export default function MapInfoTooltip({ fromDashboard, handleGoToMoment, isLoadingMapInfo, dataMapInfo, p }) {
	const [t] = useTranslation('common')
	const [tabOpened, setTabOpened] = useState('0')

	const StyledBadge = styled(Badge)(({ theme }) => ({
		'& .MuiBadge-badge': {
			right: -2,
			top: 1,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: '0 3px',
		},
	}))

	const handleTabChanged = (tab) => {
		setTabOpened(tab)
	}
	return (
		<Popup closeButton={false} className='marker-popup'>
			{!isLoadingMapInfo ? (
				<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '13vw' }}>
					<Typography sx={{ alignSelf: 'center' }}>{datetimeUTCtoString(p.date)}</Typography>
					<Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
							{p.lat.toFixed(4)}
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							{p.lon.toFixed(4)}
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							{p.speed + ' kn'}
						</Box>
						<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
							{p.course + 'º'}
						</Box>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 1, mb: 1 }}>
						{dataMapInfo?.isVisibleNotifications && (
							<Tooltip title={t('preview.secondary-panel.tab-notifications')} placement='top'>
								<IconButton
									onClick={() => handleTabChanged('0')}
									color={tabOpened == '0' ? 'primary' : 'inherit'}>
									<StyledBadge
										badgeContent={dataMapInfo?.notificationsInfo?.length}
										color='error'
										overlap='circular'>
										<NotificationsIcon />
									</StyledBadge>
								</IconButton>
							</Tooltip>
						)}
						{dataMapInfo?.isVisibleAnnotations && (
							<Tooltip title={t('preview.secondary-panel.tab-annotations')} placement='top'>
								<IconButton
									onClick={() => handleTabChanged('1')}
									color={tabOpened == '1' ? 'primary' : 'inherit'}>
									<StyledBadge
										badgeContent={dataMapInfo?.annotationsMapInfo?.length}
										color='error'
										overlap='circular'>
										<NoteIcon />
									</StyledBadge>
								</IconButton>
							</Tooltip>
						)}
						<Tooltip title={t('preview.secondary-panel.tab-sensors')} placement='top'>
							<IconButton
								onClick={() => handleTabChanged('2')}
								color={tabOpened == '2' ? 'primary' : 'inherit'}>
								<StyledBadge
									badgeContent={dataMapInfo?.sensorActivateds?.length}
									color='error'
									overlap='circular'>
									<SensorsIcon />
								</StyledBadge>
							</IconButton>
						</Tooltip>
						{!fromDashboard && (
							<Tooltip title={t('preview.annotation.go-to-moment')} sx={{ ml: 1 }}>
								<Button onClick={() => handleGoToMoment(p)} color='inherit'>
									<RedoIcon />
								</Button>
							</Tooltip>
						)}
					</Box>
					<Box>
						{tabOpened == 0 && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									maxHeight: '70px',
									minHeight: '70px',
									border: '1px solid black',
									borderRadius: '5px',
									width: '100%',
									p: 1,
									overflowY: 'auto',
								}}>
								{dataMapInfo?.notificationsInfo?.map((notif, idx) => {
									return (
										<Box sx={{ display: 'flex' }} key={notif.startDate + '-' + idx}>
											{notif.name} {datetimeUTCtoString(notif.startDate)}
										</Box>
									)
								})}
							</Box>
						)}
						{tabOpened == 1 && (
							<Box
								sx={{
									display: 'grid',
									gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr',
									justifyContent: 'space-around',
									maxHeight: '70px',
									minHeight: '70px',
									border: '1px solid black',
									borderRadius: '5px',
									width: '100%',
									p: 1,
									pr: 2,
									overflowY: 'auto',
								}}>
								{dataMapInfo?.annotationsMapInfo?.map((annotation) => {
									return (
										<Tooltip title={annotation.description} key={annotation.projectItemId}>
											<SvgIcon
												component={getDeclarationIconAux(annotation.itemType, false)}
												key={annotation.projectItemId}
											/>
										</Tooltip>
									)
								})}
							</Box>
						)}
						{tabOpened == 2 && (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									maxHeight: '70px',
									minHeight: '70px',
									border: '1px solid black',
									borderRadius: '5px',
									width: '100%',
									p: 1,
									overflowY: 'auto',
								}}>
								{dataMapInfo?.sensorActivateds?.map((sensor, idx) => {
									return (
										<Box key={sensor.sensorSeatubeId + '' + idx}>
											{sensor.sensorName + ' ' + sensor.numActivated}
										</Box>
									)
								})}
							</Box>
						)}
					</Box>
				</Box>
			) : (
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<CircularProgress
						variant='indeterminate'
						disableShrink
						color='primary'
						size={40}
						thickness={15}
						sx={{ alignSelf: 'center' }}
					/>
				</Box>
			)}
		</Popup>
	)
}
