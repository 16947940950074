import { ReactComponent as CO } from '../../assets/declarationIcons/catch-other.svg'
import { ReactComponent as SI } from '../../assets/declarationIcons/catch-ssi.svg'
import { ReactComponent as CT } from '../../assets/declarationIcons/counting.svg'
import { ReactComponent as DS } from '../../assets/declarationIcons/discard.svg'
import { ReactComponent as FA } from '../../assets/declarationIcons/fad.svg'
import { ReactComponent as FE } from '../../assets/declarationIcons/fishing-end.svg'
import { ReactComponent as FS } from '../../assets/declarationIcons/fishing-start.svg'
import { ReactComponent as BA } from '../../assets/declarationIcons/float.svg'
import { ReactComponent as BT } from '../../assets/declarationIcons/float-sum.svg'
import { ReactComponent as HE } from '../../assets/declarationIcons/hauling-end.svg'
import { ReactComponent as HS } from '../../assets/declarationIcons/hauling-start.svg'
import { ReactComponent as HO } from '../../assets/declarationIcons/hook.svg'
import { ReactComponent as IS } from '../../assets/declarationIcons/inspection.svg'
import { ReactComponent as DL } from '../../assets/declarationIcons/issue.svg'
import { ReactComponent as ME } from '../../assets/declarationIcons/meassure.svg'
import { ReactComponent as N } from '../../assets/declarationIcons/note.svg'
import { ReactComponent as PO } from '../../assets/declarationIcons/pollution.svg'
import { ReactComponent as CE } from '../../assets/declarationIcons/report-end.svg'
import { ReactComponent as CS } from '../../assets/declarationIcons/report-start.svg'
import { ReactComponent as R } from '../../assets/declarationIcons/retrieve-gear.svg'
import { ReactComponent as S } from '../../assets/declarationIcons/set-gear.svg'
import { ReactComponent as SE } from '../../assets/declarationIcons/setting-end.svg'
import { ReactComponent as SS } from '../../assets/declarationIcons/setting-start.svg'
import { ReactComponent as SG } from '../../assets/declarationIcons/sighting.svg'
import { ReactComponent as SN } from '../../assets/declarationIcons/snap.svg'
import { ReactComponent as TS } from '../../assets/declarationIcons/transshipment.svg'
import { ReactComponent as TQ } from '../../assets/declarationIcons/trip-question.svg'
import { ReactComponent as WE } from '../../assets/declarationIcons/well.svg'
import { ReactComponent as AI } from '../../assets/declarationIcons/baitbox.svg'
import { ReactComponent as AT } from '../../assets/declarationIcons/baitbox-sum.svg'
import { ReactComponent as RA } from '../../assets/declarationIcons/brail.svg'
import { ReactComponent as RT } from '../../assets/declarationIcons/brail-sum.svg'
import { ReactComponent as YC } from '../../assets/declarationIcons/bycatch.svg'
import { ReactComponent as C } from '../../assets/declarationIcons/catch.svg'
import { ReactComponent as SF } from '../../assets/declarationIcons/health-safety.svg'

import { ReactComponent as CO_DISABLED } from '../../assets/declarationIcons/catch-other_deac.svg'
import { ReactComponent as SI_DISABLED } from '../../assets/declarationIcons/catch-ssi_deac.svg'
import { ReactComponent as CT_DISABLED } from '../../assets/declarationIcons/counting_deac.svg'
import { ReactComponent as DS_DISABLED } from '../../assets/declarationIcons/discard_deac.svg'
import { ReactComponent as FA_DISABLED } from '../../assets/declarationIcons/fad_deac.svg'
import { ReactComponent as FE_DISABLED } from '../../assets/declarationIcons/fishing-end_deac.svg'
import { ReactComponent as FS_DISABLED } from '../../assets/declarationIcons/fishing-start_deac.svg'
import { ReactComponent as BA_DISABLED } from '../../assets/declarationIcons/float_deac.svg'
import { ReactComponent as BT_DISABLED } from '../../assets/declarationIcons/float-sum_deac.svg'
import { ReactComponent as HE_DISABLED } from '../../assets/declarationIcons/hauling-end_deac.svg'
import { ReactComponent as HS_DISABLED } from '../../assets/declarationIcons/hauling-start_deac.svg'
import { ReactComponent as HO_DISABLED } from '../../assets/declarationIcons/hook_deac.svg'
import { ReactComponent as IS_DISABLED } from '../../assets/declarationIcons/inspection_deac.svg'
import { ReactComponent as DL_DISABLED } from '../../assets/declarationIcons/issue_deac.svg'
import { ReactComponent as ME_DISABLED } from '../../assets/declarationIcons/meassure_deac.svg'
import { ReactComponent as N_DISABLED } from '../../assets/declarationIcons/note_deac.svg'
import { ReactComponent as PO_DISABLED } from '../../assets/declarationIcons/pollution_deac.svg'
import { ReactComponent as CE_DISABLED } from '../../assets/declarationIcons/report-end_deac.svg'
import { ReactComponent as CS_DISABLED } from '../../assets/declarationIcons/report-start_deac.svg'
import { ReactComponent as R_DISABLED } from '../../assets/declarationIcons/retrieve-gear_deac.svg'
import { ReactComponent as S_DISABLED } from '../../assets/declarationIcons/set-gear_deac.svg'
import { ReactComponent as SE_DISABLED } from '../../assets/declarationIcons/setting-end_deac.svg'
import { ReactComponent as SS_DISABLED } from '../../assets/declarationIcons/setting-start_deac.svg'
import { ReactComponent as SG_DISABLED } from '../../assets/declarationIcons/sighting_deac.svg'
import { ReactComponent as SN_DISABLED } from '../../assets/declarationIcons/snap_deac.svg'
import { ReactComponent as TS_DISABLED } from '../../assets/declarationIcons/transshipment_deac.svg'
import { ReactComponent as TQ_DISABLED } from '../../assets/declarationIcons/trip-question_deac.svg'
import { ReactComponent as WE_DISABLED } from '../../assets/declarationIcons/well_deac.svg'
import { ReactComponent as AI_DISABLED } from '../../assets/declarationIcons/baitbox_deac.svg'
import { ReactComponent as AT_DISABLED } from '../../assets/declarationIcons/baitbox-sum_deac.svg'
import { ReactComponent as RA_DISABLED } from '../../assets/declarationIcons/brail_deac.svg'
import { ReactComponent as RT_DISABLED } from '../../assets/declarationIcons/brail-sum_deac.svg'
import { ReactComponent as YC_DISABLED } from '../../assets/declarationIcons/bycatch_deac.svg'
import { ReactComponent as C_DISABLED } from '../../assets/declarationIcons/catch_deac.svg'
import { ReactComponent as SF_DISABLED } from '../../assets/declarationIcons/health-safety_deac.svg'

import CO_CHART from '../../assets/declarationIcons/catch-other.svg'
import SI_CHART from '../../assets/declarationIcons/catch-ssi.svg'
import CT_CHART from '../../assets/declarationIcons/counting.svg'
import DS_CHART from '../../assets/declarationIcons/discard.svg'
import FA_CHART from '../../assets/declarationIcons/fad.svg'
import FE_CHART from '../../assets/declarationIcons/fishing-end.svg'
import FS_CHART from '../../assets/declarationIcons/fishing-start.svg'
import BA_CHART from '../../assets/declarationIcons/float.svg'
import BT_CHART from '../../assets/declarationIcons/float-sum.svg'
import HE_CHART from '../../assets/declarationIcons/hauling-end.svg'
import HS_CHART from '../../assets/declarationIcons/hauling-start.svg'
import HO_CHART from '../../assets/declarationIcons/hook.svg'
import IS_CHART from '../../assets/declarationIcons/inspection.svg'
import DL_CHART from '../../assets/declarationIcons/issue.svg'
import ME_CHART from '../../assets/declarationIcons/meassure.svg'
import N_CHART from '../../assets/declarationIcons/note.svg'
import PO_CHART from '../../assets/declarationIcons/pollution.svg'
import CE_CHART from '../../assets/declarationIcons/report-end.svg'
import CS_CHART from '../../assets/declarationIcons/report-start.svg'
import R_CHART from '../../assets/declarationIcons/retrieve-gear.svg'
import S_CHART from '../../assets/declarationIcons/set-gear.svg'
import SE_CHART from '../../assets/declarationIcons/setting-end.svg'
import SS_CHART from '../../assets/declarationIcons/setting-start.svg'
import SG_CHART from '../../assets/declarationIcons/sighting.svg'
import SN_CHART from '../../assets/declarationIcons/snap.svg'
import TS_CHART from '../../assets/declarationIcons/transshipment.svg'
import TQ_CHART from '../../assets/declarationIcons/trip-question.svg'
import WE_CHART from '../../assets/declarationIcons/well.svg'
import AI_CHART from '../../assets/declarationIcons/baitbox.svg'
import AT_CHART from '../../assets/declarationIcons/baitbox-sum.svg'
import RA_CHART from '../../assets/declarationIcons/brail.svg'
import RT_CHART from '../../assets/declarationIcons/brail-sum.svg'
import YC_CHART from '../../assets/declarationIcons/bycatch.svg'
import C_CHART from '../../assets/declarationIcons/catch.svg'
import SF_CHART from '../../assets/declarationIcons/health-safety.svg'

const iconList = [
	{ tag: 'AI', icon: AI },
	{ tag: 'AT', icon: AT },
	{ tag: 'BA', icon: BA },
	{ tag: 'BT', icon: BT },
	{ tag: 'C', icon: C },
	{ tag: 'CE', icon: CE },
	{ tag: 'CO', icon: CO },
	{ tag: 'CS', icon: CS },
	{ tag: 'CT', icon: CT },
	{ tag: 'DL', icon: DL },
	{ tag: 'DS', icon: DS },
	{ tag: 'FA', icon: FA },
	{ tag: 'FE', icon: FE },
	{ tag: 'FS', icon: FS },
	{ tag: 'HE', icon: HE },
	{ tag: 'HO', icon: HO },
	{ tag: 'HS', icon: HS },
	{ tag: 'IS', icon: IS },
	{ tag: 'ME', icon: ME },
	{ tag: 'N', icon: N },
	{ tag: 'PO', icon: PO },
	{ tag: 'R', icon: R },
	{ tag: 'RA', icon: RA },
	{ tag: 'RT', icon: RT },
	{ tag: 'S', icon: S },
	{ tag: 'SE', icon: SE },
	{ tag: 'SG', icon: SG },
	{ tag: 'SI', icon: SI },
	{ tag: 'SN', icon: SN },
	{ tag: 'SS', icon: SS },
	{ tag: 'TQ', icon: TQ },
	{ tag: 'TS', icon: TS },
	{ tag: 'WE', icon: WE },
	{ tag: 'YC', icon: YC },
	{ tag: 'SF', icon: SF },
]

const iconListDisabled = [
	{ tag: 'AI', icon: AI_DISABLED },
	{ tag: 'AT', icon: AT_DISABLED },
	{ tag: 'BA', icon: BA_DISABLED },
	{ tag: 'BT', icon: BT_DISABLED },
	{ tag: 'C', icon: C_DISABLED },
	{ tag: 'CE', icon: CE_DISABLED },
	{ tag: 'CO', icon: CO_DISABLED },
	{ tag: 'CS', icon: CS_DISABLED },
	{ tag: 'CT', icon: CT_DISABLED },
	{ tag: 'DL', icon: DL_DISABLED },
	{ tag: 'DS', icon: DS_DISABLED },
	{ tag: 'FA', icon: FA_DISABLED },
	{ tag: 'FE', icon: FE_DISABLED },
	{ tag: 'FS', icon: FS_DISABLED },
	{ tag: 'HE', icon: HE_DISABLED },
	{ tag: 'HO', icon: HO_DISABLED },
	{ tag: 'HS', icon: HS_DISABLED },
	{ tag: 'IS', icon: IS_DISABLED },
	{ tag: 'ME', icon: ME_DISABLED },
	{ tag: 'N', icon: N_DISABLED },
	{ tag: 'PO', icon: PO_DISABLED },
	{ tag: 'R', icon: R_DISABLED },
	{ tag: 'RA', icon: RA_DISABLED },
	{ tag: 'RT', icon: RT_DISABLED },
	{ tag: 'S', icon: S_DISABLED },
	{ tag: 'SE', icon: SE_DISABLED },
	{ tag: 'SG', icon: SG_DISABLED },
	{ tag: 'SI', icon: SI_DISABLED },
	{ tag: 'SN', icon: SN_DISABLED },
	{ tag: 'SS', icon: SS_DISABLED },
	{ tag: 'TQ', icon: TQ_DISABLED },
	{ tag: 'TS', icon: TS_DISABLED },
	{ tag: 'WE', icon: WE_DISABLED },
	{ tag: 'YC', icon: YC_DISABLED },
	{ tag: 'SF', icon: SF_DISABLED },
]

const iconListChart = [
	{ tag: 'AI', icon: AI_CHART },
	{ tag: 'AT', icon: AT_CHART },
	{ tag: 'BA', icon: BA_CHART },
	{ tag: 'BT', icon: BT_CHART },
	{ tag: 'C', icon: C_CHART },
	{ tag: 'CE', icon: CE_CHART },
	{ tag: 'CO', icon: CO_CHART },
	{ tag: 'CS', icon: CS_CHART },
	{ tag: 'CT', icon: CT_CHART },
	{ tag: 'DL', icon: DL_CHART },
	{ tag: 'DS', icon: DS_CHART },
	{ tag: 'FA', icon: FA_CHART },
	{ tag: 'FE', icon: FE_CHART },
	{ tag: 'FS', icon: FS_CHART },
	{ tag: 'HE', icon: HE_CHART },
	{ tag: 'HO', icon: HO_CHART },
	{ tag: 'HS', icon: HS_CHART },
	{ tag: 'IS', icon: IS_CHART },
	{ tag: 'ME', icon: ME_CHART },
	{ tag: 'N', icon: N_CHART },
	{ tag: 'PO', icon: PO_CHART },
	{ tag: 'R', icon: R_CHART },
	{ tag: 'RA', icon: RA_CHART },
	{ tag: 'RT', icon: RT_CHART },
	{ tag: 'S', icon: S_CHART },
	{ tag: 'SE', icon: SE_CHART },
	{ tag: 'SG', icon: SG_CHART },
	{ tag: 'SI', icon: SI_CHART },
	{ tag: 'SN', icon: SN_CHART },
	{ tag: 'SS', icon: SS_CHART },
	{ tag: 'TQ', icon: TQ_CHART },
	{ tag: 'TS', icon: TS_CHART },
	{ tag: 'WE', icon: WE_CHART },
	{ tag: 'YC', icon: YC_CHART },
	{ tag: 'SF', icon: SF_CHART },
]

export const getDeclarationIcon = (itemType, disabled) => {
	let icon = null
	if (disabled) {
		icon = icon = iconListDisabled.find((icon) => icon.tag == itemType)
	} else {
		icon = iconList.find((icon) => icon.tag == itemType)
	}

	return <img src={icon.icon} width='24px' height='24px' />
}

export const getDeclarationIconAux = (itemType, disabled) => {
	let icon = null
	if (disabled) {
		icon = icon = iconListDisabled.find((icon) => icon.tag == itemType)
	} else {
		icon = iconList.find((icon) => icon.tag == itemType)
	}
	return icon.icon
}

export const getDeclarationIconSmall = (itemType) => {
	let icon = iconList.find((icon) => icon.tag == itemType)
	return <img src={icon.icon} width='16px' height='16px' />
}

export const getDeclarationIconChart = (itemType, width, height) => {
	let icon = iconListChart.find((icon) => icon.tag == itemType)
	return { image: { url: icon.icon, width: width, height: height }, visible: true }
}
