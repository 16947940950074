import { Box, Button, SvgIcon, Tooltip, Typography } from '@mui/material'

import RedoIcon from '@mui/icons-material/Redo'
import React from 'react'

import { datetimeGMTToLocaleInISOFormat, datetimeUTCtoString } from '../comun/DateUtils'
import { getDeclarationIconAux, getDeclarationIconChart } from '../comun/NotesIconHelper'
import './Chart.css'

export const customTooltipText = (pointInfo, t, handleGoToMoment, realValues) => {
	let sensor = null
	let value = null
	let unit = null
	let image = null
	if(pointInfo.seriesName != 'Shade') {
		sensor = realValues.find((rV) => pointInfo.point.data[rV.name] != null)
		value = pointInfo.point.data[sensor.name]
		unit = sensor.unit
	}
	
	if (pointInfo.seriesName == 'Declarations') {
		image = getDeclarationIconAux(value, false)
	}
	switch (pointInfo.seriesName) {
		case 'Shade':
			return (
				<></>
			)
		case 'Declarations':
			return (
				<>
					{image != null && (
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Box sx={{ display: 'flex' }}>
								<Box sx={{ width: '60%' }}>
									<Typography>{datetimeUTCtoString(pointInfo.argument, null, null, true)}</Typography>
								</Box>
								<Tooltip title={t('preview.chart.tooltip-button')}>
									<Button color='inherit' onClick={() => handleGoToMoment(pointInfo)}>
										<RedoIcon />
									</Button>
								</Tooltip>
							</Box>
							<Box sx={{ display: 'flex', m: 0 }}>
								<SvgIcon component={image} />
								<Typography sx={{ ml: 2 }}>
									<strong>{value} </strong>
								</Typography>
							</Box>
						</Box>
					)}
				</>
			)
		default:
			return (
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<Box sx={{ display: 'flex' }}>
						<Box sx={{ width: '60%' }}>
							<Typography>{datetimeUTCtoString(pointInfo.argument, null, null, true)}</Typography>
						</Box>
						<Tooltip title={t('preview.chart.tooltip-button')}>
							<Button color='inherit' onClick={() => handleGoToMoment(pointInfo)}>
								<RedoIcon />
							</Button>
						</Tooltip>
					</Box>
					<Typography>
						<strong>{pointInfo.seriesName}: </strong> {value + ' ' + unit}
					</Typography>
				</Box>
			)
	}
}

export const tooltipTemplate = (pointInfo, t, updateCurrentTime, layers) => {
	let auxArray = layers.map((l) => l.series).flat(1)
	let realValues = auxArray.map((s) => ({ name: s.sensorNameRV, unit: s.unit }))

	const handleGoToMoment = (item) => {
		updateCurrentTime(datetimeGMTToLocaleInISOFormat(item.argument))
	}
	switch(pointInfo.seriesName){
		case 'Shade':
			return (
				<></>
			)
		default:
			return (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						height: '65px',
						zIndex: '9999 !important',
						width: '200px',
					}}>
					{customTooltipText(pointInfo, t, handleGoToMoment, realValues)}
				</Box>
			)
	}
}

export const customizePoint = (pointInfo, layers) => {
	if (pointInfo.seriesName == 'Declarations') {
		let auxArray = layers.map((l) => l.series).flat(1)
		let realValues = auxArray.map((s) => ({ name: s.sensorNameRV, unit: s.unit }))
		let sensor = realValues.find((rV) => pointInfo.data[rV.name] != null)
		let value = pointInfo.data[sensor.name]
		return getDeclarationIconChart(value, 15, 15)
	}
}
