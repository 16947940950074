import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useVesselAlertRead = () => {
	const [alertReadData, setAlertReadData] = useState(null)
	const [errorAlertRead, setErrorAlertRead] = useState(null)
	const [isLoadingAlertRead, setIsLoadingAlertRead] = useState(false)

	const { client } = useContext(ApiClientContext)

	const vesselAlertRead = useCallback(
		(idAlert) => {
			client
				.put(baseUrl + 'horus/f3/alert/vessel/read?Id=' + idAlert)
				.then((result) => {
					setIsLoadingAlertRead(false)
					if (result.status === 200 && result.data.success) {
						setAlertReadData({ data: result.data })
					} else {
						setErrorAlertRead({ statusText: result.data.errorMsg })
					}
					setIsLoadingAlertRead(false)
				})
				.catch((error) => {
					setErrorAlertRead(error.response)
					setIsLoadingAlertRead(false)
				})

			setAlertReadData(null)
			setErrorAlertRead(null)
			setIsLoadingAlertRead(true)
		},
		[client]
	)

	return {
		vesselAlertRead,
		alertReadData,
		errorAlertRead,
		isLoadingAlertRead,
	}
}
