import { useCallback, useState, useContext } from 'react'

import { ApiClientContext } from '../../../../ApiClientProvider'
import { baseUrl } from '../../../../../constants'

export const useVideoAsignedInsert = () => {
	const [videoAsignedInsertData, setVideoAsignedInsertData] = useState(null)
	const [isLoadingVideoAsignedInsert, setIsLoadingVideoAsignedInsert] = useState(false)
	const [errorVideoAsignedInsert, setErrorVideoAsignedInsert] = useState(null)

	const { client } = useContext(ApiClientContext)

	const videoAsignedInsert = useCallback(
		(range) => {
			let url = 'horus/f3/project/preview/videodaterange'
			client
				.post(baseUrl + url, range)
				.then((result) => {
					setIsLoadingVideoAsignedInsert(false)
					if (result.data.success) {
						setVideoAsignedInsertData(result.data.success)
					} else {
						setErrorVideoAsignedInsert({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingVideoAsignedInsert(false)
					setErrorVideoAsignedInsert(err.response)
				})
			setIsLoadingVideoAsignedInsert(true)
			setVideoAsignedInsertData(null)
			setErrorVideoAsignedInsert(null)
		},
		[client]
	)

	return {
		videoAsignedInsert,
		videoAsignedInsertData,
		isLoadingVideoAsignedInsert,
		errorVideoAsignedInsert,
	}
}
