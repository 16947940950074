import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useMapInfoPosition = (vesselApiId, projectId, fromDashboard) => {
	const [dataMapInfo, setDataMapInfo] = useState(null)
	const [errorMapInfo, setErrorMapInfo] = useState(null)
	const [isLoadingMapInfo, setIsLoadingMapInfo] = useState(false)
	const { client } = React.useContext(ApiClientContext)
	let computedUrl = ''
	// const computedUrl = fromDashboard
	// 	? 'horus/f3/dashboard/preview/view/mapinfoposition?vesselApiId=' + vesselApiId
	// 	: vesselApiId
	// 	? 'horus/f3/dashboard/preview/view/mapinfoposition?vesselApiId=' + vesselApiId
	// 	: 'horus/f3/project/preview/view/mapinfoposition?projectId=' + projectId

	const getMapInfoPosition = useCallback(
		(vesselId, datePosition, nextDatePosition) => {
			if (fromDashboard) {
				computedUrl = 'horus/f3/dashboard/preview/view/mapinfoposition?vesselApiId=' + vesselId
			} else if (vesselApiId) {
				computedUrl = 'horus/f3/dashboard/preview/view/mapinfoposition?vesselApiId=' + vesselApiId
			} else if (projectId) {
				computedUrl = 'horus/f3/project/preview/view/mapinfoposition?projectId=' + projectId
			}
			client
				.put(baseUrl + computedUrl + '&datePosition=' + datePosition + '&nextDatePosition=' + nextDatePosition)
				.then((result) => {
					setIsLoadingMapInfo(false)
					if (result.data.success) {
						setDataMapInfo(result.data.data)
					} else {
						setErrorMapInfo({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingMapInfo(false)
					setErrorMapInfo(err.response)
				})

			setDataMapInfo(null)
			setErrorMapInfo(null)
			setIsLoadingMapInfo(true)
		},
		[client]
	)
	return {
		getMapInfoPosition,
		dataMapInfo,
		errorMapInfo,
		isLoadingMapInfo,
	}
}
