import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProgramFilterDelete = () => {
    const [dataFilter, setDataFilter] = useState(null)
    const [errorDeleteFilter, setErrorFilter] = useState(null)
    const [isLoadingFilter, setIsLoadingFilter] = useState(false)

    const { client } = useContext(ApiClientContext)
  
    const deleteFavouriteFilter = useCallback(
        (props) => {
            client
                .delete(baseUrl + 'horus/f3/program/filter/favourite?filterName='+props)
                .then((result) => {
                    setIsLoadingFilter(false)

                    if (result.status === 200 && result.data.success) {
                        setDataFilter({ data: result.data })
                    } else {
                        setErrorFilter({ statusText: result.data.errorMsg })
                    }
                    
                    setIsLoadingFilter(false)
                })
                .catch((error) => {
                    setErrorFilter(error.response)
                    setIsLoadingFilter(false)
                })

                setDataFilter(null)
			    setErrorFilter(null)
			    setIsLoadingFilter(true)
            },
        [client]
    )
  
    return {
        dataFilter,
        errorDeleteFilter,
        isLoadingFilter,
        deleteFavouriteFilter
    }
}
