import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProjectInsert = () => {
	const [projectDataInsert, setProjectDataInsert] = useState(null)
	const [errorProjectInsert, setErrorProjectInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/project', props)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setProjectDataInsert({ data: result.data })
					} else {
						setErrorProjectInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorProjectInsert(error.response)
					setIsLoadingInsert(false)
				})

			setProjectDataInsert(null)
			setErrorProjectInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		projectInsert,
		isLoadingInsert,
		projectDataInsert,
		setProjectDataInsert,
		errorProjectInsert,
		setErrorProjectInsert,
	}
}
