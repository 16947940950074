import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useHideKMLZones = (vesselApiId, projectId) => {
	const [kmlZones, setKmlZones] = useState(null)
	const [errorKmlZones, setErrorKmlZones] = useState(null)
	const [isLoadingKmlZones, setLoadingIsKmlZones] = useState(false)
	const { client } = React.useContext(ApiClientContext)
	const computedUrl = vesselApiId
		? 'horus/f3/dashboard/preview/zones/disable?vesselApiId=' + vesselApiId
		: 'horus/f3/project/preview/zones/disable?projectId=' + projectId

	const hideKMLZones = useCallback(() => {
		client
			.put(baseUrl + computedUrl)
			.then((result) => {
				setLoadingIsKmlZones(false)
				if (result.data.success) {
					setKmlZones(result.data.data)
				} else {
					setErrorKmlZones({ statusText: result.data.errorMsg })
				}
			})
			.catch((err) => {
				setLoadingIsKmlZones(false)
				setErrorKmlZones(err.response)
			})
		setKmlZones(null)
		setErrorKmlZones(null)
		setLoadingIsKmlZones(true)
	}, [client])
	return {
		hideKMLZones,
		kmlZones,
		errorKmlZones,
		isLoadingKmlZones,
	}
}
