import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../../../constants'
import { ApiClientContext } from '../../../ApiClientProvider'

export const useVideoRequestInsert = () => {
	const [videoRequestDataInsert, setVideoRequestDataInsert] = useState(null)
	const [errorVideoRequestInsert, setErrorVideoRequestInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const videoRequestInsert = useCallback(
		(props) => {
			let url = props.projectId
				? 'horus/f3/project/preview/videorequest'
				: 'horus/f3/dashboard/preview/videorequest'
			client
				.post(baseUrl + url, props)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setVideoRequestDataInsert({ data: result.data })
					} else {
						setErrorVideoRequestInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorVideoRequestInsert(error.response)
					setIsLoadingInsert(false)
				})

			setVideoRequestDataInsert(null)
			setErrorVideoRequestInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		videoRequestInsert,
		isLoadingInsert,
		videoRequestDataInsert,
		setVideoRequestDataInsert,
		errorVideoRequestInsert,
		setErrorVideoRequestInsert,
	}
}
