export const parseCalibrationData = (data) => {
	let configList = []
	data.forEach((item) => {
		let config = {}
		config.id = item.calibrationId
		config.name = item.description
		config.positions = []
		config.default = item.defaultCalibration
		config.distance = item.lineLength
		config.unit = item.unit
		config.positions.push(
			{ x: item.ax, y: item.ay, name: 'A' },
			{ x: item.bx, y: item.by1, name: 'B' },
			{ x: item.cx, y: item.cy, name: 'C' },
			{ x: item.dx, y: item.dy, name: 'D' }
		)
		configList.push(config)
	})
	return configList
}

export const parseMeasurementDataFromServer = (data) => {
	let measures = []
	data?.measures.forEach((item, idx) => {
		let measure = {
			id: idx + 1,
			description: item.description,
			measureValue: item.measureValue,
			calibrationRecordId: item.calibrationRecordId,
			calibrationDelta: item.calibrationDelta,
			positions: [],
		}
		measure.positions.push({ x: item.x1, y: item.y1, name: 'A' }, { x: item.x2, y: item.y2, name: 'B' })
		measures.push(measure)
	})
	return measures
}

export const parseMeasureProjectItem = (measureList, projectItemId) => {
	let measures = measureList.map((m) => {
		return {
			measureValue: m.measureValue,
			description: m.description,
			calibrationRecordId: m.calibrationRecordId,
			x1: m.positions[0].x,
			y1: m.positions[0].y,
			x2: m.positions[1].x,
			y2: m.positions[1].y,
		}
	})
	let projectItem = {
		projectItemId: projectItemId,
		measures: measures,
	}
	return projectItem
}

export const isMeasureComplete = (measure) => {
	if (
		measure.positions[0].x != null &&
		measure.positions[0].y != null &&
		measure.positions[1].x != null &&
		measure.positions[1].y != null
	) {
		return true
	} else {
		return false
	}
}

export class Point {
	constructor(x, y) {
		this.x = x
		this.y = y
	}

	distance(point) {
		return Math.sqrt(Math.pow(this.x - point.x, 2) + Math.pow(this.y - point.y, 2))
	}

	equals(point) {
		var errorMargin = 0.000001
		return Math.abs(this.x - point.x) < errorMargin && Math.abs(this.y - point.y) < errorMargin
	}

	toString() {
		return '(' + this.x + ',' + this.y + ')'
	}
}

export class Line {
	// straight line defined with its equation  y= mx +b ....
	// m = line's slope
	constructor(point1, point2) {
		if (point1.x === point2.x) {
			this.slope = 0
		} else {
			this.slope = (point1.y - point2.y) / (point1.x - point2.x)
		}
		this.b = point1.y - this.slope * point1.x

		this.isLine = true
	}

	getY(x) {
		return this.slope * x + this.b
	}
	getX(y) {
		return this.slope ? (y - this.b) / this.slope : 0
	}

	contains(point) {
		return point.y === this.getY(point.x)
	}

	intersection(line) {
		var intPoint = new Point(0, 0)

		intPoint.x = (line.b - this.b) / (this.slope - line.slope)
		intPoint.y = (this.slope * line.b - line.slope * this.b) / (this.slope - line.slope)

		return intPoint
	}

	toString() {
		return 'y = ' + this.slope + 'x ' + this.b
	}
}
const crossRatioProportion = (originPoint, referencePoint, startPoint, endPoint, vanisingPoint) => {
	// cross ratio
	var distOrigin = originPoint.distance(vanisingPoint)
	var distReference = referencePoint.distance(vanisingPoint)
	var distStart = startPoint.distance(vanisingPoint)
	var distEnd = endPoint.distance(vanisingPoint)

	var crossRatioEndInfinite = distEnd / distOrigin
	var crossRatioStartInfinite = distStart / distOrigin
	var crossRatioRefInfinite = distReference / distOrigin

	var crossRatioEnd0 = (distOrigin - distEnd) / distOrigin
	var crossRatioStart0 = (distOrigin - distStart) / distOrigin
	var crossRatioRef0 = (distOrigin - distReference) / distOrigin

	// Proportions

	var endProp = crossRatioEnd0 / crossRatioRef0 / (crossRatioEndInfinite / crossRatioRefInfinite)
	var startProp = crossRatioStart0 / crossRatioRef0 / (crossRatioStartInfinite / crossRatioRefInfinite)
	return Math.abs(endProp - startProp)
}

export const measureObject = (
	startObj,
	endObj,
	calibrationA,
	calibrationB,
	calibrationC,
	calibrationD,
	calibrationSize
) => {
	// calculate Vanising points
	var lineAB = new Line(calibrationA, calibrationB)
	var lineCD = new Line(calibrationC, calibrationD)
	var vanisingX = lineAB.intersection(lineCD)

	var lineAD = new Line(calibrationA, calibrationD)
	var lineBC = new Line(calibrationB, calibrationC)
	var vanisingY = lineAD.intersection(lineBC)

	// Set reference Axis
	var yAxis = lineAD
	var xAxis = lineCD

	/////////////////////////////////////////// Measure Object

	// project object onto X axis
	var startXVanisLine = new Line(vanisingY, startObj)
	var startX = startXVanisLine.intersection(xAxis)

	var endXVanisLine = new Line(vanisingY, endObj)
	var endX = endXVanisLine.intersection(xAxis)

	// get the x scale between mesaure object and reference
	var xProportion = crossRatioProportion(calibrationD, calibrationC, startX, endX, vanisingX)

	// project object onto Y axis
	var startYVanisLine = new Line(vanisingX, startObj)
	var startY = startYVanisLine.intersection(yAxis)

	var endYVanisLine = new Line(vanisingX, endObj)
	var endY = endYVanisLine.intersection(yAxis)

	// get the y scale between mesaure object and reference
	var yProportion = crossRatioProportion(calibrationD, calibrationA, startY, endY, vanisingY)

	var realDistance = Math.sqrt(
		Math.pow(calibrationSize * xProportion, 2) + Math.pow(calibrationSize * yProportion, 2)
	)
	return realDistance
}
