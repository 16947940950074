import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useParams } from 'react-router-dom'
import logoImg from '../../assets/horus-azul.png'
import backgroundImg from '../../assets/login.png'
import { messageDialogTypes, passwordRegex } from '../../constants'
import useUserSetPassword from '../../services/horus/useUserSetPassword'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import Copyright from '../Copyright'
import ErrorBoundary from '../ErrorBoundary'

export default function ChangePasswordPage() {
	const { code } = useParams()
	const [errorPasswordMatch, setErrorPasswordMatch] = useState(null)
	const history = useHistory()
	const [t] = useTranslation('common')
	const [open, setOpen] = useState(false)
	const [passwordValue, setPasswordValue] = useState('')
	const [confirmPasswordValue, setConfirmPasswordValue] = useState('')
	const [message, setMessage] = useState('')
	const [msgType, setMsgType] = useState(messageDialogTypes.INFO_MESSAGE)
	const [passwordRegexError, setPasswordRegexError] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)

	const { userSetPassword, isLoading, data, error } = useUserSetPassword()

	const handleSubmit = useCallback((event) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		const password = data.get('password')
		const confirmPassword = data.get('confirm-password')
		if (password !== confirmPassword) {
			setPasswordValue('')
			setConfirmPasswordValue('')
			setErrorPasswordMatch(t('change-password.error'))
		} else {
			setErrorPasswordMatch(null)
			userSetPassword(code, password)
		}
	}, [])

	const successMessage = {
		statusText: t('change-password.success'),
	}

	useEffect(() => {
		if (data) {
			setMsgType(messageDialogTypes.SUCCESS_MESSAGE)
			setMessage(successMessage)
			setOpen(true)
		}
		if (error) {
			setMsgType(messageDialogTypes.ERROR_MESSAGE)
			setMessage(error)
			setOpen(true)
		}
	}, [data, error])

	const goToLogin = () => {
		history.push({ pathname: '/login' })
	}

	const handleClose = () => {
		setOpen(false)
		setPasswordValue('')
		setConfirmPasswordValue('')
		if (data) {
			goToLogin()
		}
	}

	const handleChangeValue = (event) => {
		validatePassword(event.target.value)
		setPasswordValue(event.target.value)
	}

	const validatePassword = (password) => {
		var regex = new RegExp(passwordRegex)
		if (regex.test(password)) {
			//No cumple
			setPasswordRegexError(true)
		} else {
			//Cumple
			setPasswordRegexError(false)
		}
	}

	return (
		<ErrorBoundary history={history}>
			<MessageSnackBar openError={open} errorMsg={message} handleCloseError={handleClose} typeMsg={msgType} />

			<BackdropComponent open={isLoading} />
			<Grid container component='main' sx={{ height: '100vh' }}>
				<Grid
					item
					xs={4}
					sx={{
						backgroundImage: `url(${backgroundImg})`,
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
						backgroundSize: 'cover',
						backgroundPosition: 'left',
					}}
				/>
				<Grid item xs={4} component={Paper}>
					<Box
						sx={{
							my: 8,
							mx: 4,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}>
						<Link to='/'>
							<img className='logo' src={logoImg} />
						</Link>

						<Box component='form' noValidate onSubmit={handleSubmit} sx={{ mt: 10, mr: 10, ml: 10 }}>
							<Typography variant='h4' color='primary' sx={{ mb: 4 }}>
								{t('change-password.title')}
							</Typography>
							<TextField
								margin='normal'
								required
								fullWidth
								id='password'
								type={showPassword ? 'text' : 'password'}
								label={t('change-password.password')}
								name='password'
								autoFocus
								helperText={t('change-password.error-regex')}
								error={errorPasswordMatch || passwordRegexError}
								value={passwordValue}
								defaultValue={passwordValue}
								onChange={handleChangeValue}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={() => setShowPassword(!showPassword)}
												edge='end'>
												{showPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							<TextField
								margin='normal'
								required
								fullWidth
								name='confirm-password'
								label={t('change-password.confirm-password')}
								type={showConfirmPassword ? 'text' : 'password'}
								id='confirm-password'
								error={errorPasswordMatch}
								value={confirmPasswordValue}
								defaultValue={confirmPasswordValue}
								onChange={(event) => setConfirmPasswordValue(event.target.value)}
								InputProps={{
									endAdornment: (
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={() => setShowConfirmPassword(!showConfirmPassword)}
												edge='end'>
												{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
							{errorPasswordMatch && (
								<div>
									<Typography fullWidth variant='body2' color='error'>
										{errorPasswordMatch}
									</Typography>
								</div>
							)}
							<Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
								{t('change-password.submit')}
							</Button>
							<Button onClick={() => goToLogin()} fullWidth variant='contained' sx={{ mb: 2 }}>
								{t('change-password.cancel')}
							</Button>
							<Box
								sx={{
									mt: 5,
									width: '100%',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}>
								<Copyright open={true} />
							</Box>
						</Box>
					</Box>
				</Grid>
				<Grid
					item
					xs={4}
					sx={{
						backgroundImage: `url(${backgroundImg})`,
						backgroundRepeat: 'no-repeat',
						backgroundColor: (t) => (t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900]),
						backgroundSize: 'cover',
						backgroundPosition: 'right',
					}}
				/>
			</Grid>
		</ErrorBoundary>
	)
}
