import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProgramUpdate = () => {
	const [programDataUpdate, setProgramDataUpdate] = useState(null)
	const [errorProgramUpdate, setErrorProgramUpdate] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const programUpdate = useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/program', props)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setProgramDataUpdate({ data: result.data })
					} else {
						setErrorProgramUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProgramUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setProgramDataUpdate(null)
			setErrorProgramUpdate(null)
		},
		[client]
	)

	return {
		programUpdate,
		isLoadingUpdate,
		programDataUpdate,
		setProgramDataUpdate,
		errorProgramUpdate,
		setErrorProgramUpdate,
	}
}
