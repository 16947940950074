
import { TableFooter } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import TablePaginationComponent from '../comun/TablePaginationComponent'
import { EnhancedTableHead, getComparator, stableSort, TimerTableCell } from '../comun/TableUtils'

export default function TimerProjectTable({
    dataTimerProject, 
    filter, 
    onFilter,
	handleFormTimerProject,
	handleRowSelected,
	rowSelected
}) {
	const [t] = useTranslation('common')

	//This block should be the same in every table component ----------------------------------
	const [order, setOrder] = React.useState(filter.orderDirection)
	const [orderBy, setOrderBy] = React.useState(filter.orderField)
	const [page, setPage] = React.useState(0)
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(filter.pageSize)
	const columnQuantityForGroup1 = 6
	const columnQuantityForGroup2 = 5

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------
	const handleCursor = (row) => {
		return ((row.totalTime.days || row.totalTime.hours || row.totalTime.minutes || row.totalTime.seconds) ? 'pointer' : 'auto')
	}

	React.useEffect(() => {
		if (dataTimerProject) {
			setPage(dataTimerProject.pageNo - 1)
			setTotalCount(dataTimerProject.totalCount)
		}
	}, [dataTimerProject])

	const columnas = [
		{
			id: 'nameClient',
			orderBy: true,
			label: t('timer.table.header.client'),
		},
		{
			id: 'programDescription',
			orderBy: true,
			label: t('timer.table.header.program'),
		},
		{
			id: 'active',
			orderBy: false,
			label: t('timer.table.header.active'),
		},
		{
			id: 'typeAnalysisDescription',
			orderBy: true,
			label: t('timer.table.header.analysis'),
		},
		{
			id: 'projectDescription',
			orderBy: true,
			label: t('timer.table.header.project'),
		},
		{
			id: 'projectStatusId',
			orderBy: true,
			label: t('timer.table.header.project-status'),
		},
		{
			id: 'mapping',
			orderBy: false,
			label: t('timer.table.header.mapper'),
		},
		{
			id: 'analysis',
			orderBy: false,
			label: t('timer.table.header.analyst'),
		},
		{
			id: 'review',
			orderBy: false,
			label: t('timer.table.header.reviewer'),
		},
		{
			id: 'other',
			orderBy: false,
			label: t('timer.table.header.other'),
		},
		{
			id: 'total',
			orderBy: false,
			label: t('timer.table.header.total'),
			excluirExcel: true,
		}
	]

	return (
		<TableContainer>
			<Table
				id='table-timer-list'
				sx={{ minWidth: 750 }}
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					columnQuantityForGroup1={columnQuantityForGroup1}
					columnQuantityForGroup2={columnQuantityForGroup2}
					groupTitle2={t('timer.table.header.time')}
				/>
				<TableBody>
					{dataTimerProject
						? stableSort(dataTimerProject.data, getComparator(order, orderBy)).map((row, index) => {
							return (
								<StyledTableRow 
									key={ index } 
									sx={{ cursor: handleCursor(row), background: handleRowSelected(row, rowSelected)}}
									onClick={() => handleFormTimerProject(row)}
								>
									<TableCell align='center'>{row.nameClient}</TableCell>
									<TableCell align='center'>{row.programDescription}</TableCell>
									<TableCell align='center'>{row.isEnabled ? 
										t('timer.table.body.yes') : 
										t('timer.table.body.no')
									}</TableCell>
									<TableCell align='center'>{row.typeAnalysisDescription}</TableCell>
									<TableCell align='center'>{row.projectDescription}</TableCell>
									<TableCell align='center'>{row.projectStatusDescription}</TableCell>
									<TimerTableCell
										timerType={row?.selectionTime}
									/>
									<TimerTableCell
										timerType={row?.analysisTime}
									/>
									<TimerTableCell
										timerType={row?.auditTime}
									/>
									<TimerTableCell
										timerType={row?.otherTime}
									/>
									<TimerTableCell
										timerType={row?.totalTime}
									/>
								</StyledTableRow>
							)
						})
						: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
