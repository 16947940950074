import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useTranslation } from 'react-i18next'
import FieldsetComponent from '../../comun/FieldsetComponent'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'
import { TextField } from '@mui/material'
import AutoCompleteComponent from '../../comun/form_fields/AutoCompleteComponent'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import { Tooltip } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Checkbox, FormControlLabel } from '@mui/material'

export default function ComplexAlertForm({ methodsForm, zones, sensors, alertConfig }) {
	const {
		control,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = methodsForm

	const [t] = useTranslation('common')
	const [sensorsSelected, setSensorsSelected] = useState([])
	const [disableAddButton, setDisableAddButton] = useState(true)
	const [sensorsList, setSensorsList] = useState(sensors)
	const watchSensorAutoComplete = watch('sensorAutoComplete')

	useEffect(() => {
		if (alertConfig != null) {
			const copy = [...sensorsList]

			alertConfig.sensors.forEach((value) => {
				setSensorsSelected((prevState) => [...prevState, value])
				copy.splice(
					copy.findIndex((item) => {
						return item.sensorId === value.sensorId
					}),
					1
				)
			})

			setSensorsList(copy)
		}
	}, [alertConfig])

	useEffect(() => {
		setDisableAddButton(watchSensorAutoComplete ? false : true)
	}, [watchSensorAutoComplete])

	useEffect(() => {
		setValue('sensors', sensorsSelected)
	}, [sensorsSelected])

	const onAddSensors = () => {
		if (watchSensorAutoComplete) {
			//Se añade a la lista
			setSensorsSelected((prevState) => [...prevState, watchSensorAutoComplete])

			//Se elimina del autocomplete
			const copy = [...sensorsList]
			copy.splice(
				copy.findIndex((item) => item.sensorId === watchSensorAutoComplete.sensorId),
				1
			)

			setValue('sensorAutoComplete', null)
			setSensorsList(copy)
		}
	}

	const onDeleteSensor = (sensor) => {
		//Se elimina de la lista
		const copy = [...sensorsSelected]
		copy.splice(
			copy.findIndex((item) => item.sensorId === sensor.sensorId),
			1
		)
		setSensorsSelected(copy)
		//Se añade al autocomplete
		setSensorsList((prevState) => [...prevState, sensor])
	}

	const hasOnOffValue = (row) => {
		return row.onOff != null
	}

	//Function to control de changes on each row of the sensors table
	const handleChangeValue = (value, idx, field) => {
		let copy = [...sensorsSelected]
		let row = copy[idx]
		if (field == 'onOff') {
			row[field] = value
		} else {
			row[field] = Number(value)
		}
		setSensorsSelected(copy)
	}

	const isPositive = (name) => {
		var inputValue = getValues(name)
		return inputValue >= 0 ? true : false
	}

	return (
		<Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', height: '100%' }}>
			<AutoCompleteComponent
				size='small'
				/*  we do it to align "speed" and "dates" in 1 line to avoid rebuilding all component structure */
				sx={{ width: '100%', mb: 3.35 }}
				control={control}
				name='zoneId'
				label={t('alert.config.form.zone')}
				defaultValue={null}
				optionsLabel={'description'}
				keyProperty={'id'}
				options={zones}
				errors={errors?.zoneId}
				rules={{
					required: {
						value: false,
						message: t('comun.form-errors.required'),
					},
				}}
			/>

			<FieldsetComponent title={t('alert.config.form.speed')} style={{ width: '100%', marginBottom: '12px' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', pt: 1 }}>
					<TextFieldComponent
						sx={{ width: '50%', mr: 1 }}
						name='minSpeed'
						label={t('alert.config.form.speed-min')}
						control={control}
						size='small'
						type='number'
						errors={errors?.minSpeed}
						rules={{
							validate: {
								positive: () =>
									isPositive('minSpeed') || t('alert.config.form.validations.minus-value'),
								greaterThan: (minSpeedValue) =>
									parseInt(minSpeedValue) <= getValues('maxSpeed') ||
									t('alert.config.form.validations.greater-than'),
							},
						}}
					/>
					<TextFieldComponent
						sx={{ width: '50%', mr: 1 }}
						name='maxSpeed'
						label={t('alert.config.form.speed-max')}
						control={control}
						size='small'
						type='number'
						errors={errors?.maxSpeed}
						rules={{
							validate: () => isPositive('maxSpeed') || t('alert.config.form.validations.minus-value'),
						}}
					/>
					<TextFieldComponent
						sx={{ width: '50%', mr: 1 }}
						name='speedEnduranceMinutes'
						label={t('alert.config.form.speed-minutes')}
						control={control}
						size='small'
						type='number'
						errors={errors?.speedEnduranceMinutes}
						rules={{
							validate: () =>
								isPositive('speedEnduranceMinutes') || t('alert.config.form.validations.minus-value'),
						}}
					/>
				</Box>
			</FieldsetComponent>

			<FieldsetComponent title={t('alert.config.form.sensors')} style={{ width: '100%', flex: '1 1 auto' }}>
				<Box
					sx={{
						width: '100%',
						mt: 1,
						height: '100%',
					}}>
					<Box sx={{ display: 'flex', minWidth: '100%' }}>
						<Box sx={{ width: '90%' }}>
							<AutoCompleteComponent
								size='small'
								sx={{ minWidth: '100%' }}
								control={control}
								name='sensorAutoComplete'
								label={t('alert.config.form.sensors')}
								errors={errors?.sensorAutoComplete}
								optionsLabel={'description'}
								keyProperty={'sensorId'}
								options={sensorsList}
								evalMethod={(opt, val) => {
									return opt.sensorId === val.sensorId
								}}
							/>
						</Box>

						<Box sx={{ display: 'flex', width: '10%', justifyContent: 'flex-end' }}>
							<FormControl
								variant='standard'
								size='small'
								sx={{ width: '40px', alignSelf: 'flex-start' }}>
								<Tooltip title={t('alert.config.form.add-button')}>
									<span>
										<IconButton
											disabled={disableAddButton}
											color='primary'
											onClick={() => onAddSensors()}>
											<AddCircleIcon />
										</IconButton>
									</span>
								</Tooltip>
							</FormControl>
						</Box>
					</Box>

					<Box sx={{ width: '100%', maxHeight: '25vh', overflow: 'auto', display: 'flex' }}>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label='simple table'>
								<TableBody>
									{sensorsSelected.map((row, idx) => (
										<TableRow
											key={row.sensorId}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell component='th' scope='row'>
												{row.description}
											</TableCell>

											<TableCell
												sx={{ display: !hasOnOffValue(row) ? 'display-box' : 'none' }}
												component='th'
												scope='row'>
												<TextField
													type='number'
													size='small'
													label='Min'
													value={row.minValue ? row.minValue : ''}
													onChange={(e) => handleChangeValue(e.target.value, idx, 'minValue')}
												/>
											</TableCell>
											<TableCell
												sx={{ display: !hasOnOffValue(row) ? 'display-box' : 'none' }}
												component='th'
												scope='row'>
												<TextField
													type='number'
													size='small'
													label='Max'
													value={row.maxValue ? row.maxValue : ''}
													onChange={(e) =>
														handleChangeValue(e.target.value, idx, 'maxValue')
													}></TextField>
											</TableCell>
											<TableCell
												sx={{
													display: hasOnOffValue(row) ? 'block' : 'none',
												}}
												component='th'
												scope='row'>
												<FormControlLabel
													label='On/Off'
													labelPlacement='start'
													sx={{ mr: 1 }}
													control={
														<Checkbox
															checked={row.onOff}
															onChange={(e) =>
																handleChangeValue(e.target.checked, idx, 'onOff')
															}
														/>
													}></FormControlLabel>
											</TableCell>

											<TableCell component='th' scope='row' align='right'>
												<Tooltip title={t('comun.actions.delete')}>
													<span>
														<IconButton color='error' onClick={() => onDeleteSensor(row)}>
															<DeleteIcon />
														</IconButton>
													</span>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</FieldsetComponent>
		</Box>
	)
}
