import SearchIcon from '@mui/icons-material/Search'
import { Box, Tooltip } from '@mui/material'
import Button from '@mui/material/Button'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import '../../index.css'
import FieldsetComponent from '../comun/FieldsetComponent'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import DatePickerComponent from '../comun/form_fields/DatePickerComponent'
import { datetimeGMTToLocaleInISOFormat } from '../comun/DateUtils'
import { assignFilter } from '../../utils/customFunctions'
import { isStartDateBefore } from '../comun/DateUtils'

export default function ReportsFilter({
	onFilter,
	filter,
	reportOptions,
	projectId
}) {
	const [t] = useTranslation('common')
	const { control, handleSubmit, getValues, formState: { errors } } = useForm({})

	const onSearch = (data) => {
		let config = {}
		config.projectId = projectId
		config.exportId = data.exportType?.exportId
		config.startDate = datetimeGMTToLocaleInISOFormat(data.startDate)
		config.endDate = datetimeGMTToLocaleInISOFormat(data.endDate)
		assignFilter(config, filter)
		config.pageNo = 1
		onFilter(config)
	}

	return (
		<FieldsetComponent title={t('reports.fieldset')}>
			<Box
				sx={{ display: 'flex', m: 1, flexDirection: 'row' }}
				component='form'
				onSubmit={handleSubmit(onSearch)}>

				<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '95%', m: 1 }}>
					<DatePickerComponent
						defaultValue={null}
						size='small'
						sx={{ width: '33.333%', mr: 2 }}
						name='startDate'
						control={control}
						label={t('reports.filter.start-date')}
						errors={errors?.StartDate}
						rules={{
							validate: (startDateValue) => isStartDateBefore(startDateValue, getValues('EndDate')) || t('reports.filter.validations.start-date')
						}}
					/>
					<DatePickerComponent
						defaultValue={null}
						size='small'
						sx={{ width: '33.333%', mr: 2 }}
						name='endDate'
						control={control}
						label={t('reports.filter.end-date')}
					/>
					<AutoCompleteComponent
						defaultValue={null}
						size='small'
						sx={{ width: '33.333%' }}
						control={control}
						name='exportType'
						label={t('reports.filter.export-type')}
						optionsLabel={'description'}
						keyProperty={'exportId'}
						options={reportOptions.refProjectExportList ? reportOptions.refProjectExportList : []}
						evalMethod={(opt, val) => {
							return opt.exportId === val.exportId
						}}
					/>
				</Box>

				<Box sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}>
					<Tooltip title={t('track.table.content.search')}>
						<Button type='submit' variant='outlined' sx={{ height: '40px' }}>
							<SearchIcon />
						</Button>
					</Tooltip>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
