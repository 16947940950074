import { Box } from '@mui/system'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes } from '../../constants'
import { useProjectItemCountInsert } from '../../services/projectitem/count/useProjectItemCountInsert'
import { useProjectItemCountRequest } from '../../services/projectitem/count/useProjectItemCountRequest'
import { useProjectItemCountUpdate } from '../../services/projectitem/count/useProjectItemCountUpdate'
import BasicDialog from '../comun/BasicDialog'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import CountCanvas from './CountCanvas'
import CountColorForm from './CountColorForm'

export default function CountContainer({
	image,
	onClose,
	onOpen,
	projectItemId,
	canCreateEditAnnotations,
	isUpdating,
}) {
	const [t] = useTranslation('common')
	const [selectedColor, setSelectedColor] = useState(null)
	const [count, setCount] = useState(0)
	const methodsForm = useForm({ mode: 'onSubmit' })
	const [dataSelected, setDataSelected] = useState([])
	const [rowSelected, setRowSelected] = useState(null)
	const colors = ['#f44e3b', '#fe9200', '#9c27b0', '#03a9f4', '#ffeb3b', '#4caf50']
	const [comments, setComments] = useState([])

	//Error
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeMessage, setTypeMessage] = useState(null)

	//Services
	const {
		projectItemCountInsert,
		dataProjectItemCountInsert,
		isLoadingProjectItemCountInsert,
		errorProjectItemCountInsert,
	} = useProjectItemCountInsert()

	const {
		projectItemCountUpdate,
		dataProjectItemCountUpdate,
		isLoadingProjectItemCountUpdate,
		errorProjectItemCountUpdate,
	} = useProjectItemCountUpdate()

	const {
		projectItemCountRequest,
		dataProjectItemCountRequest,
		isLoadingProjectItemCountRequest,
		errorProjectItemCountRequest,
	} = useProjectItemCountRequest()

	useEffect(() => {
		projectItemCountRequest(projectItemId)
	}, [])

	useEffect(() => {
		if (dataProjectItemCountRequest) {
			setDataSelected(dataProjectItemCountRequest.counts)
			setComments(dataProjectItemCountRequest.comments)
		}
	}, [dataProjectItemCountRequest])

	useEffect(() => {
		if (dataSelected) {
			let auxCount = 0
			dataSelected.forEach((row) => {
				auxCount += row.count
			})
			setCount(auxCount)
		}
	}, [dataSelected])

	useEffect(() => {
		if (dataProjectItemCountUpdate) {
			onClose(true)
		} else if (dataProjectItemCountInsert) {
			onClose(true)
		}
	}, [dataProjectItemCountUpdate, dataProjectItemCountInsert])

	useEffect(() => {
		if (errorProjectItemCountInsert || errorProjectItemCountUpdate || errorProjectItemCountRequest) {
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setOpenError(true)
			if (errorProjectItemCountInsert) {
				setErrorMsg(errorProjectItemCountInsert)
			} else if (errorProjectItemCountUpdate) {
				setErrorMsg(errorProjectItemCountUpdate)
			} else if (errorProjectItemCountRequest) {
				setErrorMsg(errorProjectItemCountInsert)
			}
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorProjectItemCountInsert, errorProjectItemCountUpdate, errorProjectItemCountRequest])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const saveCount = () => {
		dataSelected.forEach((row) => {
			row.dots = row.dots.map((d) => {
				return { x: d.x.toFixed(0), y: d.y.toFixed(0) }
			})
		})
		let projectItem = {
			projectItemId: projectItemId,
			counts: dataSelected,
		}
		isUpdating ? projectItemCountUpdate(projectItem) : projectItemCountInsert(projectItem)
	}

	return (
		<BasicDialog title={t('count.title')} fullScreen noactions onOpen={onOpen} onClose={onClose}>
			<Box sx={{ display: 'flex', width: '100%' }}>
				<Box
					sx={{
						width: '70%',
						height: '85vh',
					}}>
					<CountCanvas
						image={image}
						selectedColor={selectedColor}
						rowSelected={rowSelected}
						setRowSelected={setRowSelected}
						dataSelected={dataSelected}
						setDataSelected={setDataSelected}></CountCanvas>
				</Box>
				<Box sx={{ width: '30%', display: 'flex', flexDirection: 'column' }}>
					<CountColorForm
						onClose={onClose}
						saveCount={saveCount}
						setRowSelected={setRowSelected}
						rowSelected={rowSelected}
						methodsForm={methodsForm}
						setSelectedColor={setSelectedColor}
						dataSelected={dataSelected}
						setDataSelected={setDataSelected}
						colors={colors}
						canCreateEditAnnotations={canCreateEditAnnotations}
						count={count}
						projectItemId={projectItemId}
						comments={comments}
					/>
				</Box>
				<BackdropComponent
					open={
						isLoadingProjectItemCountInsert ||
						isLoadingProjectItemCountUpdate ||
						isLoadingProjectItemCountRequest
					}
				/>
				<MessageSnackBar
					openError={openError}
					errorMsg={errorMsg}
					typeMsg={typeMessage}
					handleCloseError={handleCloseError}
				/>
			</Box>
		</BasicDialog>
	)
}
