import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import CalibrationContainer from '../components/calibration/CalibrationContainer'

const CalibrationsPage = () => {
	const history = useHistory()

	return (
		<ErrorBoundary history={history}>
			<CalibrationContainer />
		</ErrorBoundary>
	)
}

export default CalibrationsPage
