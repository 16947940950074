import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useCalibrationDelete = () => {
	const [calibrationDataDelete, setCalibrationDataDelete] = useState(null)
	const [errorCalibrationDelete, setErrorCalibrationDelete] = useState(null)
	const [isLoadingDelete, setIsLoadingDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const calibrationDelete = useCallback(
		(id) => {
			const config = {
				params: {
					calibrationId: id,
				},
			}

			client
				.delete(baseUrl + 'horus/f3/vessel/camera/calibrations', config)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setCalibrationDataDelete(true)
					} else {
						setErrorCalibrationDelete({ statusText: result.data.errorMsg })
					}
					setIsLoadingDelete(false)
				})
				.catch((error) => {
					setErrorCalibrationDelete(error.response)
					setIsLoadingDelete(false)
				})

			setCalibrationDataDelete(null)
			setErrorCalibrationDelete(null)
			setIsLoadingDelete(true)
		},
		[client]
	)

	return {
		calibrationDelete,
		isLoadingDelete,
		calibrationDataDelete,
		errorCalibrationDelete,
		setCalibrationDataDelete,
	}
}
