import * as React from 'react'
import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { Button } from '@mui/material'
import '../../index.css'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { CustomDialog, CustomDialogTitle } from './StyleComponentUtils'

export default function ConfirmationDialog({
	dialogTitle,
	dialogSaveButton,
	dialogCancelButton,
	dialogContent,
	onOpen,
	onClose,
	isDelete,
	annotationToDelete,
}) {
	return (
		<CustomDialog open={onOpen}>
			<CustomDialogTitle id='alert-dialog-title' onClose={onClose} onCloseText='Close'>
				{dialogTitle}
				<IconButton
					aria-label='close'
					onClick={() => onClose(false)}
					sx={{ position: 'absolute', right: 8, top: 8 }}>
					<CloseIcon />
				</IconButton>
			</CustomDialogTitle>

			<Box sx={{ ml: 3, mr: 3, mb: 1.5 }}>
				<DialogContent dividers>{dialogContent}</DialogContent>
				<DialogActions sx={{ mt: 1, pb: 1 }}>
					<Button
						variant='outlined'
						onClick={() => (annotationToDelete ? onClose(true, annotationToDelete) : onClose(true))}
						color={isDelete ? 'error' : 'primary'}>
						{dialogSaveButton}
					</Button>
					<Button variant='outlined' onClick={() => onClose(false)}>
						{dialogCancelButton}
					</Button>
				</DialogActions>
			</Box>
		</CustomDialog>
	)
}
