import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import { visuallyHidden } from '@mui/utils'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { StyledTableHeadRow } from './StyleComponentUtils'
import { formatTimeTimer, formatTimeTimerDetails } from './DateUtils'

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1
	}
	if (b[orderBy] > a[orderBy]) {
		return 1
	}
	return 0
}

export function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) {
			return order
		}
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

export function TimerTableCell(props) {
	const { timerType } = props
	const days = timerType.days
	const hours = timerType.hours
	const minutes = timerType.minutes
	const seconds = timerType.seconds
	const formattedTime = formatTimeTimer(days, hours, minutes, seconds)

	return <TableCell align='center'>{formattedTime}</TableCell>
}

export function TimerTableCellDetails(props) {
	const { timer, sx } = props
	const days = timer.days
	const hours = timer.hours
	const minutes = timer.minutes
	const seconds = timer.seconds
	const formattedTime = formatTimeTimerDetails(days, hours, minutes, seconds)

	return (
		<TableCell align='center' sx={sx}>
			{formattedTime}
		</TableCell>
	)
}

export function EnhancedTableHead(props) {
	const [t] = useTranslation('common')

	const {
		order,
		orderBy,
		onRequestSort,
		headCells,
		addAction,
		customAction,
		columnQuantityForGroup1,
		groupTitle1,
		columnQuantityForGroup2,
		groupTitle2,
		addActionDisabled,
	} = props
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	return (
		<TableHead>
			{columnQuantityForGroup1 || columnQuantityForGroup2 ? (
				<TableRow style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
					<TableCell
						align='center'
						colSpan={columnQuantityForGroup1 ? columnQuantityForGroup1 : 0}
						className='table-space-reject'>
						<Box component='div' className='table-header'>
							{groupTitle1 ? groupTitle1 : ''}
						</Box>
					</TableCell>
					<TableCell
						align='center'
						colSpan={columnQuantityForGroup2 ? columnQuantityForGroup2 : 0}
						className='table-space-reject'>
						<Box component='div' className='table-header'>
							{groupTitle2 ? groupTitle2 : ''}
						</Box>
					</TableCell>
				</TableRow>
			) : null}
			<StyledTableHeadRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={headCell.align ? headCell.align : 'center'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						className={
							headCell.orderBy
								? headCell.excluirExcel
									? 'excluir-excel, table-header-sort-padding'
									: 'table-header-sort-padding'
								: headCell.excluirExcel
								? 'excluir-excel, table-header-sort-label'
								: 'table-header-sort-label'
						}>
						<Box component='div' className={headCell.excluirExcel ? '' : 'table-header-separator'}>
							{addAction && headCell.id === 'actions' ? (
								<Tooltip title={t('comun.actions.new')}>
									<Button
										disabled={addActionDisabled}
										onClick={() => {
											addAction()
										}}
										variant='text'
										sx={{ width: '40px', height: '40px' }}>
										<AddCircleOutlineIcon />
									</Button>
								</Tooltip>
							) : (
								''
							)}
							{customAction && headCell.id === 'actions' ? customAction : null}
							{headCell.orderBy ? (
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}>
									{headCell.label}
									{orderBy === headCell.id ? (
										<Box component='span' sx={visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</Box>
									) : null}
								</TableSortLabel>
							) : (
								headCell.label
							)}
						</Box>
					</TableCell>
				))}
			</StyledTableHeadRow>
		</TableHead>
	)
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
}
