import { Button, DialogContent, DialogContentText, Grid } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import logoImg from '../assets/horus-azul-error.png'
import backgroundImg from '../assets/Login.jpg'
import { rolesCookieName } from '../constants'
import { readCookie } from '../utils/cookie'
import { calculateRouteRoles } from '../utils/securityRoles'

const ErrorPage = () => {
	const history = useHistory()
	const [t] = useTranslation('common')
	const [roles, setRoles] = useState([])
	const cookieRoles = readCookie(rolesCookieName)

	React.useEffect(() => {
		setRoles(cookieRoles.split(','))
	}, [cookieRoles])

	
	return (
		<Grid item container direction="column" alignItems="center" justifyContent="center" spacing={0} component='main' sx={{ height: '100vh' ,
			backgroundImage: `url(${backgroundImg})`,
			backgroundRepeat: 'no-repeat',
			backgroundColor: (t) =>
				t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			}}>

			<Box sx={{
				 backgroundColor: 'rgb(255 255 255 / 90%);'}}>
				<DialogContent >
					<DialogContentText
						id='alert-dialog-description'
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							
						}}>
						<Box component='div' >
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', my: 1.5 }}>
									<img  src={logoImg} />
									
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', my: 1.5 }}>
									<Typography variant='h5' sx={{width: '100%'}}>{t('comun.errors.not-have-permission')} </Typography>
								</Box>
							</Box>
							<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
								<Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%', my: 1.5 }}>
								<Button  onClick={()=> (history.push(calculateRouteRoles(roles)),  window.location.reload(false))} variant='outlined'>
									{t('comun.actions.return')}
								</Button>
								</Box>
							</Box>
						</Box>
					</DialogContentText>
				</DialogContent>
			</Box>
							
		</Grid>
	)
}

export default ErrorPage
