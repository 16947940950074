import { Autocomplete, Box, Button, IconButton, Popover, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import DateRangeIcon from '@mui/icons-material/DateRange'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useGetTimeRangeOptions } from '../../../services/preview/useGetTimeRangeOptions'
import { datetimeGMTToLocaleInISOFormat } from '../../comun/DateUtils'
import useStore from '../PreviewStore'
import { useShallow } from 'zustand/shallow'
import { getDateLocale } from '../../../App'
import { parseISO } from 'date-fns'

export default function TimeRangeSelector({
	updateTimeRange,
	vesselApiId,
	timeData: { infoRangeMillis, infoRangeShiftMillis, currentRangeStart, firstInfoTime, lastInfoTime },
	isLiveViewSelected,
}) {
	const [t, i18n] = useTranslation('common')
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const [startDate, setStartDate] = useState(null)
	const [rangeMillis, setRangeMillis] = useState(null)
	const [rangeShiftMillis, setRangeShiftMillis] = useState(null)
	const { getTimeRangeOptions, dataTimeRange } = useGetTimeRangeOptions(vesselApiId)
	const { rangeConfigEnabled } = useStore(
		useShallow((state) => ({
			rangeConfigEnabled: state.rangeConfigEnabled,
		}))
	)

	const disabledSave = startDate == null || rangeMillis == null || rangeShiftMillis == null

	useEffect(() => {
		getTimeRangeOptions()
	}, [])

	useEffect(() => {
		if (dataTimeRange) {
			let auxRange = dataTimeRange.rangeTime.find((i) => i.timeMillis == infoRangeMillis)
			let auxRangeShift = dataTimeRange.rangeShiftTime.find((i) => i.timeMillis == infoRangeShiftMillis)
			setRangeMillis(auxRange)
			setRangeShiftMillis(auxRangeShift)
			setStartDate(currentRangeStart)
		}
	}, [dataTimeRange])

	const handleOpen = (e) => {
		setAnchorEl(e.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleChangeStartDate = (newValue) => {
		setStartDate(newValue)
	}
	const handleSave = () => {
		//pause()
		updateTimeRange(datetimeGMTToLocaleInISOFormat(startDate), rangeMillis.timeMillis, rangeShiftMillis.timeMillis)
		setAnchorEl(null)
	}
	return (
		<Box sx={{ width: '5%', display: 'flex', justifyContent: 'center' }}>
			<IconButton
				onClick={handleOpen}
				color={id != null ? 'primary' : 'default'}
				disabled={!rangeConfigEnabled || isLiveViewSelected}>
				<DateRangeIcon />
			</IconButton>
			{dataTimeRange && (
				<Popover
					id={id}
					open={open}
					anchorEl={anchorEl}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}>
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '40vw', height: '20vh' }}>
						<Box
							sx={{
								width: '100%',
								height: '5vh',
								color: 'white',
								backgroundColor: '#1976d2',
								display: 'flex',
								alignItems: 'center',
								pl: 2,
							}}>
							{t('preview.time-control.title')}
						</Box>

						<Box sx={{ height: '80%', display: 'flex', p: 0, pt: 2 }}>
							<LocalizationProvider
								dateAdapter={AdapterDateFns}
								adapterLocale={getDateLocale(i18n.language)}>
								<DateTimePicker
									clearable
									minDate={parseISO(firstInfoTime)}
									maxDate={parseISO(lastInfoTime)}
									onChange={handleChangeStartDate}
									label={t('preview.time-control.start-date')}
									value={startDate}
									inputFormat='dd/MM/yyyy HH:mm'
									renderInput={(params) => (
										<TextField size='small' {...params} sx={{ width: '33%', m: 1 }} />
									)}
								/>
							</LocalizationProvider>
							<Autocomplete
								sx={{ width: '32%', m: 1 }}
								options={dataTimeRange.rangeTime}
								value={rangeMillis}
								getOptionLabel={(option) => option.name}
								onChange={(event, newValue) => {
									setRangeMillis(newValue)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t('preview.time-control.range')}
										variant='outlined'
										size='small'
									/>
								)}
							/>
							<Autocomplete
								sx={{ width: '32%', m: 1 }}
								options={dataTimeRange.rangeShiftTime}
								value={rangeShiftMillis}
								getOptionLabel={(option) => option.name}
								onChange={(event, newValue) => {
									setRangeShiftMillis(newValue)
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label={t('preview.time-control.shift-range')}
										variant='outlined'
										size='small'
									/>
								)}
							/>
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'end' }}>
							<Button variant='outlined' sx={{ m: 1 }} onClick={handleSave} disabled={disabledSave}>
								{t('comun.actions.save')}
							</Button>
							<Button variant='outlined' color='inherit' sx={{ m: 1 }} onClick={handleClose}>
								{t('comun.actions.cancel')}
							</Button>
						</Box>
					</Box>
				</Popover>
			)}
		</Box>
	)
}
