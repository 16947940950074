import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes } from '../../../constants'
import { useConfigAlertFilterDelete } from '../../../services/alerts/configalert/useConfigAlertFilterDelete'
import { useConfigAlertFilterPost } from '../../../services/alerts/configalert/useConfigAlertFilterPost'
import { assignFilter } from '../../../utils/customFunctions'
import { datetimeGMTToLocaleInISOFormat, isStartDateBefore } from '../../comun/DateUtils'
import FieldsetComponent from '../../comun/FieldsetComponent'
import FilterFunctions from '../../comun/FilterFunctions'
import MessageSnackBar from '../../comun/MessageSnackBar'
import AutoCompleteComponent from '../../comun/form_fields/AutoCompleteComponent'
import DatePickerComponent from '../../comun/form_fields/DatePickerComponent'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'

export default function AlertConfigFilter({ onFilter, filter, vessels, zones, alertConfigFilter, favouriteFilters }) {
	const {
		control,
		handleSubmit,
		getValues,
		setValue,
		reset,
		formState: { errors },
	} = useForm({ mode: 'onTouched' })

	const [t] = useTranslation('common')

	useEffect(() => {
		if (alertConfigFilter) {
			let vessel = vessels.find((v) => v.vesselApiId == alertConfigFilter.vesselApiId)
			let zone = zones.find((z) => z.id == alertConfigFilter.zoneId)
			setValue('name', alertConfigFilter.configName ? alertConfigFilter.configName : '')
			setValue('zoneId', zone)
			setValue('startDate', alertConfigFilter.startDate)
			setValue('endDate', alertConfigFilter.endDate)
			setValue('vesselApiId', vessel)
		}
	}, [alertConfigFilter])

	const onSearch = (data) => {
		data.startDate = datetimeGMTToLocaleInISOFormat(data.startDate)
		data.endDate = datetimeGMTToLocaleInISOFormat(data.endDate)
		data.zoneId = data.zoneId?.id
		data.vesselApiId = data.vesselApiId?.vesselApiId
		//We do this to mantain the data about sort, page, rowsPerPage, direction...etc
		assignFilter(data, filter)
		onFilter(data)
	}

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useConfigAlertFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useConfigAlertFilterDelete()

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({})

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const saveFilter = (filterName) => {
		let formValues = getValues()

		let filterDataAux = {
			filterName: filterName,
			configName: formValues.name,
			zoneId: formValues.zoneId?.id,
			startDate: datetimeGMTToLocaleInISOFormat(formValues.startDate),
			endDate: datetimeGMTToLocaleInISOFormat(formValues.endDate),
			vesselApiId: formValues.vesselApiId?.vesselApiId,
		}

		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter && filterData) {
			setFavFilters((prevState) => [...prevState, filterData])
		}
	}, [dataFilter, filterData])

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	}

	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			let zone = zones.find((s) => s.id == selectedFilter.zoneId)
			let vessel = vessels.find((s) => s.vesselApiId == selectedFilter.vesselApiId)

			setValue('name', selectedFilter.name)
			setValue('zoneId', zone)
			setValue('startDate', selectedFilter.startDate)
			setValue('endDate', selectedFilter.endDate)
			setValue('vesselApiId', vessel)
		}
	}, [selectedFilter])

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
	}

	// Set errrors
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<FieldsetComponent title={t('alert.config.filter.title')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box sx={{ display: 'flex' }} component='form' onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }}>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<TextFieldComponent
							defaultValue={''}
							sx={{ width: '50%', m: 1 }}
							name='name'
							label={t('alert.config.filter.name')}
							control={control}
							size='small'
						/>

						<AutoCompleteComponent
							defaultValue={null}
							size='small'
							sx={{ width: '50%', m: 1 }}
							control={control}
							name='zoneId'
							label={t('alert.config.filter.zone')}
							optionsLabel={'description'}
							keyProperty={'id'}
							options={zones || []}
						/>
					</Box>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<Box sx={{ display: 'flex', width: '50%', m: 1 }}>
							<DatePickerComponent
								defaultValue={null}
								size='small'
								sx={{ width: '50%' }}
								name='startDate'
								control={control}
								label={t('alert.config.filter.from-date')}
								errors={errors?.startDate}
								rules={{
									validate: (startDateValue) =>
										isStartDateBefore(startDateValue, getValues('endDate')) ||
										t('programs.validations.start-date'),
								}}
							/>

							<DatePickerComponent
								defaultValue={null}
								size='small'
								sx={{ width: '50%', ml: 1 }}
								name='endDate'
								control={control}
								label={t('alert.config.filter.to-date')}
							/>
						</Box>
						<Box sx={{ display: 'flex', width: '50%', m: 1 }}>
							<AutoCompleteComponent
								defaultValue={null}
								size='small'
								sx={{ width: '100%' }}
								control={control}
								name='vesselApiId'
								label={t('alert.config.filter.vessel')}
								optionsLabel={'vesselName'}
								keyProperty={'vesselApiId'}
								evalMethod={(opt, val) => {
									return opt.vesselApiId === val.vesselApiId
								}}
								options={vessels || []}
							/>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column' }}>
					<FilterFunctions
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						filters={favFilters}
						threeLines={false}
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
