import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person'
import { AppBar } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Toolbar from '@mui/material/Toolbar'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom/cjs/react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { appRoutes, jwtCookieExpiryName, jwtCookieName, rolesCookieName } from '../../constants'
import { ApiClientContext } from '../../services/ApiClientProvider'
import useLogoutRequest from '../../services/horus/useLogoutRequest'
import { useUserProfileRequest } from '../../services/usersprofile/useUserProfileRequest'
import { setCookie } from '../../utils/cookie'
import { useLocalStorage } from '../hooks/useLocalStorage'

export default function TitleBar({ backButtonAction }) {
	const [t] = useTranslation('common')
	const { userProfileRequest, userProfileData } = useUserProfileRequest()
	const history = useHistory()
	const { setTokenValue } = useContext(ApiClientContext)
	const location = useLocation()
	const [title, setTitle] = useState('')

	const { logOut, dataLogOut } = useLogoutRequest()

	const userProfileName = null
	const [storedUserProfileName, setStoredUserProfileName] = useLocalStorage('userProfileName', userProfileName)

	useEffect(() => {
		if (location) {
			console.log(location)
			switch (location.pathname) {
				case appRoutes.HOME:
					setTitle(t('menu.dashboard'))
					break
				case appRoutes.PREVIEW:
					setTitle(
						location.state?.vessel
							? t('menu.preview') + ' ' + location.state?.vessel?.vesselName
							: t('menu.preview') +
									' ' +
									location.state?.project?.description +
									' (' +
									location.state?.project?.projectStatusName +
									')'
					)
					break
				case appRoutes.TIMER_PROJECTS:
					setTitle(t('menu.timer-projects'))
					break
				case appRoutes.TIMER_USERS:
					setTitle(t('menu.timer-users'))
					break
				case appRoutes.PLANIFICATIONS:
					setTitle(t('menu.planifications'))
					break
				case appRoutes.PROJECTS:
					setTitle(t('menu.projects'))
					break
				case appRoutes.REPORTS:
					setTitle(t('menu.reports'))
					break
				case appRoutes.ALERTS:
					setTitle(t('menu.alerts'))
					break
				case appRoutes.ALERTS_CONFIG:
					setTitle(t('menu.alert-config'))
					break
				case appRoutes.ALERT_ZONES:
					setTitle(t('menu.alert-zones'))
					break
				case appRoutes.MESSENGER_PAGE:
					setTitle(t('menu.messenger'))
					break
				case appRoutes.USERS_MANAGEMENT:
					setTitle(t('menu.users'))
					break
				case appRoutes.USERS_PROFILE:
					setTitle(t('menu.users-profile'))
					break
				case appRoutes.CALIBRATIONS:
					setTitle(t('menu.calibrations'))
					break
				case appRoutes.COUNT:
					setTitle(t('menu.count'))
					break
				case appRoutes.DOWNLOADS:
				setTitle(t('menu.downloads'))
				break
			}
		}
	}, [location])

	useEffect(() => {
		if (storedUserProfileName == null) {
			userProfileRequest()
		}
	}, [])

	useEffect(() => {
		if (userProfileData) {
			setStoredUserProfileName(userProfileData.name)
		}
	}, [userProfileData])

	useEffect(() => {
		if (dataLogOut) {
			setCookie(jwtCookieName, '')
			setCookie(jwtCookieExpiryName, '')
			setCookie(rolesCookieName, '')
			setStoredUserProfileName(null)
			setTokenValue(null)
			history.push({ pathname: 'login' })
		}
	}, [dataLogOut])

	const handleLogOut = () => {
		logOut()
	}

	const handleGoBack = () => {
		history.goBack()
	}

	return (
		<AppBar
			style={{
				width: '100%',
				flexGrow: 0,
				top: 'auto',
				left: 'auto',
				right: 'auto',
				bottom: 'auto',
				position: 'relative',
				height: '4vh',
			}}>
			<Toolbar sx={{ p: 0, minHeight: 'auto !important', height: '100%' }} variant='dense'>
				{location?.pathname == appRoutes.PREVIEW && (
					<Tooltip title={t('comun.actions.return')}>
						<IconButton color='inherit' onClick={handleGoBack} sx={{ mr: 2 }}>
							<ArrowBackIosNewIcon />
						</IconButton>
					</Tooltip>
				)}
				<Typography
					component='h4'
					variant='h6'
					color='inherit'
					noWrap
					sx={{ flexGrow: 1, lineHeight: 'normal' }}>
					{title}
				</Typography>
				<Typography
					component='h5'
					variant='h6'
					color='inherit'
					noWrap
					style={{ display: 'inline-block', padding: '0 7px 0 15px' }}>
					{storedUserProfileName}
				</Typography>

				<Tooltip title={t('title-bar.profile')}>
					<IconButton color='inherit' component={Link} to={appRoutes.USERS_PROFILE}>
						<PersonIcon />
					</IconButton>
				</Tooltip>
				<Tooltip title={t('title-bar.logout')}>
					<IconButton color='inherit' onClick={handleLogOut}>
						<LogoutIcon />
					</IconButton>
				</Tooltip>
			</Toolbar>
		</AppBar>
	)
}
