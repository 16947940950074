import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { messageDialogTypes } from '../../constants'
import { useDashboardInfo } from '../../services/dashboard/useDashboardInfo'
import { dateSubstractDays, datetimeGMTToLocaleInISOFormat } from '../comun/DateUtils'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import DashboardMap from './map/DashboardMap'
import DashboardTable from './table/DashboardTable'
import { use7DayMapInfo } from '../../services/dashboard/use7DayMapInfo'
export default function NewDashboardContainer() {
	const [vessels, setVessels] = useState([])
	const [vesselSelected, setVesselSelected] = useState(null)
	const { dashboardInfo, isLoading, dashboardData, error, refreshInterval, setError } = useDashboardInfo()
	const { get7DayMapInfo, isLoadingTrack, dataTrack, setDataTrack } = use7DayMapInfo()
	useEffect(() => {
		if (dashboardData) {
			if (vesselSelected) {
				let copy = [...dashboardData]
				copy[copy.findIndex((c) => c.vesselId == vesselSelected.vesselId)].selected = true
				setVessels(copy)
			} else {
				setVessels(dashboardData)
			}
		}
		return () => {
			setVessels(null)
		}
	}, [dashboardData])

	useEffect(() => {
		let interval = null
		if (refreshInterval) {
			interval = setInterval(() => {
				dashboardInfo()
			}, [refreshInterval])
		} else {
			dashboardInfo()
		}
		return () => {
			clearInterval(interval)
		}
	}, [refreshInterval])

	const handleRowClick = (row) => {
		let copy = [...vessels]
		let idx = copy.findIndex((v) => v.vesselId == row.vesselId)
		copy[idx].selected = !copy[idx].selected
		if (vesselSelected) {
			copy[copy.findIndex((v) => v.vesselId == vesselSelected.vesselId)].selected = false
		}
		setVessels(copy)
		if (copy[idx].selected) {
			setVesselSelected(copy[idx])
			handleShowInMap(copy[idx])
		} else {
			setVesselSelected(null)
			setDataTrack(null)
		}
	}

	const handleShowInMap = (row) => {
		get7DayMapInfo(row.vesselId, row.date)
	}

	return (
		<Box
			sx={{
				display: 'grid',
				height: '100%',
				gridTemplateColumns: '1fr 1fr',
				padding: 1,
			}}>
			<MessageSnackBar
				openError={error != null}
				errorMsg={error}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
				handleCloseError={() => setError(null)}
			/>
			<BackdropComponent open={isLoading || isLoadingTrack} />
			<Box sx={{ mr: 1, mb: 1 }}>
				<DashboardTable
					rows={vessels}
					handleRowClick={handleRowClick}
					vesselSelected={vesselSelected}
					dashboardInfo={dashboardInfo}
				/>
			</Box>
			<Box sx={{ mr: 1, mb: 2 }}>
				<DashboardMap
					rows={vessels}
					vesselSelected={vesselSelected}
					dataTrack={dataTrack}
					handleRowClick={handleRowClick}
				/>
			</Box>
		</Box>
	)
}
