import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserActivate = () => {
	const [userDataActivate, setUserDataActivate] = useState(null)
	const [errorUserActivate, setErrorUserActivate] = useState(null)
	const [isLoadingUserActivate, setIsLoadingUserActivate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const userActivate = useCallback(
		(username) => {
			client
				.put(baseUrl + 'horus/f3/user/act?userName=' + username)
				.then((result) => {
					setIsLoadingUserActivate(false)
					if (result.status === 200 && result.data.success) {
						setUserDataActivate({ data: result.data })
					} else {
						setErrorUserActivate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorUserActivate(error.response)
					setIsLoadingUserActivate(false)
				})

			setIsLoadingUserActivate(true)
			setUserDataActivate(null)
			setErrorUserActivate(null)
		},
		[client]
	)

	return {
		userActivate,
		isLoadingUserActivate,
		userDataActivate,
		errorUserActivate,
		setErrorUserActivate,
	}
}
