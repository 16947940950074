import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useVideoAsignedConsolidation } from '../../../../services/projects/preview/video/video-asign/useVideoAsignedConsolidation'
import { useVideoAsignedDelete } from '../../../../services/projects/preview/video/video-asign/useVideoAsignedDelete'
import { useVideoAsignedInsert } from '../../../../services/projects/preview/video/video-asign/useVideoAsignedInsert'
import { useVideoAsignedRequest } from '../../../../services/projects/preview/video/video-asign/useVideoAsignedRequest'
import BasicDialog from '../../../comun/BasicDialog'
import { datetimeGMTToLocaleInISOFormat, datetimeUTCtoString } from '../../../comun/DateUtils'
import LabelValueComponent from '../../../comun/LabelValueComponent'
import MessageSnackBar from '../../../comun/MessageSnackBar'
import BackdropComponent from '../../../comun/utils/BackdropComponent'
import ProjectVideosForm from './ProjectVideosForm'
import ProjectVideosTable from './ProjectVideosTable'

export default function VideoRequestModal({
	open,
	onClose,
	defaultFrom,
	defaultTo,
	projectId,
	vesselApiId,
	numCameras,
	projectName,
}) {
	const [t] = useTranslation('common')
	//TODO cambiar los servicios a los de añadir videos a proyectos
	// const { videoRequest, dataVideoRequest, isLoadingVideoRequest, errorVideoRequest } = usePreviewVideoRequest()
	// const { videoRequestDelete, dataVideoDelete } = usePreviewVideoDelete()
	// const { videoRequestInsert, videoRequestDataInsert, errorVideoRequestInsert } = useVideoRequestInsert()

	const { videoAsignedRequest, videoAsignedRequestData, isLoadingVideoAsigned, errorVideoAsignedRequest } =
		useVideoAsignedRequest()

	const { videoAsignedInsert, videoAsignedInsertData, isLoadingVideoAsignedInsert, errorVideoAsignedInsert } =
		useVideoAsignedInsert()

	const { videoAsignedDelete, videoAsignedDeleteData, isLoadingVideoAsignedDelete, errorVideoAsignedDelete } =
		useVideoAsignedDelete()

	const {
		videoAsignedConsolidation,
		videoAsignedConsolidationData,
		isLoadingVideoAsignedConsolidation,
		errorVideoAsignedConsolidation,
	} = useVideoAsignedConsolidation()

	const [openMsg, setOpenMsg] = useState(false)
	const [msg, setMsg] = useState(null)
	const [from, setFrom] = useState(defaultFrom)
	const [to, setTo] = useState(defaultTo)
	const [checked, setChecked] = useState(new Array(numCameras).fill(true))
	const [dateRangeList, setDateRangeList] = useState([])
	const [videosToBeAssignedCount, setVideosToBeAssignedCount] = useState(0)
	const [videosToBeRemovedCount, setVideosToBeRemovedCount] = useState(0)

	const onFilter = () => {
		let data = {}
		let cams = checked.map((c, idx) => (c ? idx + 1 : null)).filter((c) => c != null)
		if (cams.length != 0 && from != null && to != null) {
			data.from = datetimeGMTToLocaleInISOFormat(from)
			data.to = datetimeGMTToLocaleInISOFormat(to)
			data.projectId = projectId
			videoAsignedRequest(data, cams)
		}
	}

	useEffect(() => {
		onFilter()
	}, [])

	useEffect(() => {
		if (videoAsignedRequestData) {
			setDateRangeList(videoAsignedRequestData.dateRangeList)
			setVideosToBeAssignedCount(videoAsignedRequestData.videosToBeAssignedCount)
			setVideosToBeRemovedCount(videoAsignedRequestData.videosToBeRemovedCount)
		}
	}, [videoAsignedRequestData])

	useEffect(() => {
		if (videoAsignedConsolidationData) {
			setDateRangeList(videoAsignedConsolidationData.dateRangeList)
			setVideosToBeAssignedCount(videoAsignedConsolidationData.videosToBeAssignedCount)
			setVideosToBeRemovedCount(videoAsignedConsolidationData.videosToBeRemovedCount)
		}
	}, [videoAsignedConsolidationData])

	useEffect(() => {
		if (videoAsignedDeleteData) {
			onFilter()
		}
	}, [videoAsignedDeleteData])

	useEffect(() => {
		if (videoAsignedInsertData) {
			onFilter()
		}
	}, [videoAsignedInsertData])

	useEffect(() => {
		if (errorVideoAsignedConsolidation) {
			setOpenMsg(true)
			setMsg(errorVideoAsignedConsolidation)
		} else if (errorVideoAsignedDelete) {
			setOpenMsg(true)
			setMsg(errorVideoAsignedDelete)
		} else if (errorVideoAsignedInsert) {
			setOpenMsg(true)
			setMsg(errorVideoAsignedInsert)
		} else if (errorVideoAsignedRequest) {
			setOpenMsg(true)
			setMsg(errorVideoAsignedRequest)
		} else {
			setOpenMsg(false)
			setMsg(null)
		}
	}, [errorVideoAsignedConsolidation, errorVideoAsignedDelete, errorVideoAsignedInsert, errorVideoAsignedRequest])

	const handleCloseMsg = () => {
		setOpenMsg(false)
		setMsg(null)
	}

	const onAssignVideos = () => {
		//Request videos and then close if everything is fine
		let cams = checked.map((c, idx) => (c ? idx + 1 : null)).filter((c) => c != null)
		let data = {
			cams: cams,
			from: datetimeGMTToLocaleInISOFormat(from),
			to: datetimeGMTToLocaleInISOFormat(to),
			projectId: projectId,
			vesselApiId: vesselApiId,
		}

		videoAsignedInsert(data)
	}

	const onDeleteAsigned = (row) => {
		videoAsignedDelete(row)
	}

	const onConsolidateDates = () => {
		let cams = checked.map((c, idx) => (c ? idx + 1 : null)).filter((c) => c != null)
		let data = {
			cams: cams,
			from: datetimeGMTToLocaleInISOFormat(from),
			to: datetimeGMTToLocaleInISOFormat(to),
			projectId: projectId,
			vesselApiId: vesselApiId,
		}
		videoAsignedConsolidation(data, cams)
	}

	return (
		<BasicDialog title={t('project-videos.title') + ': ' + projectName} onOpen={open} onClose={onClose}>
			<BackdropComponent
				open={
					isLoadingVideoAsignedConsolidation ||
					isLoadingVideoAsigned ||
					isLoadingVideoAsignedInsert ||
					isLoadingVideoAsignedDelete
				}
			/>
			<MessageSnackBar openError={openMsg} errorMsg={msg} handleCloseError={handleCloseMsg} />
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mr: 3 }}>
					<ProjectVideosForm
						projectId={projectId}
						from={from}
						setFrom={setFrom}
						to={to}
						setTo={setTo}
						checked={checked}
						setChecked={setChecked}
						onFilter={onFilter}
					/>
				</Box>

				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
						minHeight: '38vh',
						maxHeight: '38vh',
						mb: 2,
					}}>
					<ProjectVideosTable assignedList={dateRangeList} videoAsignedDelete={onDeleteAsigned} />
				</Box>
				<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly' }}>
					<Card
						sx={{
							width: '48%',
							borderRadius: '3px',
							border: '1px solid #5c9edf',
						}}>
						<CardContent sx={{ height: '17vh' }}>
							<Typography variant='h5' gutterBottom>
								{t('project-videos.videos-to-be-assigned')}
							</Typography>
							<LabelValueComponent
								label={t('video-request.from')}
								value={datetimeUTCtoString(from)}
								horizontal
								sx={{ mx: 1 }}
							/>
							<LabelValueComponent
								label={t('video-request.to')}
								value={datetimeUTCtoString(to)}
								horizontal
								sx={{ mx: 1 }}
							/>
							<LabelValueComponent
								label={t('video-request.cameras')}
								value={checked
									.map((c, idx) =>
										c ? (idx != checked.length - 1 ? idx + 1 + ', ' : idx + 1) : null
									)
									.filter((c) => c != null)}
								horizontal
								sx={{ mx: 1 }}
							/>
						</CardContent>
						<CardActions sx={{ justifyContent: 'flex-end' }}>
							<Button variant='outlined' onClick={onAssignVideos}>
								{t('project-videos.assign-dates')}
							</Button>
						</CardActions>
					</Card>
					<Card sx={{ width: '48%', borderRadius: '3px', border: '1px solid #5c9edf' }}>
						<CardContent sx={{ height: '17vh' }}>
							<Typography variant='h5' gutterBottom>
								{t('project-videos.consistency-title')}
							</Typography>
							<LabelValueComponent
								label={t('project-videos.pending')}
								value={videosToBeAssignedCount}
								horizontal
								sx={{ mx: 1 }}
							/>
							<LabelValueComponent
								label={t('project-videos.out-of-date')}
								value={videosToBeRemovedCount}
								horizontal
								sx={{ mx: 1 }}
							/>
						</CardContent>
						<CardActions sx={{ justifyContent: 'flex-end' }}>
							<Button variant='outlined' onClick={onConsolidateDates}>
								{t('project-videos.consolidate-videos')}
							</Button>
						</CardActions>
					</Card>
				</Box>
			</Box>
		</BasicDialog>
	)
}
