import { Box } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import '../../index.css'
import { assignFilter } from '../../utils/customFunctions'
import FieldsetComponent from '../comun/FieldsetComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'
import FilterFunctions from '../comun/FilterFunctions'
import { useUsersFilterPost } from '../../services/users/useUsersFilterPost'
import { useUsersFilterDelete } from '../../services/users/useUsersFilterDelete'
import MessageSnackBar from '../comun/MessageSnackBar'
import { messageDialogTypes } from '../../constants'

export default function UsersFilter({ onFilter, filter, filterUser, favouriteFilters }) {
	const { register, control, handleSubmit, getValues, setValue, reset } = useForm({ mode: 'onTouched' })

	const [t] = useTranslation('common')

	const [checked, setChecked] = useState(false)

	const handleChange = (e) => {
		setChecked(e.target.checked)
	}

	useEffect(() => {
		if (filterUser) {
			setValue('filterArg', filterUser.filter ? filterUser.filter : '')
			setChecked(filterUser.includeDisableUsers)
		}
	}, [filterUser])

	const onSubmit = (data) => {
		filter.includeDisabledUsersArg = data.includeDisabledUsersArg
		assignFilter(data, filter)
		onFilter(data)
	}

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useUsersFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useUsersFilterDelete()

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({})

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const saveFilter = (filterName) => {
		let formValues = getValues()

		let filterDataAux = {
			filterName: filterName,
			filter: formValues.filterArg,
			includeDisableUsers: formValues.includeDisabledUsersArg,
		}

		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter && filterData) {
			setFavFilters((prevState) => [...prevState, filterData])
		}
	}, [dataFilter, filterData])

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	}

	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			setValue('filterArg', selectedFilter.filter)
			setChecked(selectedFilter.includeDisableUsers)
		}
	}, [selectedFilter])

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
		setChecked(false)
	}

	// Set errrors
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<FieldsetComponent title={t('users.fieldset')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box sx={{ display: 'flex' }} component='form' onSubmit={handleSubmit(onSubmit)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0', m: 1 }}>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<TextFieldComponent
							defaultValue={''}
							sx={{ mr: 1, width: '100%' }}
							size='small'
							control={control}
							name='filterArg'
							label={t('users.filter.filterArg')}
						/>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
						<FormControlLabel
							label={t('users.filter.includeDisabledUsersArg')}
							labelPlacement='end'
							sx={{ mr: 1 }}
							control={
								<Checkbox
									{...register('includeDisabledUsersArg')}
									name='includeDisabledUsersArg'
									id='includeDisabledUsersArg'
									checked={checked}
									onChange={handleChange}
								/>
							}></FormControlLabel>
					</Box>
				</Box>
				<Box sx={{ mr: 1 }}>
					<FilterFunctions
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						filters={favFilters}
						threeLines={false}
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
