export const prepareParams = (props) => {
	let params = new URLSearchParams(props)
	let keysForDel = []
	params.forEach((value, key) => {
		if (value === '' || value === 'null' || value === 'undefined') {
			keysForDel.push(key)
		}
	})

	keysForDel.forEach((key) => {
		params.delete(key)
	})
	return params.toString()
}