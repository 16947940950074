import { useCallback, useState } from 'react'
import { useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUpdateLayerConfig = (vesselApiId) => {
	const [dataUpdateLayer, setDataUpdateLayer] = useState(null)
	const [errorUpdateLayer, setErrorUpdateLayer] = useState(null)
	const [isLoadingUpdateLayer, setIsLoadingUpdateLayer] = useState(false)
	const { client } = useContext(ApiClientContext)
	const url = vesselApiId ? 'horus/f3/dashboard/configlayer' : 'horus/f3/project/configlayer'

	const updateLayersConfig = useCallback(
		(layerconfig) => {
			client
				.put(baseUrl + url, layerconfig)
				.then((result) => {
					setIsLoadingUpdateLayer(false)
					if (result.data.success) {
						setDataUpdateLayer(result.data.data)
					} else {
						setErrorUpdateLayer({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingUpdateLayer(false)
					setErrorUpdateLayer(err.response)
				})
			setDataUpdateLayer(null)
			setErrorUpdateLayer(null)
			setIsLoadingUpdateLayer(true)
		},
		[client]
	)
	return {
		dataUpdateLayer,
		errorUpdateLayer,
		isLoadingUpdateLayer,
		updateLayersConfig,
	}
}
