import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MapContainer, Polygon, TileLayer } from 'react-leaflet'
import Control from 'react-leaflet-custom-control'
import { mapSpeedThresholds } from '../../../../constants'
import { useHideKMLZones } from '../../../../services/preview/useHideKMLZones'
import { useShowKMLZones } from '../../../../services/preview/useShowKMLZones'
import useStore from '../../PreviewStore'
import './MapTab.css'
import MarkersLayer from './MarkersLayer'
import PolylinesLayer from './PolylinesLayer'
import MessageSnackBar from '../../../comun/MessageSnackBar'
import { useShallow } from 'zustand/shallow'
import CurrentPositionMarker from './CurrentPositionMarker'

export default function MapTab({ mapInfo, updateCurrentTime, showKMLs }) {
	const [showKML, setShowKML] = useState(showKMLs)
	const { vessel, project } = useStore(
		useShallow((state) => ({
			vessel: state.vessel,
			project: state.project,
		}))
	)
	const [showLegend, setShowLegend] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const { showKMLZones, kmlZones, errorKmlZones } = useShowKMLZones(vessel?.vesselId, project?.projectId)
	const { hideKMLZones } = useHideKMLZones(vessel?.vesselId, project?.projectId)

	useEffect(() => {
		if (showKMLs) {
			showKMLZones()
		}
	}, [showKMLs])

	const handleChangeKML = (e) => {
		if (e.target.checked) {
			showKMLZones()
		} else {
			hideKMLZones()
		}
		setShowKML(e.target.checked)
	}

	useEffect(() => {
		if (errorKmlZones) {
			setOpenError(true)
			setErrorMsg(errorKmlZones)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorKmlZones])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<MapContainer
			center={[-5.505, -0.09]}
			zoom={2}
			minZoom={2}
			scrollWheelZoom={true}
			id='preview-map'
			attributionControl={false}>
			<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='' />
			{mapInfo && mapInfo.length > 0 && <MarkersLayer mapInfo={mapInfo} updateCurrentTime={updateCurrentTime} />}
			{mapInfo && mapInfo.length > 0 && <CurrentPositionMarker mapInfo={mapInfo} />}
			<PolylinesLayer mapInfo={mapInfo} />
			{showKML &&
				kmlZones?.map((zone) => {
					return zone.polygons.map((pos) => (
						<Polygon key={pos.polygonId} positions={pos.coordinates}></Polygon>
					))
				})}
			<Control position='bottomright' prepend>
				<Box
					sx={{ backgroundColor: 'white', opacity: 0.8, display: 'flex', flexDirection: 'column' }}
					onMouseEnter={() => setShowLegend(true)}
					onMouseLeave={() => setShowLegend(false)}
					className='legend'>
					{!showLegend ? (
						<LayersOutlinedIcon />
					) : (
						<>
							<FormControlLabel
								sx={{ mt: 0, mb: 0.5, p: 0 }}
								label={'Show kml'}
								labelPlacement='end'
								control={
									<Checkbox
										sx={{ p: 0.5, pl: 1 }}
										checked={showKML}
										value={showKML}
										onChange={handleChangeKML}
									/>
								}
							/>
							<Typography sx={{ mt: 0, mb: 0.5 }}>Speed legend</Typography>
							<Box>
								<i style={{ background: 'green' }}></i> <span>&gt; {mapSpeedThresholds.green}kn</span>
							</Box>
							<Box>
								<i style={{ background: 'yellow' }}></i>{' '}
								<span>
									{' '}
									{mapSpeedThresholds.red}kn &gt; {mapSpeedThresholds.green}kn
								</span>
							</Box>
							<Box>
								<i style={{ background: 'red' }}></i> <span>&gt; {mapSpeedThresholds.red}kn</span>
							</Box>
						</>
					)}
				</Box>
			</Control>
			<MessageSnackBar openError={openError} errorMsg={errorMsg} handleCloseError={handleCloseError} />
		</MapContainer>
	)
}
