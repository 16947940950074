import Box from '@mui/material/Box'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from '../../constants'
import BasicDialog from '../comun/BasicDialog'
import SelectComponent from '../comun/form_fields/SelectComponent'

export default function ReportsForm({ 
	mode, 
	onClose, 
	open, 
	onSave,
	reportOptions
}) {
	const {
		control,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm()

	React.useEffect(() => {
		reset({})
	}, [onClose, onSave])

	const [t] = useTranslation('common')

	return (
		<Box component='form' onSubmit={handleSubmit(onSave)}>
			<BasicDialog
				title={t('reports.form.title-new')}
				onOpen={open}
				onClose={onClose}
				mode={mode}
				saveTxt={t('comun.actions.save')}>
				<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '50vh' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<SelectComponent
							size='small'
							defaultValue={[]}
							sx={{ width: '100%', m: 1 }}
							control={control}
							name='exportType'
							label={t('reports.form.exportType')}
							options={reportOptions ? reportOptions.refProjectExportList : []}
							optionsLabel={'description'}
							optionsValue={'exportId'}
							errors={errors?.exportType}
							rules={{
								required: {
									value: true,
									message: t('reports.form.validations.required-field'),
								},
							}}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}></Box>
				</Box>
			</BasicDialog>
		</Box>
	)
}

ReportsForm.defaultProps = {
	mode: modeTypesCRUD.INSERT_MODE,
	open: false,
	close: () => {},
}
