import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import './index.css'
import Login from './components/login/Login.jsx'
import {
	timeoutIdle,
	appRoutes,
	jwtCookieExpiryName,
	jwtCookieName,
	languageCookieName,
	rolesCookieName,
} from './constants'
import { createBrowserHistory } from 'history'
import { ApiClientContext, getApiClient } from './services/ApiClientProvider'
import { readCookie, setCookie } from './utils/cookie'
import AppLayout from './components/appLayout/AppLayout'
import { useTranslation } from 'react-i18next'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import { esES, enUS } from '@mui/material/locale'
import IdleTimer from './utils/IdleTimer'
import ChangePasswordPage from './components/password_recovery/ChangePasswordPage'
import { hasAdminRole, hasEMAdminRole, hasFleetAdminRole, hasFleetStaffRole, hasEMStaffRole } from './utils/roles'
import { hasAnyRol, validateRolesAppRoutes } from './utils/securityRoles'
import BasicDialog from './components/comun/BasicDialog'
import LabelValueComponent from './components/comun/LabelValueComponent'

const history = createBrowserHistory()

export const getDateLocale = (language) => {
	switch (language) {
		case 'en':
			return enLocale
		case 'es':
			return esLocale
	}
}

export const getLocale = (language) => {
	switch (language) {
		case 'en':
			return enUS
		case 'es':
			return esES
	}
}

const App = () => {
	const [t, i18n] = useTranslation('common')
	const cookieLanguage = readCookie(languageCookieName)

	const cookieJwtToken = readCookie(jwtCookieName)
	const [roles, setRoles] = useState([])
	const [openInactivity, setOpenInactivity] = useState(false)
	const [sendRequest, setSendRequest] = useState(false)

	const cookieRoles = readCookie(rolesCookieName)

	const [jwtTokenValue, setJwtTokenValue] = useState(cookieJwtToken)

	const apiClient = useMemo(() => getApiClient(jwtTokenValue), [jwtTokenValue])

	useEffect(() => {
		setJwtTokenValue(cookieJwtToken)
	}, [cookieJwtToken])

	useEffect(() => {
		i18n.changeLanguage(cookieLanguage)
	}, [cookieLanguage])

	useEffect(() => {
		setRoles(cookieRoles.split(','))
	}, [cookieRoles])

	const cookieJwtTokenExpiry = readCookie(jwtCookieExpiryName)
	useEffect(() => {
		let fechaExpiracion = new Date(cookieJwtTokenExpiry)
		let fechaActual = new Date()
		if (fechaExpiracion <= fechaActual) {
			setCookie(jwtCookieName, '')
			setCookie(jwtCookieExpiryName, '')
			document.location.reload()
		}
	}, [cookieJwtTokenExpiry])

	const setTokenValue = useCallback(
		(value) => {
			setJwtTokenValue(value)
			history.push(appRoutes.HOME)
		},
		[setJwtTokenValue]
	)

	const clearCache = () => {
		const cookies = document.cookie.split(";");
		for (let i = 0; i < cookies.length; i++) {
			if ( cookies[i].includes('session_jwt') ) {
				const cookie = cookies[i];
				const eqPos = cookie.indexOf("=");
				const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
				document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
			}
		}
	}

	const loggedInSwitch = (
		<Switch>
			{history.location.pathname == '/login' ? (
				<>
					<Redirect to={appRoutes.LOGIN} />
					{clearCache()}
				</>
			) : null}  
			{history.location.pathname != appRoutes.HOME &&
			hasAnyRol(roles) &&
			validateRolesAppRoutes(roles, history.location.pathname) ? (
				<>
					<Route path={appRoutes.HOME} component={AppLayout} exact={false} />
					<Redirect to={history.location.pathname} />
				</>
			) : null}
			{hasFleetAdminRole(roles) || hasFleetStaffRole(roles) ? (
				<>
					<Route path={appRoutes.HOME} component={AppLayout} exact={false} />
					{history.location.pathname == appRoutes.HOME && <Redirect to={appRoutes.HOME} />}
				</>
			) : null}
			{hasEMAdminRole(roles) ? (
				<>
					<Route path={appRoutes.PREVIEW} component={AppLayout} exact={false} />
					<Route path={appRoutes.USERS_PROFILE} component={AppLayout} exact={false} />
					<Route path={appRoutes.PROJECTS} component={AppLayout} exact={false} />
					{/* <Route path={appRoutes.VIDEO} component={AppLayout} exact={false} /> */}
					<Route path={appRoutes.PLANIFICATIONS} component={AppLayout} exact={false} />
					{history.location.pathname == appRoutes.HOME && <Redirect to={appRoutes.PLANIFICATIONS} />}
				</>
			) : null}
			{hasEMStaffRole(roles) ? (
				<>
					<Route path={appRoutes.PREVIEW} component={AppLayout} exact={false} />
					<Route path={appRoutes.USERS_PROFILE} component={AppLayout} exact={false} />
					{/* <Route path={appRoutes.VIDEO} component={AppLayout} exact={false} /> */}
					<Route path={appRoutes.PROJECTS} component={AppLayout} exact={false} />
					<Redirect to={appRoutes.PROJECTS} />
				</>
			) : null}

			{hasAdminRole(roles) ? (
				<>
					<Route path={appRoutes.USERS_MANAGEMENT} component={AppLayout} exact={false} />
					<Route path={appRoutes.USERS_PROFILE} component={AppLayout} exact={false} />
					<Route path={appRoutes.TIMER_PROJECTS} component={AppLayout} exact={false} />
					<Route path={appRoutes.TIMER_USERS} component={AppLayout} exact={false} />
					<Route path={appRoutes.DOWNLOADS} component={AppLayout} exact={false} />
					<Redirect to={appRoutes.USERS_MANAGEMENT} />
				</>
			) : null}
		</Switch>
	)

	const unloggedSwitch = (
		<Switch>
			<Route path={appRoutes.LOGIN} component={Login} exact={true} />

			<Route path={appRoutes.CHANGE_PASSWORD} component={ChangePasswordPage} exact={true} />
			<Redirect to={appRoutes.LOGIN} />
		</Switch>
	)

	const onCloseInactivity = () => {
		setOpenInactivity(false)
		setSendRequest(true)
	}
	return (
		<>
			<ApiClientContext.Provider
				value={{
					client: apiClient,
					setTokenValue,
				}}>
				<Router history={history}>{jwtTokenValue ? loggedInSwitch : unloggedSwitch}</Router>
				<IdleTimer sendRequest={sendRequest} setOpenInactivity={setOpenInactivity} timeoutValue={timeoutIdle} />
			</ApiClientContext.Provider>

			{openInactivity && jwtTokenValue ? (
				<BasicDialog
					title={t('timer.title-inactive')}
					closeTxt={t('timer.return')}
					onClose={onCloseInactivity}
					closeColor={'info'}
					onOpen={openInactivity}>
					<LabelValueComponent label={t('timer.inactive-message')} width='15vw' />
				</BasicDialog>
			) : null}
		</>
	)
}

export default App
