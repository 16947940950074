import { Box, Checkbox, MenuItem, Select, SvgIcon, TextField, Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as Circle } from '../../assets/chartIcons/circle.svg'
import { ReactComponent as Cross } from '../../assets/chartIcons/cross.svg'
import { ReactComponent as Polygon } from '../../assets/chartIcons/polygon.svg'
import { ReactComponent as Square } from '../../assets/chartIcons/square.svg'
import { ReactComponent as TriangleDown } from '../../assets/chartIcons/triangle-down.svg'
import { ReactComponent as TriangleUp } from '../../assets/chartIcons/triangle-up.svg'
import { chartPalette, getPaleteForSerie } from './ChartPalette'

const iconOptions = [
	{ id: 1, name: 'circle', icon: Circle },
	{ id: 2, name: 'square', icon: Square },
	{ id: 3, name: 'polygon', icon: Polygon },
	{ id: 4, name: 'triangleDown', icon: TriangleDown },
	{ id: 5, name: 'triangleUp', icon: TriangleUp },
	{ id: 6, name: 'cross', icon: Cross },
]

export default function ChartLayerConfigFormLine({ seriesIdx, layer, serie, setLayers, layers, layerIdx }) {
	const handleChangeIcon = (e) => {
		let auxLayers = [...layers]
		auxLayers[layerIdx].series[seriesIdx] = {
			...auxLayers[layerIdx].series[seriesIdx],
			icon: e.target.value,
		}
		setLayers(auxLayers)
	}

	const handleChangeColor = (e) => {
		let auxLayers = [...layers]
		auxLayers[layerIdx].series[seriesIdx] = {
			...getPaleteForSerie(e.target.value, auxLayers[layerIdx].series[seriesIdx]),
		}
		setLayers(auxLayers)
	}

	const handleChangeChecked = (e) => {
		let auxLayers = [...layers]
		auxLayers[layerIdx].series[seriesIdx] = {
			...auxLayers[layerIdx].series[seriesIdx],
			hidden: !e.target.checked,
		}
		setLayers(auxLayers)
	}

	const handleChangeMin = (e) => {
		var value = parseInt(e.target.value, 10)
		if (value > serie.maxValue) value = serie.maxValue
		if (value < serie.minValue) value = serie.minValue
		let auxLayers = [...layers]
		auxLayers[layerIdx].series[seriesIdx] = {
			...auxLayers[layerIdx].series[seriesIdx],
			min: value,
		}
		setLayers(auxLayers)
	}

	const handleChangeMax = (e) => {
		var value = parseInt(e.target.value, 10)
		if (value > serie.maxValue) value = serie.maxValue
		if (value < serie.minValue) value = serie.minValue

		let auxLayers = [...layers]
		auxLayers[layerIdx].series[seriesIdx] = {
			...auxLayers[layerIdx].series[seriesIdx],
			max: value,
		}
		setLayers(auxLayers)
	}

	return (
		<Box sx={{ display: 'flex', p: 1, alignItems: 'center' }}>
			<Checkbox checked={!serie?.hidden} onChange={handleChangeChecked} sx={{ mx: 0.5 }} />
			<Typography sx={{ width: '15%', mx: 0.5 }}>{serie.name}</Typography>
			{serie?.sensorSeatubeId != -10 && (
				<>
					<Select
						IconComponent={() => null}
						size='small'
						disabled={serie?.hidden}
						value={serie?.colorLine}
						onChange={handleChangeColor}
						sx={{ backgroundColor: serie?.colorLine, color: serie?.colorLine, width: '20%', mx: 0.5 }}>
						{chartPalette.map((c, idx) => (
							<MenuItem
								key={idx + '-' + serie.sensorSeatubeId + '-' + layer.layer}
								value={c.line}
								sx={{ backgroundColor: c.line, color: c.line }}>
								{c.line}
							</MenuItem>
						))}
					</Select>
					<Select
						IconComponent={() => null}
						size='small'
						disabled={serie?.hidden}
						value={serie?.icon}
						onChange={handleChangeIcon}
						sx={{ color: serie?.colorLine, width: '10%', mx: 0.5, height: '40px' }}>
						<MenuItem value={''}>None</MenuItem>
						{iconOptions.map((i, idx) => (
							<MenuItem key={idx + '-' + serie.sensorSeatubeId + '-' + layer.layer} value={i.id}>
								<SvgIcon
									component={i.icon}
									sx={{ color: serie?.colorIcon2 }}
									width='5px'
									height='5px'
								/>
							</MenuItem>
						))}
					</Select>
					<TextField
						sx={{ width: '15%', mx: 0.5 }}
						size='small'
						label='Min'
						disabled={serie?.hidden}
						variant='outlined'
						type='number'
						value={serie?.min}
						onChange={handleChangeMin}
						InputProps={{ inputProps: { min: serie.tHoldMinValue, max: serie.tHoldMaxValue } }}
					/>
					<TextField
						sx={{ width: '15%', mx: 0.5 }}
						size='small'
						label='Max'
						disabled={serie?.hidden}
						variant='outlined'
						type='number'
						value={serie?.max}
						onChange={handleChangeMax}
						InputProps={{ inputProps: { min: serie.tHoldMinValue, max: serie.tHoldMaxValue } }}
					/>
					<Typography sx={{ ml: 0.5 }}>{serie.unit == 'IR' ? '%' : serie.unit}</Typography>
				</>
			)}
		</Box>
	)
}
