import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useZoneAlertInsert = () => {
	const [dataZoneAlertInsert, setDataZoneAlertInsert] = useState(null)
	const [errorZoneAlertInsert, setErrorZoneAlertInsert] = useState(null)
	const [isLoadingZoneAlertInsert, setIsLoadingZoneAlertInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const zoneAlertInsert = useCallback(
		(formData, file) => {
			const data = new FormData()
			data.append('fileKML', file)

			const headers = {
				'Content-Type': 'multipart/form-data',
				// 'Accept': 'text/plain',
			}

			client
				.post(
					baseUrl + 'horus/f3/alert/zone?name=' + formData.name + '&fileNameKML=' + formData.fileNameKML,
					data,
					headers
				)
				.then((result) => {
					setIsLoadingZoneAlertInsert(false)
					if (result.status === 200 && result.data.success) {
						setDataZoneAlertInsert(result.data.data)
					} else {
						setErrorZoneAlertInsert({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setIsLoadingZoneAlertInsert(false)
					setErrorZoneAlertInsert(error.response)
				})

			setDataZoneAlertInsert(null)
			setErrorZoneAlertInsert(null)
			setIsLoadingZoneAlertInsert(true)
		},
		[client]
	)

	return {
		zoneAlertInsert,
		dataZoneAlertInsert,
		errorZoneAlertInsert,
		isLoadingZoneAlertInsert,
	}
}
