import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes } from '../../constants'
import '../../index.css'
import { useTimerProjectFilterDelete } from '../../services/timer/useTimerProjectFilterDelete'
import { useTimerProjectFilterPost } from '../../services/timer/useTimerProjectFilterPost'
import { assignFilter } from '../../utils/customFunctions'
import FieldsetComponent from '../comun/FieldsetComponent'
import FilterFunctions from '../comun/FilterFunctions'
import MessageSnackBar from '../comun/MessageSnackBar'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'

export default function TimerProjectFilter({
	timerOptions,
	onFilter,
	filter,
	filterTimerReview,
	setFilterTimerReview,
	favouriteFilters,
	statusOptions,
	activeList,
}) {
	const { control, handleSubmit, setValue, watch, reset, getValues } = useForm({ mode: 'onTouched' })

	const [t] = useTranslation('common')

	const [disableField, setDisableField] = useState(true)
	const selectProgram = watch('program')

	useEffect(() => {
		setValue('typeAnalysis', null)
		setValue('client', null)
		setValue('username', null)
		setValue('project', null)
		setValue('vessel', null)
		setDisableField(selectProgram ? false : true)

		if (filterTimerReview && selectProgram) {
			let typeAnalysis = selectProgram.typeAnalysisList.find(
				(t) => t.typeAnalysisId == filterTimerReview.typeAnalysisId
			)
			let client = selectProgram.clientList.find((c) => c.idClient == filterTimerReview.clientId)
			let username = selectProgram.userList.find((u) => u.userName == filterTimerReview.userName)
			let project = selectProgram.projectList.find((p) => p.projectId == filterTimerReview.projectId)
			let vessel = selectProgram.vesselList.find((v) => v.vesselApiId == filterTimerReview.vesselApiId)

			setValue('typeAnalysis', typeAnalysis)
			setValue('client', client)
			setValue('username', username)
			setValue('project', project)
			setValue('vessel', vessel)
			setFilterTimerReview(null)
		}
	}, [selectProgram])

	useEffect(() => {
		if (filterTimerReview) {
			let program = timerOptions.find((p) => p.programId == filterTimerReview.programId)
			let isEnabled = activeList.find((e) => e.value == filterTimerReview.isEnabled)
			let status = statusOptions.find((s) => s.id == filterTimerReview.projectStatusId)

			setValue('program', program)
			setValue('isEnabled', isEnabled)
			setValue('status', status)
		}
	}, [filterTimerReview])

	const onSearch = (data) => {
		let config = {}
		config.programId = data.program?.programId
		config.typeAnalysisId = data.typeAnalysis?.typeAnalysisId
		config.clientid = data.client?.idClient
		config.userName = data.username?.userName
		config.projectId = data.project?.projectId
		config.vesselApiId = data.vessel?.vesselApiId
		config.isEnabled = data.isEnabled?.value
		config.projectStatusId = data.status?.id
		assignFilter(config, filter)
		config.pageNo = 1
		onFilter(config)
	}

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useTimerProjectFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useTimerProjectFilterDelete()

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({})

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const saveFilter = (filterName) => {
		let formValues = getValues()

		let filterDataAux = {
			filterName: filterName,
			programId: formValues.program?.programId,
			typeAnalysisId: formValues.typeAnalysis?.typeAnalysisId,
			clientId: formValues.client?.idClient,
			userName: formValues.username?.userName,
			projectId: formValues.project?.projectId,
			vesselApiId: formValues.vessel?.vesselApiId,
			isEnabled: formValues.isEnabled?.value,
			projectStatusId: formValues.status?.id,
		}

		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter && filterData) {
			setFavFilters((prevState) => [...prevState, filterData])
		}
	}, [dataFilter, filterData])

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	}

	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			let program = timerOptions.find((t) => t.programId == selectedFilter.programId)

			setValue('program', program)

			if (selectProgram) {
				let typeAnalysis = selectProgram.typeAnalysisList.find(
					(t) => t.typeAnalysisId == selectedFilter.typeAnalysisId
				)
				let client = selectProgram.clientList.find((c) => c.idClient == selectedFilter.clientId)
				let username = selectProgram.userList.find((u) => u.userName == selectedFilter.userName)
				let project = selectProgram.projectList.find((p) => p.projectId == selectedFilter.projectId)
				let vessel = selectProgram.vesselList.find((v) => v.vesselApiId == selectedFilter.vesselApiId)

				setValue('typeAnalysis', typeAnalysis)
				setValue('client', client)
				setValue('username', username)
				setValue('project', project)
				setValue('vessel', vessel)
			}

			let enabled = activeList.find((s) => s.value == selectedFilter.isEnabled)
			let status = statusOptions.find((s) => s.id == selectedFilter.projectStatusId)

			setValue('isEnabled', enabled)
			setValue('status', status)
		}
	}, [selectedFilter, selectProgram])

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
	}

	// Set errrors
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<FieldsetComponent title={t('timer.filter.title')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box sx={{ display: 'flex' }} component='form' onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0', mr: 1 }}>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<AutoCompleteComponent
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', m: 1 }}
							control={control}
							name='program'
							label={t('timer.filter.program')}
							optionsLabel={'programDescription'}
							options={timerOptions ? timerOptions : []}
							keyProperty={'programId'}
							evalMethod={(opt, val) => {
								return opt.programId === val.programId
							}}
						/>
						<AutoCompleteComponent
							disabled={disableField}
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', m: 1 }}
							control={control}
							name='typeAnalysis'
							label={t('timer.filter.analysis')}
							options={selectProgram ? selectProgram.typeAnalysisList : []}
							optionsLabel={'typeAnalysisDescription'}
							keyProperty={'typeAnalysisId'}
							evalMethod={(opt, val) => {
								return opt.typeAnalysisId === val.typeAnalysisId
							}}
						/>
						<AutoCompleteComponent
							disabled={disableField}
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', m: 1 }}
							control={control}
							name='client'
							label={t('timer.filter.client')}
							options={selectProgram ? selectProgram.clientList : []}
							optionsLabel={'name'}
							keyProperty={'idClient'}
							evalMethod={(opt, val) => {
								return opt.idClient === val.idClient
							}}
						/>
					</Box>

					<Box sx={{ display: 'flex', width: '100%' }}>
						<AutoCompleteComponent
							disabled={disableField}
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', m: 1 }}
							control={control}
							name='username'
							label={t('timer.filter.user')}
							options={selectProgram ? selectProgram.userList : []}
							optionsLabel={'userName'}
							keyProperty={'userName'}
							evalMethod={(opt, val) => {
								return opt.userName === val.userName
							}}
						/>
						<AutoCompleteComponent
							disabled={disableField}
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', m: 1 }}
							control={control}
							name='project'
							label={t('timer.filter.project')}
							options={selectProgram ? selectProgram.projectList : []}
							optionsLabel={'description'}
							keyProperty={'projectId'}
							evalMethod={(opt, val) => {
								return opt.projectId === val.projectId
							}}
						/>
						<AutoCompleteComponent
							disabled={disableField}
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', m: 1 }}
							control={control}
							name='vessel'
							label={t('timer.filter.vessel')}
							options={selectProgram ? selectProgram.vesselList : []}
							optionsLabel={'vesselName'}
							keyProperty={'vesselApiId'}
							evalMethod={(opt, val) => {
								return opt.vesselApiId === val.vesselApiId
							}}
						/>
					</Box>

					<Box sx={{ display: 'flex', width: '100%' }}>
						<AutoCompleteComponent
							defaultValue={null}
							size='small'
							sx={{ width: '50%', m: 1 }}
							control={control}
							name='isEnabled'
							label={t('timer.filter.active')}
							options={activeList ? activeList : []}
							optionsLabel={'description'}
							keyProperty={'value'}
							evalMethod={(opt, val) => {
								return opt.value === val.value
							}}
						/>
						<AutoCompleteComponent
							defaultValue={null}
							size='small'
							sx={{ width: '50%', m: 1 }}
							control={control}
							name='status'
							label={t('timer.filter.project-status')}
							options={statusOptions ? statusOptions : []}
							optionsLabel={'description'}
							keyProperty={'id'}
						/>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
					<FilterFunctions
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						filters={favFilters}
						threeLines
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
