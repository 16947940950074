import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectItemCommentDelete } from '../../services/projectitem/comments/useProjectItemCommentDelete'
import { useProjectItemCommentInsert } from '../../services/projectitem/comments/useProjectItemCommentInsert'
import { useProjectItemCommentUpdate } from '../../services/projectitem/comments/useProjectItemCommentUpdate'
import { StyledTableHeadRow, StyledTableRow } from '../comun/StyleComponentUtils'
import AddCommentDialog from '../notes/AddCommentDialog'

export default function MeasureAndCountComments({ projectItemId, height, commentList }) {
	const [t] = useTranslation('common')
	const [comments, setComments] = useState(commentList)
	const [openComentario, setOpenComentario] = useState(false)
	const [modeComments, setModeComments] = useState(null)
	const [selectedComment, setSelectedComment] = useState({})

	const { projectItemCommentInsert, dataProjectItemCommentInsert } = useProjectItemCommentInsert()

	const { projectItemCommentUpdate, dataProjectItemCommentUpdate } = useProjectItemCommentUpdate()

	const { projectItemCommentDelete, dataProjectItemCommentDelete } = useProjectItemCommentDelete()

	useEffect(() => {
		setComments(commentList)
	}, [commentList])

	useEffect(() => {
		if (dataProjectItemCommentInsert) {
			setComments((oldComments) => [...oldComments, dataProjectItemCommentInsert])
		}
	}, [dataProjectItemCommentInsert])

	useEffect(() => {
		if (dataProjectItemCommentUpdate) {
			let auxList = [...comments]
			let idx = auxList.findIndex(
				(c) => c.projectItemLineCommentId == dataProjectItemCommentUpdate.projectItemLineCommentId
			)
			auxList[idx] = dataProjectItemCommentUpdate
			setComments(auxList)
		}
	}, [dataProjectItemCommentUpdate])

	useEffect(() => {
		if (dataProjectItemCommentDelete) {
			let auxList = [...comments]
			let idx = auxList.findIndex((c) => c.projectItemLineCommentId == selectedComment.projectItemLineCommentId)
			auxList.splice(idx, 1)
			setComments(auxList)
		}
	}, [dataProjectItemCommentDelete])

	const onAddComment = () => {
		setModeComments('INSERT_COMMENTS')
		setOpenComentario(true)
	}

	const onUpdateComment = (comment) => {
		setSelectedComment(comment)
		setModeComments('UPDATE_COMMENTS')
		setOpenComentario(true)
	}

	const onDeleteComment = (comment) => {
		setSelectedComment(comment)
		projectItemCommentDelete({ projectItemLineCommentId: comment.projectItemLineCommentId })
	}

	const onCloseDialog = (success, value, idx, mode) => {
		if (success) {
			saveComment(value, mode)
		}
		setOpenComentario(false)
		setSelectedComment(null)
	}

	const saveComment = (value, mode) => {
		if (mode === 'INSERT_COMMENTS') {
			let commentInsert = {
				projectItemLineCommentId: '999',
				projectItemId: projectItemId,
				lineNo: 1,
				comment: value,
			}
			projectItemCommentInsert(commentInsert)
		} else if (mode === 'UPDATE_COMMENTS') {
			let commentUpdate = {
				projectItemLineCommentId: selectedComment.projectItemLineCommentId,
				projectItemId: selectedComment.projectItemId,
				lineNo: selectedComment.lineNo,
				comment: value,
			}
			projectItemCommentUpdate(commentUpdate)
		}
	}
	return (
		<Box>
			<TableContainer sx={{ height: height, border: '1px solid #8ab8e5', borderRadius: '5px' }}>
				<Table sx={{ width: '100%', borderRadius: '5px' }} stickyHeader>
					<TableHead sx={{ borderRadius: '5px', borderBottom: '1px solid #8ab8e5' }}>
						<StyledTableHeadRow sx={{ borderRadius: '5px', borderBottom: '1px solid #8ab8e5' }}>
							<TableCell
								sx={{ borderTopLeftRadius: '5px', borderBottom: '2px solid #1976d2 !important', p: 1 }}
								align='center'
								className='table-header-separator table-header-sort-label'>
								{t('anotaciones-form.table.header.author')}
							</TableCell>
							<TableCell
								sx={{ borderBottom: '2px solid #1976d2 !important', p: 1 }}
								align='center'
								className='table-header-separator table-header-sort-label'>
								{t('anotaciones-form.table.header.comment')}
							</TableCell>
							<TableCell
								align='center'
								sx={{ borderTopRightRadius: '5px', borderBottom: '2px solid #1976d2', p: 1 }}>
								<Tooltip title={t('comun.actions.new')}>
									<Button
										onClick={() => {
											onAddComment()
										}}
										variant='text'
										sx={{ width: '40px', height: '40px' }}>
										<AddCircleOutlineIcon />
									</Button>
								</Tooltip>
							</TableCell>
						</StyledTableHeadRow>
					</TableHead>
					<TableBody>
						{comments.map((comment, idx) => (
							<StyledTableRow
								key={comment.projectItemLineCommentId + '-' + idx}
								sx={{
									'&:last-child td, &:last-child th': { border: 0 },
								}}>
								<TableCell align='left'>
									<Box
										sx={{
											textOverflow: 'ellipsis',
											overflow: 'hidden',
											width: '5vw',
											display: 'block',
											whiteSpace: 'nowrap',
										}}>
										<Tooltip title={comment.authorToolTips}>
											<Typography>{comment.author}</Typography>
										</Tooltip>
									</Box>
								</TableCell>
								<TableCell align='left'>
									<Box
										sx={{
											textOverflow: 'ellipsis',
											overflow: 'hidden',
											width: '10vw',
											display: 'block',
											whiteSpace: 'nowrap',
										}}>
										<Typography>{comment.comment}</Typography>
									</Box>
								</TableCell>
								<TableCell align='center'>
									{comment.canEdit && (
										<Tooltip title={t('comun.actions.update')}>
											<IconButton
												color='primary'
												sx={{ p: 0, mr: 0.5, height: '30px', width: '30px' }}
												onClick={() => onUpdateComment(comment)}>
												<EditIcon />
											</IconButton>
										</Tooltip>
									)}
									{comment.canDelete && (
										<Tooltip title={t('comun.actions.delete')}>
											<IconButton
												sx={{ p: 0, height: '30px', width: '30px' }}
												color='error'
												onClick={() => onDeleteComment(comment)}>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									)}
								</TableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<AddCommentDialog
				onOpen={openComentario}
				onClose={onCloseDialog}
				commentValue={selectedComment ? selectedComment.comment : ''}
				mode={modeComments}
				paperProps={{ sx: { position: 'fixed', top: 400, right: 10, width: '25%' } }}></AddCommentDialog>
		</Box>
	)
}
