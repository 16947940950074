import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes } from '../../../constants'
import { useVesselAlertFilterDelete } from '../../../services/alerts/vesselalert/useVesselAlertFilterDelete'
import { useVesselAlertFilterPost } from '../../../services/alerts/vesselalert/useVesselAlertFilterPost'
import { assignFilter } from '../../../utils/customFunctions'
import { datetimeGMTToLocaleInISOFormat, isStartDateBefore } from '../../comun/DateUtils'
import FieldsetComponent from '../../comun/FieldsetComponent'
import FilterFunctions from '../../comun/FilterFunctions'
import MessageSnackBar from '../../comun/MessageSnackBar'
import AutoCompleteComponent from '../../comun/form_fields/AutoCompleteComponent'
import DatePickerComponent from '../../comun/form_fields/DatePickerComponent'
import SelectComponent from '../../comun/form_fields/SelectComponent'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'

export default function AlertViewFilter({
	onFilter,
	filter,
	vessels,
	alertVesselFilter,
	formActions,
	favouriteFilters,
	statusOptions,
}) {
	const [t] = useTranslation('common')

	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = formActions

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useVesselAlertFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useVesselAlertFilterDelete()

	useEffect(() => {
		if (alertVesselFilter) {
			let status = statusOptions.find((s) => s.value == alertVesselFilter.status)
			let vessel = vessels.find((v) => v.vesselApiId == alertVesselFilter.vesselApiId)
			setValue('name', alertVesselFilter.name ? alertVesselFilter.name : '')
			setValue('vesselApiId', vessel)
			setValue('startDate', alertVesselFilter.startDate)
			setValue('endDate', alertVesselFilter.endDate)
			setValue('status', status)
		}
	}, [alertVesselFilter])

	const onSearch = (data) => {
		data.startDate = datetimeGMTToLocaleInISOFormat(data.startDate)
		data.endDate = datetimeGMTToLocaleInISOFormat(data.endDate)
		data.status = data.status?.value
		data.vesselApiId = data.vesselApiId?.vesselApiId
		//We do this to mantain the data about sort, page, rowsPerPage, direction...etc
		assignFilter(data, filter)
		onFilter(data)
	}

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({})

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const saveFilter = (filterName) => {
		const formValues = getValues()

		let filterDataAux = {
			filterName: filterName,
			name: formValues.name,
			vesselApiId: formValues.vesselApiId?.vesselApiId,
			startDate: datetimeGMTToLocaleInISOFormat(formValues.startDate),
			endDate: datetimeGMTToLocaleInISOFormat(formValues.endDate),
			status: formValues.status?.value,
		}

		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter && filterData) {
			setFavFilters((prevState) => [...prevState, filterData])
		}
	}, [dataFilter, filterData])

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	}

	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			let status = statusOptions.find((s) => s.value == selectedFilter.status)
			let vessel = vessels.find((s) => s.vesselApiId == selectedFilter.vesselApiId)

			setValue('name', selectedFilter.name)
			setValue('vesselApiId', vessel)
			setValue('startDate', selectedFilter.startDate)
			setValue('endDate', selectedFilter.endDate)
			setValue('status', status)
		}
	}, [selectedFilter])

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
	}

	// Set errrors
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<FieldsetComponent title={t('alert.view.filter.title')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box sx={{ display: 'flex' }} component='form' onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<TextFieldComponent
							sx={{ width: '50%', m: 1 }}
							size='small'
							label={t('alert.view.filter.name')}
							name='name'
							control={control}
						/>
						<AutoCompleteComponent
							control={control}
							defaultValue={null}
							sx={{ width: '50%', m: 1 }}
							size='small'
							label={t('alert.view.filter.vessel')}
							name='vesselApiId'
							options={vessels || []}
							optionsLabel={'vesselName'}
							keyProperty={'vesselApiId'}
							evalMethod={(opt, val) => {
								return opt.vesselApiId === val.vesselApiId
							}}
						/>
					</Box>
					<Box sx={{ display: 'flex', width: '100%' }}>
						<Box sx={{ display: 'flex', width: '50%', m: 1 }}>
							<DatePickerComponent
								sx={{ width: '50%' }}
								size='small'
								name='startDate'
								control={control}
								defaultValue={null}
								label={t('alert.view.filter.from-date')}
								errors={errors?.startDate}
								rules={{
									validate: (startDateValue) =>
										isStartDateBefore(startDateValue, getValues('endDate')) ||
										t('programs.validations.start-date'),
								}}
							/>
							<DatePickerComponent
								sx={{ width: '50%', ml: 1 }}
								size='small'
								name='endDate'
								control={control}
								defaultValue={null}
								label={t('alert.view.filter.to-date')}
							/>
						</Box>
						<Box sx={{ display: 'flex', width: '50%', m: 1 }}>
							<SelectComponent
								sx={{ width: '100%' }}
								label={t('alert.view.filter.status')}
								defaultValue={statusOptions ? statusOptions[0] : null}
								name='status'
								control={control}
								size='small'
								options={statusOptions}
								optionsLabel={'description'}
								optionsValue={'value'}
							/>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
					<FilterFunctions
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						filters={favFilters}
						threeLines={false}
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
