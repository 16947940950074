import { FormHelperText, Input } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import { Circle } from '@uiw/react-color'
import React from 'react'
import { Controller } from 'react-hook-form'

export default function SelectColor({
	rules,
	control,
	defaultValue,
	name,
	label,
	errors,
	sx,
	required,
	InputProps,
	disabled,
	colors,
}) {
	const [hex, setHex] = React.useState('#fff')
	const inputRef = React.useRef(null)
	return (
		<FormControl sx={sx} error={!!errors}>
			<Circle
				colors={colors}
				color={hex}
				onChange={(color) => {
					setHex(color.hex)
					var input = inputRef.current
					var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
						window.HTMLInputElement.prototype,
						'value'
					).set
					nativeInputValueSetter.call(input, color.hex)

					var ev2 = new Event('input', { bubbles: true })
					input.dispatchEvent(ev2)
				}}
			/>

			<Controller
				rules={rules}
				defaultValue={defaultValue ? defaultValue : ''}
				name={name}
				control={control}
				render={({ field: { onChange, value, onBlur } }) => (
					<Input
						hidden={true}
						sx={{ display: { xl: 'none', xs: 'block' } }}
						disabled={disabled}
						InputProps={InputProps}
						required={required}
						onBlur={onBlur}
						onChange={onChange}
						value={value}
						label={label} //Etiqueta del campo
						inputRef={inputRef}
						error={!!errors}
					/>
				)}
			/>
			<FormHelperText>{errors?.message}</FormHelperText>
		</FormControl>
	)
}
