//export const baseUrl = 'https://localhost:49153/' //Docker
//export const baseUrl = 'https://localhost:44347/' //IISERVER

import { bUrl } from "./baseUrl"
export const baseUrl = bUrl //PRE

//export const baseUrl = 'https://api.seatube.es/emapi/' //PRO

//export const baseUrl = 'https://emwebservice.azurewebsites.net/'
//export const baseUrl = 'https://emwebserviceprod.azurewebsites.net/'

// console.log("Version: " + process.env.REACT_APP_VERSION)

export const refreshTokenUrl = 'horus/f3/refresh-token'

export const version = {
	company: 'Horus ',
	value: 'v' + process.env.REACT_APP_VERSION,
}

export const appRoutes = {
	LOGIN: '/login',
	HOME: '/',
	PROJECTS: '/project',
	REPORTS: '/project/:projectId/reports',
	CALIBRATIONS: '/calibrations',
	COUNT: '/count',
	TIMER: '/timer',
	TIMER_PROJECTS: '/timer-projects',
	TIMER_USERS: '/timer-users',
	TESTING: '/testing',
	PLANIFICATIONS: '/planifications',
	USERS_MANAGEMENT: '/users',
	USERS_PROFILE: '/users-profile',
	PREVIEW: '/preview',
	CHANGE_PASSWORD: '/changepassword/:code',
	ALERTS: '/alert',
	ALERTS_CONFIG: '/alert-config',
	ALERT_ZONES: '/alert-zones',
	ERROR_PAGE: '/error',
	MESSENGER_PAGE: '/messenger',
	DOWNLOADS: '/downloads'
}

export const errorTypes = {
	WRONG_JWT: 'ERR_WRONG_JWT',
}

export const messageDialogTypes = {
	INFO_MESSAGE: 'INFO_MESSAGE',
	WARNING_MESSAGE: 'WARNING_MESSAGE',
	ERROR_MESSAGE: 'ERROR_MESSAGE',
	SUCCESS_MESSAGE: 'SUCCESS_MESSAGE',
}

export const modeTypesCRUD = {
	UPDATE_MODE: 'UPDATE_MODE',
	INSERT_MODE: 'INSERT_MODE',
	DELETE_MODE: 'DELETE_MODE',
	ENABLE_MODE: 'ENABLE_MODE',
	CLEAR_MODE: 'CLEAR_MODE',
	DOWNLOAD_REPORT: 'DOWNLOAD_REPORT',
}

export const rolesConstants = {
	ADMIN: 'Admin',
	FLEET_ADMIN: 'FleetAdmin',
	FLEET_STAFF: 'FleetStaff',
	EM_ADMIN: 'EMAdmin',
	EM_STAFF: 'EMStaff',
}

export const codesActivityTimer = {
	LOGIN: 1,
	LOGOUT: 2,
	INACTIVITY: 3,
	INIT_ACTIVITY: 4,
	DASHBOARD: 100,
	DASHBOARD_DETAILS_VIEW: 101,
	DASHBOARD_DETAILS_EDIT: 103,
	DASHBOARD_CHANGE_MODE_RECORDED: 104,
	DASHBOARD_ALERTS: 200,
	ALERTS_VIEW: 201,
	ALERTS_REPORT: 202,
	ALERTS_LINK_PREVIEW: 203,
	ALERTS_CONFIG_VIEW: 301,
	ALERTS_CONFIG_CREATE: 302,
	ALERTS_CONFIG_EDIT: 303,
	ALERTS_ZONE_VIEW: 311,
	ALERTS_ZONE_CREATE: 312,
	ALERTS_ZONE_EDIT: 313,
	ALERTS_ZONE_ACT: 353,
	ALERTS_ZONE_DEACT: 354,
	PREVIEW: 400,
	PREVIEW_IMAGE_VIEW: 401,
	PREVIEW_IMAGE_DOWNLOAD: 402,
	PREVIEW_REQUEST_VIDEO: 403,
	PREVIEW_PLAY_VIDEO: 404,
	NOTE_CREATE: 405,
	NOTE_EDIT: 406,
	NOTE_DELETE: 407,
	NOTE_COMMENTS_CREATE: 408,
	ADD_PROJECT_VIDEO: 409,
	VIEW_PROJECT_NOTES: 410,
	USERS_PAGE: 500,
	TIMER_PAGE: 550,
	PLANNING_PAGE: 575,
	PROJECT_PAGE: 600,
	PROJECT_CREATE: 601,
	PROJECT_EDIT: 602,
	PROJECT_DELETE: 603,
	PROJECT_CHANGE_STATE: 604,
	PROJECT_GET: 605,
	PROJECT_REPORT_VIEW: 606,
	PROJECT_REPORT_CREATE: 607,
	PROJECT_ANALYSIS: 608,
	PROJECT_REVIEW: 609,
	PROJECT_NOTES_VIEW: 610,
	PROJECT_NOTES_LINK: 611,
	DOWNLOAD_IMAGE: 700,
}

export const projectStates = {
	MAPPER: 1,
	READY_ANALYZE: 2,
	READY_REVIEW: 3,
	PENDING_VERIFICATIONS: 4,
	CLOSED: 5,
	REMOVED: 6,
}

export const jwtCookieName = 'session_jwt'
export const jwtRefreshToken = 'refresh_token'
export const rolesCookieName = 'session_roles'
export const jwtCookieExpiryName = 'session_jwt_expiry'
export const currentVesselCookie = 'current_vessel'
export const currentModeValue = 'current_mode_value'
export const currentVideoInfo = 'current_video_info'
export const currentProjectCookie = 'current_project_info'
export const previousState = 'previous_state'

export const languageCookieName = 'language'
export const acceptCookieName = 'cookie-accept'

export const timeoutIdle = 300000 // 5min

export const passwordRegex = '^(.{0,7}|[^0-9]*|[^A-Z]*|[^a-z]*|[a-zA-Z0-9]*)$'

export const baseFilter = { pageSize: 10, pageNo: 1 }

export const mapSpeedThresholds = {
	red: 10.8,
	green: 11.2,
}

export const mapMarkerSize = 15

export const previewVideoZoomStep = 0.5
