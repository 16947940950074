import { Box } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import CalibrationCanvas from './CalibrationCanvas'
import CalibrationControls from './CalibrationControls'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../comun/BasicDialog'
import { useCalibration } from '../../services/calibrations/useCalibration'
import { useCalibrationInsert } from '../../services/calibrations/useCalibrationInsert'
import { useCalibrationUpdate } from '../../services/calibrations/useCalibrationUpdate'
import { useCalibrationDelete } from '../../services/calibrations/useCalibrationDelete'
import { messageDialogTypes } from '../../constants'
import BackdropComponent from '../comun/utils/BackdropComponent'
import MessageSnackBar from '../comun/MessageSnackBar'

export default function CalibrationContainer({ onOpen, onClose, image, vesselApiId, cam, imageId }) {
	const [t] = useTranslation('common')
	const canvas = useRef()
	const [posCounter, setPosCounter] = useState(0)
	const [isEditing, setIsEditing] = useState(false)
	const [isCreating, setIsCreating] = useState(false)

	const [sqrList, setSqrList] = useState([])
	const [sqrSelected, setSqrSelected] = useState(null)

	const [errorName, setErrorName] = useState(null)

	const { calibrationRequest, isLoadingCalibration, calibrationData, calibrationError } = useCalibration()
	const {
		calibrationInsert,
		isLoadingInsert,
		calibrationDataInsert,
		errorCalibrationInsert,
		setCalibrationDataInsert,
	} = useCalibrationInsert()
	const {
		calibrationUpdate,
		isLoadingUpdate,
		calibrationDataUpdate,
		errorCalibrationUpdate,
		setCalibrationDataUpdate,
	} = useCalibrationUpdate()
	const {
		calibrationDelete,
		isLoadingDelete,
		calibrationDataDelete,
		errorCalibrationDelete,
		setCalibrationDataDelete,
	} = useCalibrationDelete()

	//Control Errors
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeMessage, setTypeMessage] = useState(null)

	React.useEffect(() => {
		calibrationRequest(vesselApiId, cam)
	}, [])

	React.useEffect(() => {
		if (calibrationDataInsert) {
			let auxList = [...sqrList]
			auxList.push(parseCalibrationSquare(calibrationDataInsert))
			setSqrList(auxList)
			setCalibrationDataInsert(null)
		} else if (calibrationDataUpdate) {
			let updatedItem = parseCalibrationSquare(calibrationDataUpdate)
			let auxList = [...sqrList]
			let idx = auxList.findIndex((sqr) => sqr.name == updatedItem.name)
			auxList[idx] = updatedItem
			setSqrList(auxList)
			setCalibrationDataUpdate(null)
		} else if (calibrationDataDelete) {
			calibrationRequest(vesselApiId, cam)
			setCalibrationDataDelete(null)
		}
	}, [calibrationDataInsert, calibrationDataUpdate, calibrationDataDelete])

	const handleChangeDistance = (e) => {
		setSqrSelected((prevState) => ({
			...prevState,
			distance: e.target.value,
		}))
	}
	const handleChangeUnit = (e) => {
		setSqrSelected((prevState) => ({
			...prevState,
			unit: e.target.value,
		}))
	}

	const handleChangeName = (e) => {
		if (sqrList.some((item) => item.name == e.target.value)) {
			setErrorName(t('calibration.error-name'))
		} else if (e.target.value.length >= 50) {
			setErrorName(t('calibration.error-name-length'))
		} else {
			setErrorName(null)
		}
		setSqrSelected((prevState) => ({
			...prevState,
			name: e.target.value,
		}))
	}
	const handleChangeDefault = (e) => {
		if (e.target.checked) {
			let auxList = [...sqrList]
			auxList.forEach((item) => (item.default = item.default ? false : item.default))
		}
		setSqrSelected((prevState) => ({
			...prevState,
			default: e.target.checked,
		}))
	}

	const parseData = (data) => {
		let configList = []
		data.forEach((item) => {
			let config = {}
			config.id = item.calibrationId
			config.name = item.description
			config.positions = []
			config.default = item.defaultCalibration
			config.distance = item.lineLength
			config.unit = item.unit
			config.positions.push(
				{ x: item.ax, y: item.ay, name: 'A' },
				{ x: item.bx, y: item.by1, name: 'B' },
				{ x: item.cx, y: item.cy, name: 'C' },
				{ x: item.dx, y: item.dy, name: 'D' }
			)
			configList.push(config)
		})
		return configList
	}

	const parseCalibrationSquare = (data) => {
		let config = {}
		config.id = data.calibrationId
		config.name = data.description
		config.positions = []
		config.default = data.defaultCalibration
		config.distance = data.lineLength
		config.unit = data.unit
		config.positions.push(
			{ x: data.ax, y: data.ay, name: 'A' },
			{ x: data.bx, y: data.by1, name: 'B' },
			{ x: data.cx, y: data.cy, name: 'C' },
			{ x: data.dx, y: data.dy, name: 'D' }
		)
		return config
	}

	useEffect(() => {
		if (calibrationData) {
			let config = parseData(calibrationData)
			setSqrList(config)
		}
	}, [calibrationData])

	useEffect(() => {
		if (calibrationError || errorCalibrationInsert || errorCalibrationUpdate || errorCalibrationDelete) {
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setOpenError(true)
			if (calibrationError) {
				setErrorMsg(calibrationError)
			} else if (errorCalibrationInsert) {
				setErrorMsg(errorCalibrationInsert)
			} else if (errorCalibrationUpdate) {
				setErrorMsg(errorCalibrationUpdate)
			} else if (errorCalibrationDelete) {
				setErrorMsg(errorCalibrationDelete)
			}
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [calibrationError, errorCalibrationInsert, errorCalibrationUpdate, errorCalibrationDelete])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<BasicDialog
			title={t('calibration.title')}
			onOpen={onOpen}
			onClose={onClose}
			noactions
			fullScreen
			annotationModal>
			<Box sx={{ display: 'flex', width: '100%' }}>
				<Box sx={{ width: '70%', height: '85vh' }}>
					<CalibrationCanvas
						sqrList={sqrList}
						posCounter={posCounter}
						setPosCounter={setPosCounter}
						sqrSelected={sqrSelected}
						setSqrSelected={setSqrSelected}
						setSqrList={setSqrList}
						image={image}
					/>
				</Box>

				<Box sx={{ width: '30%' }}>
					<CalibrationControls
						cam={cam}
						vesselApiId={vesselApiId}
						canvas={canvas}
						handleChangeDistance={handleChangeDistance}
						setPosCounter={setPosCounter}
						posCounter={posCounter}
						setIsCreating={setIsCreating}
						isCreating={isCreating}
						isEditing={isEditing}
						setIsEditing={setIsEditing}
						sqrList={sqrList}
						setSqrList={setSqrList}
						sqrSelected={sqrSelected}
						setSqrSelected={setSqrSelected}
						handleChangeName={handleChangeName}
						handleChangeDefault={handleChangeDefault}
						handleChangeUnit={handleChangeUnit}
						calibrationInsert={calibrationInsert}
						calibrationUpdate={calibrationUpdate}
						calibrationDelete={calibrationDelete}
						setErrorName={setErrorName}
						errorName={errorName}
						imageId={imageId}
					/>
				</Box>

				<BackdropComponent
					open={isLoadingCalibration || isLoadingDelete || isLoadingInsert || isLoadingUpdate}
				/>
				<MessageSnackBar
					openError={openError}
					errorMsg={errorMsg}
					handleCloseError={handleCloseError}
					typeMsg={typeMessage}
				/>
			</Box>
		</BasicDialog>
	)
}
