import DownloadIcon from '@mui/icons-material/Download'
import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, TableFooter, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePaginationComponent from '../comun/TablePaginationComponent'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from '../../constants'
import '../../index.css'
import { datetimeUTCtoString } from '../comun/DateUtils'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import { EnhancedTableHead, getComparator, stableSort } from '../comun/TableUtils'

export default function ReportsTable({ reportList, handleFormReports, filter, onFilter, statusOptions }) {
	const [t] = useTranslation('common')
	//This block should be the same in every table component ----------------------------------
	const [order, setOrder] = React.useState(filter.orderDirection)
	const [orderBy, setOrderBy] = React.useState(filter.orderField)
	const [page, setPage] = React.useState(0)
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(filter.pageSize)

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------

	React.useEffect(() => {
		if (reportList) {
			setPage(reportList.pageNo - 1)
			setTotalCount(reportList.totalCount)
		}
	}, [reportList])

	const handleAddAction = () => {
		handleFormReports(null, modeTypesCRUD.INSERT_MODE)
	}

	const columnas = [
		{
			id: 'requestDate',
			orderBy: true,
			label: t('reports.table.header.start-date'),
		},
		{
			id: 'description',
			orderBy: true,
			label: t('reports.table.header.export-type'),
		},
		{
			id: 'generationDate',
			orderBy: true,
			label: t('reports.table.header.status'),
		},
		{
			id: 'actions',
			label: '',
			excluirExcel: true,
		},
	]

	return (
		<TableContainer>
			<Table
				id='table-reports-list'
				aria-labelledby='tableTitle'
				size='small'
				sx={{ minWidth: 750 }}
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					addAction={handleAddAction}
				/>
				<TableBody>
					{reportList
						? stableSort(reportList.data, getComparator(order, orderBy)).map((row, index) => {
								return (
									<StyledTableRow key={index}>
										<TableCell align='center'>{datetimeUTCtoString(row.requestDate)}</TableCell>
										<TableCell align='center'>{row.description}</TableCell>
										<TableCell align='center'>
											{row.generationDate != null ? statusOptions[0] : statusOptions[1]}
										</TableCell>
										<TableCell align='center'>
											{row.generationDate != null && (
												<Tooltip title={t('reports.table.content.download')}>
													<IconButton
														color='primary'
														onClick={() =>
															handleFormReports(row, modeTypesCRUD.DOWNLOAD_REPORT)
														}>
														<DownloadIcon />
													</IconButton>
												</Tooltip>
											)}
											<Tooltip title={t('reports.table.content.delete')}>
												<IconButton
													color='error'
													onClick={() => handleFormReports(row, modeTypesCRUD.DELETE_MODE)}>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</StyledTableRow>
								)
						  })
						: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
