
import { codesActivityTimer } from '../constants'
import { nowDateToUTC } from '../components/comun/DateUtils'

export function getInactiveDataUser() {

  const data = { "logDate": nowDateToUTC(), "activityId": codesActivityTimer.INACTIVITY, "action": "INACTIVITY" }

  return data;
}


export function getRestartActivityDataUser() {

  const data = { "logDate": nowDateToUTC(), "activityId": codesActivityTimer.INIT_ACTIVITY, "action": "INIT_ACTIVITY" }

  return data;
}

export function getLogoutDataUser() {

  const data = { "logDate": nowDateToUTC(), "activityId": codesActivityTimer.LOGOUT, "action": "LOGOUT" }
  return data;
}

export function getGeneralActivity(idActivity, action) {

  const data = { "logDate": nowDateToUTC(), "activityId": idActivity, "action": action }

  return data;
}