import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCalibration } from '../../services/calibrations/useCalibration'
import { useMeasurementInsert } from '../../services/measurements/useMeasurementInsert'
import { useMeasurementRequest } from '../../services/measurements/useMeasurementRequest'
import { useMeasurementUpdate } from '../../services/measurements/useMeasurementUpdate'
import BasicDialog from '../comun/BasicDialog'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import MeasureCanvas from './MeasureCanvas'
import MeasureControls from './MeasureControls'
import {
	isMeasureComplete,
	measureObject,
	parseCalibrationData,
	parseMeasurementDataFromServer,
	parseMeasureProjectItem,
	Point,
} from './MeasureFunctions'
import { useState } from 'react'
import { useEffect } from 'react'

export default function MeasureContainer({ onOpen, onClose, image, cam, projectItemId, vesselApiId, isUpdating }) {
	const [t] = useTranslation('common')
	//1. Get all the calibrations for this cam, create new measure, select calibration to be used, click the points, get the measurement and save
	const [measureList, setMeasureList] = useState([])
	const [measureSelected, setMeasureSelected] = useState(null)
	const [sqrList, setSqrList] = useState([])
	const [sqrSelected, setSqrSelected] = useState(null)
	const [posCounter, setPosCounter] = useState(0)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [comments, setComments] = useState([])

	const { calibrationRequest, isLoadingCalibration, calibrationData, calibrationError } = useCalibration()
	const { measureRequest, measureData, measureError, isLoadingMeasure } = useMeasurementRequest()
	const { measureInsert, measureInsertData, measureInsertError, isLoadingMeasureInsert } = useMeasurementInsert()
	const { measureUpdate, measureUpdateData, measureUpdateError, isLoadingMeasureUpdate } = useMeasurementUpdate()

	useEffect(() => {
		if (measureInsertData) {
			onClose(true)
		} else if (measureUpdateData) {
			onClose(true)
		}
	}, [measureInsertData, measureUpdateData])

	useEffect(() => {
		if (measureInsertError) {
			setOpenError(true)
			setErrorMsg(measureInsertError)
		} else if (measureUpdateError) {
			setOpenError(true)
			setErrorMsg(measureUpdateError)
		} else if (measureError) {
			setOpenError(true)
			setErrorMsg(measureError)
		} else if (calibrationError) {
			setOpenError(true)
			setErrorMsg(calibrationError)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [measureInsertError, measureUpdateError, measureError, calibrationError])

	const handleCloseError = () => {
		setErrorMsg(null)
		setOpenError(false)
	}

	useEffect(() => {
		if (calibrationData) {
			let config = parseCalibrationData(calibrationData)
			setSqrList(config)
		}
	}, [calibrationData])

	useEffect(() => {
		calibrationRequest(vesselApiId, cam)
		measureRequest(projectItemId)
	}, [])

	useEffect(() => {
		if (measureData) {
			let measures = parseMeasurementDataFromServer(measureData)
			setMeasureList(measures)
			if (measureData?.comments) {
				setComments(measureData?.comments)
			}
		}
	}, [measureData])

	useEffect(() => {
		if (measureSelected) {
			setSqrSelected(sqrList.find((sqr) => sqr.id == measureSelected.calibrationRecordId))
			if (isMeasureComplete(measureSelected) && measureSelected.updated) {
				let measure = measureObject(
					new Point(measureSelected.positions[0].x, measureSelected.positions[0].y),
					new Point(measureSelected.positions[1].x, measureSelected.positions[1].y),
					new Point(sqrSelected.positions[0].x, sqrSelected.positions[0].y),
					new Point(sqrSelected.positions[1].x, sqrSelected.positions[1].y),
					new Point(sqrSelected.positions[2].x, sqrSelected.positions[2].y),
					new Point(sqrSelected.positions[3].x, sqrSelected.positions[3].y),
					sqrSelected.distance
				)
				setMeasureSelected((prev) => ({
					...prev,
					measureValue: measure.toFixed(4),
					updated: false,
				}))
			}
		} else {
			setSqrSelected(null)
		}
	}, [measureSelected])

	const saveMeasureProjectItem = () => {
		let projectItem = parseMeasureProjectItem(measureList, projectItemId)
		isUpdating ? measureUpdate(projectItem) : measureInsert(projectItem)
	}

	return (
		<BasicDialog title={t('measure.title')} onOpen={onOpen} onClose={onClose} noactions fullScreen>
			<BackdropComponent
				open={isLoadingCalibration || isLoadingMeasure || isLoadingMeasureInsert || isLoadingMeasureUpdate}
			/>
			<MessageSnackBar openError={openError} handleCloseError={handleCloseError} errorMsg={errorMsg} />
			<Box sx={{ display: 'flex', width: '100%' }}>
				<Box sx={{ width: '70%', height: '85vh' }}>
					<MeasureCanvas
						img={image}
						measureSelected={measureSelected}
						measureList={measureList}
						setMeasureList={setMeasureList}
						sqrList={sqrList}
						setMeasureSelected={setMeasureSelected}
						posCounter={posCounter}
						setPosCounter={setPosCounter}
						sqrSelected={sqrSelected}
					/>
				</Box>
				<Box sx={{ width: '30%' }}>
					<MeasureControls
						posCounter={posCounter}
						measureList={measureList}
						measureSelected={measureSelected}
						setMeasureSelected={setMeasureSelected}
						setSqrSelected={setSqrSelected}
						sqrList={sqrList}
						setMeasureList={setMeasureList}
						setPosCounter={setPosCounter}
						saveMeasureProjectItem={saveMeasureProjectItem}
						onClose={onClose}
						sqrSelected={sqrSelected}
						projectItemId={projectItemId}
						comments={comments}
					/>
				</Box>
			</Box>
		</BasicDialog>
	)
}
