import { Box, Button, Popover } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ChartLayerConfigFormLine from './ChartLayerConfigFormLine'

export default function ChartLayerConfigForm({
	open,
	anchorEl,
	handleClose,
	layer,
	id,
	updateLayersConfig,
	setLayers,
	layers,
	layerIdx,
}) {
	const [t] = useTranslation('common')
	const handleSave = () => {
		updateLayersConfig(layer)
		handleClose()
	}

	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}>
			<Box
				sx={{
					width: '100%',
					height: '5vh',
					color: 'white',
					backgroundColor: '#1976d2',
					display: 'flex',
					alignItems: 'center',
					pl: 2,
				}}>
				{t('preview.chart.layer') + layer.layer}
			</Box>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					minHeight: '32vh',
					minWidth: '35vw',
					maxHeight: '32vh',
					overflowY: 'auto',
				}}>
				{layer?.series.map((serie, seriesIdx) => {
					return (
						<ChartLayerConfigFormLine
							layerIdx={layerIdx}
							setLayers={setLayers}
							layers={layers}
							key={serie.sensorSeatubeId + layer.layer}
							layer={layer}
							seriesIdx={seriesIdx}
							serie={serie}
						/>
					)
				})}
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'end' }}>
				<Button variant='outlined' sx={{ m: 1 }} onClick={handleSave}>
					{t('comun.actions.save')}
				</Button>
				<Button variant='outlined' color='inherit' sx={{ m: 1 }} onClick={handleClose}>
					{t('comun.actions.cancel')}
				</Button>
			</Box>
		</Popover>
	)
}
