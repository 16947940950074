import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import MessengerContainer from '../components/messenger/MessengerContainer'

const MessengerPage = ({ location: { state } }) => {
	const history = useHistory()
	return (
		<ErrorBoundary history={history}>
			<MessengerContainer state={state} />
		</ErrorBoundary>
	)
}

export default MessengerPage
