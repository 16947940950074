import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useConfigAlertUpdate = () => {
	const [configAlertDataUpdate, setConfigAlertDataUpdate] = useState(null)
	const [errorConfigAlertUpdate, setErrorConfigAlertUpdate] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const configAlertUpdate = useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/alert/config', props)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setConfigAlertDataUpdate({ data: result.data })
					} else {
						setErrorConfigAlertUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorConfigAlertUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setConfigAlertDataUpdate(null)
			setErrorConfigAlertUpdate(null)
		},
		[client]
	)

	return {
		configAlertUpdate,
		isLoadingUpdate,
		configAlertDataUpdate,
		setConfigAlertDataUpdate,
		errorConfigAlertUpdate,
		setErrorConfigAlertUpdate,
	}
}
