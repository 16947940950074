import { useCallback, useState, useContext } from 'react'
import { ApiClientContext } from '../../../../ApiClientProvider'
import { baseUrl } from '../../../../../constants'

export const useVideoAsignedRequest = () => {
	const [videoAsignedRequestData, setVideoAsignedRequestData] = useState(null)
	const [isLoadingVideoAsignedRequest, setIsLoadingVideoAsignedRequest] = useState(false)
	const [errorVideoAsignedRequest, setErrorVideoAsignedRequest] = useState(null)

	const { client } = useContext(ApiClientContext)

	const videoAsignedRequest = useCallback(
		(filter, cams) => {
			let url = 'horus/f3/project/preview/videodaterange?projectId=' + filter.projectId
			url = url + '&from=' + filter.from + '&to=' + filter.to
			url = url + cams.map((c) => '&cam=' + c)
			url = url.replaceAll(',', '')
			client
				.get(baseUrl + url)
				.then((result) => {
					setIsLoadingVideoAsignedRequest(false)
					if (result.data.success) {
						setVideoAsignedRequestData(result.data.data)
					} else {
						setErrorVideoAsignedRequest({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingVideoAsignedRequest(false)
					setErrorVideoAsignedRequest(err.response)
				})
			setIsLoadingVideoAsignedRequest(true)
			setVideoAsignedRequestData(null)
			setErrorVideoAsignedRequest(null)
		},
		[client]
	)

	return {
		videoAsignedRequest,
		videoAsignedRequestData,
		isLoadingVideoAsignedRequest,
		errorVideoAsignedRequest,
	}
}
