import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserInsert = () => {
	const [userDataInsert, setUserDataInsert] = useState(null)
	const [errorUserInsert, setErrorUserInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const userInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/user', props)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setUserDataInsert({ data: result.data })
					} else {
						setErrorUserInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorUserInsert(error.response)
					setIsLoadingInsert(false)
				})

			setUserDataInsert(null)
			setErrorUserInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		userInsert,
		isLoadingInsert,
		userDataInsert,
		setUserDataInsert,
		errorUserInsert,
		setErrorUserInsert,
	}
}
