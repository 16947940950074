import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Box, Button, Divider, List, ListItemButton, ListItemText } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConfirmationDialog from '../comun/ConfirmationDialog'
import CalibrationSquareCard from './CalibrationSquareCard'

export default function CalibrationControls({
	handleChangeDistance,
	setPosCounter,
	posCounter,
	setIsCreating,
	sqrList,
	sqrSelected,
	setSqrSelected,
	handleChangeName,
	handleChangeDefault,
	handleChangeUnit,
	calibrationInsert,
	calibrationUpdate,
	calibrationDelete,
	vesselApiId,
	cam,
	errorName,
	setErrorName,
	imageId,
}) {
	const [isEditingAction, setIsEditingAction] = useState(false)
	const [openConfirmation, setOpenConfirmation] = useState(false)

	const [t] = useTranslation('common')
	const createSquare = () => {
		setIsCreating(true)
		let newSqr = {
			name: '',
			positions: [{}, {}, {}, {}],
			distance: 0,
			default: sqrList.length == 0 ? true : false,
		}
		setSqrSelected(newSqr)
		setPosCounter(0)
	}

	const parseData = (data) => {
		let config = {}
		if (isEditingAction) {
			config.calibrationId = data.id
		}
		config.vesselApiId = vesselApiId
		config.camNo = cam
		config.calibrationImage = imageId
		config.description = data.name
		config.ax = data.positions[0].x
		config.ay = data.positions[0].y
		config.bx = data.positions[1].x
		config.by1 = data.positions[1].y
		config.cx = data.positions[2].x
		config.cy = data.positions[2].y
		config.dx = data.positions[3].x
		config.dy = data.positions[3].y
		config.lineLength = data.distance
		config.unit = data.unit
		config.defaultCalibration = data.default
		config.calibrationDate = new Date()
		return config
	}

	const saveSqr = () => {
		let config = parseData(sqrSelected)
		if (config.description == '') {
			setErrorName(t('calibration.error-name-required'))
		} else {
			isEditingAction ? calibrationUpdate(config) : calibrationInsert(config)
			setSqrSelected(null)
			setIsEditingAction(false)
			setPosCounter(0)
		}
	}

	const openDeleteConfirmation = () => {
		setOpenConfirmation(true)
	}

	const deleteSqr = (success) => {
		if (success) {
			calibrationDelete(sqrSelected.id)
			setSqrSelected(null)
			setIsEditingAction(false)
			setPosCounter(0)
		}
		setOpenConfirmation(false)
	}
	const cancelCreateSqr = () => {
		setSqrSelected(null)
		setIsEditingAction(false)
		setPosCounter(0)
	}

	const handleSetSelected = (sqr) => {
		setIsEditingAction(true)
		setSqrSelected(sqr)
		setPosCounter(0)
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
			<ConfirmationDialog
				onOpen={openConfirmation}
				dialogTitle={t('calibration.delete-title')}
				dialogContent={t('calibration.delete-content')}
				onClose={deleteSqr}
				dialogSaveButton={t('comun.actions.delete')}
				dialogCancelButton={t('comun.actions.cancel')}
				isDelete
			/>
			<Button
				sx={{ width: '95%', mx: 1, mb: 1 }}
				variant='outlined'
				onClick={createSquare}
				disabled={sqrSelected != null}>
				{t('calibration.new-square')}
			</Button>

			<List sx={{ maxHeight: '30vh', minHeight: '30vh', overflowY: 'auto', width: '100%' }}>
				{sqrList?.map((sqr, idx) => {
					return (
						<React.Fragment key={sqr.name + idx}>
							<ListItemButton
								sx={{
									width: '95%',
									mx: 1,
								}}
								selected={sqr.name == sqrSelected?.name}
								onClick={() => handleSetSelected(sqr)}>
								<ListItemText primary={sqr.name} />

								{sqr.default && <CheckBoxIcon color='primary' />}
							</ListItemButton>
							{idx != sqrList.length - 1 && <Divider variant='middle' component='li' />}
						</React.Fragment>
					)
				})}
			</List>
			{sqrSelected && (
				<CalibrationSquareCard
					errorName={errorName}
					handleChangeDistance={handleChangeDistance}
					posCounter={posCounter}
					setPosCounter={setPosCounter}
					sqrSelected={sqrSelected}
					deleteSqr={openDeleteConfirmation}
					saveSqr={saveSqr}
					handleChangeName={handleChangeName}
					handleChangeUnit={handleChangeUnit}
					handleChangeDefault={handleChangeDefault}
					cancelCreateSqr={cancelCreateSqr}
				/>
			)}
		</Box>
	)
}
