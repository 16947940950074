import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import SearchIcon from '@mui/icons-material/Search'
import { Avatar, Box, InputAdornment, List, ListItem, ListItemAvatar, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import vesselIcon from '../../assets/vessel.png'
import { datetimeUTCtoString } from '../comun/DateUtils'
import { useTranslation } from 'react-i18next'

export default function ChatList({ chatSelected, chats, handleChangeSelected }) {
	const [searchText, setSearchText] = useState('')
	const [displayedChats, setDisplayedChats] = useState(chats)
	const [t] = useTranslation('common')
	useEffect(() => {
		setDisplayedChats(chats)
	}, [chats])

	const handleChangeSearchText = (e) => {
		setSearchText(e.target.value)
		let auxChats = [...chats]
		auxChats = auxChats.filter((c) => c.name.toUpperCase().includes(e.target.value.toUpperCase()))
		setDisplayedChats(auxChats)
	}

	const getTickStatus = (status) => {
		switch (status) {
			case 1:
				return <DoneIcon sx={{ width: '16px', height: '16px', color: 'green', mr: 0.5 }} />
			case 2:
				return <DoneAllIcon sx={{ width: '16px', height: '16px', color: 'green', mr: 0.5 }} />
			case 3:
				return <AccessTimeIcon sx={{ width: '16px', height: '16px', color: 'grey', mr: 0.5 }} />
			default:
				return null
		}
	}

	return (
		<Box sx={{ width: '25%', height: '100%' }}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<TextField
					value={searchText}
					size='small'
					onChange={handleChangeSearchText}
					autoComplete='off'
					placeholder={t('messenger.search')}
					sx={{ flex: 1, mt: 1, mr: 0.5, p: '6px', pl: 0 }}
					InputProps={{
						endAdornment: (
							<InputAdornment position='end'>
								<SearchIcon />
							</InputAdornment>
						),
					}}
				/>
			</Box>
			<List
				sx={{
					mt: 1,
					p: 0,
					overflowY: 'scroll',
					maxHeight: '85vh',
					height: '100%',
					width: '100%',
				}}>
				{displayedChats &&
					displayedChats.map((chat) => {
						return (
							<React.Fragment key={chat.vesselApiId}>
								<ListItem
									sx={{
										minHeight: '76px',
										borderRadius: 1,
										backgroundColor:
											chatSelected == null || chat.vesselApiId != chatSelected?.vesselApiId
												? '#fffff'
												: '#b8dafc7a',
										cursor: 'pointer',
										display: 'flex',
										border:
											chatSelected == null || chat.vesselApiId != chatSelected?.vesselApiId
												? '1px solid #9cc5ee'
												: '1px solid #1976d2',
									}}
									onClick={() => handleChangeSelected(chat)}>
									<ListItemAvatar>
										<Avatar>
											<img src={vesselIcon} width='24px' height='24px' />
										</Avatar>
									</ListItemAvatar>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'flex-start',
											width: '55%',
										}}>
										<Typography sx={{ fontWeight: chat.nmsgNRead != 0 ? 'bold' : 'regular' }}>
											{chat.name}
										</Typography>
										<Box sx={{ display: 'flex' }}>
											{chat.src == 0 && getTickStatus(chat.st)}

											<Typography
												variant='body'
												color='#646464'
												sx={{
													fontWeight: chat.nmsgNRead != 0 ? 'bold' : 'regular',
													fontSize: '12px',
												}}>
												{chat.lastTextMsg}
											</Typography>
										</Box>
									</Box>
									<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
										<Typography
											variant='overline'
											color='#646464'
											sx={{
												fontWeight: chat.nmsgNRead != 0 ? 'bold' : 'regular',
												fontSize: '10px',
											}}>
											{datetimeUTCtoString(chat.lastDateMsg, false, true)}
										</Typography>
										{chat.nmsgNRead != 0 ? (
											<Avatar
												sx={{
													mt: 1,
													backgroundColor: '#1976d2',
													height: '20px',
													width: '20px',
													fontSize: '12px',
												}}>
												{chat.nmsgNRead}
											</Avatar>
										) : (
											<Box sx={{ mt: 3 }}></Box>
										)}
									</Box>
								</ListItem>
							</React.Fragment>
						)
					})}
			</List>
		</Box>
	)
}
