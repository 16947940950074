import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useVideoScreenshotInfo = () => {
	const [screenshotInfoData, setScreenshotInfoData] = useState(null)
	const [isLoadingScreenshotInfo, setIsLoadingScreenshotInfo] = useState(false)
	const [screenshotInfoError, setScreenshotInfoError] = useState(null)

	const { client } = useContext(ApiClientContext)

	const getScreenshotInfo = useCallback(
		(props) => {
			client
				.post(
					baseUrl +
						'horus/f3/video/screenshot?VideoId=' +
						props.VideoId +
						'&Offset=' +
						props.Offset +
						'&Opt=' +
						props.Opt
				)
				.then((result) => {
					setIsLoadingScreenshotInfo(false)
					if (result.data.success) {
						setScreenshotInfoData(result.data.data)
					} else {
						setScreenshotInfoError({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingScreenshotInfo(false)
					setScreenshotInfoError({ statusText: err.toString() })
				})
			setIsLoadingScreenshotInfo(true)
			setScreenshotInfoData(null)
			setScreenshotInfoError(null)
		},
		[client]
	)

	return {
		getScreenshotInfo,
		screenshotInfoData,
		isLoadingScreenshotInfo,
		screenshotInfoError,
	}
}
