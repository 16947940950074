import React from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserProfileRequest = () => {
	const [userProfileData, setUserProfileData] = React.useState(null)
	const [errorUserProfileData, setError] = React.useState(null)
	const [isLoading, setIsLoading] = React.useState(false)
	const { client } = React.useContext(ApiClientContext)

	const userProfileRequest = React.useCallback(() => {
		client
			.get(baseUrl + 'horus/f3/user/profile')
			.then((result) => {
				setIsLoading(false)
				if (result.data.success) {
					setUserProfileData(result.data.data)
				} else {
					setError({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoading(false)
				setError(err.response)
			})

		setUserProfileData(null)
		setError(null)
		setIsLoading(true)
	}, [client])
	return {
		userProfileRequest,
		isLoading,
		userProfileData,
		errorUserProfileData,
	}
}
