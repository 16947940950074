import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useReportRequest = () => {
	const [reportData, setReportData] = useState(null)
	const [errorReportData, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const reportRequest = useCallback(
		(props) => {
			client
				.get(baseUrl + 'horus/f3/project/export?' + prepareParams(props))
				.then((result) => {
					setIsLoading(false)
					if (result.data.success) {
						setReportData(result.data)
					} else {
						setError({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoading(false)
					setError(err.response)
				})

			setReportData(null)
			setError(null)
			setIsLoading(true)
		},
		[client]
	)
	return {
		reportRequest,
		isLoading,
		reportData,
		errorReportData,
	}
}
