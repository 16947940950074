import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useMeasurementRequest = () => {
	const [measureData, setMeasureData] = useState(null)
	const [measureError, setMeasureError] = useState(null)
	const [isLoadingMeasure, setIsLoadingMeasure] = useState(false)

	const { client } = useContext(ApiClientContext)

	const measureRequest = useCallback(
		(projectItemId) => {
			client
				.get(baseUrl + 'horus/f3/project/item/measure?projectItemId=' + projectItemId)
				.then((result) => {
					setIsLoadingMeasure(false)
					if (result.data.success) {
						setMeasureData(result.data.data)
					} else {
						setMeasureError({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setIsLoadingMeasure(false)
					setMeasureError(error.response)
				})
			setIsLoadingMeasure(true)
			setMeasureData(null)
			setMeasureError(null)
		},
		[client]
	)

	return {
		measureRequest,
		measureData,
		measureError,
		isLoadingMeasure,
	}
}
