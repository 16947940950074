import React from 'react'
import {
	Avatar,
	Box,
	Button,
	Divider,
	FormControl,
	InputLabel,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isMeasureComplete } from './MeasureFunctions'

export default function MeasureCard({
	measureSelected,
	handleChangeDescription,
	errorDescription,
	setPosCounter,
	sqrList,
	handleChangeSqrSelected,
	posCounter,
	saveMeasure,
	deleteMeasure,
	cancelMeasure,
	sqrSelected,
}) {
	const [t] = useTranslation('common')

	return (
		<Box sx={{ width: '100%', border: '1px solid #8ab8e5', borderRadius: '5px', m: 1, ml: 0 }}>
			<TextField
				sx={{ width: '95%', m: 1 }}
				value={measureSelected?.description}
				onChange={handleChangeDescription}
				error={errorDescription != null}
				label={t('measure.description')}
				InputProps={{ maxLength: 50 }}
				helperText={errorDescription}></TextField>

			<Divider sx={{ bgcolor: '#8ab8e5' }} />
			<FormControl sx={{ width: '95%', m: 1 }}>
				<InputLabel id='calibration-select'>{t('measure.calibration')}</InputLabel>
				<Select
					labelId='calibration-select'
					value={measureSelected?.calibrationRecordId}
					onChange={handleChangeSqrSelected}
					label={t('measure.calibration')}>
					{sqrList &&
						sqrList.map((sqr) => {
							return (
								<MenuItem key={sqr.id} value={sqr.id}>
									{sqr.name}
								</MenuItem>
							)
						})}
				</Select>
			</FormControl>
			<List>
				{measureSelected?.positions?.map((pos, idx) => {
					return (
						<ListItemButton
							sx={{
								border: 'none',
								borderRadius: '10px',
								mx: 1,
							}}
							selected={idx == posCounter}
							onClick={() => setPosCounter(idx)}
							key={idx}>
							<ListItemAvatar>
								<Avatar>{idx == 0 ? 'A' : 'B'}</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									'X : ' +
									(pos.x ? pos.x.toFixed(4) : '') +
									' - Y : ' +
									(pos.y ? pos.y.toFixed(4) : '')
								}
							/>
						</ListItemButton>
					)
				})}
			</List>
			<TextField
				value={measureSelected?.measureValue + ' ' + sqrSelected?.unit}
				InputProps={{
					readOnly: true,
				}}
				label={t('measure.measure-value')}
				sx={{ width: '95%', m: 1 }}></TextField>
			<Box sx={{ width: '100%', display: 'flex' }}>
				<Button
					variant='outlined'
					sx={{ m: 1, width: '100%' }}
					onClick={saveMeasure}
					disabled={!isMeasureComplete(measureSelected) || errorDescription}>
					{t('comun.actions.save')}
				</Button>
				<Button variant='outlined' sx={{ m: 1, width: '100%' }} color='secondary' onClick={cancelMeasure}>
					{t('comun.actions.cancel')}
				</Button>
				<Button variant='outlined' color='error' sx={{ m: 1, width: '100%' }} onClick={deleteMeasure}>
					{t('comun.actions.delete')}
				</Button>
			</Box>
		</Box>
	)
}
