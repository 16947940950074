import * as React from 'react'
import { Box, Divider } from '@mui/material'
import { baseFilter, messageDialogTypes } from '../../constants'
import { useTimerProjectFilterRequest } from '../../services/timer/useTimerProjectFilterRequest'
import { useTimerProjectRequest } from '../../services/timer/useTimerProjectRequest'
import { useTimerProjectInfo } from '../../services/timer/useTimerProjectInfo'
import { useTimerDetails } from '../../services/timer/useTimerDetails'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import TimerProjectFilter from './TimerProjectFilter'
import TimerProjectTable from './TimerProjectTable'
import TimerProjectForm from './TimerProjectForm'
import { assignFilter } from '../../utils/customFunctions'

export default function TimerProjectsContainer() {
	const initialFilter = { ...baseFilter, orderDirection: 'asc', orderField: 'projectDescription' }

	//Services
	const { timerProjectFilterRequest, isLoadingTimerProjectFilter, timerProjectFilter, errorTimerProjectFilter } =
		useTimerProjectFilterRequest()
	const { timerProjectRequest, isLoadingTimerProject, dataTimerProject, errorTimerProject } = useTimerProjectRequest()
	const { timerProjectInfoRequest, isLoadingTimerProjectInfo, timerProjectInfo, errorTimerProjectInfo } =
		useTimerProjectInfo()
	const { timerDetailsRequest, isLoadingTimerDetails, timerDetails, errorTimerDetails } = useTimerDetails()

	const [filterTimerReview, setFilterTimerReview] = React.useState(null)
	const [timerProjectList, setTimerProjectList] = React.useState(null)
	const [timerOptions, setTimerOptions] = React.useState([])
	const [filter, setFilter] = React.useState(initialFilter)
	const [rowSelected, setRowSelected] = React.useState(null)
	const [formRowSelected, setFormRowSelected] = React.useState(null)
	const activeRowColor = '#ccddff'
	const [isVisible, setIsVisible] = React.useState(false)

	//Errors control
	const [openError, setOpenError] = React.useState(false)
	const [errorMsg, setErrorMsg] = React.useState(null)
	const [typeMessage, setTypeMessage] = React.useState(null)

	//Modals
	const [openForm, setOpenForm] = React.useState(false)

	const [activeList, setActiveList] = React.useState(null)
	const [favouriteFilters, setFavourites] = React.useState(null)
	const [statusOptions, setStatusOptions] = React.useState(null)

	const projectStatuses = {
		Mapping: 1,
		Remapping: 1,
		'Ready to analyze': 2,
		'Correction pending': 2,
		'Ready to review': 3,
		Deleted: 4,
		Closed: 4,
	}

	React.useEffect(() => {
		timerProjectFilterRequest()
	}, [])

	React.useEffect(() => {
		if (timerProjectFilter) {
			setTimerOptions(timerProjectFilter.programList)
			setStatusOptions(timerProjectFilter.listProjectStatus)
			setFilterTimerReview(timerProjectFilter.filterTimerReview)
			setTimerProjectList(timerProjectFilter.resultFilter)
			setFavourites(timerProjectFilter.favouritesDic)
			setActiveList(timerProjectFilter.activeList)
		}
	}, [timerProjectFilter])

	React.useEffect(() => {
		if (timerProjectInfo) {
			setOpenForm(true)
		}
	}, [timerProjectInfo])

	React.useEffect(() => {
		if (timerDetails) {
			setIsVisible(true)
		}
	}, [timerDetails])

	React.useEffect(() => {
		if(dataTimerProject){
			setTimerProjectList(dataTimerProject)
		}
	}, [dataTimerProject])

	React.useEffect(() => {
		if (errorTimerProjectFilter || errorTimerProject || errorTimerProjectInfo || errorTimerDetails) {
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setOpenError(true)
			if (errorTimerProjectFilter) {
				setErrorMsg(errorTimerProjectFilter)
			} else if (errorTimerProject) {
				setErrorMsg(errorTimerProject)
			} else if (errorTimerProjectInfo) {
				setErrorMsg(errorTimerProjectInfo)
			} else if (errorTimerDetails) {
				setErrorMsg(errorTimerDetails)
			}
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorTimerProjectFilter, errorTimerProject, errorTimerProjectInfo, errorTimerDetails])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const onClose = () => {
		setOpenForm(false)
		setRowSelected(null)
		setFormRowSelected(null)
		setIsVisible(false)
	}

	const onFilter = (data) => {
		setFilter(data)
		timerProjectRequest(data)
	}

	const handleFormTimerProject = (row) => {
		if (
			row.totalTime.days ||
			row.totalTime.hours ||
			row.totalTime.minutes ||
			row.totalTime.seconds ||
			row.totalTime.milliseconds
		) {
			setRowSelected(row)
			timerProjectInfoRequest(row.projectId)
		}
	}

	const parseData = (data) => {
		let config = {}
		config.ProjectId = rowSelected.projectId
		config.ActionId = rowSelected ? projectStatuses[rowSelected.projectStatusDescription] : ''
		config.userName = data.user

		return config
	}

	const handleTimerInfo = (row) => {
		let config = parseData(row)
		let newFilter = { ...filter }
		newFilter.orderField = 'DESCRIPTIONACTIVITY'
		assignFilter(config, newFilter)
		timerDetailsRequest(config)
		setFormRowSelected(row)
	}

	const handleRowSelected = (row, selectedRow) => {
		return selectedRow === row ? `${activeRowColor} !important` : 'auto'
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<Box sx={{ width: '100%' }}>
				<TimerProjectFilter
					timerOptions={timerOptions}
					onFilter={onFilter}
					filter={filter}
					statusOptions={statusOptions}
					filterTimerReview={filterTimerReview}
					setFilterTimerReview={setFilterTimerReview}
					favouriteFilters={favouriteFilters}
					activeList={activeList}
				/>
			</Box>

			<Divider sx={{ my: 2 }} variant='middle' />

			<Box>
				<TimerProjectTable
					rowSelected={rowSelected}
					handleRowSelected={handleRowSelected}
					dataTimerProject={timerProjectList}
					filter={filter}
					onFilter={onFilter}
					handleFormTimerProject={handleFormTimerProject}
				/>
			</Box>

			<BackdropComponent
				open={
					isLoadingTimerProjectFilter ||
					isLoadingTimerProject ||
					isLoadingTimerProjectInfo ||
					isLoadingTimerDetails
				}
			/>

			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={typeMessage}
			/>

			<TimerProjectForm
				rowSelected={rowSelected}
				formRowSelected={formRowSelected}
				handleRowSelected={handleRowSelected}
				open={openForm}
				onClose={onClose}
				onFilter={onFilter}
				filter={filter}
				handleTimerInfo={handleTimerInfo}
				timerProjectInfo={timerProjectInfo}
				timerDetails={timerDetails}
				isVisible={isVisible}
			/>
		</Box>
	)
}
