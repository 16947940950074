import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProgramInsert = () => {
	const [programDataInsert, setProgramDataInsert] = useState(null)
	const [errorProgramInsert, setErrorProgramInsert] = useState(null)
	const [isLoadingInsert, setIsLoadingInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const programInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/program', props)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setProgramDataInsert({ data: result.data })
					} else {
						setErrorProgramInsert({ statusText: result.data.errorMsg })
					}
					setIsLoadingInsert(false)
				})
				.catch((error) => {
					setErrorProgramInsert(error.response)
					setIsLoadingInsert(false)
				})

			setProgramDataInsert(null)
			setErrorProgramInsert(null)
			setIsLoadingInsert(true)
		},
		[client]
	)

	return {
		programInsert,
		isLoadingInsert,
		programDataInsert,
		setProgramDataInsert,
		errorProgramInsert,
		setErrorProgramInsert,
	}
}
