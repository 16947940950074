import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProjectDeclarationFilterRequest = () => {
	const [projectDeclarationFilter, setProjectDeclarationFilter] = useState(null)
	const [errorProjectDeclarationFilter, setErrorProjectDeclarationFilter] = useState(null)
	const [isLoadingProjectDeclarationFilter, setIsLoadingProjectDeclarationFilter] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const projectDeclarationFilterRequest = useCallback(
		(id) => {
			const config = {
				params: {
					projectId: id,
				},
			}

			client
				.get(baseUrl + 'horus/f3/project/inform/declarations/filters', config)
				.then((result) => {
					setIsLoadingProjectDeclarationFilter(false)
					if (result.data.success) {
						setProjectDeclarationFilter(result.data.data)
					} else {
						setErrorProjectDeclarationFilter({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingProjectDeclarationFilter(false)
					setErrorProjectDeclarationFilter(err.response)
				})

			setProjectDeclarationFilter(null)
			setErrorProjectDeclarationFilter(null)
			setIsLoadingProjectDeclarationFilter(true)
		},
		[client]
	)
	return {
		projectDeclarationFilterRequest,
		isLoadingProjectDeclarationFilter,
		projectDeclarationFilter,
		errorProjectDeclarationFilter,
	}
}
