import RefreshIcon from '@mui/icons-material/Refresh'
import { Button, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { EnhancedTableHead } from '../../comun/TableUtils'
import DashboardRow from './DashboardRow'

export default function DashboardTable({ rows, handleRowClick, vesselSelected, dashboardInfo }) {
	const [t] = useTranslation('common')
	const order = 'asc'
	const orderBy = 'id'
	const history = useHistory()

	const goToMessenger = (row, e) => {
		e.stopPropagation()
		history.push({ pathname: '/messenger', state: { vessel: row } })
	}

	const goToNotifications = (row, e) => {
		e.stopPropagation()
		history.push({ pathname: '/alert', state: { vessel: row } })
	}

	const goToPreview = (row, e) => {
		e.stopPropagation()
		history.push({ pathname: '/preview', state: { vessel: row, project: null } })
	}

	const handleRequestSort = () => {}

	const RefreshOption = () => {
		return (
			<Tooltip title={t('comun.actions.refresh')}>
				<Button
					variant='text'
					sx={{ width: '40px', height: '40px', alignSelf: 'right !important' }}
					onClick={dashboardInfo}>
					<RefreshIcon />
				</Button>
			</Tooltip>
		)
	}
	const columnas = [
		{
			id: 'vessel-name',
			disablePadding: true,
			orderBy: false,
			label: t('vessel.table.header.vessel-name'),
		},
		{
			id: 'last-connection',
			orderBy: false,
			label: t('vessel.table.header.last-conection'),
		},
		{
			id: 'health-status',
			orderBy: false,
			label: t('vessel.table.header.status'),
		},
		{
			id: 'actions',
			label: '',
			excluirExcel: true,
		},
	]

	return (
		<TableContainer sx={{ maxHeight: '89vh', overflowY: 'scroll' }}>
			<Table
				id='table-vessel'
				sx={{ minWidth: '50vw', background: 'white' }}
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					customAction={<RefreshOption />}
				/>
				<TableBody>
					{rows?.map((row) => {
						return (
							<DashboardRow
								row={row}
								key={row.vesselId}
								handleRowClick={handleRowClick}
								vesselSelected={vesselSelected}
								goToMessenger={goToMessenger}
								goToNotifications={goToNotifications}
								goToPreview={goToPreview}
							/>
						)
					})}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
