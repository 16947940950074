import { Box } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../../comun/BasicDialog'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'

export default function AlertReportConfirmation({ open, onClose, alertToReport, vesselAlertReport }) {
	const [t] = useTranslation('common')
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm({ mode: 'onBlur', shouldFocusError: true })

	const onConfirm = (data) => {
		vesselAlertReport(alertToReport.id, data.comment)
		onCloseForm()
	}

	const onCloseForm = () => {
		onClose()
		reset({})
	}

	return (
		<Box component='form' onSubmit={handleSubmit(onConfirm)}>
			<BasicDialog
				title={t('alert.view.report.title')}
				onOpen={open}
				onClose={onCloseForm}
				saveTxt={t('comun.actions.confirm')}>
				<Box sx={{ display: 'flex', flexDirection: 'column', mr: 3, minWidth: '600px' }}>
					<TextFieldComponent
						sx={{ width: '100%', mr: 1 }}
						control={control}
						rows={4}
						label={t('alert.view.report.comment')}
						name='comment'
						rules={{
							required: {
								value: true,
								message: t('comun.form-errors.required'),
							},
							maxLength: {
								value: 450,
								message: t('alert.view.report.comment-error'),
							},
						}}
						errors={errors?.comment}
					/>
				</Box>
			</BasicDialog>
		</Box>
	)
}
