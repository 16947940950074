import * as React from 'react'

import { useTranslation } from 'react-i18next'
import FieldsetComponent from '../comun/FieldsetComponent'

import Box from '@mui/material/Box'

import VesselListComponent from '../comun/VesselListComponent'

export default function VesselsProgramForm({ methodsForm, selected, vessels }) {
	const [t] = useTranslation('common')

	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
			}}>
			<FieldsetComponent title={t('programs.form.vessel.title')}>
				<Box
					sx={{
						width: '100%',
						m: 1,
					}}>
					<VesselListComponent
						methodsForm={methodsForm}
						selected={selected}
						vessels={vessels}></VesselListComponent>
				</Box>
			</FieldsetComponent>
		</Box>
	)
}
