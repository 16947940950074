import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserClearCache = () => {
	const [userProfileClearCacheData, setUserProfileClearCacheData] = useState(null)
	const [errorUserProfileClearCache, setErrorUserProfileClearCache] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const userClearCache = useCallback(
		() => {
				client
				.get(baseUrl + 'horus/f3/user/profile/cache')
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setUserProfileClearCacheData({ data: result.data })
					} else {
						setErrorUserProfileClearCache({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setErrorUserProfileClearCache(err.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setUserProfileClearCacheData(null)
			setErrorUserProfileClearCache(null)
		},
		[client]
	)

	return {
		userClearCache,
		isLoadingUpdate,
		userProfileClearCacheData,
		setUserProfileClearCacheData,
		errorUserProfileClearCache,
		setErrorUserProfileClearCache,
	}
}
