import * as React from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProjectUpdate = () => {
	const [projectDataUpdate, setProjectDataUpdate] = React.useState(null)
	const [errorProjectUpdate, setErrorProjectUpdate] = React.useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = React.useState(false)

	const { client } = React.useContext(ApiClientContext)

	const projectUpdate = React.useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/project', props)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setProjectDataUpdate({ data: result.data })
					} else {
						setErrorProjectUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setProjectDataUpdate(null)
			setErrorProjectUpdate(null)
		},
		[client]
	)

	return {
		projectUpdate,
		isLoadingUpdate,
		projectDataUpdate,
		errorProjectUpdate,
	}
}
