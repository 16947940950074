import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import React from 'react'
import LogoSatlinkOndas from '../assets/Satlink-onda.png'
import './Copyright.css'

export default function Copyright({ open }) {
	return (
		<>
			{open ? (
				<Typography
					component='span'
					variant='body2'
					color='text.secondary'
					align='center'
					sx={{ p: 2, fontStyle: 'italic' }}>
					{'Copyright © '}
					<img className='imagen' src={LogoSatlinkOndas} />{' '}
					<Link color='inherit' href='https://www.satlink.es/' target='_blank'>
						Satlink
					</Link>{' '}
					{new Date().getFullYear()}
				</Typography>
			) : (
				<Typography id='main-div' component='div' variant='body2' color='text.secondary' align='center'>
					©
					<img className='imagen' src={LogoSatlinkOndas} />{' '}
				</Typography>
			)}
		</>
	)
}
