import SendIcon from '@mui/icons-material/Send'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import './Messenger.css'

export default function MessageBox({ chat, isLoading, handleSendMessage }) {
	const [message, setMessage] = useState('')
	const [t] = useTranslation('common')
	const handleSend = () => {
		if (message.length > 0) {
			handleSendMessage(chat, message)
			setMessage('')
		}
	}

	return (
		<TextField
			multiline
			maxRows={3}
			minRows={3}
			disabled={chat == null || isLoading}
			value={message}
			onChange={(e) => setMessage(e.target.value)}
			// onKeyDown={handleKeyDown}
			autoComplete='off'
			placeholder={t("messenger.write-message")}
			sx={{ width: '100%', borderTopLeftRadius: '0px !important', borderTopRightRadius: '0px !important' }}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton disabled={chat == null || isLoading} onClick={handleSend}>
							<SendIcon />
						</IconButton>
					</InputAdornment>
				),
			}}
		/>
	)
}
