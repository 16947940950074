import React, { useMemo } from 'react'
import { Polyline } from 'react-leaflet'
import { mapSpeedThresholds } from '../../../../constants'

export default function PolylinesLayer({ mapInfo }) {
	const getColor = (p) => {
		if (p.speed < mapSpeedThresholds.red) {
			return 'red'
		} else if (p.speed >= mapSpeedThresholds.red && p.speed <= mapSpeedThresholds.green) {
			return 'yellow'
		} else if (p.speed > mapSpeedThresholds.green) {
			return 'green'
		} else return 'blue'
	}

	const trackListWithColor = useMemo(() => mapInfo?.map((p) => ({ ...p, color: getColor(p) })), [mapInfo])
	return (
		<>
			{trackListWithColor &&
				trackListWithColor.length != 0 &&
				trackListWithColor
					?.map((latlng, index) =>
						index < trackListWithColor.length - 1 ? (
							<Polyline
								key={index}
								positions={[
									[latlng.lat, latlng.lon],
									[trackListWithColor[index + 1].lat, trackListWithColor[index + 1].lon],
								]}
								color={latlng.color}
								weight={1}></Polyline>
						) : null
					)
					.filter(Boolean)}
		</>
	)
}
