import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useProgramRequest = () => {
	const [planningProgramData, setPlanningProgramData] = useState(null)
	const [errorPlanningProgramData, setErrorPlanningProgramData] = useState(null)
	const [isLoadingPlanningProgram, setIsLoadingPlanningProgram] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const requestProgram = useCallback(
		(filter) => {
			client
				.get(baseUrl + 'horus/f3/program?' + prepareParams(filter))
				.then((result) => {
					setIsLoadingPlanningProgram(false)
					if (result.data.success) {
						setPlanningProgramData(result.data)
					} else {
						setErrorPlanningProgramData({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingPlanningProgram(false)
					setErrorPlanningProgramData(err.response)
				})

			setPlanningProgramData(null)
			setErrorPlanningProgramData(null)
			setIsLoadingPlanningProgram(true)
		},
		[client]
	)
	return {
		requestProgram,
		isLoadingPlanningProgram,
		planningProgramData,
		errorPlanningProgramData,
	}
}
