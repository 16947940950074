import { FormHelperText } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller } from 'react-hook-form'

export default function TextFieldComponent({
	rules,
	control,
	defaultValue,
	name,
	label,
	errors,
	sx,
	size,
	required,
	rows,
	type,
	autoFocus,
	autoComplete,
	InputProps,
	disabled,
}) {
	return (
		<FormControl sx={sx} error={!!errors}>
			<Controller
				rules={rules}
				defaultValue={defaultValue ? defaultValue : ''}
				name={name}
				control={control}
				render={({ field: { onChange, value, onBlur } }) => (
					<TextField
						disabled={disabled}
						InputProps={InputProps}
						required={required}
						size={size}
						autoComplete={autoComplete}
						autoFocus={autoFocus}
						multiline={rows ? true : false}
						type={type}
						rows={rows}
						onBlur={onBlur}
						onChange={onChange}
						value={value}
						label={label} //Etiqueta del campo
						error={!!errors}
					/>
				)}
			/>
			<FormHelperText>{errors?.message}</FormHelperText>
		</FormControl>
	)
}
