import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useTimerUsersInfo = () => {
	const [timerUsersInfo, setTimerUsersInfo] = useState(null)
	const [errorTimerUsersInfo, setErrorTimerUsersInfo] = useState(null)
	const [isLoadingTimerUsersInfo, seIsLoadingTimerUsersInfo] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerUsersInfoRequest = useCallback(
		(props) => {
			client
				.get(baseUrl + 'horus/f3/timer/users/n2?' + prepareParams(props))
				.then((result) => {
					seIsLoadingTimerUsersInfo(false)
					setTimerUsersInfo(result.data)
					if (result.data.success) {
						setTimerUsersInfo(result.data)
					} else {
						setErrorTimerUsersInfo({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					seIsLoadingTimerUsersInfo(false)
					setErrorTimerUsersInfo(err.response)
				})

			setTimerUsersInfo(null)
			setErrorTimerUsersInfo(null)
			seIsLoadingTimerUsersInfo(true)
		},
		[client]
	)
	return {
		timerUsersInfoRequest,
		isLoadingTimerUsersInfo,
		timerUsersInfo,
		errorTimerUsersInfo,
	}
}
