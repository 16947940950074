import TextFieldComponent from '../comun/form_fields/TextFieldComponent'
import SelectColor from './SelectColor'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import { Box, Button, IconButton, Paper, Radio, Table, TableContainer, TextField, Tooltip } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import MeasureAndCountComments from '../measurement/MeasureAndCountComments'

export default function CountColorForm({
	methodsForm,
	setSelectedColor,
	selected,
	count,
	colors,
	dataSelected,
	setDataSelected,
	canCreateEditAnnotations,
	setRowSelected,
	onClose,
	saveCount,
	projectItemId,
	comments,
}) {
	const {
		control,
		register,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = methodsForm
	const [selectedRadioValue, setSelectedRadioValue] = React.useState(null)

	React.useEffect(() => {
		setValue('description_color', '')
	}, [dataSelected])

	const [t] = useTranslation('common')

	const [disableAddButton, setDisableAddButton] = React.useState(true)

	const onDeleteColors = (row) => {
		const auxData = [...dataSelected]
		auxData.splice(
			auxData.findIndex((item) => item.color === row.color && item.description_color === row.description_color),
			1
		)
		if (row.color == selectedRadioValue) {
			setSelectedRadioValue(null)
		}
		//modifyCanvasFunction(row.color)
		setDataSelected(auxData)
	}

	React.useEffect(() => {
		setValue('selected_colors', dataSelected)
	}, [dataSelected])

	React.useEffect(() => {
		const subscription = watch((value) => {
			setDisableAddButton(!value.colors || !value.description_color)
		})

		return () => subscription.unsubscribe()
	}, [watch])

	const addRowTable = () => {
		var color = getValues('colors')
		var description = getValues('description_color')
		const auxData = [...dataSelected]
		var existeColor = false
		auxData.forEach((element) => {
			if (element != null && (element.color == color || element.description == description)) {
				existeColor = true
			}
		})

		var newRow = {
			description: description,
			color: color,
			count: 0,
			dots: [],
		}
		let maxLenght = description.length >= 50
		if (!existeColor && !maxLenght) {
			errors.description_color = null
			handleChangeRadio({ target: { value: newRow.color } }, newRow)
			setDataSelected((prevState) => [...prevState, newRow])
		} else if (existeColor) {
			setRowSelected(null)
			errors.description_color = { message: t('count.table.content.error') }
		} else if (maxLenght) {
			setRowSelected(null)
			errors.description_color = { message: t('count.form.error-name') }
		}
	}

	const handleChangeRadio = (event, row) => {
		setSelectedRadioValue(event.target.value)
		setSelectedColor(event.target.value)
		setRowSelected(row)
	}

	return (
		<>
			<Box sx={{ width: '100%', ml: 1, display: 'flex', flexDirection: 'column', minHeight: '79vh' }}>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ display: 'flex' }}>
						<Box sx={{ width: '40%' }}>
							<TextFieldComponent
								defaultValue={selected?.description_color}
								sx={{ minWidth: '100%', pr: 1 }}
								disabled={!canCreateEditAnnotations}
								control={control}
								label={t('count.form.color.description')}
								name='description_color'
								size='small'
								rules={{
									required: {
										value: true,
										message: t('comun.form-errors.required'),
									},
								}}
								errors={errors?.description_color}
							/>
						</Box>

						<Box sx={{ width: '50%' }}>
							<SelectColor
								disabled={!canCreateEditAnnotations}
								sx={{ minWidth: '100%', ml: 4, mt: 1, pr: 1 }}
								control={control}
								name='colors'
								colors={colors}
								errors={errors?.colors}
							/>
						</Box>

						<Box sx={{ display: 'flex', width: '10%', justifyContent: 'flex-end' }}>
							<FormControl
								variant='standard'
								size='small'
								sx={{ width: '40px', alignSelf: 'flex-start' }}>
								<Tooltip title={t('count.form.vessel.add-button')}>
									<span>
										<IconButton
											disabled={disableAddButton || !canCreateEditAnnotations}
											color='primary'
											onClick={() => addRowTable()}>
											<AddCircleIcon />
										</IconButton>
									</span>
								</Tooltip>
							</FormControl>
						</Box>
					</Box>
					<Box>
						<TableContainer component={Paper} sx={{ maxHeight: '35vh', minHeight: '35vh' }}>
							<Table {...register('selected_colors')} sx={{ minWidth: 300 }} aria-label='simple table'>
								<TableBody>
									{dataSelected?.map((row) => (
										<TableRow
											key={row.color}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
											<TableCell component='th' scope='row'>
												<Radio
													checked={selectedRadioValue === row.color}
													onChange={(e) => handleChangeRadio(e, row)}
													value={row.color}
													name='radio-buttons'
												/>
											</TableCell>
											<TableCell component='th' scope='row'>
												{row.description}
											</TableCell>
											<TableCell component='th' scope='row'>
												<Box
													sx={{
														borderRadius: '50%',
														width: 25,
														height: 25,
														backgroundColor: row.color,
													}}
												/>
											</TableCell>
											<TableCell component='th' scope='row'>
												{row.count}
											</TableCell>
											<TableCell component='th' scope='row' align='right'>
												<Tooltip title={t('count.table.content.delete')}>
													<span>
														<IconButton
															color='error'
															onClick={() => onDeleteColors(row)}
															disabled={!canCreateEditAnnotations}>
															<DeleteIcon />
														</IconButton>
													</span>
												</Tooltip>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', mt: 2, justifyContent: 'flex-end', mb: 1 }}>
					<TextField
						InputProps={{
							readOnly: true,
						}}
						size='small'
						id='tfconteo'
						label={t('count.table.content.total-count')}
						variant='outlined'
						value={count}
					/>
				</Box>
				<MeasureAndCountComments height={'30vh'} projectItemId={projectItemId} commentList={comments} />
			</Box>
			<Box sx={{ display: 'flex', ml: 1, mt: 2, width: '100%' }}>
				<Button
					variant='outlined'
					sx={{ mr: 1, width: '100%' }}
					onClick={saveCount}
					disabled={dataSelected?.length == 0 || !canCreateEditAnnotations}>
					{t('comun.actions.save')}
				</Button>
				<Button
					variant='outlined'
					sx={{ ml: 1, width: '100%' }}
					color='secondary'
					onClick={() => onClose(false)}>
					{t('comun.actions.close')}
				</Button>
			</Box>
		</>
	)
}
