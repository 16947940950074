import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { messageDialogTypes } from '../../../../constants'
import { usePreviewVideoDelete } from '../../../../services/dashboard/video/usePreviewVideoDelete'
import { useVideoRequestInsert } from '../../../../services/projects/preview/video/usePreviewVideoInsert'
import { usePreviewVideoRequest } from '../../../../services/projects/preview/video/usePreviewVideoRequest'
import BasicDialog from '../../../comun/BasicDialog'
import { datetimeGMTToLocaleInISOFormat, datetimeUTCtoString } from '../../../comun/DateUtils'
import FieldsetComponent from '../../../comun/FieldsetComponent'
import LabelValueComponent from '../../../comun/LabelValueComponent'
import MessageSnackBar from '../../../comun/MessageSnackBar'
import BackdropComponent from '../../../comun/utils/BackdropComponent'
import VideoRequestForm from './VideoRequestForm'
import VideoRequestTable from './VideoRequestTable'

export default function VideoRequestModal({
	open,
	onClose,
	defaultFrom,
	defaultTo,
	projectId,
	vesselApiId,
	numCameras,
	vesselName,
}) {
	const [t] = useTranslation('common')

	const { videoRequest, dataVideoRequest, isLoadingVideoRequest, errorVideoRequest } = usePreviewVideoRequest()
	const { videoRequestDelete, dataVideoDelete } = usePreviewVideoDelete()
	const { videoRequestInsert, videoRequestDataInsert, errorVideoRequestInsert } = useVideoRequestInsert()

	const [openMsg, setOpenMsg] = useState(false)
	const [msg, setMsg] = useState(null)
	const [typeMsg, setTypeMsg] = useState(messageDialogTypes.SUCCESS_MESSAGE)

	const [from, setFrom] = useState(defaultFrom)
	const [to, setTo] = useState(defaultTo)
	const [checked, setChecked] = useState(new Array(numCameras).fill(true))
	const [hideCompleted, setHideCompleted] = useState(false)

	const onFilter = () => {
		let data = {}
		let cams = checked.map((c, idx) => (c ? idx + 1 : null)).filter((c) => c != null)
		if (cams.length != 0 && from != null && to != null) {
			data.from = datetimeGMTToLocaleInISOFormat(from)
			data.to = datetimeGMTToLocaleInISOFormat(to)
			data.projectId = projectId
			if (projectId != null) {
				data.projectId = projectId
				delete data.vesselApiId
			} else if (vesselApiId != null) {
				data.vesselApiId = vesselApiId
				delete data.projectId
			}
			data.isDone = hideCompleted
			videoRequest(data, cams)
		}
	}

	useEffect(() => {
		onFilter()
	}, [])

	useEffect(() => {
		if (dataVideoDelete) {
			onFilter()
		}
	}, [dataVideoDelete])

	useEffect(() => {
		if (videoRequestDataInsert) {
			onFilter()
		}
	}, [videoRequestDataInsert])

	useEffect(() => {
		if (errorVideoRequest) {
			setOpenMsg(true)
			setMsg(errorVideoRequest)
			setTypeMsg(messageDialogTypes.ERROR_MESSAGE)
		} else if (errorVideoRequestInsert) {
			setOpenMsg(true)
			setMsg(errorVideoRequestInsert)
			setTypeMsg(messageDialogTypes.ERROR_MESSAGE)
		} else {
			setOpenMsg(false)
		}
	}, [errorVideoRequest, errorVideoRequestInsert])

	const handleCloseMsg = () => {
		setOpenMsg(false)
	}

	const onRequestVideos = () => {
		//Request videos and then close if everything is fine
		let cams = checked.map((c, idx) => (c ? idx + 1 : null)).filter((c) => c != null)
		let data = {
			cams: cams,
			from: datetimeGMTToLocaleInISOFormat(from),
			to: datetimeGMTToLocaleInISOFormat(to),
			projectId: projectId,
			vesselApiId: vesselApiId,
		}

		videoRequestInsert(data)
	}

	const onDeleteRequest = (reqId) => {
		videoRequestDelete(reqId, projectId != null ? 'project' : 'dashboard')
	}

	return (
		<BasicDialog
			title={t('video-request.title') + ': ' + vesselName}
			onOpen={open}
			onClose={onClose}
			saveTxt={t('video-request.request')}
			onSave={onRequestVideos}>
			<BackdropComponent open={isLoadingVideoRequest} />
			<MessageSnackBar openError={openMsg} errorMsg={msg} typeMsg={typeMsg} handleCloseError={handleCloseMsg} />
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minWidth: '60vw' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', mr: 3 }}>
					<VideoRequestForm
						videoRequest={videoRequest}
						projectId={projectId}
						from={from}
						setFrom={setFrom}
						to={to}
						setTo={setTo}
						checked={checked}
						setChecked={setChecked}
						hideCompleted={hideCompleted}
						setHideCompleted={setHideCompleted}
						onFilter={onFilter}
					/>
				</Box>

				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', minHeight: '40vh' }}>
					<VideoRequestTable requestList={dataVideoRequest} videoRequestDelete={onDeleteRequest} />
				</Box>

				<FieldsetComponent
					title={t('video-request.to-be-requested')}
					style={{
						color: 'black',
						display: 'flex',
						m: 1,
					}}>
					<LabelValueComponent
						label={t('video-request.from')}
						value={datetimeUTCtoString(from)}
						horizontal
						sx={{ mx: 1 }}
					/>
					<LabelValueComponent
						label={t('video-request.to')}
						value={datetimeUTCtoString(to)}
						horizontal
						sx={{ mx: 1 }}
					/>
					<LabelValueComponent
						label={t('video-request.cameras')}
						value={checked
							.map((c, idx) => (c ? (idx != checked.length - 1 ? idx + 1 + ', ' : idx + 1) : null))
							.filter((c) => c != null)}
						horizontal
						sx={{ mx: 1 }}
					/>
				</FieldsetComponent>
			</Box>
		</BasicDialog>
	)
}
