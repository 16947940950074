import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useMeasurementUpdate = () => {
	const [measureUpdateData, setMeasureUpdateData] = useState(null)
	const [measureUpdateError, setMeasureUpdateError] = useState(null)
	const [isLoadingMeasureUpdate, setIsLoadingMeasureUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const measureUpdate = useCallback(
		(data) => {
			client
				.put(baseUrl + 'horus/f3/project/item/measure', data)
				.then((result) => {
					setIsLoadingMeasureUpdate(false)
					if (result.data.success) {
						setMeasureUpdateData(result.data.data)
					} else {
						setMeasureUpdateError({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setIsLoadingMeasureUpdate(false)
					setMeasureUpdateError(error.response)
				})
			setIsLoadingMeasureUpdate(true)
			setMeasureUpdateData(null)
			setMeasureUpdateError(null)
		},
		[client]
	)

	return {
		measureUpdate,
		measureUpdateData,
		measureUpdateError,
		isLoadingMeasureUpdate,
	}
}
