import AlertReportConfirmation from './AlertReportConfirmation'
import AlertViewFilter from './AlertViewFilter'
import AlertViewForm from './AlertViewForm'
import AlertViewTable from './AlertViewTable'
import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { baseFilter, messageDialogTypes } from '../../../constants'
import { useVesselAlertFilterRequest } from '../../../services/alerts/vesselalert/useVesselAlertFilterRequest'
import { codesActivityTimer } from '../../../constants'
import { useVesselAlertReport } from '../../../services/alerts/vesselalert/useVesselAlertReport'
import { useVesselAlertRequest } from '../../../services/alerts/vesselalert/useVesselAlertRequest'
import { useUserLog } from '../../../services/timer/useUserLog'
import { datetimeUTCtoString } from '../../comun/DateUtils'
import MessageSnackBar from '../../comun/MessageSnackBar'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import { getGeneralActivity } from './../../../utils/TimerDataUtils'
import { useVesselAlertRead } from '../../../services/alerts/vesselalert/useVesselAlertRead'

//Set de column to sort and the direction for the first search, baseFilter contains the rowsPerPage and pageNo
const initialFilter = {
	...baseFilter,
	orderDirection: 'asc',
	orderField: 'vesselApiId',
	StartDate: null,
	EndDate: null,
	Status: null,
	auxStatus: null,
}

export default function AlertViewContainer({ state }) {
	const [t] = useTranslation('common')

	const formActions = useForm({})

	//Needed for the show preview option
	const history = useHistory()

	//Services
	const { vesselAlertFilterRequest, isLoadingVesselAlertFilter, vesselAlertFilter, errorVesselAlertFilter } =
		useVesselAlertFilterRequest()
	const { vesselAlertRequest, vesselAlertData, errorVesselAlert, isLoadingVesselAlert } = useVesselAlertRequest()
	const { vesselAlertReport, alertReportData, errorAlertReport, isLoadingAlertReport } = useVesselAlertReport()
	const { vesselAlertRead, errorAlertRead, isLoadingAlertRead } = useVesselAlertRead()
	const { userLogRequest } = useUserLog()

	//Filter
	const [filter, setFilter] = useState(initialFilter)

	const [alertVesselFilter, setAlertVesselFilter] = React.useState(null)
	const [alertVesselList, setAlertVesselList] = React.useState(null)
	const [vessels, setVessels] = React.useState([])

	const [favouriteFilters, setFavourites] = React.useState(null)
	const [statusOptions, setStatusOptions] = React.useState(null)

	//Errors control
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	//Modals
	const [openConfirmation, setOpenConfirmation] = useState(false)
	const [alertToReport, setAlertToReport] = useState(null)
	const [openForm, setOpenForm] = useState(false)
	const [alertSelected, setAlertSelected] = useState(null)

	const onFilter = (data) => {
		setFilter(data)
		vesselAlertRequest(data)
	}

	React.useEffect(() => {
		if (vesselAlertData) {
			setAlertVesselList(vesselAlertData)
		}
	}, [vesselAlertData])

	useEffect(() => {
		if (state && vesselAlertFilter) {
			let auxFilter = filter
			auxFilter.vesselApiId = state?.vessel?.vesselId
			formActions.setValue('vesselApiId', {
				vesselName: state?.vessel?.vesselName,
				vesselApiId: state?.vessel?.vesselId,
			})
			auxFilter.status = 2
			formActions.setValue('status', { label: t('alert.view.filter.unread'), value: 2 })
			onFilter(auxFilter)
		}
	}, [vesselAlertFilter])

	React.useEffect(() => {
		if (vesselAlertFilter) {
			if (vesselAlertFilter.filterVesselNotifications !== null) {
				let copyFilter = { ...vesselAlertFilter.filterVesselNotifications }

				if (vesselAlertFilter.filterVesselNotifications.status === null) {
					copyFilter.status = 'null'
				} else {
					copyFilter.status = copyFilter.status.toString()
				}

				setAlertVesselFilter(copyFilter)
			}

			setVessels(vesselAlertFilter.vessels)
			setAlertVesselList(vesselAlertFilter.resultFilter)
			setFavourites(vesselAlertFilter.favouritesDic)
			setStatusOptions(vesselAlertFilter.statusOptions)
		}
	}, [vesselAlertFilter])

	useEffect(() => {
		vesselAlertFilterRequest()
		userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_VIEW, 'ALERTS_VIEW'))
	}, [])

	useEffect(() => {
		if (alertReportData) {
			userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_REPORT, 'ALERTS_REPORT'))
			vesselAlertRequest(filter)
		}
	}, [alertReportData])

	useEffect(() => {
		if (errorAlertReport) {
			userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_REPORT, 'ALERTS_REPORT'))
			setErrorMsg(errorAlertReport)
			setOpenError(true)
		} else if (errorVesselAlert) {
			setErrorMsg(errorVesselAlert)
			setOpenError(true)
		} else if (errorVesselAlertFilter) {
			setErrorMsg(errorVesselAlertFilter)
			setOpenError(true)
		} else if (errorAlertRead) {
			setErrorMsg(errorAlertRead)
			setOpenError(true)
		} else {
			setErrorMsg(null)
			setOpenError(false)
		}
	}, [errorAlertReport, errorVesselAlert, errorVesselAlertFilter, errorAlertRead])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const handleOpenConfirmation = (row) => {
		setAlertToReport(row)
		setOpenConfirmation(true)
	}

	const handleCloseConfirmation = () => {
		setAlertToReport(null)
		setOpenConfirmation(false)
	}
	const getStatus = (value) => {
		return statusOptions.find((s) => s.value == value)?.description
	}

	const handleOpenForm = (row) => {
		let copy = { ...row }
		copy.startDate = datetimeUTCtoString(row.startDate)
		copy.endDate = row.endDate ? datetimeUTCtoString(row.endDate) : 'N/A'
		copy.auxStatus = getStatus(row.status)
		copy.latlon = row.lat + ', ' + row.lon
		setAlertSelected(copy)
		vesselAlertRead(row.id)
		setOpenForm(true)
	}

	const handleCloseForm = () => {
		setAlertSelected(null)
		setOpenForm(false)
	}

	const handleReportFromForm = () => {
		setOpenForm(false)
		handleOpenConfirmation(alertSelected)
		setAlertSelected(null)
	}

	const handleGoToPreview = (row) => {
		userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_LINK_PREVIEW))
		let vessel = { vesselId: row.vesselApiId, vesselName: row.vesselName }
		history.push({ pathname: '/preview', state: { vessel: vessel, project: null, jumpTime: row.startDate } })
	}

	// const handleGoToPreview = (row) => {
	// 	userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_LINK_PREVIEW, 'ALERTS_LINK_PREVIEW'))
	// 	let from = addMinutes(new Date(row.startDate), -120)
	// 	let mode = {
	// 		mode: 'Vessel',
	// 		value: row.vesselApiId,
	// 		fromFilter: from,
	// 		toFilter: row.endDate,
	// 	}

	// 	getVessel(row.vesselApiId)
	// 	setCookie(currentModeValue, JSON.stringify(mode))
	// }

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<Box sx={{ width: '100%' }}>
				{/* Filter */}
				<AlertViewFilter
					onFilter={onFilter}
					filter={filter}
					vessels={vessels}
					alertVesselFilter={alertVesselFilter}
					formActions={formActions}
					favouriteFilters={favouriteFilters}
					statusOptions={statusOptions}
				/>
			</Box>

			<Divider sx={{ my: 2 }} variant='middle' />

			<Box>
				{/* Table */}
				<AlertViewTable
					alertList={alertVesselList}
					filter={filter}
					onFilter={onFilter}
					handleOpenConfirmation={handleOpenConfirmation}
					handleOpenForm={handleOpenForm}
					handleShowPreview={handleGoToPreview}
					statusOptions={statusOptions}
				/>
			</Box>

			<BackdropComponent
				open={
					isLoadingAlertReport ||
					isLoadingVesselAlert ||
					isLoadingAlertReport ||
					isLoadingVesselAlertFilter ||
					isLoadingAlertRead
				}
			/>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<AlertReportConfirmation
				open={openConfirmation}
				onClose={handleCloseConfirmation}
				alertToReport={alertToReport}
				vesselAlertReport={vesselAlertReport}
			/>
			<AlertViewForm
				alert={alertSelected}
				open={openForm}
				onClose={handleCloseForm}
				onReport={handleReportFromForm}
			/>
		</Box>
	)
}
