// icono		línea		sombreado
// #922B21		#CD6155		#E6B0AA
// #76448A		#AF7AC5		#D7BDE2
// #1F618D		#5499C7		#A9CCE3
// #2874A6		#5DADE2		#AED6F1
// #148F77		#48C9B0		#A3E4D7
// #1E8449		#52BE80		#A9DFBF
// #239B56		#58D68D		#ABEBC6
// #B7950B		#F4D03F		#F9E79F
// #B9770E		#F5B041		#FAD7A0
// #AF601A		#EB984E		#F5CBA7
// #A04000		#DC7633		#EDBB99
// #909497		#CACFD2		#E5E7E9
// #717D7E		#AAB7B8		#D5DBDB
// #616A6B		#99A3A4		#CCD1D1
// #283747		#5D6D7E		#AEB6BF
// #212F3D		#566573		#566573
// #6C3483		#A569BD		#D2B4DE
// #B03A2E		#EC7063		#F5B7B1
// #827717		#C0CA33		#E6EE9C
// #5D4037		#8D6E63		#BCAAA4
export const chartPalette = [
    {line: "#CD6155", icon: "#922B21", shade: "#E6B0AA"},
    {line: "#AF7AC5", icon: "#76448A", shade: "#D7BDE2"},
    {line: "#5499C7", icon: "#1F618D", shade: "#A9CCE3"},
    {line: "#5DADE2", icon: "#2874A6", shade: "#AED6F1"},
    {line: "#48C9B0", icon: "#148F77", shade: "#A3E4D7"},
    {line: "#52BE80", icon: "#1E8449", shade: "#A9DFBF"},
    {line: "#58D68D", icon: "#239B56", shade: "#ABEBC6"},
    {line: "#F4D03F", icon: "#B7950B", shade: "#F9E79F"},
    {line: "#F5B041", icon: "#B9770E", shade: "#FAD7A0"},
    {line: "#EB984E", icon: "#AF601A", shade: "#F5CBA7"},
    {line: "#DC7633", icon: "#A04000", shade: "#EDBB99"},
    {line: "#CACFD2", icon: "#909497", shade: "#E5E7E9"},
    {line: "#AAB7B8", icon: "#717D7E", shade: "#D5DBDB"},
    {line: "#99A3A4", icon: "#616A6B", shade: "#CCD1D1"},
    {line: "#5D6D7E", icon: "#283747", shade: "#AEB6BF"},
    {line: "#566573", icon: "#212F3D", shade: "#566573"},
    {line: "#A569BD", icon: "#6C3483", shade: "#D2B4DE"},
    {line: "#EC7063", icon: "#B03A2E", shade: "#F5B7B1"},
    {line: "#C0CA33", icon: "#827717", shade: "#E6EE9C"},
    {line: "#8D6E63", icon: "#5D4037", shade: "#BCAAA4"}
]
export function getPaleteForSerie(colorLine, serie){
    let palete = chartPalette.find(p => p.line == colorLine)
    if(palete != null){
        serie.colorLine = palete.line
        serie.colorIcon2 = palete.icon
        serie.colorShaded = palete.shade
    }else{
        serie.colorLine = colorLine
        serie.colorIcon2 = colorLine
        serie.colorShaded = colorLine
    }

    return serie
}