import { Box, Typography } from '@mui/material'
import React from 'react'
import { datetimeUTCtoString } from '../../comun/DateUtils'

function TimeRangeDisplay({ currentRangeStart, currentRangeEnd }) {
	return (
		<Box sx={{ display: 'flex', justifyContent: 'space-between', ml: '5%' }}>
			<Typography sx={{ fontSize: '0.85rem', color: '#1976d2' }}>
				{datetimeUTCtoString(currentRangeStart)}
			</Typography>
			<Typography sx={{ fontSize: '0.85rem', color: '#1976d2' }}>
				{datetimeUTCtoString(currentRangeEnd)}
			</Typography>
		</Box>
	)
}

export default React.memo(TimeRangeDisplay)
