import axios from 'axios'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export default function useLoginRequest() {
	const [dataLogin, setDataLogin] = useState(null)
	const [t] = useTranslation('common')
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const url = baseUrl + 'horus/f3/authenticate'

	const login = useCallback((email, password, languageSelectValue) => {
		const headers = {
			UserName: email,
			Password: password,
			Language: languageSelectValue.value === 'es' ? 'Es-es' : 'En-en',
		}

		axios
			.post(url, headers)
			.then((result) => {
				setIsLoading(false)
				if (result.data.success) {
					setDataLogin({
						token: result.data.data.token,
						expiration: new Date(result.data.data.expiration),
						refreshToken: result.data.data.refreshToken,
					})
				} else {
					setError({ statusText: t('login.error') })
				}
			})
			.catch((err) => {
				setIsLoading(false)
				setError(err.response)
			})

		setDataLogin(null)
		setError(null)
		setIsLoading(true)
	}, [])

	return {
		login,
		isLoading,
		dataLogin,
		error,
	}
}
