import { Button, Divider, IconButton, List, ListItemButton, ListItemText, Tooltip } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import MeasureCard from './MeasureCard'
import MeasureAndCountComments from './MeasureAndCountComments'
import RateReviewIcon from '@mui/icons-material/RateReview'

export default function MeasureControls({
	measureSelected,
	setMeasureSelected,
	measureList,
	setMeasureList,
	sqrList,
	posCounter,
	setSqrSelected,
	setPosCounter,
	saveMeasureProjectItem,
	onClose,
	sqrSelected,
	projectItemId,
	comments,
}) {
	const [t] = useTranslation('common')
	const [errorDescription, setErrorDescription] = useState(null)
	const [showComments, setShowComments] = useState(false)

	const toggleShowComments = () => {
		if (showComments) {
			setShowComments(false)
		} else {
			setShowComments(true)
			cancelMeasure()
		}
	}

	const createMeasure = () => {
		let newMeasure = {
			id: measureList.length + 1,
			description: 'Measure ' + (measureList.length + 1),
			positions: [{}, {}],
			measureValue: 0,
			updated: true,
			calibrationRecordId: sqrList.find((sqr) => sqr.default == true)?.id,
		}
		setSqrSelected(sqrList.find((sqr) => sqr.default == true))
		setPosCounter(0)
		setMeasureSelected(newMeasure)
	}

	const cancelMeasure = () => {
		setMeasureSelected(null)
		setSqrSelected(null)
	}
	const saveMeasure = () => {
		let aux = [...measureList]
		let idx = aux.findIndex((m) => m.id == measureSelected.id)
		let auxMeasure = { ...measureSelected }
		auxMeasure.unit = sqrSelected.unit
		if (idx == -1) {
			aux.push(auxMeasure)
		} else {
			aux[idx] = auxMeasure
		}
		setSqrSelected(null)
		setMeasureSelected(null)
		setMeasureList(aux)
	}

	const deleteMeasure = () => {
		let aux = [...measureList]
		let idx = aux.findIndex((m) => m.description == measureSelected.description)
		if (idx != -1) {
			aux.splice(idx, 1)
			setMeasureList(aux)
		}
		setMeasureSelected(null)
		setSqrSelected(null)
	}

	const handleChangeSqrSelected = (e) => {
		let sqr = sqrList.find((s) => s.id == e.target.value)
		setSqrSelected(sqr)
		setMeasureSelected((prevState) => ({
			...prevState,
			calibrationRecordId: e.target.value,
			updated: true,
		}))
	}

	const handleSetSelected = (measure) => {
		setMeasureSelected(measure)
		setShowComments(false)
	}

	const handleChangeDescription = (e) => {
		if (measureList.some((item) => item.description == e.target.value)) {
			setErrorDescription(t('measure.error-name'))
		} else if (e.target.value.length >= 50) {
			setErrorDescription(t('measure.error-name-length'))
		} else {
			setErrorDescription(null)
		}
		setMeasureSelected((prevState) => ({
			...prevState,
			description: e.target.value,
		}))
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mx: 1 }}>
			<Box sx={{ display: 'flex', width: '95%' }}>
				<Button
					sx={{ mr: 1, width: '90%' }}
					variant='outlined'
					onClick={createMeasure}
					disabled={measureSelected != null}>
					{t('measure.new-measure')}
				</Button>
				<Tooltip title={showComments ? t('measure.hide-comments') : t('measure.show-comments')}>
					<IconButton onClick={toggleShowComments} color={showComments ? 'primary' : 'secondary'}>
						<RateReviewIcon />
					</IconButton>
				</Tooltip>
			</Box>
			<Box sx={{ minHeight: '76vh', width: '95%' }}>
				<List
					sx={{
						maxHeight: '30vh',
						minHeight: '30vh',
						overflowY: 'auto',
						width: '100%',
						border: '1px solid #8ab8e5',
						borderRadius: '5px',
						my: 1,
					}}>
					{measureList?.map((measure, idx) => {
						return (
							<React.Fragment key={measure.description + idx}>
								<ListItemButton
									sx={{
										width: '95%',
										mx: 1,
									}}
									selected={measure.description == measureSelected?.description}
									onClick={() => handleSetSelected(measure)}>
									<ListItemText
										primary={measure.description}
										secondary={t('measure.measure-value') + ': ' + measure.measureValue}
									/>
								</ListItemButton>
								{idx != measureList.length - 1 && <Divider variant='middle' component='li' />}
							</React.Fragment>
						)
					})}
				</List>

				{measureSelected && !showComments && (
					<MeasureCard
						posCounter={posCounter}
						setPosCounter={setPosCounter}
						errorDescription={errorDescription}
						measureSelected={measureSelected}
						handleChangeDescription={handleChangeDescription}
						handleChangeSqrSelected={handleChangeSqrSelected}
						sqrList={sqrList}
						cancelMeasure={cancelMeasure}
						deleteMeasure={deleteMeasure}
						saveMeasure={saveMeasure}
						sqrSelected={sqrSelected}
					/>
				)}
				{showComments && (
					<MeasureAndCountComments height={'40vh'} projectItemId={projectItemId} commentList={comments} />
				)}
			</Box>
			<Box sx={{ width: '100%', display: 'flex' }}>
				<Button
					variant='outlined'
					color='secondary'
					sx={{ m: 1, width: '100%' }}
					onClick={() => onClose(false)}>
					{t('comun.actions.close')}
				</Button>
				<Button
					variant='outlined'
					sx={{ m: 1, width: '100%' }}
					onClick={saveMeasureProjectItem}
					disabled={measureList?.length == 0}>
					{t('comun.actions.save')}
				</Button>
			</Box>
		</Box>
	)
}
