import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import MapIcon from '@mui/icons-material/Map'
import PublicIcon from '@mui/icons-material/Public'
import PublicOffIcon from '@mui/icons-material/PublicOff'
import { IconButton, TableFooter, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { datetimeUTCtoString } from '../../comun/DateUtils'
import { StyledTableRow } from '../../comun/StyleComponentUtils'
import { EnhancedTableHead, getComparator, stableSort } from '../../comun/TableUtils'
import TablePaginationComponent from '../../comun/TablePaginationComponent'

export default function AlertZonesTable({
	alertZonesList,
	handleAddAction,
	filter,
	onFilter,
	zoneDesactivate,
	zoneActivate,
	handleShowMap,
}) {
	const [t] = useTranslation('common')

	//This block should be the same in every table component ----------------------------------
	const [order, setOrder] = useState(filter.orderDirection)
	const [orderBy, setOrderBy] = useState(filter.orderField)
	const [page, setPage] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------

	React.useEffect(() => {
		if (alertZonesList) {
			setPage(alertZonesList.pageNo - 1)
			setTotalCount(alertZonesList.totalCount)
		}
	}, [alertZonesList])

	const columnas = [
		{
			id: 'name',
			orderBy: true,
			label: t('alert.zones.table.header.name'),
		},
		{
			id: 'creationDate',
			disablePadding: true,
			orderBy: true,
			label: t('alert.zones.table.header.creation-date'),
		},

		{
			id: 'enabled',
			orderBy: false,
			label: t('alert.zones.table.header.enabled'),
		},
		{
			id: 'fileKML',
			orderBy: true,
			label: t('alert.zones.table.header.kml-file'),
		},
		{
			id: 'actions',
			label: '',
			excluirExcel: true,
		},
	]
	return (
		<TableContainer>
			<Table
				id='table-alert-zones-list'
				sx={{ minWidth: 750 }}
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					addAction={handleAddAction}
				/>
				<TableBody>
					{alertZonesList
						? stableSort(alertZonesList.data, getComparator(order, orderBy)).map((row, index) => {
								return (
									<StyledTableRow key={index}>
										<TableCell align='center'>{row.name}</TableCell>
										<TableCell align='center'>{datetimeUTCtoString(row.creationDate)}</TableCell>

										<TableCell align='center'>
											{row.isEnabled ? (
												<CheckRoundedIcon color='success' />
											) : (
												<CloseRoundedIcon color='error' />
											)}
										</TableCell>
										<TableCell align='center'>
											{row.fileKML ? row.fileKML : 'N/A'}
											{row.fileKML && (
												<Tooltip title={t('alert.zones.table.content.show-in-map')}>
													<IconButton onClick={() => handleShowMap(row)} color='primary'>
														<MapIcon />
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
										<TableCell align='center' className='excluir-excel'>
											{row.isEnabled ? (
												<Tooltip title={t('alert.zones.table.content.disable')}>
													<IconButton onClick={() => zoneDesactivate(row.id)} color='error'>
														<PublicOffIcon />
													</IconButton>
												</Tooltip>
											) : (
												<Tooltip title={t('alert.zones.table.content.enable')}>
													<IconButton onClick={() => zoneActivate(row.id)} color='success'>
														<PublicIcon />
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
									</StyledTableRow>
								)
						  })
						: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
