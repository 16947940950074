import { Alert, Snackbar } from "@mui/material";

export default function CustomSnackBar({open, autoHideDuration, handleClose, message}) {
    
    
    return (
        <Snackbar open={open} autoHideDuration={autoHideDuration} onClose={handleClose} anchorOrigin={{vertical: "bottom", horizontal:"center"}}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    )
}