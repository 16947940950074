import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useProjectRequest = () => {
	const [dataProject, setDataProject] = useState(null)
	const [errorProject, setErrorProject] = useState(null)
	const [isLoadingProject, setIsLoadingProject] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const projectRequest = useCallback(
		(props) => {
			client
				.get(baseUrl + 'horus/f3/project?' + prepareParams(props))
				.then((result) => {
					setIsLoadingProject(false)

					if (result.data.success) {
						setDataProject(result.data)
					} else {
						setErrorProject({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingProject(false)
					setErrorProject(err.response)
				})

			setDataProject(null)
			setErrorProject(null)
			setIsLoadingProject(true)
		},
		[client]
	)
	return {
		projectRequest,
		isLoadingProject,
		dataProject,
		errorProject,
	}
}
