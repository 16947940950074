import { useCallback, useState } from 'react'
import { useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useShowKMLDashboard = () => {
	const [kmlZones, setKmlZones] = useState(null)
	const [errorKmlZones, setErrorKmlZones] = useState(null)
	const [isLoadingKmlZones, setLoadingIsKmlZones] = useState(false)
	const { client } = useContext(ApiClientContext)

	const showKMLDashboard = useCallback(() => {
		client
			.get(baseUrl + 'horus/f3/dashboard/zones')
			.then((result) => {
				setLoadingIsKmlZones(false)
				if (result.data.success) {
					setKmlZones(result.data.data)
				} else {
					setErrorKmlZones({ statusText: result.data.errorMsg })
				}
			})
			.catch((err) => {
				setLoadingIsKmlZones(false)
				setErrorKmlZones(err.response)
			})
		setKmlZones(null)
		setErrorKmlZones(null)
		setLoadingIsKmlZones(true)
	}, [client])
	return {
		kmlZones,
		errorKmlZones,
		isLoadingKmlZones,
		showKMLDashboard,
	}
}
