import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { baseFilter, messageDialogTypes, modeTypesCRUD, codesActivityTimer } from '../../../constants'
import { useConfigAlertFilterRequest } from '../../../services/alerts/configalert/useConfigAlertFilterRequest'
import { useConfigAlertDelete } from '../../../services/alerts/configalert/useConfigAlertDelete'
import { useConfigAlertRequest } from '../../../services/alerts/configalert/useConfigAlertRequest'
import { useConfigAlertInsert } from '../../../services/alerts/configalert/useConfigAlertInsert'
import { useConfigAlertRequestById } from '../../../services/alerts/configalert/useConfigAlertRequestById'
import { useConfigAlertUpdate } from '../../../services/alerts/configalert/useConfigAlertUpdate'
import { useUserLog } from '../../../services/timer/useUserLog'
import ConfirmationDialog from '../../comun/ConfirmationDialog'
import MessageSnackBar from '../../comun/MessageSnackBar'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import AlertConfigFilter from './AlertConfigFilter'
import AlertConfigForm from './AlertConfigForm'
import AlertConfigTable from './AlertConfigTable'
import { getGeneralActivity } from './../../../utils/TimerDataUtils'

//Set de column to sort and the direction for the first search, baseFilter contains the rowsPerPage and pageNo
const initialFilter = {
	...baseFilter,
	orderDirection: 'asc',
	orderField: 'name',
	StartDate: null,
	EndDate: null,
	Status: null,
	auxStatus: null,
}

export default function AlertConfigContainer() {
	const [t] = useTranslation('common')

	const [configToDelete, setConfigToDelete] = useState([])

	//Services
	const { userLogRequest } = useUserLog()
	const { configAlertFilterRequest, isLoadingConfigAlertFilter, configAlertFilter, errorConfigAlertFilter } =
		useConfigAlertFilterRequest()
	const { configAlertRequest, isLoadingConfigAlert, dataConfigAlert, errorConfigAlert } = useConfigAlertRequest()
	const {
		configAlertRequestById,
		isLoadingAlertConfigById,
		dataAlertConfigById,
		errorAlertConfigById,
		setDataAlertConfigById,
	} = useConfigAlertRequestById()
	const { configAlertDelete, isLoadingDelete, configAlertDataDelete, errorConfigAlertDelete } = useConfigAlertDelete()
	const { configAlertInsert, isLoadingInsert, configAlertData, errorConfigAlertInsert } = useConfigAlertInsert()
	const { configAlertUpdate, isLoadingUpdate, configAlertDataUpdate, errorConfigAlertUpdate } = useConfigAlertUpdate()

	//Filter
	const [filter, setFilter] = useState(initialFilter)

	const [favouriteFilters, setFavourites] = useState(null)

	const [alertConfigFilter, setAlertConfigFilter] = useState(null)
	const [alertConfigList, setAlertConfigList] = useState(null)
	const [emailInterval, setEmailInterval] = useState([])
	const [vessels, setVessels] = useState([])
	const [zones, setZones] = useState([])
	const [sensors, setSensors] = useState([])

	//Errors control
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeMessage, setTypeMessage] = useState(null)

	//Modals
	const [openForm, setOpenForm] = useState(false)
	const [mode, setMode] = useState(false)
	const [openDelete, setOpenDelete] = useState(false)

	useEffect(() => {
		configAlertFilterRequest()
		userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_CONFIG_VIEW, 'ALERTS_CONFIG_VIEW'))
	}, [])

	useEffect(() => {
		if (dataConfigAlert) {
			setAlertConfigList(dataConfigAlert)
		}
	}, [dataConfigAlert])

	useEffect(() => {
		if (configAlertFilter) {
			setAlertConfigFilter(configAlertFilter.filterNotificationsConfiguration)
			setEmailInterval(configAlertFilter.emailInterval)
			setVessels(configAlertFilter.vessels)
			setZones(configAlertFilter.zones)
			setSensors(configAlertFilter.sensors)
			setAlertConfigList(configAlertFilter.resultFilter)
			setFavourites(configAlertFilter.favouritesDic)
		}
	}, [configAlertFilter])

	useEffect(() => {
		if (
			errorConfigAlertFilter ||
			errorConfigAlertUpdate ||
			errorConfigAlertInsert ||
			errorConfigAlert ||
			errorConfigAlertDelete ||
			errorAlertConfigById
		) {
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setOpenError(true)
			if (errorConfigAlertFilter) {
				setErrorMsg(errorConfigAlertFilter)
			} else if (errorConfigAlertInsert) {
				setErrorMsg(errorConfigAlertInsert)
			} else if (errorConfigAlertUpdate) {
				setErrorMsg(errorConfigAlertUpdate)
			} else if (errorConfigAlert) {
				setErrorMsg(errorConfigAlert)
			} else if (errorConfigAlertDelete) {
				setErrorMsg(errorConfigAlertDelete)
			} else if (errorAlertConfigById) {
				setErrorMsg(errorAlertConfigById)
			}
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [
		errorConfigAlertFilter,
		errorConfigAlertUpdate,
		errorConfigAlertInsert,
		errorConfigAlert,
		errorConfigAlertDelete,
		errorAlertConfigById,
	])

	useEffect(() => {
		if (dataAlertConfigById) {
			setOpenForm(true)
		}
	}, [dataAlertConfigById])

	useEffect(() => {
		if (configAlertData || configAlertDataUpdate || configAlertDataDelete) {
			onClose()
			configAlertRequest(filter)
		}
	}, [configAlertData, configAlertDataUpdate, configAlertDataDelete])

	const onFilter = (f) => {
		setFilter(f)
		configAlertRequest(f)
	}

	const handleCloseError = () => {
		setOpenError(false)
	}

	const parseData = (data, checked) => {
		let config = { ...data }
		config.isEmailNotificationActive = checked
		config.email = data.email
		config.minSendInterval = data.emailInterval?.sendInterval
		config.zoneId = data.zoneId?.id
		config.type = Number(data.type.typeId)
		config.sensors = config.sensors.map((sensor) => {
			sensor.minValue = sensor.minValue == '' ? null : sensor.minValue
			sensor.maxValue = sensor.maxValue == '' ? null : sensor.maxValue
			return sensor
		})
		config.minSpeed = data.minSpeed == '' ? null : data.minSpeed
		config.maxSpeed = data.maxSpeed == '' ? null : data.maxSpeed
		config.speedEnduranceMinutes = data.speedEnduranceMinutes == '' ? null : data.speedEnduranceMinutes
		delete config.sensorAutoComplete
		delete config.vesselAutocomplete

		return config
	}

	const onSave = (data, checked) => {
		let config = parseData(data, checked)

		if (modeTypesCRUD.UPDATE_MODE == mode) {
			config.id = dataAlertConfigById.id
			configAlertUpdate(config)
			userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_CONFIG_EDIT, 'ALERTS_CONFIG_EDIT'))
		} else if (modeTypesCRUD.INSERT_MODE == mode) {
			configAlertInsert(config)
			userLogRequest(getGeneralActivity(codesActivityTimer.ALERTS_CONFIG_CREATE, 'ALERTS_CONFIG_CREATE'))
		}
	}

	const handleFormConfigAlert = (row, mode) => {
		setMode(mode)
		if (modeTypesCRUD.UPDATE_MODE == mode) {
			configAlertRequestById(row.id)
		} else if (modeTypesCRUD.DELETE_MODE == mode) {
			setOpenDelete(true)
			setConfigToDelete(row)
		} else if (modeTypesCRUD.INSERT_MODE == mode) {
			setOpenForm(true)
		}
	}

	const onClose = () => {
		setOpenForm(false)
		setDataAlertConfigById(null)
	}

	const onCloseDelete = (confirmed) => {
		setOpenDelete(false)
		if (confirmed) {
			configAlertDelete(configToDelete.id)
		}
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<BackdropComponent
				open={
					isLoadingConfigAlertFilter ||
					isLoadingConfigAlert ||
					isLoadingAlertConfigById ||
					isLoadingDelete ||
					isLoadingInsert ||
					isLoadingUpdate
				}
			/>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={typeMessage}
				handleCloseError={handleCloseError}
			/>
			<Box sx={{ width: '100%' }}>
				{/* Filter */}
				<AlertConfigFilter
					onFilter={onFilter}
					filter={filter}
					vessels={vessels}
					zones={zones}
					alertConfigFilter={alertConfigFilter}
					favouriteFilters={favouriteFilters}></AlertConfigFilter>
			</Box>

			<Divider sx={{ my: 2 }} variant='middle' />

			<Box>
				{/* Table */}
				<AlertConfigTable
					alertConfigList={alertConfigList}
					filter={filter}
					onFilter={onFilter}
					handleFormConfigAlert={handleFormConfigAlert}></AlertConfigTable>
			</Box>
			{openForm ? (
				<AlertConfigForm
					onSave={onSave}
					mode={mode}
					onClose={onClose}
					open={openForm}
					alertConfig={dataAlertConfigById}
					emailInterval={emailInterval}
					vessels={vessels}
					zones={zones}
					sensorsArr={sensors}
				/>
			) : null}
			<ConfirmationDialog
				isDelete
				onOpen={openDelete}
				onClose={onCloseDelete}
				dialogTitle={t('alert.config.form.title-delete')}
				dialogContent={t('alert.config.form.delete-content')}
				dialogSaveButton={t('comun.actions.delete')}
				dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>
		</Box>
	)
}
