import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useConfigAlertRequestById = () => {
	const [dataAlertConfigById, setDataAlertConfigById] = useState(null)
	const [errorAlertConfigById, setErrorAlertConfigById] = useState(null)
	const [isLoadingAlertConfigById, setIsLoadingAlertConfigById] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const configAlertRequestById = useCallback(
		(id) => {
			const config = {
				params: {
					id: id,
				},
			}
			client
				.get(baseUrl + 'horus/f3/alert/config', config)
				.then((result) => {
					setIsLoadingAlertConfigById(false)
					if (result.data.success) {
						setDataAlertConfigById(result.data.data)
					} else {
						setErrorAlertConfigById({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingAlertConfigById(false)
					setErrorAlertConfigById(err.response)
				})

			setDataAlertConfigById(null)
			setErrorAlertConfigById(null)
			setIsLoadingAlertConfigById(true)
		},
		[client]
	)
	return {
		configAlertRequestById,
		isLoadingAlertConfigById,
		dataAlertConfigById,
		errorAlertConfigById,
		setDataAlertConfigById,
	}
}
