import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../../services/ApiClientProvider'

export const useProjectItemCountUpdate = () => {
	const [dataProjectItemCountUpdate, setDataProjectItemCountUpdate] = useState(null)
	const [errorProjectItemCountUpdate, setErrorProjectItemCountUpdate] = useState(null)
	const [isLoadingProjectItemCountUpdate, setIsLoadingProjectItemCountUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemCountUpdate = useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/project/item/count', props)
				.then((result) => {
					setIsLoadingProjectItemCountUpdate(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemCountUpdate(result.data.data)
					} else {
						setErrorProjectItemCountUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemCountUpdate(error.response)
					setIsLoadingProjectItemCountUpdate(false)
				})

			setIsLoadingProjectItemCountUpdate(true)
			setDataProjectItemCountUpdate(null)
			setErrorProjectItemCountUpdate(null)
		},
		[client]
	)

	return {
		projectItemCountUpdate,
		dataProjectItemCountUpdate,
		errorProjectItemCountUpdate,
		isLoadingProjectItemCountUpdate,
	}
}
