import { Box, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { dateDiffInSeconds } from '../../comun/DateUtils'
import { useTranslation } from 'react-i18next'

function VideosTimeline({
	infoDownloadVideos,
	timeData: { currentVisualizationTime, currentRangeEnd, currentRangeStart, infoRangeMillis },
}) {
	const [t] = useTranslation('common')
	const Tramo = ({ step: { start, end, value } }) => {
		const [pos, setPos] = useState('0%')
		const [color, setColor] = useState('#d3d3d3')
		const [tooltip, setTooltip] = useState('')

		useEffect(() => {
			let offset = 0
			if (currentRangeStart > start) {
				offset = 0
			} else {
				offset = dateDiffInSeconds(currentRangeStart, start)
			}
			let p = (offset / (infoRangeMillis / 1000)) * 100
			setPos(p + '%')
			switch (value) {
				case 0:
					setColor('#d3d3d3')
					setTooltip(t('preview.videos-status.pending-request'))
					break
				case 1:
					setColor('#05539e')
					setTooltip(t('preview.videos-status.downloading'))
					break
				case 2:
					setColor('#04a9df')
					setTooltip(t('preview.videos-status.video'))
					break
				case 3:
					setColor('#de4373')
					setTooltip(t('preview.videos-status.no-video'))
					break
			}
		}, [])

		return (
			<Tooltip title={tooltip} placement='bottom-start' followCursor>
				<Box
					sx={{
						position: 'absolute',
						backgroundColor: color,
						left: pos,
						width: '-webkit-fill-available',
						height: '100%',
					}}></Box>
			</Tooltip>
		)
	}
	return (
		<Box
			sx={{
				width: '95%',
				height: '2.5vh',
				minHeight: '2.5vh',
				display: 'flex',
				position: 'relative',
				ml: '5%',
				mt: 0.5,
				boxShadow: '1px 1px 2px 1px rgb(25 118 210 / 85%), 0px -1px 1px 0px rgb(25 118 210 / 85%)',
			}}>
			{infoDownloadVideos.map((step, idx) => (
				<Tramo key={idx} step={step} />
			))}
		</Box>
	)
}

export default React.memo(VideosTimeline)
