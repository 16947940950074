import { Button, Dialog, DialogTitle, FormControl, TextField } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import * as React from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import '../../index.css'
import { useState } from 'react'

export default function AddCommentDialog({ idx, onOpen, onClose, commentValue, mode, paperProps }) {
	const [comment, setComment] = useState(commentValue)
	const [t] = useTranslation('common')

	useEffect(() => {
		setComment(commentValue)
	}, [commentValue])

	const handleChange = (e) => {
		setComment(e.target.value)
	}

	const guardar = () => {
		onClose(true, comment, idx, mode)
		setComment('')
	}
	return (
		<Dialog open={onOpen} sx={{ m: 1 }} PaperProps={paperProps}>
			<DialogTitle>{t('anotaciones-form.add-comment-dialog.title')}</DialogTitle>
			<DialogContent>
				<FormControl sx={{ width: '100%' }}>
					<TextField
						multiline
						sx={{ m: 1, width: '100%' }}
						value={comment}
						onChange={handleChange}
						label='Comment'></TextField>
				</FormControl>
			</DialogContent>
			<DialogActions sx={{ mx: 1 }}>
				<Button onClick={() => guardar()} variant='outlined'>
					{t('anotaciones-form.add-comment-dialog.save')}
				</Button>
				<Button onClick={() => onClose(false)} color='secondary' variant='outlined'>
					{t('anotaciones-form.add-comment-dialog.cancel')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}
