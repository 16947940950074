import NoteAddIcon from '@mui/icons-material/NoteAdd'
import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes, modeTypesCRUD } from '../../../constants'
import FormAnotacionesDinamico from '../../notes/FormAnotacionesDinamico'
import { getDeclarationIconAux } from '../../comun/NotesIconHelper'
import useStore from '../PreviewStore'
import MeasureContainer from '../../measurement/MeasureContainer'
import CountContainer from '../../count/CountContainer'
import { useVideoScreenshotInfo } from '../../../services/videos/useVideoScreenshotInfo'
import MessageSnackBar from '../../comun/MessageSnackBar'
import { dateDiffInSeconds, datetimeGMTToLocaleInISOFormat } from '../../comun/DateUtils'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import { useShallow } from 'zustand/shallow'

const img = new Image()
export default function AnnotationButtons({
	fishingProfile,
	isAnnotationShortcutEnabled,
	isAnnotationShortcutVisible,
}) {
	const [t] = useTranslation('common')
	const [openModal, setOpenModal] = useState(false)
	const [note, setNote] = useState(null)
	const { pause, play, project, setUpdatedSecondaryTab, getCurrentTime, mainVideo } = useStore(
		useShallow((state) => ({
			pause: state.pause,
			play: state.play,
			project: state.project,
			setUpdatedSecondaryTab: state.setUpdatedSecondaryTab,
			getCurrentTime: state.getCurrentTime,
			mainVideo: state.mainVideo,
		}))
	)

	const handleAddNote = (item) => {
		pause()
		setNote({
			itemType: item.itemType,
			videoId: mainVideo.videoId,
			itemDateTime: datetimeGMTToLocaleInISOFormat(getCurrentTime()),
			camNo: mainVideo.cam,
			fileType: 'VI',
			course: 100,
			latitude: 50,
			longitude: 50,
			speed: 50,
			offset: dateDiffInSeconds(getCurrentTime(), mainVideo.startDate).toFixed(0),
		})
		setOpenModal(true)
	}

	const handleClose = (idx, success, itemSaved) => {
		setOpenModal(false)
		if (success) {
			if (itemSaved?.itemType == 'ME') {
				setProjectItemId(itemSaved.projectItemId)
				handleMeasurement()
			} else if (itemSaved?.itemType == 'CT') {
				setProjectItemId(itemSaved.projectItemId)
				handleCount()
			} else {
				//TODO ver como añadir el listado a la gráfica
				play()
				setNote(null)
			}
			setUpdatedSecondaryTab('2')
		} else {
			play()
			setNote(null)
		}
	}

	//Measures
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeNoteModal, setTypeNoteModal] = useState(null)
	const [openMeasure, setOpenMeasure] = React.useState(false)

	const [projectItemId, setProjectItemId] = React.useState(null)
	const { getScreenshotInfo, screenshotInfoData, isLoadingScreenshotInfo, screenshotInfoError } =
		useVideoScreenshotInfo()

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const handleCloseMeasure = (success) => {
		if (success) {
			setUpdatedSecondaryTab('2')
		}
		setOpenMeasure(false)
	}

	React.useEffect(() => {
		if (screenshotInfoError) {
			setErrorMsg(screenshotInfoError)
			setOpenError(true)
		} else {
			setErrorMsg(null)
			setOpenError(false)
		}
	}, [screenshotInfoError])

	React.useEffect(() => {
		if (screenshotInfoData) {
			if (typeNoteModal == 'COU') {
				img.src = screenshotInfoData.url
				setOpenCount(true)
			} else if (typeNoteModal == 'MEA') {
				img.src = screenshotInfoData.url
				setOpenMeasure(true)
			}
		}
	}, [screenshotInfoData])

	const handleMeasurement = () => {
		let params = {
			VideoId: mainVideo.videoId,
			Offset: dateDiffInSeconds(getCurrentTime(), mainVideo.startDate).toFixed(0),
			Opt: 'MEA',
		}
		setTypeNoteModal('MEA')
		getScreenshotInfo(params)
	}
	///--------------------------------------------------------------------------

	//Count
	const [openCount, setOpenCount] = React.useState(false)

	const handleCloseCount = (success) => {
		if (success) {
			setUpdatedSecondaryTab('2')
		}
		setOpenCount(false)
	}
	const handleCount = () => {
		let params = {
			VideoId: mainVideo.videoId,
			Offset: dateDiffInSeconds(getCurrentTime(), mainVideo.startDate).toFixed(0),
			Opt: 'COU',
		}
		setTypeNoteModal('COU')
		getScreenshotInfo(params)
	}

	return (
		<React.Fragment>
			<BackdropComponent open={isLoadingScreenshotInfo} />
			<Box
				sx={{
					display: 'flex',
					height: '100%',
					justifyContent: 'flex-end',
				}}>
				{fishingProfile &&
					isAnnotationShortcutVisible &&
					fishingProfile.topItemTypeList &&
					fishingProfile.topItemTypeList.map((item, idx) => {
						return (
							<Tooltip key={'keyBotonAnotaciones' + idx} title={item.description} placement='top'>
								<span>
									<IconButton
										sx={{ mx: 0.25, height: '90%' }}
										color='primary'
										onClick={() => handleAddNote(item)}
										disabled={!isAnnotationShortcutEnabled}>
										<SvgIcon
											sx={{ height: '180%' }}
											component={getDeclarationIconAux(
												item.itemType,
												!isAnnotationShortcutEnabled
											)}
										/>
									</IconButton>
								</span>
							</Tooltip>
						)
					})}
				{isAnnotationShortcutVisible && (
					<Tooltip title={t('track.table.content.add-note')} placement='top'>
						<span>
							<IconButton
								size='small'
								sx={{ mx: 0.5, height: '90%' }}
								color='primary'
								disabled={!isAnnotationShortcutEnabled}
								onClick={() => handleAddNote({ itemType: null })}>
								<NoteAddIcon></NoteAddIcon>
							</IconButton>
						</span>
					</Tooltip>
				)}
			</Box>
			{project && openModal && (
				<FormAnotacionesDinamico
					fishingProfileList={fishingProfile}
					tipoNota={note?.itemType}
					tipoNotaDisabled={false}
					onOpen={openModal}
					onClose={handleClose}
					mode={modeTypesCRUD.INSERT_MODE}
					noteProp={note}
					projectId={project?.projectId}
					canCreateEditAnnotations={true}
					canReviewAnnotations={true}
					showReviewComments={false}
				/>
			)}

			{openMeasure && (
				<MeasureContainer
					canCreateEditAnnotations={true}
					onOpen={openMeasure}
					onClose={handleCloseMeasure}
					image={img}
					imageId={screenshotInfoData?.imageId}
					cam={mainVideo.cam}
					vesselApiId={project.vesselApiId}
					projectItemId={projectItemId}
					isUpdating={false}
				/>
			)}

			{openCount && (
				<CountContainer
					canCreateEditAnnotations={true}
					onOpen={openCount}
					onClose={handleCloseCount}
					image={img}
					mode={modeTypesCRUD.INSERT_MODE}
					projectItemId={projectItemId}
					isUpdating={false}
				/>
			)}
			<MessageSnackBar
				openError={openError}
				handleCloseError={handleCloseError}
				errorMsg={errorMsg}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
		</React.Fragment>
	)
}
