import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import '../../index.css'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FieldsetComponent from '../comun/FieldsetComponent'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import { assignFilter } from '../../utils/customFunctions'
import DatePickerComponent from '../comun/form_fields/DatePickerComponent'
import { isStartDateBefore } from '../comun/DateUtils'
import { datetimeGMTToLocaleInISOFormat } from '../comun/DateUtils'
import { useTimerUserFilterPost } from '../../services/timer/useTimerUserFilterPost'
import { useTimerUserFilterDelete } from '../../services/timer/useTimerUserFilterDelete'
import FilterFunctions from '../comun/FilterFunctions'
import MessageSnackBar from '../comun/MessageSnackBar'
import { messageDialogTypes } from '../../constants'

export default function TimerUsersFilter({ usersOptions, onFilter, filter, filterTimeUsers, favouriteFilters }) {
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useForm({ mode: 'onTouched' })

	const [t] = useTranslation('common')

	useEffect(() => {
		if (filterTimeUsers) {
			let user = usersOptions.find((u) => u.id == filterTimeUsers.user)
			setValue('user', user)
			setValue('startDate', filterTimeUsers.start)
			setValue('endDate', filterTimeUsers.end)
		}
	}, [filterTimeUsers])

	const onSearch = (data) => {
		let config = {}
		config.user = data.user?.id
		config.start = datetimeGMTToLocaleInISOFormat(data.startDate)
		config.end = datetimeGMTToLocaleInISOFormat(data.endDate)
		assignFilter(config, filter)
		config.pageNo = 1
		onFilter(config)
	}

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useTimerUserFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useTimerUserFilterDelete()

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({});

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const saveFilter = (filterName) => {
		let formValues = getValues()
		
		let filterDataAux = {
			filterName: filterName,
			start: datetimeGMTToLocaleInISOFormat(formValues.startDate),
			end: datetimeGMTToLocaleInISOFormat(formValues.endDate),
			user: formValues.user?.id
		}
		
		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter && filterData) {
			setFavFilters(prevState => [...prevState, filterData])
		}
	}, [dataFilter, filterData])

	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			let user = usersOptions.find((u) => u.id == selectedFilter.user)
			setValue('user', user)
			setValue('startDate', selectedFilter.start)
			setValue('endDate', selectedFilter.end)
		}
	}, [selectedFilter])

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
	}

	// Set errrors
	const [ openError, setOpenError ] = useState(false)
	const [ errorMsg, setErrorMsg ] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	}

	return (
		<FieldsetComponent title={t('timer.filter.title')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box
				sx={{ display: 'flex' }}
				component='form'
				onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }}>
					<Box sx={{ display: 'flex', width: '100%', m: 1 }}>
						<AutoCompleteComponent
							defaultValue={null}
							size='small'
							sx={{ width: '100%', mr: 1 }}
							control={control}
							name='user'
							label={t('timer.filter.user')}
							optionsLabel={'userName'}
							keyProperty={'id'}
							options={usersOptions ? usersOptions : []}
						/>
					</Box>
					<Box sx={{ display: 'flex', width: '100%', m: 1 }}>
						<DatePickerComponent
							defaultValue={null}
							size='small'
							sx={{ width: '50%', mr: 1 }}
							name='startDate'
							control={control}
							label={t('timer.filter.start-date')}
							errors={errors?.startDate}
							rules={{
								validate: (startDateValue) =>
									isStartDateBefore(startDateValue, getValues('endDate')) ||
									t('timer.filter.validations.start-date'),
							}}
						/>
						<DatePickerComponent
							defaultValue={null}
							size='small'
							sx={{ width: '50%', mr: 1 }}
							name='endDate'
							control={control}
							label={t('timer.filter.end-date')}
						/>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
					<FilterFunctions
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						filters={favFilters}
						threeLines={false}
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
