import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProgramRequestById = () => {
	const [programData, setProgramData] = useState(null)
	const [errorProgramData, setErrorProgramData] = useState(null)
	const [isLoadingProgram, setIsLoadingProgram] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const requestProgramById = useCallback(
		(id) => {
			const config = {
				params: {
					id: id,
				},
			}
			client
				.get(baseUrl + 'horus/f3/program/info', config)
				.then((result) => {
					setIsLoadingProgram(false)
					if (result.data.success) {
						setProgramData(result.data.data)
					} else {
						setErrorProgramData({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingProgram(false)
					setErrorProgramData(err.response)
				})

			setProgramData(null)
			setErrorProgramData(null)
			setIsLoadingProgram(true)
		},
		[client]
	)
	return {
		requestProgramById,
		isLoadingProgram,
		programData,
		setProgramData,
		errorProgramData,
	}
}
