import {
	Alert,
	Box,
	Button,
	MenuItem,
	Select,
	Snackbar,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
	styled,
} from '@mui/material'
import React, { useState } from 'react'
import ReactHlsPlayer from 'react-hls-player'
import { addSeconds, datetimeUTCtoString } from '../../comun/DateUtils'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import FastForwardIcon from '@mui/icons-material/FastForward'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { useRef } from 'react'
import { useEffect } from 'react'
import './MainPanel.css'
import { useChangeFootageCam } from '../../../services/preview/useChangeFootageCam'
import useStore from '../PreviewStore'
import MessageSnackBar from '../../comun/MessageSnackBar'
import { messageDialogTypes, previewVideoForwardTime, previewVideoZoomStep } from '../../../constants'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/shallow'

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
	'& .MuiToggleButtonGroup-grouped': {
		border: '1px solid #1976d2',
		borderRadius: 5,

		'&.Mui-selected': {
			color: 'white',
			fontWeight: 700,
			backgroundColor: '#1976d2',
			textTransform: 'unset !important',
		},
		'&': {
			color: '#1976d2',
			fontWeight: 700,
			backgroundColor: 'white',
			textTransform: 'unset !important',
		},
		'&.Mui-disabled': {
			color: '#bdbdbd',
			border: '1px solid #bdbdbd',
		},
	},
}))

function MainPanel({
	dataPreview: {
		visualizationData: { cameraEnum, isLiveViewEnabled, isLiveViewSelected, mainVideoFootage },
		controlsData: { isPlaying },
	},
	dataPreview,
	updatePreview,
	zoomedIn,
	vesselApiId,
	projectId,
}) {
	const [mainFootage, setMainFootage] = useState(mainVideoFootage)
	const [openSnack, setOpenSnack] = useState(false)
	const [snackMessage, setSnackMessage] = useState('')
	const {
		currentTime,
		setCurrentTime,
		isPlayingStore,
		play,
		pause,
		speed,
		setMainVideoSeeked,
		jumpRequested,
		setJumpRequested,
		tabSelected,
		callFromMain,
		setMainVideo,
	} = useStore(
		useShallow((state) => ({
			currentTime: state.currentTime,
			setCurrentTime: state.setCurrentTime,
			isPlayingStore: state.isPlayingStore,
			play: state.play,
			pause: state.pause,
			speed: state.speed,
			setMainVideoSeeked: state.setMainVideoSeeked,
			jumpRequested: state.jumpRequested,
			setJumpRequested: state.setJumpRequested,
			tabSelected: state.tabSelected,
			callFromMain: state.callFromMain,
			setMainVideo: state.setMainVideo,
		}))
	)
	const [t] = useTranslation('common')

	const playerRef = useRef(null)
	const { changeFootageCam, footageData, errorfootage, setErrorFootage } = useChangeFootageCam(vesselApiId, projectId)

	const transformWrapper = useRef(null)

	const handleCloseError = () => {
		setErrorFootage(null)
	}

	const handleClose = () => {
		setOpenSnack(false)
	}

	useEffect(() => {
		if (jumpRequested) {
			pause()
			changeFootageCam('mainvideo/cam', mainFootage.cam, jumpRequested)
			setJumpRequested(null)
		}
	}, [jumpRequested])

	useEffect(() => {
		if (footageData) {
			setMainFootage(footageData)
			setMainVideo(footageData)
		}
	}, [footageData])

	useEffect(() => {
		if (mainVideoFootage) {
			setMainFootage(mainVideoFootage)
			setMainVideo(mainVideoFootage)
		}
	}, [mainVideoFootage])

	useEffect(() => {
		if (mainFootage) {
			if (playerRef.current) {
				playerRef.current.currentTime = mainFootage.offsetInSec
			}
			// if (mainFootage.offsetInSec == 0) {
			// 	console.log('llamo por offset 0')
			// 	setMainVideoSeeked(true)
			// }
		}
	}, [mainFootage])

	const handleChangeMainCam = (e) => {
		changeFootageCam('mainvideo/cam', e.target.value, currentTime)
	}

	useEffect(() => {
		if (playerRef?.current) {
			if (zoomedIn) {
				playerRef.current.controls = false
				setSnackMessage(t('preview.main-panel.zoom-pan-on'))
			} else {
				transformWrapper.current?.resetTransform(300, 'easeOut')
				playerRef.current.controls = true
				setSnackMessage(t('preview.main-panel.zoom-pan-off'))
			}
			setOpenSnack(true)
		}
	}, [zoomedIn])

	useEffect(() => {
		if (playerRef?.current) {
			if (isLiveViewSelected) {
				playerRef.current.controls = false
			} else {
				transformWrapper.current?.resetTransform(300, 'easeOut')
				playerRef.current.controls = true
			}
		}
	}, [isLiveViewSelected])

	useEffect(() => {
		if (speed && playerRef?.current) {
			playerRef.current.playbackRate = speed
		}
	}, [speed, mainFootage])

	useEffect(() => {
		if (isPlayingStore != null) {
			if (isPlayingStore && playerRef.current?.paused && !callFromMain) {
				playerRef.current?.play()
			} else if (!isPlayingStore && !playerRef.current?.paused && !callFromMain) {
				playerRef.current?.pause()
			}
		}
	}, [isPlayingStore])

	const togglePlayPause = () => {
		if (!isPlayingStore) {
			play()
		} else {
			pause()
		}
	}

	const onPlay = () => {
		play(true)
	}

	const onPause = () => {
		pause(true)
	}

	const fastFordward = () => {
		const timeToAdd = 1000 / mainFootage.fps / 1000
		playerRef.current.currentTime += timeToAdd
	}

	const fastRewind = () => {
		const timeToSubstract = 1000 / mainFootage.fps / 1000
		playerRef.current.currentTime -= timeToSubstract
	}

	const onTimeUpdate = (e) => {
		setCurrentTime(addSeconds(mainFootage.startDate, e.target.currentTime.toFixed(2)))
	}

	const TimeDisplay = () => {
		return (
			<Box
				sx={{
					borderRadius: '5px',
					height: '100%',
					display: 'flex',
					alignItems: 'center',
					backgroundColor: '#1976d2',
					p: 1,
				}}>
				<Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '0.8rem' }}>
					{datetimeUTCtoString(currentTime)}
				</Typography>
			</Box>
		)
	}

	const onSeeked = () => {
		if (tabSelected == '0') {
			console.log('llamo por seeked')
			setMainVideoSeeked(true)
		}
	}

	const onEnded = () => {
		changeFootageCam('mainvideo/cam', mainFootage.cam, currentTime)
	}

	const LiveView = () => {
		const [liveViewOn, setLiveViewOn] = useState(isLiveViewSelected)

		const handleChangeLiveView = () => {
			if (liveViewOn) {
				updatePreview('liveview/disable')
			} else {
				updatePreview('liveview/enable')
			}
			setLiveViewOn(!liveViewOn)
		}

		const handleClick = (event) => {
			event.stopPropagation(); // Prevenir la propagación del evento
			if (localStorage.getItem("userProfileName").toString().includes('icente')) {
				window.open('http://192.168.128.50', '_blank');
			}
		};
		
		return (
			!localStorage.getItem("userProfileName").toString().includes('icente') ?
			(<StyledToggleButtonGroup
				disabled={!isLiveViewEnabled}
				color='primary'
				size='small'
				exclusive
				sx={{ mx: 1, height: '100%', justifyContent: 'flex-end' }}
				value={liveViewOn != null ? liveViewOn : false}
				onChange={handleChangeLiveView}>
				<ToggleButton color='primary' variant='outlined' selected={liveViewOn} value={true}>
					<FiberManualRecordIcon color={liveViewOn ? 'error' : 'disabled'} />
					Live view
				</ToggleButton>
			</StyledToggleButtonGroup>)
			: 
			(
				<StyledToggleButtonGroup
				color='primary'
				size='small'
				exclusive
				sx={{ mx: 1, height: '100%', justifyContent: 'flex-end' }}
				value={true}
				onClick={handleClick}
				>
				<ToggleButton color='primary' variant='outlined' selected={liveViewOn} value={true}>
					<FiberManualRecordIcon color={'error'} />
					Live view
				</ToggleButton>
			</StyledToggleButtonGroup>
			)
		)
	}

	return (
		<Box
			sx={{
				height: '100%',
				width: '55%',
				display: 'flex',
				flexDirection: 'column',
			}}>
			<MessageSnackBar
				openError={errorfootage != null}
				errorMsg={errorfootage}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
				handleCloseError={handleCloseError}
			/>
			{dataPreview && (
				<Box sx={{ display: 'flex', width: '100%', height: '7%', justifyContent: 'flex-end', pt: 0.5 }}>
					<TimeDisplay />
					<LiveView />
				</Box>
			)}
			<Snackbar
				open={openSnack}
				autoHideDuration={1000}
				onClose={handleClose}
				sx={{ height: '35px', top: '10%', left: '25% !important', opacity: '0.8' }}>
				<Alert
					onClose={handleClose}
					severity='info'
					sx={{ width: '100%', height: '100%', alignItems: 'center' }}>
					{snackMessage}
				</Alert>
			</Snackbar>

			<Box
				sx={{
					display: 'flex',
					height: '91%',
					position: 'relative',
					justifyContent: 'center',
					background: 'black',
					mt: '0.5%',
					ml: '0.5%',
					boxShadow:
						'1px 1px 2px 1px rgb(25 118 210 / 85%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
				}}>
				{mainVideoFootage && (
					<TransformWrapper
						wheel={{ step: previewVideoZoomStep ? previewVideoZoomStep : 0.5 }}
						disabled={!zoomedIn}
						ref={transformWrapper}>
						<TransformComponent
							wrapperStyle={{ height: '100%', width: 'auto' }}
							contentStyle={{ height: '100%', width: '100%' }}>
							<ReactHlsPlayer
								playerRef={playerRef}
								src={mainFootage?.url}
								controls={!zoomedIn && !isLiveViewSelected}
								autoPlay={isPlaying}
								height='100%'
								muted
								onTimeUpdate={onTimeUpdate}
								onPlay={onPlay}
								onPause={onPause}
								onEnded={onEnded}
								onSeeked={onSeeked}
							/>
						</TransformComponent>
					</TransformWrapper>
				)}
				<Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', top: 10, right: 10 }}>
					<Select
						size='small'
						value={mainFootage?.cam}
						onChange={handleChangeMainCam}
						sx={{ background: 'white', height: '35px' }}>
						{cameraEnum.map((c, idx) => (
							<MenuItem key={idx} value={c}>
								{c}
							</MenuItem>
						))}
					</Select>
					{zoomedIn && (
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<Button
								sx={{ background: 'white', minWidth: '0px', height: '25px', my: 0.5 }}
								onClick={togglePlayPause}>
								{isPlayingStore ? <PauseIcon /> : <PlayArrowIcon />}
							</Button>
							<Button
								sx={{ background: 'white', minWidth: '0px', height: '25px', my: 0.5 }}
								onClick={fastFordward}>
								<FastForwardIcon />
							</Button>
							<Button
								sx={{ background: 'white', minWidth: '0px', height: '25px', my: 0.5 }}
								onClick={fastRewind}>
								<FastRewindIcon />
							</Button>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	)
}
export default React.memo(MainPanel)
