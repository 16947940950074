import { Box, Divider } from '@mui/material'
import * as React from 'react'
import { baseFilter, messageDialogTypes } from '../../constants'
import { useTimerUsersDetails } from '../../services/timer/useTimerUsersDetails'
import { useTimerUsersFilterRequest } from '../../services/timer/useTimerUsersFilterRequest'
import { useTimerUsersInfo } from '../../services/timer/useTimerUsersInfo'
import { useTimerUsersRequest } from '../../services/timer/useTimerUsersRequest'
import { assignFilter } from '../../utils/customFunctions'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import TimerUsersFilter from './TimerUsersFilter'
import TimerUsersForm from './TimerUsersForm'
import TimerUsersTable from './TimerUsersTable'

export default function TimerUsersContainer() {
	const initialFilter = { ...baseFilter, orderDirection: 'asc', orderField: 'userName' }
	const initialFilterForm = { ...baseFilter, orderDirection: 'asc', orderField: 'activity' }

	//Services
	const { timerProjectUsersRequest, isLoadingTimerUsersFilter, timerUsersFilter, errorTimerUsersFilter } =
		useTimerUsersFilterRequest()
	const { timerUsersRequest, isLoadingTimerUsers, dataTimerUsers, errorTimerUsers } = useTimerUsersRequest()
	const { timerUsersInfoRequest, isLoadingTimerUsersInfo, timerUsersInfo, errorTimerUsersInfo } = useTimerUsersInfo()
	const { timerUsersDetailsRequest, isLoadingTimerUsersDetails, timerUsersDetails, errorTimerUsersDetails } =
		useTimerUsersDetails()

	const [filterTimeUsers, setFilterTimeUsers] = React.useState(null)
	const [timeUsersList, setTimeUsersList] = React.useState(null)
	const [usersOptions, setUsersOptions] = React.useState([])
	const [activitiesOptions, setActivitiesOptions] = React.useState([])
	const [filter, setFilter] = React.useState(initialFilter)
	const [filterForm, setFilterForm] = React.useState(initialFilterForm)
	const [rowSelected, setRowSelected] = React.useState(null)
	const activeRowColor = '#ccddff'
	const initialRadio = 'grouped-activity'
	const alternativeRadio = 'grouped-timeline'
	const [radioValue, setRadioValue] = React.useState(initialRadio)

	//Errors control
	const [openError, setOpenError] = React.useState(false)
	const [errorMsg, setErrorMsg] = React.useState(null)

	//Modals
	const [openForm, setOpenForm] = React.useState(false)

	// Filters
	const [favouriteFilters, setFavourites] = React.useState(null)

	React.useEffect(() => {
		timerProjectUsersRequest()
	}, [])

	React.useEffect(() => {
		if (timerUsersFilter) {
			setUsersOptions(timerUsersFilter.listUsers)
			setActivitiesOptions(timerUsersFilter.listUserGroupActivities)
			setFilterTimeUsers(timerUsersFilter.filterTimerUsers)
			setTimeUsersList(timerUsersFilter.resultFilter)
			setFavourites(timerUsersFilter.favouritesDic)
		}
	}, [timerUsersFilter])

	React.useEffect(() => {
		if (timerUsersInfo) {
			setOpenForm(true)
		}
	}, [timerUsersInfo])

	React.useEffect(() => {
		if (dataTimerUsers) {
			setTimeUsersList(dataTimerUsers)
		}
	}, [dataTimerUsers])

	React.useEffect(() => {
		if (errorTimerUsersFilter || errorTimerUsers || errorTimerUsersInfo || errorTimerUsersDetails) {
			setOpenError(true)
			if (errorTimerUsersFilter) {
				setErrorMsg(errorTimerUsersFilter)
			} else if (errorTimerUsers) {
				setErrorMsg(errorTimerUsers)
			} else if (errorTimerUsersInfo) {
				setErrorMsg(errorTimerUsersInfo)
			} else if (errorTimerUsersDetails) {
				setErrorMsg(errorTimerUsersDetails)
			}
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorTimerUsersFilter, errorTimerUsers, errorTimerUsersInfo, errorTimerUsersDetails])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const onClose = () => {
		setOpenForm(false)
		setRowSelected(null)
		setRadioValue(initialRadio)
	}

	const onFilter = (data) => {
		setFilter(data)
		timerUsersRequest(data)
	}

	const onFilterForm = (data) => {
		if (radioValue == initialRadio) {
			timerUsersInfoRequest(data)
			setFilterForm(data)
		} else if (radioValue == alternativeRadio) {
			timerUsersDetailsRequest(data)
			setFilterForm(data)
		}
	}

	const parseFilterInitial = (row, selectedActivityId) => {
		let config = {}
		config.userName = row.userName
		config.date = row.date
		config.activityId = selectedActivityId ? selectedActivityId : ''
		assignFilter(config, initialFilterForm)

		return config
	}

	const handleFormTimerUsers = (row) => {
		if (row.time.days || row.time.hours || row.time.minutes || row.time.seconds || row.time.milliseconds) {
			setRowSelected(row)
			timerUsersInfoRequest(parseFilterInitial(row))
			setFilterForm(parseFilterInitial(row))
		}
	}

	const handleRowSelected = (row, selectedRow) => {
		return selectedRow === row ? `${activeRowColor} !important` : 'auto'
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<Box sx={{ width: '100%' }}>
				<TimerUsersFilter
					usersOptions={usersOptions}
					onFilter={onFilter}
					filter={filter}
					filterTimeUsers={filterTimeUsers}
					favouriteFilters={favouriteFilters}
				/>
			</Box>

			<Divider sx={{ my: 2 }} variant='middle' />

			<Box>
				<TimerUsersTable
					rowSelected={rowSelected}
					handleRowSelected={handleRowSelected}
					dataTimerUsers={timeUsersList}
					filter={filter}
					onFilter={onFilter}
					handleFormTimerUsers={handleFormTimerUsers}
				/>
			</Box>

			<BackdropComponent
				open={
					isLoadingTimerUsersFilter ||
					isLoadingTimerUsers ||
					isLoadingTimerUsersInfo ||
					isLoadingTimerUsersDetails
				}
			/>

			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>

			<TimerUsersForm
				rowSelected={rowSelected}
				open={openForm}
				onClose={onClose}
				onFilterForm={onFilterForm}
				filter={filterForm}
				timerUsersInfo={timerUsersInfo}
				timerUsersDetails={timerUsersDetails}
				activitiesOptions={activitiesOptions}
				initialRadio={initialRadio}
				alternativeRadio={alternativeRadio}
				radioValue={radioValue}
				setRadioValue={setRadioValue}
				parseFilterInitial={parseFilterInitial}
				timerUsersInfoRequest={timerUsersInfoRequest}
				timerUsersDetailsRequest={timerUsersDetailsRequest}
				isLoadingTimerUsersDetails={isLoadingTimerUsersDetails}
				isLoadingTimerUsersInfo={isLoadingTimerUsersInfo}
			/>
		</Box>
	)
}
