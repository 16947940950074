import { Box } from '@mui/material'
import React, { useEffect, useState }from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { datetimeGMTToLocaleInISOFormat } from '../../comun/DateUtils'
import FieldsetComponent from '../../comun/FieldsetComponent'
import DatePickerComponent from '../../comun/form_fields/DatePickerComponent'
import SelectComponent from '../../comun/form_fields/SelectComponent'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'
import { isStartDateBefore } from '../../comun/DateUtils'
import { assignFilter } from '../../../utils/customFunctions'
import FilterFunctions from '../../comun/FilterFunctions'
import { useZoneFilterPost } from '../../../services/alerts/zonealert/useZoneFilterPost'
import { useZoneFilterDelete } from '../../../services/alerts/zonealert/useZoneFilterDelete'
import MessageSnackBar from '../../comun/MessageSnackBar'
import { messageDialogTypes } from '../../../constants'

export default function AlertZonesFilter({ onFilter, filter, alertZoneFilter, favouriteFilters, statusOptions }) {
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		formState: { errors },
	} = useForm({})

	const [t] = useTranslation('common')

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useZoneFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useZoneFilterDelete()

	useEffect(() => {
		if (alertZoneFilter) {
			let status = statusOptions.find((s) => s.value == alertZoneFilter.isEnabled)
			setValue('description', alertZoneFilter.description || '')
			setValue('startDate', alertZoneFilter.startDate)
			setValue('endDate', alertZoneFilter.endDate)
			setValue('isEnabled', status)
		}
	}, [alertZoneFilter])

	const onSearch = (data) => {
		data.startDate = datetimeGMTToLocaleInISOFormat(data.startDate)
		data.endDate = datetimeGMTToLocaleInISOFormat(data.endDate)
		data.isEnabled = data.isEnabled.value
		
		//We do this to mantain the data about sort, page, rowsPerPage, direction...etc
		assignFilter(data, filter)
		onFilter(data)
	}

	// Filter functions

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({})

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName);
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)
		
		if (event instanceof Event) {
			event.stopPropagation()
		}
	}

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
	}

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const saveFilter = (filterName) => {
		const formValues = getValues()
		
		let filterDataAux = {
			filterName: filterName,
			startDate: datetimeGMTToLocaleInISOFormat(formValues.startDate),
			endDate: datetimeGMTToLocaleInISOFormat(formValues.endDate),
			description: formValues.description,
			isEnabled: formValues.isEnabled?.value
		}
	  
		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter && filterData) {
			setFavFilters(prevState => [...prevState, filterData])
		}
	}, [dataFilter, filterData])

	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			let status = statusOptions.find((s) => s.value == selectedFilter.isEnabled)
			setValue('description', selectedFilter.description)
			setValue('startDate', selectedFilter.startDate)
			setValue('endDate', selectedFilter.endDate)
			setValue('isEnabled', status)
		}
	}, [selectedFilter])

	// Set errrors
	const [ openError, setOpenError ] = useState(false)
	const [ errorMsg, setErrorMsg ] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<FieldsetComponent title={t('alert.zones.filter.title')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box
				sx={{ display: 'flex' }}
				component='form'
				onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1, flex: '1 1 0' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<DatePickerComponent
							defaultValue={null}
							size='small'
							sx={{ width: '50%', m: 1 }}
							name='startDate'
							control={control}
							label={t('alert.zones.filter.from-date')}
							errors={errors?.startDate}
							rules={{
								validate: (startDateValue) =>
									isStartDateBefore(startDateValue, getValues('endDate')) ||
									t('programs.validations.start-date'),
							}}
						/>

						<DatePickerComponent
							defaultValue={null}
							size='small'
							sx={{ width: '50%', m: 1 }}
							name='endDate'
							control={control}
							label={t('alert.zones.filter.to-date')}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<TextFieldComponent
							defaultValue={''}
							sx={{ width: '50%', m: 1 }}
							name='description'
							label={t('alert.zones.filter.name')}
							control={control}
							size='small'
						/>

						<Box sx={{ display: 'flex', width: '50%', flexDirection: 'row', m: 1 }}>
							<SelectComponent
								label={t('alert.zones.filter.status')}
								defaultValue={statusOptions ? statusOptions[0] : null}
								sx={{ width: '100%' }}
								name='isEnabled'
								control={control}
								size='small'
								options={statusOptions}
								optionsLabel={'description'}
								optionsValue={'value'}
							></SelectComponent>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', mr: 1 }}>
					<FilterFunctions
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						filters={favFilters}
						threeLines={false}
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
