import { Box, Typography } from '@mui/material'
import React from 'react'

export default function LabelValueComponent({ label, value, width, horizontal, sx }) {
	return (
		<Box
			sx={{
				...sx,
				width: width,
				display: 'flex',
				flexDirection: horizontal ? 'row' : 'column',
				alignItems: 'center',
			}}>
			<Typography variant='h6' color='black' fontSize='1.05rem' sx={{ mr: 1 }}>
				{horizontal ? label + ': ' : label}
			</Typography>
			<Typography color='#4c4c4c'>{value}</Typography>
		</Box>
	)
}
