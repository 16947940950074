import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
// In case filter: 
// import { prepareParams } from '../ServicesUtils'

export const useDownloadRequest = () => {
	const [downloadData, setDownloadData] = useState(null)
	const [errorDownloadData, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const downloadRequest = useCallback(
		() => {
			client
				.get(baseUrl + 'horus/f3/downloads')
				.then((result) => {
					setIsLoading(false)
					if (result.data.success) {
						setDownloadData(result.data)
					} else {
						setError({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoading(false)
					setError(err.response)
				})

			setDownloadData(null)
			setError(null)
			setIsLoading(true)
		},
		[client]
	)
	return {
		downloadRequest,
		isLoading,
		downloadData,
		errorDownloadData,
	}
}
