import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useZoneActivate = () => {
	const [dataZoneActivate, setDataZoneActivate] = useState(null)
	const [errorZoneActivate, setErrorZoneActivate] = useState(null)
	const [isLoadingZoneActivate, setIsLoadingZoneActivate] = useState(false)
	const { client } = useContext(ApiClientContext)

	const zoneActivate = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/alert/zone/act?ZoneId=' + props)
				.then((result) => {
					setIsLoadingZoneActivate(false)
					if (result.status === 200 && result.data.success) {
						setDataZoneActivate({ data: result.data })
					} else {
						setErrorZoneActivate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorZoneActivate(error.response)
					setIsLoadingZoneActivate(false)
				})

			setIsLoadingZoneActivate(true)
			setDataZoneActivate(null)
			setErrorZoneActivate(null)
		},
		[client]
	)

	return {
		dataZoneActivate,
		errorZoneActivate,
		isLoadingZoneActivate,
		zoneActivate,
	}
}
