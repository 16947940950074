import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../comun/BasicDialog'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import { EnhancedTableHead, TimerTableCell, TimerTableCellDetails } from '../comun/TableUtils'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import { datetimeUTCtoString } from '../comun/DateUtils'

export default function TimerProjectForm({
	rowSelected,
	formRowSelected,
	handleRowSelected,
	timerProjectInfo,
	filter,
	handleTimerInfo,
	open,
	onClose,
	onFilter,
	timerDetails,
	isVisible,
}) {
	const [t] = useTranslation('common')
	const columnQuantityForGroup1 = 1
	const columnQuantityForGroup2 = 5

	const handleRequestSort = () => {
		onFilter(filter)
	}

	const columnas = [
		{
			id: 'formUser',
			orderBy: false,
			label: t('timer.table.header.user'),
		},
		{
			id: 'mapping',
			orderBy: false,
			label: t('timer.table.header.mapper'),
		},
		{
			id: 'analysis',
			orderBy: false,
			label: t('timer.table.header.analyst'),
		},
		{
			id: 'review',
			orderBy: false,
			label: t('timer.table.header.reviewer'),
		},
		{
			id: 'other',
			orderBy: false,
			label: t('timer.table.header.other'),
		},
		{
			id: 'total',
			orderBy: false,
			label: t('timer.table.header.total'),
			excluirExcel: true,
		},
	]

	const columnasDetails = [
		{
			id: 'typeActivity',
			orderBy: false,
			label: t('timer.table.details.type-activity'),
		},
		{
			id: 'descriptionActivity',
			orderBy: false,
			label: t('timer.table.details.action'),
		},
		{
			id: 'detailsTime',
			orderBy: false,
			label: t('timer.table.details.time'),
		},
		{
			id: 'startTime',
			orderBy: false,
			label: t('timer.table.details.start-date'),
		},
		{
			id: 'endTime',
			orderBy: false,
			label: t('timer.table.details.end-date'),
			excluirExcel: true,
		},
	]

	return (
		<Box>
			{open ? (
				<Box component='form'>
					<BasicDialog
						title={t('timer.form.title') + `'${rowSelected.projectDescription}'`}
						onOpen={open}
						onClose={onClose}>
						<Box sx={{ display: 'flex', flexDirection: 'column' }}>
							<TableContainer
								sx={{
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
									mb: '40px',
								}}>
								<Table
									id='table-timer-form-list'
									sx={{ minWidth: 1050 }}
									aria-labelledby='tableTitle'
									size='small'
									style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
									<EnhancedTableHead
										headCells={columnas}
										order={filter.orderDirection}
										orderBy={filter.orderField}
										onRequestSort={handleRequestSort}
										columnQuantityForGroup1={columnQuantityForGroup1}
										columnQuantityForGroup2={columnQuantityForGroup2}
										groupTitle2={t('timer.table.header.time')}
									/>
									<TableBody sx={{ maxHeight: '40vh' }}>
										{timerProjectInfo
											? timerProjectInfo.map((row, index) => {
													return (
														<React.Fragment key={index}>
															<StyledTableRow
																sx={{
																	cursor: 'pointer',
																	background: handleRowSelected(row, formRowSelected),
																}}
																onClick={() => handleTimerInfo(row)}>
																<TableCell align='center' sx={{ minWidth: 150 }}>
																	{row.user}
																</TableCell>
																<TimerTableCell timerType={row?.selectionTime} />
																<TimerTableCell timerType={row?.analysisTime} />
																<TimerTableCell timerType={row?.auditTime} />
																<TimerTableCell timerType={row?.otherTime} />
																<TimerTableCell timerType={row?.totalTime} />
															</StyledTableRow>
														</React.Fragment>
													)
											  })
											: null}
									</TableBody>
								</Table>
							</TableContainer>

							{isVisible ? (
								<TableContainer sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
									<Table
										id='table-timer-form-details-list'
										sx={{ minWidth: 1050 }}
										aria-labelledby='tableTitle'
										size='small'
										style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
										<EnhancedTableHead
											headCells={columnasDetails}
											order={filter.orderDirection}
											orderBy={filter.orderField}
											onRequestSort={handleRequestSort}
										/>
										<TableBody>
											{timerDetails
												? timerDetails.map((row, index) => {
														return (
															<React.Fragment key={index}>
																<StyledTableRow>
																	<TableCell align='center'>
																		{row.typeActivity}
																	</TableCell>
																	<TableCell align='center'>{row.activity}</TableCell>
																	<TimerTableCellDetails timer={row?.time} />
																	<TableCell align='center'>
																		{datetimeUTCtoString(row.start)}
																	</TableCell>
																	<TableCell align='center'>
																		{datetimeUTCtoString(row.end)}
																	</TableCell>
																</StyledTableRow>
															</React.Fragment>
														)
												  })
												: null}
										</TableBody>
									</Table>
								</TableContainer>
							) : null}
						</Box>
					</BasicDialog>
				</Box>
			) : null}
		</Box>
	)
}
