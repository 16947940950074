import { FormHelperText } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller } from 'react-hook-form'

export default function AutoCompleteComponent({
	rules,
	control,
	defaultValue,
	name,
	label,
	errors,
	sx,
	size,
	required,
	options,
	optionsLabel,
	multiple,
	disabled,
	evalMethod,
	keyProperty,
}) {
	return (
		<FormControl sx={sx} error={!!errors}>
			<Controller
				rules={rules}
				defaultValue={defaultValue !== undefined ? defaultValue : null}
				name={name}
				control={control}
				render={({ field }) => (
					<Autocomplete
						disabled={disabled}
						//filterOptions={filterOptions}
						value={field.value}
						multiple={multiple}
						{...field}
						onBlur={field.onBlur}
						onChange={(_, v) => {
							field.onChange(v)
						}}
						options={options}
						getOptionLabel={(option) => (option[optionsLabel] ? option[optionsLabel].toString() : '')}
						isOptionEqualToValue={
							evalMethod
								? evalMethod
								: (option, value) => {
										if (value === '') {
											return true
										} else {
											return option.id === value.id
										}
								  }
						}
						renderInput={(params) => (
							<TextField {...params} label={label} size={size} error={!!errors} required={required} />
						)}
						renderOption={(props, option) => {
							return (
								<li {...props} key={option[keyProperty]}>
									{option[optionsLabel]}
								</li>
							)
						}}
					/>
				)}
			/>
			<FormHelperText>{errors?.message}</FormHelperText>
		</FormControl>
	)
}
