import Box from '@mui/material/Box'
import React, { useEffect, useState } from 'react'
import '../../index.css'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import FieldsetComponent from '../comun/FieldsetComponent'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'
import DatePickerComponent from '../comun/form_fields/DatePickerComponent'
import { isStartDateBefore } from '../comun/DateUtils'
import { datetimeGMTToLocaleInISOFormat } from '../comun/DateUtils'
import { assignFilter } from '../../utils/customFunctions'
import FilterFunctions from '../comun/FilterFunctions'
import { useProjectFilterPost } from '../../services/projects/useProjectFilterPost'
import { useProjectFilterDelete } from '../../services/projects/useProjectFilterDelete'
import MessageSnackBar from '../comun/MessageSnackBar'
import { messageDialogTypes } from '../../constants'

export default function ProjectFilter({ programs, onFilter, filter, filterReviews, favouriteFilters, statusOptions }) {
	const [t] = useTranslation('common')
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		formState: { errors },
	} = useForm({ mode: 'onTouched' })

	const [disableField, setDisableField] = useState(true)
	const selectProgram = watch('ProgramIdArg')

	const { dataFilter, saveFavouriteFilter, errorPostFilter } = useProjectFilterPost()
	const { deleteFavouriteFilter, errorDeleteFilter } = useProjectFilterDelete()

	const [selectedFilter, setSelectedFilter] = useState('')
	const [favFilters, setFavFilters] = useState([])
	const [filterData, setFilterData] = useState({})

	const onClickSavedFilter = (selectedFilter) => {
		setSelectedFilter(selectedFilter)
	}

	const handleClearClick = () => {
		setSelectedFilter('')
	}

	const onDeleteSavedFilter = (event, filterName) => {
		deleteFavouriteFilter(filterName)
		let filters = favFilters.filter((f) => f.filterName != filterName)
		setFavFilters(filters)

		if (event instanceof Event) {
			event.stopPropagation()
		}
	}

	const resetFilter = () => {
		reset()
		setSelectedFilter('')
	}

	useEffect(() => {
		if (filterReviews) {
			setValue('Description', filterReviews.description ? filterReviews.description : '')
			let program = programs.find((p) => p.programId == filterReviews.programId)
			setValue('ProgramIdArg', program)
			setValue('StartDate', filterReviews.startDate)
			setValue('EndDate', filterReviews.endDate)
			let statusValue = statusOptions.find((s) => s.value == filterReviews.status)
			setValue('Status', statusValue)
		}
	}, [filterReviews])

	useEffect(() => {
		setValue('TypeAnalysisIdArg', null)
		setValue('VesselApiIdArg', null)
		setDisableField(selectProgram ? false : true)

		if (filterReviews && selectProgram) {
			let vessel = selectProgram.vesselList.find((v) => v.vesselApiId == filterReviews.vesselApiId)
			let typeAnalysis = selectProgram.typeAnalysisList.find(
				(t) => t.typeAnalysisId == filterReviews.typeAnalysisId
			)
			setValue('TypeAnalysisIdArg', typeAnalysis)
			setValue('VesselApiIdArg', vessel)
		}
	}, [selectProgram])

	const onSearch = (data) => {
		data.ProgramIdArg = data.ProgramIdArg?.programId
		data.TypeAnalysisIdArg = data.TypeAnalysisIdArg?.typeAnalysisId
		data.VesselApiIdArg = data.VesselApiIdArg?.vesselApiId
		data.StartDate = datetimeGMTToLocaleInISOFormat(data.StartDate)
		data.EndDate = datetimeGMTToLocaleInISOFormat(data.EndDate)
		data.Status = data.Status?.value
		assignFilter(data, filter)
		onFilter(data)
	}

	useEffect(() => {
		if (favouriteFilters) {
			const favFiltersArray = Object.entries(favouriteFilters).map(([filterName, filter]) => {
				return {
					...filter,
					filterName,
				}
			})

			setFavFilters(favFiltersArray)
		}
	}, [favouriteFilters])

	const saveFilter = (filterName) => {
		let formValues = getValues()

		let filterDataAux = {
			filterName: filterName,
			startDate: datetimeGMTToLocaleInISOFormat(formValues.StartDate),
			endDate: datetimeGMTToLocaleInISOFormat(formValues.EndDate),
			description: formValues.Description,
			status: formValues.Status?.value,
			programId: formValues.ProgramIdArg?.programId,
			typeAnalysisId: formValues.TypeAnalysisIdArg?.typeAnalysisId,
			vesselApiId: formValues.VesselApiIdArg?.vesselApiId,
		}

		// let err = false

		// for (let key in filterDataAux) {
		// 	if (filterDataAux[key] === null || filterDataAux[key] === undefined || filterDataAux[key] === '') {
		// 		err = true
		// 	}
		// }

		// if( err ){
		// 	alert("Debes completar todos los campos para guardar un filtro...")
		// 	return
		// }

		saveFavouriteFilter(filterDataAux)
		setFilterData(filterDataAux)
	}

	useEffect(() => {
		if (dataFilter) {
			setFavFilters((prevState) => [...prevState, filterData])
		}
	}, [dataFilter])

	useEffect(() => {
		if (selectedFilter && selectedFilter.filterName) {
			let status = statusOptions.find((s) => s.value == selectedFilter.status)
			let program = programs.find((s) => s.programId == selectedFilter.programId)

			setValue('StartDate', selectedFilter.startDate)
			setValue('EndDate', selectedFilter.endDate)
			setValue('Status', status)
			setValue('Description', selectedFilter.description)
			setValue('ProgramIdArg', program)
			setValue('TypeAnalysisIdArg', null)
			setValue('VesselApiIdArg', null)

			if (selectProgram) {
				let type = selectProgram.typeAnalysisList.find((s) => s.typeAnalysisId == selectedFilter.typeAnalysisId)
				let vessel = selectProgram.vesselList.find((s) => s.vesselApiId == selectedFilter.vesselApiId)

				setValue('TypeAnalysisIdArg', type)
				setValue('VesselApiIdArg', vessel)
			}
		}
	}, [selectedFilter])

	// Set errrors
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	useEffect(() => {
		if (errorPostFilter) {
			setOpenError(true)
			setErrorMsg(errorPostFilter)
		} else if (errorDeleteFilter) {
			setOpenError(true)
			setErrorMsg(errorDeleteFilter)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorPostFilter, errorDeleteFilter])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<FieldsetComponent title={t('project.filter.title')}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
			/>
			<Box sx={{ display: 'flex', width: '100%' }} component='form' onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 0' }}>
					<Box sx={{ display: 'flex', m: 1, width: '100%' }}>
						<TextFieldComponent
							defaultValue={''}
							sx={{ width: '100%' }}
							name='Description'
							label={t('project.filter.description')}
							control={control}
							size='small'
						/>
					</Box>

					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', m: 1 }}>
						<AutoCompleteComponent
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', mr: 1 }}
							control={control}
							name='ProgramIdArg'
							label={t('project.filter.program')}
							optionsLabel={'programDescription'}
							keyProperty={'programId'}
							options={programs ? programs : []}
							evalMethod={(opt, val) => {
								return opt.programId === val.programId
							}}
						/>
						<AutoCompleteComponent
							disabled={disableField}
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%', mr: 1 }}
							control={control}
							name='TypeAnalysisIdArg'
							label={t('project.filter.analysis')}
							options={selectProgram ? selectProgram.typeAnalysisList : []}
							optionsLabel={'typeAnalysisDescription'}
							keyProperty={'typeAnalysisId'}
							evalMethod={(opt, val) => {
								return opt.typeAnalysisId === val.typeAnalysisId
							}}
						/>
						<AutoCompleteComponent
							disabled={disableField}
							defaultValue={null}
							size='small'
							sx={{ width: '33.333%' }}
							control={control}
							name='VesselApiIdArg'
							label={t('project.filter.vessel')}
							options={selectProgram ? selectProgram.vesselList : []}
							optionsLabel={'vesselName'}
							keyProperty={'vesselApiId'}
							evalMethod={(opt, val) => {
								return opt.vesselApiId === val.vesselApiId
							}}
						/>
					</Box>

					<Box sx={{ display: 'flex', width: '100%', m: 1 }}>
						<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '50%', mr: 1 }}>
							<DatePickerComponent
								defaultValue={null}
								size='small'
								sx={{ width: '50%', mr: 1 }}
								name='StartDate'
								control={control}
								label={t('project.filter.start-date')}
								errors={errors?.StartDate}
								rules={{
									validate: (startDateValue) =>
										isStartDateBefore(startDateValue, getValues('EndDate')) ||
										t('project.filter.validations.start-date'),
								}}
							/>
							<DatePickerComponent
								defaultValue={null}
								size='small'
								sx={{ width: '50%' }}
								name='EndDate'
								control={control}
								label={t('project.filter.end-date')}
							/>
						</Box>
						<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
							<AutoCompleteComponent
								defaultValue={null}
								sx={{ width: '100%' }}
								label={t('project.filter.status.title')}
								name='Status'
								control={control}
								size='small'
								options={statusOptions ? statusOptions : []}
								optionsLabel={'description'}
								keyProperty={'value'}
								evalMethod={(opt, val) => {
									return opt.value == val.value
								}}
							/>
						</Box>
					</Box>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'column', ml: 1 }}>
					<FilterFunctions
						threeLines
						filters={favFilters}
						handleClearClick={handleClearClick}
						onClickSavedFilter={onClickSavedFilter}
						resetFilter={resetFilter}
						saveFilter={saveFilter}
						onDeleteSavedFilter={onDeleteSavedFilter}
						selectedFilter={selectedFilter}
					/>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
