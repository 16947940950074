import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import ErrorBoundary from '../components/ErrorBoundary'
import NewPreviewContainer from '../components/new_preview/NewPreviewContainer'

export default function PreviewPage({ location: { state } }) {
	const history = useHistory()
	return (
		<ErrorBoundary history={history}>
			<NewPreviewContainer
				vesselApiId={state?.vessel?.vesselId}
				vessel={state?.vessel}
				project={state?.project}
				projectId={state?.project?.projectId}
				jumpTime={state?.jumpTime}
			/>
		</ErrorBoundary>
	)
}
