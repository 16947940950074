import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { isStartDateBefore } from '../comun/DateUtils'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import DatePickerComponent from '../comun/form_fields/DatePickerComponent'
import SelectComponent from '../comun/form_fields/SelectComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'

export default function GeneralDataProgramForm({ methodsForm, selected, clients }) {
	const {
		control,
		reset,
		getValues,
		formState: { errors },
	} = methodsForm

	const [clientsList] = React.useState(clients)

	const [t] = useTranslation('common')

	React.useEffect(() => {
		if (selected != null) {
			reset(selected)
		}
	}, [selected])

	return (
		<Box sx={{ p: 1, width: '100%' }}>
			<Box sx={{ display: 'flex' }}>
				<TextFieldComponent
					defaultValue={selected?.description}
					sx={{ width: '100%', mt: 0, pr: 1 }}
					rules={{
						maxLength: {
							value: 250,
							message: t('programs.validations.max-length'),
						},
						required: t('programs.validations.required-field'),
					}}
					control={control}
					label={t('programs.filter.description')}
					name='description'
					size='small'
					errors={errors?.description}
				/>
			</Box>

			<Box sx={{ display: 'none' }}>
				{clientsList && clientsList.length > 0 ? (
					<AutoCompleteComponent
						size='small'
						sx={{ minWidth: '100%', mt: 1 }}
						control={control}
						defaultValue={selected.client ? { id: selected.client.id, name: selected.client.name } : null}
						name='clients'
						label={t('programs.filter.clients')}
						errors={errors?.clients}
						optionsLabel={'name'}
						keyProperty={'id'}
						options={clientsList}
					/>
				) : null}
			</Box>

			<Box sx={{ display: 'flex' }}>
				<Box sx={{ width: '50%' }}>
					<DatePickerComponent
						size='small'
						sx={{ width: '100%', mt: 1, pr: 1 }}
						control={control}
						defaultValue={selected.startDate}
						name='startDate'
						label={t('programs.filter.start-date')}
						errors={errors?.startDate}
						rules={{
							required: {
								value: true,
								message: t('comun.form-errors.required'),
							},
							validate: (startDateValue) =>
								isStartDateBefore(startDateValue, getValues('endDate')) ||
								t('programs.validations.start-date'),
						}}></DatePickerComponent>
				</Box>
				<Box sx={{ width: '50%' }}>
					<DatePickerComponent
						size='small'
						sx={{ width: '100%', mt: 1 }}
						control={control}
						defaultValue={selected.endDate}
						name='endDate'
						label={t('programs.filter.end-date')}></DatePickerComponent>
				</Box>
			</Box>
		</Box>
	)
}
