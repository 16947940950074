import { Box, Button, Tooltip, RadioGroup, Radio, FormControl, FormLabel, FormControlLabel } from '@mui/material'
import React from 'react'
import '../../index.css'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import FieldsetComponent from '../comun/FieldsetComponent'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import { assignFilter } from '../../utils/customFunctions'
import BackdropComponent from '../comun/utils/BackdropComponent'

export default function TimerUsersFilterModal({
	onFilter,
	filter,
	activitiesOptions,
	rowSelected,
	radioValue,
	setRadioValue,
	initialRadio,
	alternativeRadio,
	parseFilterInitial,
	timerUsersInfoRequest,
    timerUsersDetailsRequest,
	isLoadingTimerUsersDetails,
    isLoadingTimerUsersInfo
}) {
	const {
		control,
		handleSubmit,
		formState: { errors },
		getValues
	} = useForm({})

	const [t] = useTranslation('common')

	const onSearch = (data) => {
		let config = {}
		config.activityId = data.activities?.id
		config.userName = rowSelected.userName
		config.date = rowSelected.date
		assignFilter(config, filter)
		onFilter(config)
	}

	const handleChange = (event) => {
		setRadioValue(event.target._wrapperState.initialValue)      
		radioValue == initialRadio ?  
			timerUsersDetailsRequest(parseFilterInitial(rowSelected, getValues('activities')?.id)) :
			timerUsersInfoRequest(parseFilterInitial(rowSelected, getValues('activities')?.id))		
	}
	
	return (
		<FieldsetComponent title={t('timer.filter.title')}>
			<Box
				sx={{ display: 'flex', flexDirection: 'column'}}
				component='form'
				onSubmit={handleSubmit(onSearch)}>

				<BackdropComponent
					open={
						isLoadingTimerUsersInfo ||
						isLoadingTimerUsersDetails
					}
				/>

				<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<AutoCompleteComponent
						defaultValue={null}
						size='small'
						sx={{ width: '100%', m: 1 }}
						control={control}
						name='activities'
						label={t('timer.filter.activities')}
						options={activitiesOptions ? activitiesOptions : []}
						optionsLabel={'description'}
						keyProperty={'description'}
						evalMethod={(opt, val) => {
							return opt.description === val.description
						}}
					/>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<FormControl sx={{ display: 'flex', flexDirection: 'row', width: '100%', m: 1 }}>
						<FormLabel 
							sx={{ display: 'flex', alignItems: 'center', px:2 }} 
							id="radioTableType">
							{t('timer.filter.radio-title')}
						</FormLabel>
						<RadioGroup
							defaultValue={initialRadio}
							aria-labelledby="radioTableType"
							name="grouppedBy"
							sx={{ display: 'flex', flexDirection: 'row' }}
    						onChange={handleChange}
							errors={errors?.grouppedBy}
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								}
							}}
						>
							<FormControlLabel 
								value={initialRadio} 
								control={<Radio />} 
								label={t('timer.filter.grouped')} 
							/>
							<FormControlLabel 
								value={alternativeRadio} 
								control={<Radio />} 
								label={t('timer.filter.timeline')} 
							/>
						</RadioGroup>
					</FormControl>
					<Box sx={{ display: 'flex', width: '50%', m: 1, justifyContent: 'flex-end' }}>
						<Tooltip title={t('comun.actions.search')}>
							<Button type='submit' variant='outlined' sx={{ height: '40px' }}>
								<SearchIcon />
							</Button>
						</Tooltip>
					</Box>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
