export const assignFilter = (data, filter) => {
    data.orderDirection = filter.orderDirection
    data.orderField = filter.orderField
    data.pageNo = filter.pageNo
    data.pageSize = filter.pageSize
    return data;
}

export function isContainRequiredWord(initialString, requiredWord) {
	return initialString.toLowerCase().includes("-"+requiredWord.toLowerCase())
}

export function getChangedString (baseString, cutNumber) {
    if (baseString.length > cutNumber) {
        let shortString = baseString.slice(0, cutNumber)
        shortString = shortString + '...'

        return shortString
    }
    
    return baseString
}

export function getBounds(gpsLocations) {
    let maxLat, minLat, maxLng, minLng
    maxLat = Math.max(...gpsLocations.map(gps => gps.lat))
    minLat = Math.min(...gpsLocations.map(gps => gps.lat))
    maxLng = Math.max(...gpsLocations.map(gps => gps.lon))
    minLng = Math.min(...gpsLocations.map(gps => gps.lon))
    return [[maxLat, minLng],[minLat, maxLng]]
}