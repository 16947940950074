import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import AlertViewContainer from '../components/alerts/alert_view/AlertViewContainer'
import ErrorBoundary from '../components/ErrorBoundary'

const AlertsPage = ({ location: { state } }) => {
	const history = useHistory()

	return (
		<ErrorBoundary history={history}>
			<AlertViewContainer state={state} />
		</ErrorBoundary>
	)
}

export default AlertsPage
