import Box from '@mui/material/Box'
import * as React from 'react'
import { useUserProfileRequest } from '../../../services/usersprofile/useUserProfileRequest'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import UsersProfileForm from './UsersProfileForm'

export default function UsersProfileContainer() {
	const { userProfileRequest, isLoading, userProfileData } = useUserProfileRequest()

	React.useEffect(() => {
		userProfileRequest()
	}, [])

	return (
		<Box component='main'>
			<BackdropComponent open={isLoading} />
			{userProfileData && <UsersProfileForm userProfile={userProfileData}></UsersProfileForm>}
		</Box>
	)
}
