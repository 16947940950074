import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useTimerProjectInfo = () => {
	const [timerProjectInfo, setTimerProjectInfo] = useState(null)
	const [errorTimerProjectInfo, setErrorTimerProjectInfo] = useState(null)
	const [isLoadingTimerProjectInfo, setIsLoadingTimerProjectInfo] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerProjectInfoRequest = useCallback(
		(projectId) => {
			client
				.get(baseUrl + 'horus/f3/timer/projects/n2?ProjectId=' + projectId)
				.then((result) => {
					setIsLoadingTimerProjectInfo(false)
					setTimerProjectInfo(result.data.data)
					if (result.data.success) {
						setTimerProjectInfo(result.data.data)
					} else {
						setErrorTimerProjectInfo({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingTimerProjectInfo(false)
					setErrorTimerProjectInfo(err.response)
				})

			setTimerProjectInfo(null)
			setErrorTimerProjectInfo(null)
			setIsLoadingTimerProjectInfo(true)
		},
		[client]
	)
	return {
		timerProjectInfoRequest,
		isLoadingTimerProjectInfo,
		timerProjectInfo,
		errorTimerProjectInfo,
	}
}
