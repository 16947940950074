import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useMeasurementInsert = () => {
	const [measureInsertData, setMeasureInsertData] = useState(null)
	const [measureInsertError, setMeasureInsertError] = useState(null)
	const [isLoadingMeasureInsert, setIsLoadingMeasureInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const measureInsert = useCallback(
		(data) => {
			client
				.post(baseUrl + 'horus/f3/project/item/measure', data)
				.then((result) => {
					setIsLoadingMeasureInsert(false)
					if (result.data.success) {
						setMeasureInsertData(result.data.data)
					} else {
						setMeasureInsertError({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setIsLoadingMeasureInsert(false)
					setMeasureInsertError(error.response)
				})
			setIsLoadingMeasureInsert(true)
			setMeasureInsertData(null)
			setMeasureInsertError(null)
		},
		[client]
	)

	return {
		measureInsert,
		measureInsertData,
		measureInsertError,
		isLoadingMeasureInsert,
	}
}
