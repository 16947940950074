import TableRow from '@mui/material/TableRow'
import TablePaginationComponent from '../comun/TablePaginationComponent'
import { TableFooter } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import { EnhancedTableHead, TimerTableCell, getComparator, stableSort } from '../comun/TableUtils'
import { StyledTableRow } from '../comun/StyleComponentUtils'

export default function TimerUsersTableModalInfo({
    timerUsersInfo,
    filter,
    onFilter
}) {
	const [t] = useTranslation('common')

	//This block should be the same in every table component ----------------------------------
	const [order, setOrder] = React.useState(filter.orderDirection)
	const [orderBy, setOrderBy] = React.useState(filter.orderField)
	const [page, setPage] = React.useState(0)
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(filter.pageSize)

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------
	
	React.useEffect(() => {
        if (timerUsersInfo) {
            setPage(timerUsersInfo.pageNo - 1)
            setTotalCount(timerUsersInfo.totalCount)
        }
    }, [timerUsersInfo])

	const columnas = [
		{
			id: 'activity',
			orderBy: true,
			label: t('timer.table.header.activity'),
		},
        {
			id: 'time',
			orderBy: true,
			label: t('timer.table.header.time'),
            excluirExcel: true,
		}
	]

	return (
		<TableContainer sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
			<Table
				id='table-timer-users-form-grouped'
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					headCells={columnas}
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
				/>
				<TableBody>
					{timerUsersInfo ?
						stableSort(timerUsersInfo.data, getComparator(order, orderBy)).map((row, index) => {
							return (
								<React.Fragment  key={ index }>
									<StyledTableRow>
										<TableCell align='center' sx={{ minWidth: 100 }}>{row.activity}</TableCell>
										<TimerTableCell timerType={row.time}/>
									</StyledTableRow>
								</React.Fragment>
							)
						})
					: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
