import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../../services/ApiClientProvider'

export const useProjectItemInsert = () => {
	const [dataProjectItemInsert, setDataProjectItemInsert] = useState(null)
	const [errorProjectItemInsert, setErrorProjectItemInsert] = useState(null)
	const [isLoadingProjectItemInsert, setIsLoadingProjectItemInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/project/item', props)
				.then((result) => {
					setIsLoadingProjectItemInsert(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemInsert(result.data.data)
					} else {
						setErrorProjectItemInsert({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemInsert(error.response)
					setIsLoadingProjectItemInsert(false)
				})

			setIsLoadingProjectItemInsert(true)
			setDataProjectItemInsert(null)
			setErrorProjectItemInsert(null)
		},
		[client]
	)

	return {
		projectItemInsert,
		dataProjectItemInsert,
		errorProjectItemInsert,
		isLoadingProjectItemInsert,
	}
}
