import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserProfileUpdate = () => {
	const [userProfileDataUpdate, setUserProfileDataUpdate] = useState(null)
	const [errorUserProfileUpdate, setErrorUserProfileUpdate] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const userProfileUpdate = useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/user/profile', props)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setUserProfileDataUpdate({ data: result.data })
					} else {
						setErrorUserProfileUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorUserProfileUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setUserProfileDataUpdate(null)
			setErrorUserProfileUpdate(null)
		},
		[client]
	)

	return {
		userProfileUpdate,
		isLoadingUpdate,
		userProfileDataUpdate,
		setUserProfileDataUpdate,
		errorUserProfileUpdate,
		setErrorUserProfileUpdate,
	}
}
