import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../comun/BasicDialog'
import TimerUsersFilterModal from './TimerUsersFilterModal'
import TimerUsersTableModalInfo from './TimerUsersTableModalInfo'
import TimerUsersTableModalDetails from './TimerUsersTableModalDetails'
import { Divider } from '@mui/material'

export default function TimerUsersForm({ 
    rowSelected,
    timerUsersInfo,
    filter,
	open, 
	onClose,
    onFilterForm,
    timerUsersDetails,
    activitiesOptions,
    radioValue,
    setRadioValue,
    initialRadio,
    alternativeRadio,
    parseFilterInitial,
    timerUsersInfoRequest,
    timerUsersDetailsRequest,
    isLoadingTimerUsersDetails,
    isLoadingTimerUsersInfo
}) {
	const [t] = useTranslation('common')

	return (
        <Box>
			{open ? (
				<Box component='form'>
					<BasicDialog
						title={t('timer.form.title-user') + `'${rowSelected.userName}'`}
						onOpen={open}
						onClose={onClose}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 1050, minHeight: 470 }}>  
                            <Box sx={{ width: '100%' }}>
                                <TimerUsersFilterModal
                                    activitiesOptions={activitiesOptions}
                                    onFilter={onFilterForm}
                                    filter={filter}
                                    rowSelected={rowSelected}
                                    radioValue={radioValue}
                                    setRadioValue={setRadioValue}
                                    initialRadio={initialRadio}
                                    alternativeRadio={alternativeRadio}
                                    parseFilterInitial={parseFilterInitial}
                                    timerUsersInfoRequest={timerUsersInfoRequest}
				                    timerUsersDetailsRequest={timerUsersDetailsRequest}
                                    isLoadingTimerUsersDetails={isLoadingTimerUsersDetails}
				                    isLoadingTimerUsersInfo={isLoadingTimerUsersInfo}
                                />
                            </Box> 

                            <Divider sx={{ my: 2 }} variant='middle' />

                            {radioValue == initialRadio ?
                                <TimerUsersTableModalInfo
                                    timerUsersInfo={timerUsersInfo}
                                    onFilter={onFilterForm}
                                    filter={filter}
                                />
                            :
                                <TimerUsersTableModalDetails
                                    timerUsersDetails={timerUsersDetails}
                                    filter={filter}
                                    onFilter={onFilterForm}
                                />
                            }
                        </Box>
                    </BasicDialog >
				</Box >
			) : null}
		</Box>
	)
}
