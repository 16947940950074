import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const use7DayMapInfo = () => {
	const [dataTrack, setDataTrack] = useState(null)
	const [errorTrack, setErrorTrack] = useState(null)
	const [isLoadingTrack, setIsLoadingTrack] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const get7DayMapInfo = useCallback(
		(vesselApiId, date) => {
			let url = 'horus/f3/dashboard/preview/view/map7day?vesselApiId=' + vesselApiId + '&date=' + date
			client
				.get(baseUrl + url)
				.then((result) => {
					setIsLoadingTrack(false)
					if (result.data.success) {
						setDataTrack(result.data.data)
					} else {
						setErrorTrack({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingTrack(false)
					setErrorTrack(err.response)
				})

			setDataTrack(null)
			setErrorTrack(null)
			setIsLoadingTrack(true)
		},
		[client]
	)
	return {
		get7DayMapInfo,
		isLoadingTrack,
		dataTrack,
		errorTrack,
		setDataTrack,
	}
}
