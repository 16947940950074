import { useCallback, useState } from 'react'
import { useContext } from 'react'

import { datetimeGMTToLocaleInISOFormat } from '../../components/comun/DateUtils'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useChatInfo = () => {
	const [chatInfo, setChatInfo] = useState(null)
	const [errorChat, setErrorChat] = useState(null)
	const [isLoadingChat, setIsLoadingChat] = useState(false)
	const [noMoreMessages, setNoMoreMessages] = useState(false)
	const { client } = useContext(ApiClientContext)

	const getChatInfo = useCallback(
		(vesselApiId, formerDate, lastDate) => {
			let params =
				'?vesselApiId=' +
				vesselApiId +
				(formerDate ? '&formerDate=' + formerDate : '') +
				(lastDate ? '&lastDate=' + datetimeGMTToLocaleInISOFormat(lastDate) : '')
			client
				.get(baseUrl + 'horus/f3/chat/vessel' + params)
				.then((result) => {
					setIsLoadingChat(false)
					if (result.data.success) {
						setChatInfo(result.data.data)
						if (result.data.data.length == 0 && formerDate != null) {
							setNoMoreMessages(true)
						} else {
							setNoMoreMessages(false)
						}
					} else {
						setErrorChat({ statusText: result.data.errorMsg })
						setNoMoreMessages(false)
					}
				})
				.catch((err) => {
					setIsLoadingChat(false)
					setErrorChat(err.response)
					setNoMoreMessages(false)
				})
			setChatInfo(null)
			setErrorChat(null)
			setIsLoadingChat(true)
			setNoMoreMessages(false)
		},
		[client]
	)
	return {
		getChatInfo,
		isLoadingChat,
		chatInfo,
		errorChat,
		setChatInfo,
		noMoreMessages,
	}
}
