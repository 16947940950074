import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../../services/ApiClientProvider'

export const useProjectItemDelete = () => {
	const [dataProjectItemDelete, setDataProjectItemDelete] = useState(null)
	const [errorProjectItemDelete, setErrorProjectItemDelete] = useState(null)
	const [isLoadingProjectItemDelete, setIsLoadingProjectItemDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemDelete = useCallback(
		(projectItemId) => {
			client
				.delete(baseUrl + 'horus/f3/project/item?projectItemId=' + projectItemId)
				.then((result) => {
					setIsLoadingProjectItemDelete(false)
					setDataProjectItemDelete(result.data.data)
					if (result.data.success) {
						setDataProjectItemDelete(projectItemId)
					} else {
						setErrorProjectItemDelete({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemDelete(error.response)
					setIsLoadingProjectItemDelete(false)
				})

			setIsLoadingProjectItemDelete(true)
			setDataProjectItemDelete(null)
			setErrorProjectItemDelete(null)
		},
		[client]
	)

	return {
		projectItemDelete,
		dataProjectItemDelete,
		errorProjectItemDelete,
		isLoadingProjectItemDelete,
	}
}
