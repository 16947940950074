import { Visibility, VisibilityOff } from '@mui/icons-material'
import SettingsIcon from '@mui/icons-material/Settings'
import { Box, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import ChartLayerConfigForm from './ChartLayerConfigForm'

export default function ChartLayerConfig({
	layer,
	handleChangeHidden,
	updateLayersConfig,
	setLayers,
	layers,
	layerIdx,
	isLiveViewSelected,
}) {
	const [anchorEl, setAnchorEl] = useState(null)
	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined
	const handleOpenConfig = (e) => {
		setAnchorEl(e.currentTarget)
	}
	const handleClose = () => {
		setAnchorEl(null)
	}
	return (
		<Box sx={{ display: 'flex', p: 0, width: '100%', alignItems: 'center', height: '25%' }}>
			<Box
				sx={{
					display: 'flex',
					height: '100%',
					mr: 0.5,
					p: 0,
					ml: '10%',
					alignItems: 'center',
				}}>
				<IconButton
					disabled={isLiveViewSelected}
					onClick={() => handleChangeHidden(layer)}
					sx={{ p: 0, pr: 0.5, height: '30px', width: '40%' }}>
					{layer.isHidden ? (
						<VisibilityOff sx={{ height: '80%' }} />
					) : (
						<Visibility color={isLiveViewSelected ? 'disabled' : 'primary'} sx={{ height: '80%' }} />
					)}
				</IconButton>
				<IconButton
					disabled={isLiveViewSelected}
					onClick={handleOpenConfig}
					aria-describedby={id}
					color={id != null ? 'primary' : 'default'}
					sx={{ p: 0, width: '40%' }}>
					<SettingsIcon sx={{ height: '80%' }} />
				</IconButton>
				<Typography sx={{ width: '20%', pl: 1 }}>{layer.layer}</Typography>
			</Box>

			<ChartLayerConfigForm
				layerIdx={layerIdx}
				setLayers={setLayers}
				layers={layers}
				id={id}
				layer={layer}
				open={open}
				anchorEl={anchorEl}
				handleClose={handleClose}
				updateLayersConfig={updateLayersConfig}
			/>
		</Box>
	)
}
