import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { dateDiffInSeconds, isStartDateBefore } from '../../comun/DateUtils'
import useStore from '../PreviewStore'
import { useShallow } from 'zustand/shallow'

export default function VerticalTimeline({ timeData: { currentRangeEnd, currentRangeStart, infoRangeMillis } }) {
	const [progress, setProgress] = useState('0%')
	const { currentTime, pause, setCurrentTime } = useStore(
		useShallow((state) => ({
			currentTime: state.currentTime,
			pause: state.pause,
			setCurrentTime: state.setCurrentTime,
		}))
	)

	useEffect(() => {
		if (currentTime) {
			let offset = dateDiffInSeconds(currentRangeStart, currentTime)
			calculateProgress(offset, infoRangeMillis / 1000)
			if (dateDiffInSeconds(currentTime, currentRangeEnd) <= 0.5) {
				//PAUSE when current time reaches the end of the range
				pause()
			} else if (new Date(currentTime) > new Date(currentRangeEnd)) {
				pause()
				setCurrentTime(currentRangeEnd)
			}
		}
	}, [currentTime])

	const calculateProgress = (current, total) => {
		let p = (current / total) * 100
		if (p > 100) {
			setProgress('0%')
		} else {
			setProgress(p + '%')
		}
	}

	return (
		<Box sx={{ display: 'flex', width: '100%', height: '91%', position: 'absolute', top: 0, zIndex: -10 }}>
			<Box
				sx={{
					height: '100%',
					position: 'absolute',
					top: 0,
					left: progress,
					display: 'flex',
					flexDirection: 'column',
				}}>
				<Box
					sx={{
						width: '2px',
						height: '100%',
						bgcolor: '#505050ab',
					}}
				/>
			</Box>
		</Box>
	)
}
