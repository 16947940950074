import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import { Avatar, Box, Typography } from '@mui/material'
import React, { useEffect, useRef } from 'react'
import backgroundChat from '../../assets/backgroundChat.avif'
import sateliteIcon from '../../assets/satelite.png'
import vesselIcon from '../../assets/vessel.png'
import { datetimeUTCtoString } from '../comun/DateUtils'
import MessageBox from './MessageBox'
import './Messenger.css'
import { useTranslation } from 'react-i18next'

export default function Chat({ chat, handleSendMessage, chatHistory, isLoading, setReachedTop, noMoreMessages }) {
	const messageContainer = useRef(null)
	const [t] = useTranslation('common')

	useEffect(() => {
		if (messageContainer?.current) {
			messageContainer.current.addEventListener('scroll', onScrollHitTop);
		}
	  
		return () => {
			if (messageContainer?.current) {
				messageContainer.current.removeEventListener('scroll', onScrollHitTop);
		  	}
		};
	}, []);

	const onScrollHitTop = (e) => {
		if (messageContainer?.current) {
			if (e.target?.scrollHeight - (e.target?.scrollTop * -1 + e.target?.clientHeight) <= 2) {
				setReachedTop(true);
		  	} else {
				setReachedTop(false);
		  	}
		}
	}

	const getTickStatus = (status) => {
		switch (status) {
			case 1:
				return <DoneIcon sx={{ width: '16px', height: '16px', color: 'green' }} />
			case 2:
				return <DoneAllIcon sx={{ width: '16px', height: '16px', color: 'green' }} />
			case 3:
				return <AccessTimeIcon sx={{ width: '16px', height: '16px', color: 'grey' }} />
			default:
				return null
		}
	}

	const Message = ({ m }) => {
		return (
			<Box
				sx={{
					p: 1,
					my: 1,
					overflowAnchor: 'none',
					alignSelf: m.src == 0 ? 'end' : 'start',
					justifyContent: m.src == 0 ? 'end' : 'start',
					display: 'flex',
					width: '60%',
					flexDirection: 'row',
				}}>
				{m.src != 0 && (
					<Avatar sx={{ width: '32px', height: '32px' }}>
						<img src={vesselIcon} width='20px' height='20px' />
					</Avatar>
				)}
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						mr: m.src == 0 && 1,
						ml: m.src != 0 && 1,
					}}>
					<Typography
						sx={{
							borderRadius: m.src == 0 ? '10px 0px 10px 10px' : '0px 10px 10px 10px',
							backgroundColor: m.src == 0 ? '#dceeff' : 'white',
							padding: '5px 10px 5px 12px',
							boxShadow: 'rgb(0 0 0 / 25%) 0px 2px 5px 2px',
							overflowWrap: 'anywhere',
							alignSelf: m.src == 0 ? 'end' : 'start',
						}}>
						{m.msg}
					</Typography>
					<Box sx={{ alignSelf: m.src == 0 ? 'end' : 'start', display: 'flex', alignItems: 'center' }}>
						<Typography variant='overline' color='#646464' sx={{ fontSize: '11px', mr: 0.5 }}>
							{datetimeUTCtoString(m.dt, false, true)}
						</Typography>
						{m.src == 0 && getTickStatus(m.st)}
					</Box>
				</Box>
				{m.src == 0 && (
					<Avatar sx={{ width: '32px', height: '32px' }}>
						<img src={sateliteIcon} width='20px' height='20px' />
					</Avatar>
				)}
			</Box>
		)
	}
	return (
		<Box sx={{ width: '70%', minHeight: '100%', display: 'flex', flexDirection: 'column' }}>
			<Box
				sx={{
					backgroundColor: '#9cc5ee',
					minHeight: '8%',
					borderRadius: 1,
					alignItems: 'center',
					border: '1px solid #1976d2',
				}}>
				<Typography sx={{ ml: 3, mt: 1, lineHeight: 2.7 }}> {chat?.name}</Typography>
			</Box>
			<Box
				ref={messageContainer}
				sx={{
					backgroundImage: `url(${backgroundChat})`,
					backgroundSize: '100% 100%',
					backgroundColor: '#9cc5ee',
					backgroundBlendMode: 'overlay',
					overflowY: 'auto',
					minHeight: '87.5%',
					maxHeight: '87.5%',
					borderRadius: 1,
					display: 'flex',
					flexDirection: 'column-reverse',
					p: 2,
				}}>
				{chatHistory
					?.slice(0)
					.reverse()
					.map((m) => {
						return <Message key={m.dt} m={m} />
					})}
				{noMoreMessages && (
					<Box
						sx={{
							width: '50%',
							alignSelf: 'center',
							backgroundColor: '#dceeff',
							borderRadius: '10px',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 5px 2px',
						}}>
						<Typography>{t('messenger.no-more-messages')}</Typography>
					</Box>
				)}
			</Box>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<MessageBox chat={chat} isLoading={isLoading} handleSendMessage={handleSendMessage} />
			</Box>
		</Box>
	)
}
