import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import FolderIcon from '@mui/icons-material/Folder';
import GroupIcon from '@mui/icons-material/Group';
import InventoryRoundedIcon from '@mui/icons-material/InventoryRounded';
import MapIcon from '@mui/icons-material/Map';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import ChatIcon from '@mui/icons-material/Chat'
import { Collapse, List, ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { appRoutes, rolesCookieName } from '../../constants';
import { readCookie } from '../../utils/cookie';
import { hasAdminRole, hasEMAdminRole, hasEMStaffRole, hasFleetAdminRole, hasFleetStaffRole } from '../../utils/roles';


export default function MenuOptions({ t, open, setOpen }) {

	const [openAlerts, setOpenAlerts] = useState(false)
	const [openTimer, setOpenTimer] = useState(false)
	const [roles, setRoles] = useState([])
	const cookieRoles = readCookie(rolesCookieName)

	React.useEffect(() => {

		setRoles(cookieRoles.split(","));

	}, [cookieRoles])


	const handleAlertClick = () => {
		setOpenAlerts(!openAlerts)
	}

	const handleTimerClick = () => {
		setOpenTimer(!openTimer)
	}

	const handleCloseDrawer = () => {
		setOpen(false)
	}

	return (
		<div>
			{hasFleetAdminRole(roles) || hasFleetStaffRole(roles) ?
				<>

					<ListItemButton component={Link} to={appRoutes.HOME} onClick={handleCloseDrawer}>
						<ListItemIcon>
							<DashboardIcon />
						</ListItemIcon>
						<ListItemText primary={t('menu.dashboard')} />
					</ListItemButton>
					<ListItemButton component={Link} to={appRoutes.MESSENGER_PAGE} onClick={handleCloseDrawer}>
						<ListItemIcon>
							<ChatIcon />
						</ListItemIcon>
						<ListItemText primary={t('menu.messenger')} />
					</ListItemButton>
					<ListItemButton onClick={handleAlertClick}>
						<ListItemIcon>
							<NotificationsIcon />
						</ListItemIcon>
						<ListItemText primary={t('menu.alerts')} />
						{openAlerts ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>
					<Collapse in={openAlerts && open} timeout='auto'>
						<List component='div' disablePadding>
							<ListItemButton sx={{ pl: 4 }} component={Link} to={appRoutes.ALERTS} onClick={handleCloseDrawer}>
								<ListItemIcon sx={{ minWidth: '25px', pr: 3 }}>
									<NotificationsIcon />
								</ListItemIcon>
								<ListItemText primary={t('menu.vessel-alerts')} />
							</ListItemButton>
							{hasFleetAdminRole(roles) ? 
								<>
								<ListItemButton
									sx={{ pl: 4 }}
									component={Link}
									to={appRoutes.ALERTS_CONFIG}
									onClick={handleCloseDrawer}>
									<ListItemIcon sx={{ minWidth: '25px', pr: 3 }}>
										<EditNotificationsIcon />
									</ListItemIcon>
									<ListItemText primary={t('menu.alert-config')} />
								</ListItemButton>
								<ListItemButton
									sx={{ pl: 4 }}
									component={Link}
									to={appRoutes.ALERT_ZONES}
									onClick={handleCloseDrawer}>
									<ListItemIcon sx={{ minWidth: '25px', pr: 3 }}>
										<MapIcon />
									</ListItemIcon>
									<ListItemText primary={t('menu.alert-zones')} />
								</ListItemButton>
							</> : null
							}		
						</List>
					</Collapse>
				</> : null}

			{hasEMAdminRole(roles) ?
				(<ListItemButton component={Link} to={appRoutes.PLANIFICATIONS} onClick={handleCloseDrawer}>
					<ListItemIcon>
						<InventoryRoundedIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu.planifications')} />
				</ListItemButton>)
				: null
			}

			{hasEMAdminRole(roles) || hasEMStaffRole(roles) ?
				(< ListItemButton component={Link} to={appRoutes.PROJECTS} onClick={handleCloseDrawer}>
					<ListItemIcon>
						<FolderIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu.projects')} />
				</ListItemButton>)
				: null
			}

			{hasAdminRole(roles) ?
				(<ListItemButton component={Link} to={appRoutes.USERS_MANAGEMENT} onClick={handleCloseDrawer}>
					<ListItemIcon>
						<GroupIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu.users')} />
				</ListItemButton>
				)
				: null
			}

			{hasAdminRole(roles) ?
				(<>
					<ListItemButton onClick={handleTimerClick}>
						<ListItemIcon>
							<AccessTimeFilledIcon />
						</ListItemIcon>
						<ListItemText primary={t('menu.timer')} />
						{openTimer ? <ExpandLess /> : <ExpandMore />}
					</ListItemButton>

					<Collapse in={openTimer && open} timeout='auto'>
						<List component='div' disablePadding>
							<ListItemButton
								sx={{ pl: 4 }}
								component={Link}
								to={appRoutes.TIMER_USERS}
								onClick={handleCloseDrawer}
							>
								<ListItemIcon sx={{ minWidth: '25px', pr: 3 }}>
									<AccessTimeIcon />
								</ListItemIcon>
								<ListItemText primary={t('menu.timer-users')} />
							</ListItemButton>
							<ListItemButton
								sx={{ pl: 4 }}
								component={Link}
								to={appRoutes.TIMER_PROJECTS}
								onClick={handleCloseDrawer}
							>
								<ListItemIcon sx={{ minWidth: '25px', pr: 3 }}>
									<AccessTimeIcon />
								</ListItemIcon>
								<ListItemText primary={t('menu.timer-projects')} />
							</ListItemButton>
						</List>
					</Collapse>
				</>
				) : null
			}

			{hasAdminRole(roles) ?
				(<ListItemButton component={Link} to={appRoutes.DOWNLOADS} onClick={handleCloseDrawer}>
					<ListItemIcon>
						<CloudDownloadIcon />
					</ListItemIcon>
					<ListItemText primary={t('menu.downloads')} />
				</ListItemButton>
				)
				: null
			}
		</div >
	)
}
