import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useConfigAlertDelete = () => {
	const [configAlertDataDelete, setConfigAlertDataDelete] = useState(null)
	const [errorConfigAlertDelete, setErrorConfigAlertDelete] = useState(null)
	const [isLoadingDelete, setIsLoadingDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const configAlertDelete = useCallback(
		(id) => {
			const config = {
				params: {
					id: id,
				},
			}

			client
				.delete(baseUrl + 'horus/f3/alert/config', config)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setConfigAlertDataDelete({ data: result.data })
					} else {
						setErrorConfigAlertDelete({ statusText: result.data.errorMsg })
					}
					setIsLoadingDelete(false)
				})
				.catch((error) => {
					setErrorConfigAlertDelete(error.response)
					setIsLoadingDelete(false)
				})

			setConfigAlertDataDelete(null)
			setErrorConfigAlertDelete(null)
			setIsLoadingDelete(true)
		},
		[client]
	)

	return {
		configAlertDelete,
		isLoadingDelete,
		configAlertDataDelete,
		setConfigAlertDataDelete,
		errorConfigAlertDelete,
		setErrorConfigAlertDelete,
	}
}
