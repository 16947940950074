import { useIdleTimer } from 'react-idle-timer'

import { useUserLog } from '../services/timer/useUserLog'

import * as React from 'react'
import { useHistory } from 'react-router-dom'
import { ApiClientContext } from '../services/ApiClientProvider'
import { getInactiveDataUser, getRestartActivityDataUser } from './TimerDataUtils'

const IdleTimer = ({ timeoutValue, setOpenInactivity }) => {
	const history = useHistory()
	const { userLogRequest, errorUserLog } = useUserLog()
	const { setTokenValue } = React.useContext(ApiClientContext)

	const onIdle = () => {
		setOpenInactivity(true)
		userLogRequest(getInactiveDataUser())
	}

	const onActive = () => {
		userLogRequest(getRestartActivityDataUser())
	}

	const onAction = () => {
		// console.log("user action...");
	}

	const onPrompt = () => {
		//  console.log("prompt action...");
	}

	const { timer } = useIdleTimer({
		onPrompt,
		onIdle,
		onActive,
		onAction,
		timeout: timeoutValue,
		promptTimeout: 0,
		events: [
			'mousemove',
			'keydown',
			'wheel',
			'DOMMouseScroll',
			'mousewheel',
			'mousedown',
			'touchstart',
			'touchmove',
			'MSPointerDown',
			'MSPointerMove',
			'visibilitychange',
		],
		immediateEvents: [],
		debounce: 0,
		throttle: 0,
		eventsThrottle: 200,
		element: document,
		startOnMount: true,
		startManually: false,
		stopOnIdle: false,
		crossTab: false,
		emitOnAllTabs: false,
	})

	return <div></div>
}

export default IdleTimer
