import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../../services/ApiClientProvider'

export const useProjectItemCountRequest = () => {
	const [dataProjectItemCountRequest, setDataProjectItemCountRequest] = useState(null)
	const [errorProjectItemCountRequest, setErrorProjectItemCountRequest] = useState(null)
	const [isLoadingProjectItemCountRequest, setIsLoadingProjectItemCountRequest] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemCountRequest = useCallback(
		(props) => {
			const config = {
				params: {
					projectItemId: props,
				},
			}
			client
				.get(baseUrl + 'horus/f3/project/item/count', config)
				.then((result) => {
					setIsLoadingProjectItemCountRequest(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemCountRequest(result.data.data)
					} else {
						setErrorProjectItemCountRequest({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemCountRequest(error.response)
					setIsLoadingProjectItemCountRequest(false)
				})

			setIsLoadingProjectItemCountRequest(true)
			setDataProjectItemCountRequest(null)
			setErrorProjectItemCountRequest(null)
		},
		[client]
	)

	return {
		projectItemCountRequest,
		dataProjectItemCountRequest,
		errorProjectItemCountRequest,
		isLoadingProjectItemCountRequest,
	}
}
