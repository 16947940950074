import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserClearCache = () => {
	const [userDataClear, setUserDataClear] = useState(null)
	const [errorUserClear, setErrorUserClear] = useState(null)
	const [isLoadingUserClear, setIsLoadingUserClear] = useState(false)

	const { client } = useContext(ApiClientContext)

	const userClearCache = useCallback(
		(userName) => {
			client
				.put(baseUrl + 'horus/f3/user/clear?userName=' + userName)
				.then((result) => {
					setIsLoadingUserClear(false)
					if (result.status === 200 && result.data.success) {
						setUserDataClear({ data: result.data })
					} else {
						setErrorUserClear({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorUserClear(error.response)
					setIsLoadingUserClear(false)
				})

			setIsLoadingUserClear(true)
			setUserDataClear(null)
			setErrorUserClear(null)
		},
		[client]
	)

	return {
		userClearCache,
		isLoadingUserClear,
		userDataClear,
		errorUserClear,
		setErrorUserClear,
	}
}
