import { IconButton, Tooltip, Typography } from '@mui/material'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Box from '@mui/material/Box'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { datetimeUTCtoString } from '../comun/DateUtils'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import { EnhancedTableHead, getComparator, stableSort } from '../comun/TableUtils'

export default function ListNotesTable({ listNotesData, handleListNote }) {
	const [t] = useTranslation('common')
	const [order, setOrder] = React.useState('desc')
	const [orderBy, setOrderBy] = React.useState('date')

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const columnas = [
		{
			id: 'date',
			orderBy: true,
			align: 'left',
			label: t('project.list-note.table.header.date'),
		},
		{
			id: 'camera',
			orderBy: true,
			align: 'left',
			label: t('project.list-note.table.header.camera'),
		},
		{
			id: 'itemTypeDesc',
			orderBy: true,
			align: 'left',
			label: t('project.list-note.table.header.type-desc'),
		},
		{
			id: 'notes',
			orderBy: false,
			align: 'left',
			label: t('project.list-note.table.header.declaration'),
		},
		{
			id: 'comments',
			orderBy: false,
			align: 'left',
			label: t('project.list-note.table.header.comments'),
		},
		{
			id: 'computedData',
			orderBy: false,
			align: 'left',
			label: t('project.list-note.table.header.computed-data'),
		},
		{
			id: 'actions',
			align: 'center',
			label: t('project.list-note.table.header.actions'),
			excluirExcel: true,
		},
	]

	const getCellLines = (array) => {
		return (
			<>
				{array &&
					array.map((item, idx) => {
						return (
							<Box key={idx} sx={{ display: 'table' }}>
								<Typography sx={{ pl: 1 }} variant='body'>
									{item}
								</Typography>
							</Box>
						)
					})}
			</>
		)
	}

	return (
		<TableContainer
			sx={{
				overflowY: 'auto',
				minHeight: '50vh',
				maxHeight: '50vh',
			}}>
			<Table
				id='table-list-notes-list'
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
				/>
				<TableBody>
					{listNotesData
						? stableSort(listNotesData, getComparator(order, orderBy)).map((row, index) => {
								return (
									<StyledTableRow key={index}>
										<TableCell align='left'>{datetimeUTCtoString(row.date)}</TableCell>
										<TableCell align='left'>{row.camera}</TableCell>
										<TableCell align='left'>{row.itemTypeDesc}</TableCell>
										<TableCell align='left'>{getCellLines(row.notes)}</TableCell>
										<TableCell align='left'>{getCellLines(row.comments)}</TableCell>
										<TableCell align='left'>{getCellLines(row.computedData)}</TableCell>
										<TableCell align='center'>
											<Tooltip title={t('project.list-note.table.video-project')}>
												<IconButton color='primary' onClick={(e) => handleListNote(e, row)}>
													<VideoCallIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</StyledTableRow>
								)
						  })
						: null}
				</TableBody>
			</Table>
		</TableContainer>
	)
}
