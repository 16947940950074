import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import { Box, Button, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useShallow } from 'zustand/shallow'
import {
	addSeconds,
	dateDiffInSecondsAux,
	datetimeGMTToLocaleInISOFormat,
	datetimeUTCtoString,
} from '../../comun/DateUtils'
import useStore from '../PreviewStore'
import TimeRangeSelector from './TimeRangeSelector'

function TimeControl({ updateTimeRange, vesselApiId, timeData, isLiveViewSelected }) {
	const [step, setStep] = useState({ width: '0%', position: { x: 0, y: 0 }, jumpWidth: 0, stepNum: 0 })
	const [steps, setSteps] = useState([])
	const [startDrag, setStartDrag] = useState(0)
	const scrollbarRef = useRef(null)
	const [isScrolling, setIsScrolling] = useState(false)
	const thumbsRef = useRef(null)
	const { setJumpRequested } = useStore(
		useShallow((state) => ({
			setJumpRequested: state.setJumpRequested,
		}))
	)

	useEffect(() => {
		if (timeData) {
			let shiftSeconds = timeData.infoRangeShiftMillis / 1000
			let rangeSeconds = timeData.infoRangeMillis / 1000
			let startStep = addSeconds(timeData.nextJump, shiftSeconds * -1)

			calculateStepWidth(startStep, timeData.currentRangeStart, timeData.numJumps, rangeSeconds)
			let auxSteps = []

			for (let i = 0; i < timeData.numJumps; i++) {
				if (i == 0) {
					let startStep = timeData.firstJump
					let endStep = addSeconds(startStep, shiftSeconds)
					auxSteps[i] = { start: startStep, end: endStep }
				} else {
					let startStep = auxSteps[i - 1].end
					let endStep = addSeconds(startStep, shiftSeconds)
					auxSteps[i] = { start: startStep, end: endStep }
				}
			}
			setSteps(auxSteps)
		}
	}, [timeData])

	const calculateStepWidth = (startStep, startWholeRange, numJumps, rangeSeconds) => {
		let diff = dateDiffInSecondsAux(startStep, startWholeRange)
		if (diff < 0) {
			diff = 0
		}
		let positionPercentage = diff / rangeSeconds
		let position = scrollbarRef.current.clientWidth * positionPercentage
		let jumpWidth = numJumps != 0 ? scrollbarRef.current.clientWidth / numJumps : scrollbarRef.current.clientWidth
		let stepNumAux = numJumps != 0 ? (position / jumpWidth).toFixed(0) : '0'
		setStep({
			width: jumpWidth + 'px',
			position: { x: Number(stepNumAux) * jumpWidth, y: 0 },
			jumpWidth: jumpWidth,
			stepNum: stepNumAux,
		})
		setStartDrag(stepNumAux)
	}

	const previousStep = () => {
		onStart(null, step.position)
		onDrag(null, { x: step.position.x - step.jumpWidth, y: 0 })
		onStop(null, { x: step.position.x - step.jumpWidth, y: 0 })
	}

	useEffect(() => {
		if (!isScrolling) {
			if (step.stepNum != startDrag) {
				setJumpRequested(steps[step.stepNum]?.start)
			}
			let pos = step.jumpWidth * step.stepNum
			setStep((prevState) => ({
				...prevState,
				position: { x: pos, y: 0 },
			}))
		}
	}, [step?.stepNum, isScrolling])

	const nextStep = () => {
		onStart(null, step.position)
		onDrag(null, { x: step.position.x + step.jumpWidth, y: 0 })
		onStop(null, { x: step.position.x + step.jumpWidth, y: 0 })
	}

	const previousWindow = () => {
		let startDate = addSeconds(timeData.currentRangeStart, (timeData.infoRangeMillis / 1000) * -1)
		updateTimeRange(
			datetimeGMTToLocaleInISOFormat(startDate),
			timeData.infoRangeMillis,
			timeData.infoRangeShiftMillis
		)
	}

	const nextWindow = () => {
		updateTimeRange(timeData.currentRangeEnd, timeData.infoRangeMillis, timeData.infoRangeShiftMillis)
	}

	const onStart = (_, data) => {
		if (isLiveViewSelected) return
		setStartDrag((data.x / step.jumpWidth).toFixed(0))
	}

	const onStop = () => {
		if (isLiveViewSelected) return
		setIsScrolling(false)
	}

	const onDrag = (_, data) => {
		if (isLiveViewSelected) return
		setIsScrolling(true)
		setStep((prevState) => ({
			...prevState,
			position: { x: data.x, y: 0 },
			stepNum: (data.x / step.jumpWidth).toFixed(0),
		}))
	}

	return (
		<Box sx={{ display: 'flex', width: '100%', height: '2.5vh', mt: 0.25 }}>
			<TimeRangeSelector
				updateTimeRange={updateTimeRange}
				vesselApiId={vesselApiId}
				timeData={timeData}
				isLiveViewSelected={isLiveViewSelected}
			/>
			<Tooltip
				title={
					datetimeUTCtoString(
						addSeconds(timeData.currentRangeStart, (timeData.infoRangeMillis / 1000) * -1)
					) +
					' - ' +
					datetimeUTCtoString(timeData.currentRangeStart)
				}>
				<span style={{ height: '23px', lineHeight: 'normal' }}>
					<Button
						sx={{ height: '100%' }}
						variant='outlined'
						size='small'
						onClick={previousWindow}
						disabled={timeData.currentRangeStart == timeData.firstInfoTime || isLiveViewSelected}>
						<SkipPreviousIcon />
					</Button>
				</span> 
			</Tooltip>
			{/* <Tooltip title={t('preview.time-control.prev-step')}> */}
			<Button
				variant='outlined'
				size='small'
				onClick={previousStep}
				disabled={step.stepNum == 0 || isLiveViewSelected}>
				<NavigateBeforeIcon />
			</Button>
			{/* </Tooltip> */}
			{/* <Tooltip
				title={datetimeUTCtoString(timeData.firstInfoTime) + ' ' + datetimeUTCtoString(timeData.lastInfoTime)}
				placement='bottom-start'
				followCursor> */}
			<Box
				ref={scrollbarRef}
				sx={{
					height: '100%',
					width: '83%',
					backgroundColor: '#1976d252',
					borderRadius: '3px',
					border: '1px solid #1976d2',
					position: 'relative',
				}}>
				<Draggable
					disabled={isLiveViewSelected}
					axis='x'
					bounds='parent'
					position={step.position}
					onStop={onStop}
					onDrag={onDrag}
					onStart={onStart}>
					<Box
						ref={thumbsRef}
						sx={{
							height: '100%',
							width: step.width,
							backgroundColor: isScrolling ? '#05539e' : '#1976d2',
							borderRadius: '3px',
							cursor: isScrolling ? 'grabbing' : 'grab',
							display: 'flex',
							justifyContent: 'center',
						}}></Box>
				</Draggable>

				<Box sx={{ justifyContent: 'space-between', display: 'flex' }}>
					{/* sx={{ fontFamily: 'Orbitron' }} */}
					{/* <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '0.8rem', mr: 3 }}>
							Start {datetimeUTCtoString(steps[step.stepNum]?.start)}
						</Typography> */}
					<Typography sx={{ fontSize: '0.85rem', color: '#1976d2' }}>
						{datetimeUTCtoString(steps[step.stepNum]?.start)}
					</Typography>
					<Typography sx={{ fontSize: '0.85rem', color: '#1976d2' }}>
						{datetimeUTCtoString(steps[step.stepNum]?.end)}
					</Typography>
					{/* <Typography sx={{ color: 'white', fontWeight: 'bold', fontSize: '0.8rem' }}>
							End {datetimeUTCtoString(steps[step.stepNum]?.end)}
						</Typography> */}
				</Box>
			</Box>
			{/* <Tooltip title={t('preview.time-control.next-step')}> */}
			<Button
				variant='outlined'
				size='small'
				onClick={nextStep}
				disabled={step.stepNum == steps.length - 1 || isLiveViewSelected}>
				<NavigateNextIcon />
			</Button>
			{/* </Tooltip> */}
			<Tooltip
				title={
					datetimeUTCtoString(timeData.currentRangeEnd) +
					' - ' +
					datetimeUTCtoString(addSeconds(timeData.currentRangeEnd, timeData.infoRangeMillis / 1000))
				}
				placement='top-start'>
				<span style={{ height: '23px', lineHeight: 'normal' }}>
					<Button
						sx={{ height: '100%' }}
						variant='outlined'
						size='small'
						onClick={nextWindow}
						disabled={timeData.currentRangeEnd == timeData.lastInfoTime || isLiveViewSelected}>
						<SkipNextIcon />
					</Button>
				</span>
			</Tooltip>
		</Box>
	)
}

export default React.memo(TimeControl)
