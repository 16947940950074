import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import TimerProjectsContainer from '../components/timer/TimerProjectsContainer'

const TimerProjectsPage = () => {
	const history = useHistory()

	return (
		<ErrorBoundary history={history}>
			<TimerProjectsContainer></TimerProjectsContainer>
		</ErrorBoundary>
	)
}

export default TimerProjectsPage
