export const readCookie = (name) => document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''

export const setCookie = (name, value, expDate) => {
	const expires = 'expires=' + expDate
	document.cookie = name + '=' + value + '; ' + expires + '; path=/'
}

export const readLocalStorageItem = (name) => window.localStorage.getItem(name)

export const setLocalStorageItem = (name, value) => {
	window.localStorage.setItem(name, value)
}

export const removeLocalStorageItem = (name) => {
	window.localStorage.removeItem(name)
}
