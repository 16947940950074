import Box from '@mui/material/Box'
import React from 'react'
import '../../index.css'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import SearchIcon from '@mui/icons-material/Search'
import Button from '@mui/material/Button'
import { Tooltip } from '@mui/material'
import FieldsetComponent from '../comun/FieldsetComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'

export default function ListNotesFilter({ listNotesOptions, onFilter, filterListNotes }) {
	const { control, setValue, handleSubmit } = useForm({})

	const [t] = useTranslation('common')

	const onSearch = (data) => {
		onFilter(data)
	}

	React.useEffect(() => {
		if (filterListNotes) {
			setValue('cameras', filterListNotes.cameras)
			setValue('types', filterListNotes.types)
			setValue('description', filterListNotes.description)
		}
	}, [filterListNotes])

	return (
		<FieldsetComponent title={t('project.list-note.filter.title')}>
			<Box
				sx={{ display: 'flex', flexDirection: 'column', minHeight: '70px' }}
				component='form'
				onSubmit={handleSubmit(onSearch)}>
				<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
					<AutoCompleteComponent
						multiple
						defaultValue={[]}
						sx={{ width: '100%', m: 1 }}
						control={control}
						name='cameras'
						label={t('project.list-note.filter.cameras')}
						optionsLabel={'option'}
						keyProperty={'val'}
						options={listNotesOptions.cameras}
						evalMethod={(opt, val) => {
							return opt === val
						}}
					/>
					<AutoCompleteComponent
						multiple
						defaultValue={[]}
						sx={{ width: '100%', m: 1 }}
						control={control}
						name='types'
						label={t('project.list-note.filter.types')}
						optionsLabel={'description'}
						keyProperty={'code'}
						options={listNotesOptions.itemTypes}
						evalMethod={(opt, val) => {
							return opt.code === val.code
						}}
					/>
					<TextFieldComponent
						defaultValue={null}
						sx={{ width: '100%', m: 1 }}
						name='description'
						label={t('project.list-note.filter.description')}
						control={control}
					/>
					<Tooltip title={t('comun.actions.search')}>
						<Button type='submit' variant='outlined' sx={{ m: 1, height: '40px' }}>
							<SearchIcon />
						</Button>
					</Tooltip>
				</Box>
			</Box>
		</FieldsetComponent>
	)
}
