import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Tab } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import './SecondaryPanel.css'
import AnnotationTab from './annotation_tab/AnnotationTab'
import FootageTab from './footage_tab/FootageTab'
import MapTab from './map_tab/MapTab'
import NotificacionTab from './notification_tab/NotificationTab'
import { useEffect } from 'react'
import useStore from '../PreviewStore'
import { useShallow } from 'zustand/shallow'

const tabHeight = '91%'

export default function SecondaryPanel({
	visualizationData: {
		annotationsInfo,
		footageInfo,
		mapInfo,
		notificationsInfo,
		isAnnotationsSelected,
		isFootageSelected,
		isMapSelected,
		isNotificationsSelected,
		cameraEnum,
		showKMLs,
	},
	visualizationData,
	updatePreview,
	dataPreview: {
		controlsData: { fishingProfile },
	},
	vesselApiId,
	projectId,
	updateCurrentTime,
}) {
	const [t] = useTranslation('common')
	const [tabValue, setTabValue] = useState(() => {
		if (isFootageSelected) return '0'
		if (isMapSelected) return '1'
		if (isAnnotationsSelected) return '2'
		if (isNotificationsSelected) return '3'
	})

	const { setTabSelected, updatedSecondaryTab, setUpdatedSecondaryTab } = useStore(
		useShallow((state) => ({
			setTabSelected: state.setTabSelected,
			updatedSecondaryTab: state.updatedSecondaryTab,
			setUpdatedSecondaryTab: state.setUpdatedSecondaryTab,
		}))
	)

	const handleChange = (_, newValue) => {
		updatePreview(tabOptions[Number(newValue)].updateUrl)
		setTabValue(newValue)
		setTabSelected(newValue)
	}

	useEffect(() => {
		setTabSelected(tabValue)
	}, [])

	useEffect(() => {
		if (updatedSecondaryTab != null) {
			handleChange(null, updatedSecondaryTab)
			setUpdatedSecondaryTab(null)
		}
	}, [updatedSecondaryTab])

	const tabOptions = [
		{
			id: '0',
			label: t('preview.secondary-panel.tab-footage'),
			updateUrl: 'footage',
			enabledProperty: 'isFootageEnabled',
		},
		{
			id: '1',
			label: t('preview.secondary-panel.tab-map'),
			updateUrl: 'map',
			enabledProperty: 'isMapEnabled',
		},
		{
			id: '2',
			label: t('preview.secondary-panel.tab-annotations'),
			updateUrl: 'annotations',
			enabledProperty: 'isAnnotationsEnabled',
		},
		{
			id: '3',
			label: t('preview.secondary-panel.tab-notifications'),
			updateUrl: 'notifications',
			enabledProperty: 'isNotificationsEnabled',
		},
	]
	return (
		<Box sx={{ height: '100%', width: '45%', ml: 0.5 }}>
			<TabContext value={tabValue ? tabValue : '0'} sx={{ width: '100%' }}>
				<TabList
					centered
					onChange={handleChange}
					sx={{
						width: '100%',
						minHeight: 'auto !important',
						display: 'flex',
						height: '8.05%',
					}}>
					{tabOptions.map(
						(tab) =>
							visualizationData[tab.enabledProperty] && (
								<Tab
									key={tab.id}
									label={tab.label}
									value={tab.id}
									sx={{ minHeight: 'auto !important', height: '100%' }}
								/>
							)
					)}
				</TabList>
				{footageInfo && (
					<TabPanel value={'0'} sx={{ p: 0, height: tabHeight }}>
						<FootageTab
							footageInfo={footageInfo}
							cameraEnum={cameraEnum}
							vesselApiId={vesselApiId}
							projectId={projectId}
						/>
					</TabPanel>
				)}
				{mapInfo && (
					<TabPanel value={'1'} sx={{ p: 0, height: tabHeight, zIndex: 0, position: 'relative' }}>
						<MapTab mapInfo={mapInfo} updateCurrentTime={updateCurrentTime} showKMLs={showKMLs} />
					</TabPanel>
				)}
				{annotationsInfo && (
					<TabPanel value={'2'} sx={{ p: 0, height: tabHeight }}>
						<AnnotationTab
							annotationsInfo={annotationsInfo}
							fishingProfile={fishingProfile}
							cameraEnum={cameraEnum}
							updateCurrentTime={updateCurrentTime}
						/>
					</TabPanel>
				)}
				{notificationsInfo && (
					<TabPanel value={'3'} sx={{ p: 0, height: tabHeight }}>
						<NotificacionTab notificationsInfo={notificationsInfo} updateCurrentTime={updateCurrentTime} />
					</TabPanel>
				)}
				{/* {liveViewInfo && (
					<TabPanel value={'3'} sx={{ p: 0, height: '90%' }}>
						<LiveViewTab liveViewInfo={liveViewInfo} />
					</TabPanel>
				)} */}
			</TabContext>
		</Box>
	)
}
