import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProgramFilters = () => {
	const [planningData, setPlanningData] = useState(null)
	const [errorPlanningData, setErrorPlanningData] = useState(null)
	const [isLoadingPlanning, setIsLoadingPlanning] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const programFilters = useCallback(() => {
		client
			.get(baseUrl + 'horus/f3/program/filter')
			.then((result) => {
				setIsLoadingPlanning(false)
				if (result.data.success) {
					setPlanningData(result.data.data)
				} else {
					setErrorPlanningData({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingPlanning(false)
				setErrorPlanningData(err.response)
			})

		setPlanningData(null)
		setErrorPlanningData(null)
		setIsLoadingPlanning(true)
	}, [client])
	return {
		programFilters,
		isLoadingPlanning,
		planningData,
		errorPlanningData,
	}
}
