import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../../services/ApiClientProvider'

export const useProjectItemCountInsert = () => {
	const [dataProjectItemCountInsert, setDataProjectItemCountInsert] = useState(null)
	const [errorProjectItemCountInsert, setErrorProjectItemCountInsert] = useState(null)
	const [isLoadingProjectItemCountInsert, setIsLoadingProjectItemCountInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemCountInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/project/item/count', props)
				.then((result) => {
					setIsLoadingProjectItemCountInsert(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemCountInsert(result.data.data)
					} else {
						setErrorProjectItemCountInsert({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemCountInsert(error.response)
					setIsLoadingProjectItemCountInsert(false)
				})

			setIsLoadingProjectItemCountInsert(true)
			setDataProjectItemCountInsert(null)
			setErrorProjectItemCountInsert(null)
		},
		[client]
	)

	return {
		projectItemCountInsert,
		dataProjectItemCountInsert,
		errorProjectItemCountInsert,
		isLoadingProjectItemCountInsert,
	}
}
