import { IconButton, TableFooter, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { datetimeUTCtoString } from '../../../comun/DateUtils'
import { StyledTableRow } from '../../../comun/StyleComponentUtils'
import { EnhancedTableHead, getComparator, stableSort } from '../../../comun/TableUtils'
import CancelIcon from '@mui/icons-material/Cancel'

export default function ProjectVideosTable({ assignedList, videoAsignedDelete }) {
	const [t] = useTranslation('common')
	const [order, setOrder] = useState('asc')
	const [orderBy, setOrderBy] = useState('id')
	const [page, setPage] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(5)

	const handleRequestSort = (_, property) => {
		const isAsc = orderBy === property && order === 'asc'
		setOrder(isAsc ? 'desc' : 'asc')
		setOrderBy(property)
	}

	const handleChangePage = (_, newPage) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 5))
		setPage(0)
	}

	const columnas = [
		{
			id: 'from',
			orderBy: false,
			label: t('video-request.from'),
		},
		{
			id: 'to',
			orderBy: false,
			label: t('video-request.to'),
		},
		{
			id: 'cams',
			disablePadding: true,
			orderBy: false,
			label: t('video-request.cameras'),
		},
		{
			id: 'actions',
			orderBy: false,
			label: '',
		},
	]

	return (
		<TableContainer>
			<Table sx={{ minWidth: 750 }} size='small' style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
				/>
				<TableBody>
					{assignedList
						? stableSort(assignedList, getComparator(order, orderBy))
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row, index) => {
									return (
										<StyledTableRow key={index + row.from}>
											<TableCell align='center'>{datetimeUTCtoString(row.from)}</TableCell>
											<TableCell align='center'>{datetimeUTCtoString(row.to)}</TableCell>
											<TableCell align='center'>
												{row.cams?.map((c, i) => (i == row.cams.length - 1 ? c : c + ', '))}
											</TableCell>
											<TableCell align='center'>
												{!row.isDone && (
													<Tooltip title={t('comun.actions.delete')}>
														<IconButton onClick={() => videoAsignedDelete(row)}>
															<CancelIcon color='error' />
														</IconButton>
													</Tooltip>
												)}
											</TableCell>
										</StyledTableRow>
									)
								})
						: null}
					{/* {assignedList.map((row, index) => {
						return (
							<StyledTableRow key={index + row.from}>
								<TableCell align='center'>{datetimeUTCtoString(row.from)}</TableCell>
								<TableCell align='center'>{datetimeUTCtoString(row.to)}</TableCell>
								<TableCell align='center'>
									{row.cams?.map((c, i) => (i == row.cams.length - 1 ? c : c + ', '))}
								</TableCell>
								<TableCell align='center'>
									{!row.isDone && (
										<Tooltip title={t('comun.actions.delete')}>
											<IconButton onClick={() => videoAsignedDelete(row)}>
												<CancelIcon color='error' />
											</IconButton>
										</Tooltip>
									)}
								</TableCell>
							</StyledTableRow>
						)
					})} */}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePagination
							rowsPerPageOptions={[5]}
							count={assignedList ? assignedList.length : 0}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
