import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const usePreview = () => {
	const [dataPreview, setDataPreview] = useState(null)
	const [errorPreview, setErrorPreview] = useState(null)
	const [isLoadingPreview, setIsLoadingPreview] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const preview = useCallback(
		(url, iD, jumpTime) => {
			let auxUrl = jumpTime != null ? '&jumpTime=' + jumpTime : ''
			client
				.get(baseUrl + url + iD + auxUrl)
				.then((result) => {
					setIsLoadingPreview(false)
					if (result.data.success) {
						setDataPreview(result.data)
					} else {
						setErrorPreview({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingPreview(false)
					setErrorPreview(err.response)
				})

			setDataPreview(null)
			setErrorPreview(null)
			setIsLoadingPreview(true)
		},
		[client]
	)
	return {
		preview,
		dataPreview,
		errorPreview,
		isLoadingPreview,
		setDataPreview,
	}
}
