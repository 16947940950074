import DeleteIcon from '@mui/icons-material/Delete'
import { IconButton, TableFooter, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { datetimeUTCtoString } from '../../comun/DateUtils'
import { StyledTableRow } from '../../comun/StyleComponentUtils'
import { EnhancedTableHead, getComparator, stableSort } from '../../comun/TableUtils'
import EditIcon from '@mui/icons-material/Edit'
import { modeTypesCRUD } from '../../../constants'
import TablePaginationComponent from '../../comun/TablePaginationComponent'

export default function AlertConfigTable({ alertConfigList, handleFormConfigAlert, filter, onFilter }) {
	const [t] = useTranslation('common')

	//This block should be the same in every table component ----------------------------------
	const [order, setOrder] = useState(filter.orderDirection)
	const [orderBy, setOrderBy] = useState(filter.orderField)
	const [page, setPage] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)

	useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------

	useEffect(() => {
		if (alertConfigList) {
			setPage(alertConfigList.pageNo - 1)
			setTotalCount(alertConfigList.totalCount)
		}
	}, [alertConfigList])

	const handleAddAction = () => {
		handleFormConfigAlert(null, modeTypesCRUD.INSERT_MODE)
	}

	const columnas = [
		{
			id: 'name',
			orderBy: true,
			label: t('alert.config.table.header.name'),
		},
		{
			id: 'description',
			orderBy: false,
			label: t('alert.config.table.header.description'),
		},
		{
			id: 'startDate',
			disablePadding: true,
			orderBy: true,
			label: t('alert.config.table.header.start-date'),
		},
		{
			id: 'endDate',
			orderBy: true,
			label: t('alert.config.table.header.end-date'),
		},

		{
			id: 'actions',
			label: '',
			excluirExcel: true,
		},
	]
	return (
		<TableContainer>
			<Table
				id='table-alert-config-list'
				sx={{ minWidth: 750 }}
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					addAction={handleAddAction}
				/>
				<TableBody>
					{alertConfigList
						? stableSort(alertConfigList.data, getComparator(order, orderBy)).map((row, index) => {
								return (
									<StyledTableRow key={index}>
										<TableCell align='center'>{row.name}</TableCell>
										<TableCell align='center'>{row.description}</TableCell>
										<TableCell align='center'>{datetimeUTCtoString(row.startDate)}</TableCell>
										<TableCell align='center'>{datetimeUTCtoString(row.endDate)}</TableCell>

										<TableCell align='center' className='excluir-excel'>
											<Tooltip title={t('alert.config.table.content.update')}>
												<span>
													<IconButton
														color='primary'
														onClick={() =>
															handleFormConfigAlert(row, modeTypesCRUD.UPDATE_MODE)
														}>
														<EditIcon />
													</IconButton>
												</span>
											</Tooltip>
											<Tooltip title={t('alert.config.table.content.delete')}>
												<IconButton
													color='error'
													onClick={() =>
														handleFormConfigAlert(row, modeTypesCRUD.DELETE_MODE)
													}>
													<DeleteIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</StyledTableRow>
								)
						  })
						: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
