import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useTimerUsersRequest = () => {
	const [dataTimerUsers, setDataTimerUsers] = useState(null)
	const [errorTimerUsers, setErrorTimerUsers] = useState(null)
	const [isLoadingTimerUsers, setIsLoadingTimerUsers] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerUsersRequest = useCallback(
		(filter) => {
			client
				.get(baseUrl + 'horus/f3/timer/users/n1?' + prepareParams(filter))
				.then((result) => {
					setIsLoadingTimerUsers(false)
					setDataTimerUsers(result.data)
					if (result.data.success) {
						setDataTimerUsers(result.data)
					} else {
						setErrorTimerUsers({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingTimerUsers(false)
					setErrorTimerUsers(err.response)
				})

			setDataTimerUsers(null)
			setErrorTimerUsers(null)
			setIsLoadingTimerUsers(true)
		},
		[client]
	)
	return {
		timerUsersRequest,
		isLoadingTimerUsers,
		dataTimerUsers,
		errorTimerUsers,
	}
}
