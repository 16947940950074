import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUpdateTimeRange = (vesselApiId, projectId) => {
	const [dataUpdateTimeRange, setDataUpdateTimeRange] = useState(null)
	const [errorUpdateTimeRange, setErrorUpdateTimeRange] = useState(null)
	const [isLoadingUpdateTimeRange, setIsLoadingUpdate] = useState(false)
	const { client } = React.useContext(ApiClientContext)
	const computedUrl = vesselApiId ? 'horus/f3/dashboard/preview/time/range' : 'horus/f3/project/preview/time/range'
	const computedArgument = vesselApiId ? 'vesselApiId=' + vesselApiId : 'projectId=' + projectId

	const updateTimeRange = useCallback(
		(startDate, rangeMillis, rangeShiftMillis, timeValue) => {
			let middleUrl = ''
			if (timeValue) {
				middleUrl = '/' + timeValue + '?'
			} else {
				middleUrl =
					'/config?rangeMillis=' +
					rangeMillis +
					'&rangeShiftMillis=' +
					rangeShiftMillis +
					'&date=' +
					startDate +
					'&'
			}
			client
				.put(baseUrl + computedUrl + middleUrl + computedArgument)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.data.success) {
						setDataUpdateTimeRange(result.data)
					} else {
						setErrorUpdateTimeRange({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingUpdate(false)
					setErrorUpdateTimeRange(err.response)
				})

			setDataUpdateTimeRange(null)
			setErrorUpdateTimeRange(null)
			setIsLoadingUpdate(true)
		},
		[client]
	)
	return {
		updateTimeRange,
		dataUpdateTimeRange,
		errorUpdateTimeRange,
		isLoadingUpdateTimeRange,
	}
}
