import {
	Avatar,
	Box,
	Button,
	Checkbox,
	Divider,
	FormControlLabel,
	List,
	ListItemAvatar,
	ListItemButton,
	ListItemText,
	TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function CalibrationSquareCard({
	handleChangeDistance,
	posCounter,
	setPosCounter,
	deleteSqr,
	saveSqr,
	sqrSelected,
	handleChangeName,
	handleChangeDefault,
	handleChangeUnit,
	cancelCreateSqr,
	errorName,
}) {
	const [t] = useTranslation('common')
	return (
		<Box sx={{ width: '95%', border: '1px solid #8ab8e5', borderRadius: '5px', mt: 1 }}>
			<TextField
				size='small'
				value={sqrSelected?.name}
				onChange={handleChangeName}
				sx={{ width: '95%', m: 1 }}
				error={errorName != null}
				InputProps={{ maxLength: 50 }}
				helperText={errorName}></TextField>
			<Divider sx={{ bgcolor: '#8ab8e5' }} />
			<List>
				{sqrSelected?.positions?.map((pos, idx) => {
					return (
						<ListItemButton
							sx={{
								border: idx == posCounter ? '1px solid #1976d2' : 'none',
								borderRadius: '10px',
								mx: 1,
								height: '6vh',
							}}
							selected={idx == posCounter}
							onClick={() => setPosCounter(idx)}
							key={idx}>
							<ListItemAvatar>
								<Avatar sx={{ height: '30px', width: '30px' }}>
									{idx == 0 ? 'A' : idx == 1 ? 'B' : idx == 2 ? 'C' : idx == 3 ? 'D' : null}
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									'X : ' +
									(pos.x ? pos.x.toFixed(4) : '') +
									' - Y : ' +
									(pos.y ? pos.y.toFixed(4) : '')
								}
							/>
						</ListItemButton>
					)
				})}
			</List>
			<Box sx={{ width: '100%', display: 'flex' }}>
				<TextField
					size='small'
					value={sqrSelected?.distance}
					onChange={handleChangeDistance}
					label={t('calibration.side-distance')}
					sx={{ width: '30%', m: 0.5 }}></TextField>
				<TextField
					size='small'
					value={sqrSelected?.unit}
					onChange={handleChangeUnit}
					label={t('calibration.side-distance-unit')}
					sx={{ width: '30%', m: 0.5 }}></TextField>
				<FormControlLabel
					label={t('calibration.default')}
					labelPlacement='start'
					control={<Checkbox checked={sqrSelected?.default} onChange={(e) => handleChangeDefault(e)} />}
				/>
			</Box>
			<Box sx={{ width: '100%', display: 'flex' }}>
				<Button
					variant='outlined'
					sx={{ m: 1, width: '100%' }}
					onClick={saveSqr}
					disabled={sqrSelected?.positions.length != 4 || errorName}>
					{t('comun.actions.save')}
				</Button>
				<Button variant='outlined' color='secondary' sx={{ m: 1, width: '100%' }} onClick={cancelCreateSqr}>
					{t('comun.actions.cancel')}
				</Button>
				{sqrSelected?.id && (
					<Button variant='outlined' color='error' sx={{ m: 1, width: '100%' }} onClick={deleteSqr}>
						{t('comun.actions.delete')}
					</Button>
				)}
			</Box>
		</Box>
	)
}
