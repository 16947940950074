import React, { useEffect, useState } from 'react'
import { Marker } from 'react-leaflet'
import useStore from '../../PreviewStore'
import crosshair from '../../../../assets/crosshair.svg'
import { Icon } from 'leaflet'
import crosshairRed from '../../../../assets/crosshair-red.svg'
import crosshairGreen from '../../../../assets/crosshair-green.svg'
import crosshairYellow from '../../../../assets/crosshair-yellow.svg'
import { useShallow } from 'zustand/shallow'
import { mapSpeedThresholds } from '../../../../constants'

const crosshairIcon = new Icon({
	iconUrl: crosshair,
	iconSize: [40, 40],
})

const crosshairRedIcon = new Icon({
	iconUrl: crosshairRed,
	iconSize: [40, 40],
})

const crosshairGreenIcon = new Icon({
	iconUrl: crosshairGreen,
	iconSize: [40, 40],
})

const crosshairYellowIcon = new Icon({
	iconUrl: crosshairYellow,
	iconSize: [40, 40],
})

export default function CurrentPositionMarker({ mapInfo }) {
	const [currentPosition, setCurrentPosition] = useState(null)
	const [currentIcon, setCurrentIcon] = useState(crosshair)
	const { currentTime } = useStore(
		useShallow((state) => ({
			currentTime: state.currentTime,
		}))
	)

	const closestDate = (mapInfo, currentDate) =>
		mapInfo.reduce((x, p) => {
			const distance = Math.abs(currentDate - new Date(p.date))
			if (!x.distance || distance < x.distance) return { distance, p }
			return x
		}, {})

	useEffect(() => {
		if (mapInfo && currentTime) {
			let p = closestDate(mapInfo, currentTime).p
			if (p) {
				setCurrentPosition(p)
				if (p.speed < mapSpeedThresholds.red) {
					setCurrentIcon(crosshairRedIcon)
				} else if (p.speed >= mapSpeedThresholds.red && p.speed <= mapSpeedThresholds.green) {
					setCurrentIcon(crosshairYellowIcon)
				} else if (p.speed > mapSpeedThresholds.green) {
					setCurrentIcon(crosshairGreenIcon)
				} else setCurrentIcon(crosshairIcon)
			}
		}
	}, [currentTime])
	return (
		<>
			{currentPosition && (
				<Marker position={[currentPosition.lat, currentPosition.lon]} icon={currentIcon} zIndexOffset={1} />
			)}
		</>
	)
}
