import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserLog = () => {
	const [userLog, setUserLog] = useState(null)
	const [errorUserLog, setErrorUserLog] = useState(null)
	const [isLoadingUserLog, setIsLoadingUserLog] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const userLogRequest = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/activity', props)
				.then((result) => {
					setIsLoadingUserLog(false)
					if (result.data.success) {
						setUserLog('OK')
					} else {
						setErrorUserLog({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingUserLog(false)
					setErrorUserLog(err.response)
				})

			setUserLog(null)
			setErrorUserLog(null)
			setIsLoadingUserLog(true)
		},
		[client]
	)
	return {
		userLogRequest,
		isLoadingUserLog,
		userLog,
		errorUserLog,
	}
}
