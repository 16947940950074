import React, { useEffect } from 'react'
import { useMap } from 'react-leaflet'

import 'leaflet-rotatedmarker'
import { getBounds } from '../../../../utils/customFunctions'
import CustomMarker from './CustomMarker'
import './MapTab.css'

export default function MarkersLayer({ mapInfo, updateCurrentTime }) {
	const map = useMap()
	useEffect(() => {
		if (map && mapInfo) {
			map.fitBounds(getBounds(mapInfo))
		}
	}, [map, mapInfo])

	return (
		<>
			{mapInfo?.map((p, index) => {
				let nextPos = index == mapInfo.length - 1 ? p : mapInfo[index + 1]
				return <CustomMarker p={p} updateCurrentTime={updateCurrentTime} key={p.date} nextPos={nextPos} />
			})}
		</>
	)
}
