import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserDelete = () => {
	const [userDataDelete, setUserDataDelete] = useState(null)
	const [errorUserDelete, setErrorUserDelete] = useState(null)
	const [isLoadingDelete, setIsLoadingDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const userDelete = useCallback(
		(username) => {
			const config = {
				params: {
					userName: username,
				},
			}

			client
				.delete(baseUrl + 'horus/f3/user', config)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setUserDataDelete({ data: result.data })
					} else {
						setErrorUserDelete({ statusText: result.data.errorMsg })
					}
					setIsLoadingDelete(false)
				})
				.catch((error) => {
					setErrorUserDelete(error.response)
					setIsLoadingDelete(false)
				})

			setUserDataDelete(null)
			setErrorUserDelete(null)
			setIsLoadingDelete(true)
		},
		[client]
	)

	return {
		userDelete,
		isLoadingDelete,
		userDataDelete,
		setUserDataDelete,
		errorUserDelete,
		setErrorUserDelete,
	}
}
