import React from 'react'

export default function FieldsetComponent({ title, children, style }) {
	return (
		<fieldset style={style}>
			<legend>{title}</legend>
			{children}
		</fieldset>
	)
}
