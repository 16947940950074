import { Box, Divider, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { baseFilter, modeTypesCRUD, messageDialogTypes } from '../../constants'
import { useReportRequestFilter } from '../../services/reports/useReportRequestFilter'
import { useReportRequest } from '../../services/reports/useReportRequest'
import { useReportDelete } from '../../services/reports/useReportDelete'
import { useReportExport } from '../../services/reports/useReportExport'
import { useReportDownload } from '../../services/reports/useReportDownload'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import ReportsFilter from './ReportsFilter'
import ReportsForm from './ReportsForm'
import ConfirmationDialog from '../comun/ConfirmationDialog'
import ReportsTable from './ReportsTable'

export default function ReportsContainer({ projectId }) {
	const [t] = useTranslation('common')

	const initialFilter = { ...baseFilter, orderDirection: 'desc', orderField: 'requestDate', projectId: projectId }

	//Services
	const { reportFilterRequest, isLoadingFilter, reportFilterData, errorFilterReport } = useReportRequestFilter()
	const { reportRequest, isLoading, reportData, errorReportData } = useReportRequest()
	const { reportDelete, isLoadingReporDelete, reportDataDelete, errorReportDelete } = useReportDelete()
	const { reportExport, isLoadingReportExport, reportDataExport, errorReportExport } = useReportExport()
	const { reportDownload, isLoadingDownload, reportDataDownload, errorReportDownload } = useReportDownload()

	const [reportOptions, setReportOptions] = React.useState([])

	//Error control
	const [openError, setOpenError] = React.useState(false)
	const [errorMsg, setErrorMsg] = React.useState(null)
	const [typeMessage, setTypeMessage] = React.useState(null)

	//Filter
	const [filter, setFilter] = React.useState(initialFilter)

	const statusOptions = [t('reports.filter.status-options.finished'), t('reports.filter.status-options.pending')]

	//Modals
	const [open, setOpen] = React.useState(false)
	const [mode, setMode] = React.useState(false)
	const [openDelete, setOpenDelete] = React.useState(false)
	const [selectedReport, setSelectedReport] = React.useState([])

	const handleCloseError = () => {
		setOpenError(false)
	}

	React.useEffect(() => {
		reportFilterRequest(projectId)
		reportRequest(filter)
	}, [])

	React.useEffect(() => {
		if (reportFilterData) {
			setReportOptions(reportFilterData)
		}
	}, [reportFilterData])

	React.useEffect(() => {
		if (reportDataDelete || reportDataExport) {
			onClose()
			reportRequest(filter)
		}
	}, [reportDataDelete, reportDataExport])

	React.useEffect(() => {
		if (errorFilterReport || errorReportData || errorReportDelete || errorReportExport || errorReportDownload) {
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setOpenError(true)
			if (errorFilterReport) {
				setErrorMsg(errorFilterReport)
			} else if (errorReportData) {
				setErrorMsg(errorReportData)
			} else if (errorReportDelete) {
				setErrorMsg(errorReportDelete)
			} else if (errorReportExport) {
				setErrorMsg(errorReportExport)
			} else if (errorReportDownload) {
				setErrorMsg(errorReportDownload)
			}
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorFilterReport, errorReportData, errorReportDelete, errorReportExport, errorReportDownload])

	/***************** FILTER *********************/
	const onFilter = (data) => {
		setFilter(data)
		reportRequest(data)
	}

	/**************** LIST RESULTS ****************/
	const handleFormReports = (row, mode) => {
		setMode(mode)
		if (modeTypesCRUD.INSERT_MODE === mode) {
			setOpen(true)
		} else if (modeTypesCRUD.DELETE_MODE === mode) {
			setOpenDelete(true)
			setSelectedReport(row)
		} else if (modeTypesCRUD.DOWNLOAD_REPORT === mode) {
			setSelectedReport(row)
			reportDownload({ projectId: row.projectId, exportId: row.id })
		}
	}

	React.useEffect(() => {
		if (reportDataDownload) {
			var headers = reportDataDownload.data.headers
			var contentType = headers['content-type']
			let link = document.createElement('a')
			link.href = URL.createObjectURL(new Blob([reportDataDownload.data.data], { type: contentType }))

			let fileName = selectedReport.exportFileName
			link.setAttribute('download', fileName)

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	}, [reportDataDownload])

	/******************** MODAL EVENTS ************/
	const onClose = () => {
		setOpen(false)
	}

	const onCloseDelete = (confirmed) => {
		setOpenDelete(false)
		if (confirmed) {
			reportDelete(selectedReport)
		}
	}

	const parseData = (data) => {
		let config = {}

		if (modeTypesCRUD.INSERT_MODE == mode) {
			config.projectId = projectId
			config.exportId = data.exportType?.exportId
			config.description = data.description
			config.requestDate = new Date()
			config.generationDate = new Date()
		}

		return config
	}

	const onSave = (data) => {
		let config = parseData(data)
		if (modeTypesCRUD.INSERT_MODE == mode) {
			reportExport(config)
		}
		setOpen(false)
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={typeMessage}
				handleCloseError={handleCloseError}
			/>

			<BackdropComponent
				open={
					isLoadingFilter || isLoading || isLoadingReporDelete || isLoadingReportExport || isLoadingDownload
				}
			/>

			<Box sx={{ width: '100%' }}>
				<Grid>
					<ReportsFilter
						filter={filter}
						onFilter={onFilter}
						reportOptions={reportOptions}
						projectId={projectId}
					/>

					<Divider sx={{ m: 2 }} variant='middle' />

					<Paper sx={{ width: '100%' }}>
						<ReportsTable
							reportList={reportData}
							handleFormReports={handleFormReports}
							filter={filter}
							onFilter={onFilter}
							statusOptions={statusOptions}
						/>
					</Paper>
				</Grid>

				<ReportsForm mode={mode} onClose={onClose} open={open} onSave={onSave} reportOptions={reportOptions} />

				<ConfirmationDialog
					onOpen={openDelete}
					onClose={onCloseDelete}
					dialogTitle={t('reports.form.delete.title-delete')}
					dialogContent={t('reports.form.delete.delete-content')}
					dialogSaveButton={t('comun.actions.delete')}
					dialogCancelButton={t('comun.actions.close')}
				/>
			</Box>
		</Box>
	)
}
