import * as React from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useReportExport = () => {
	const [reportDataExport, setReportDataExport] = React.useState(null)
	const [errorReportExport, setErrorReportExport] = React.useState(null)
	const [isLoadingReportExport, setIsLoadingReportExport] = React.useState(false)

	const { client } = React.useContext(ApiClientContext)

	const reportExport = React.useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/project/export', props)
				.then((result) => {
					setIsLoadingReportExport(false)
					if (result.status === 200 && result.data.success) {
						setReportDataExport({ data: result.data })
					} else {
						setErrorReportExport({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorReportExport(error.response)
					setIsLoadingReportExport(false)
				})

			setIsLoadingReportExport(true)
			setReportDataExport(null)
			setErrorReportExport(null)
		},
		[client]
	)

	return {
		reportExport,
		isLoadingReportExport,
		reportDataExport,
		errorReportExport,
	}
}
