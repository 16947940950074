import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { messageDialogTypes } from '../../constants'
import { useChatInfo } from '../../services/messenger/useChatInfo'
import { useGetAllChats } from '../../services/messenger/useGetAllChats'
import { useSendMessage } from '../../services/messenger/useSendMessage'
import { addSeconds } from '../comun/DateUtils'
import MessageSnackBar from '../comun/MessageSnackBar'
import Chat from './Chat'
import ChatList from './ChatList'

export default function MessengerContainer({ state }) {
	const [chatSelected, setChatSelected] = useState(null)
	const [chats, setChats] = useState([])
	const [chatHistory, setChatHistory] = useState([])
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [reachedTop, setReachedTop] = useState(false)
	const [lastDate, setLastDate] = useState(null)

	const { refreshInterval, getAllChats, chatList, errorGetAllChats } = useGetAllChats()
	const { sendMessage, dataSend, errorSend } = useSendMessage()
	const { getChatInfo, isLoadingChat, chatInfo, errorChat, noMoreMessages } = useChatInfo()

	useEffect(() => {
		if (chatList) {
			if (chatSelected) {
				handleChangeSelected(chatSelected, true)
			} else {
				if (state?.vessel) {
					handleChangeSelected({ vesselApiId: state.vessel.vesselId })
				}
				setChats(chatList)
			}
		}
	}, [chatList])

	useEffect(() => {
		if (errorSend) {
			setOpenError(true)
			setErrorMsg(errorSend)
		} else if (errorGetAllChats) {
			setOpenError(true)
			setErrorMsg(errorGetAllChats)
		} else if (errorChat) {
			setOpenError(true)
			setErrorMsg(errorChat)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorSend, errorGetAllChats, errorChat])

	const handleCloseError = () => {
		setOpenError(false)
	}

	useEffect(() => {
		let interval = null
		if (refreshInterval) {
			interval = setInterval(() => {
				getAllChats()
			}, [refreshInterval])
		} else {
			getAllChats()
		}
		return () => {
			clearInterval(interval)
		}
	}, [refreshInterval])

	const handleChangeSelected = (chat, isPeriodic) => {
		let auxChats = [...chatList]
		let index = auxChats.findIndex((c) => c.vesselApiId == chat.vesselApiId)
		auxChats[index].nmsgNRead = 0
		setChats(auxChats)
		setChatSelected(auxChats[index])
		if (isPeriodic) {
			if(chatHistory?.length > 0){
				getChatInfo(chat.vesselApiId, null, addSeconds(chatHistory[chatHistory.length - 1]?.dt, 0.1))
				setLastDate(chatHistory[chatHistory.length - 1]?.dt)
			} else {
				getChatInfo(chat.vesselApiId, null, null)
				setLastDate(null)
			}
		} else {
			getChatInfo(chat.vesselApiId)
			setLastDate(null)
		}
	}

	useEffect(() => {
		if (dataSend) {
			//refresh info in chatlist and in the chat history
			let auxChats = [...chats]
			let index = auxChats.findIndex((c) => c.vesselApiId == chatSelected.vesselApiId)
			auxChats[index].lastDateMsg = dataSend[0].dt
			auxChats[index].lastTextMsg = dataSend[0].msg.slice(0, 30)
			auxChats[index].src = dataSend[0].src
			auxChats[index].st = dataSend[0].st
			setChats(auxChats)
			let auxChatInfo = [...chatInfo]
			auxChatInfo.push({
				dt: dataSend[0].dt,
				msg: dataSend[0].msg,
				src: dataSend[0].src,
				st: dataSend[0].st,
			})
			setChatHistory(auxChatInfo)
		}
	}, [dataSend])

	useEffect(() => {
		if (chatInfo) {
			if (reachedTop) {
				let auxHistory = [...chatHistory]
				auxHistory.unshift(...chatInfo)
				setChatHistory(auxHistory)
				setReachedTop(false)
			} else if (lastDate) {
				let auxHistory = [...chatHistory]
				auxHistory.push(...chatInfo)
				setChatHistory(auxHistory)
				setReachedTop(false)
			} else {
				setChatHistory(chatInfo)
			}
		}
	}, [chatInfo])

	useEffect(() => {
		if (reachedTop && chatHistory?.length > 0 && !noMoreMessages) {
			let formerDate = chatHistory[0]?.dt
			getChatInfo(chatSelected.vesselApiId, formerDate)
		}
	}, [reachedTop])

	const handleSendMessage = (chat, message) => {
		sendMessage(chat.vesselApiId, message)
	}

	return (
		<Box sx={{ display: 'flex', flexDirection: 'row', width: '85%', height: '80vh', ml: 25, mt: 1 }}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
				handleCloseError={handleCloseError}
			/>
			{/* <BackdropComponent open={isLoadingGetAllChats} /> */}
			<ChatList
				chats={chats}
				chatSelected={chatSelected}
				handleChangeSelected={handleChangeSelected}
				setChats={setChats}
			/>
			<Chat
				chat={chatSelected}
				handleSendMessage={handleSendMessage}
				chatHistory={chatHistory}
				isLoading={isLoadingChat}
				setReachedTop={setReachedTop}
				noMoreMessages={noMoreMessages}
			/>
		</Box>
	)
}
