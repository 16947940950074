import * as React from 'react'
import Box from '@mui/material/Box'
import { Tooltip } from '@mui/material'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import FormControl from '@mui/material/FormControl'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'
import { useTranslation } from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

export default function VesselListComponent({ methodsForm, vessels, selected }) {
	const {
		control,
		register,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = methodsForm

	const [vesselList, setVesselList] = React.useState(vessels)
	const [vesselListPreviewSelected, setVesselListPreviewSelected] = React.useState([])

	const [disableAddButton, setDisableAddButton] = React.useState(true)

	const [t] = useTranslation('common')

	const watchVesselAutocomplete = watch('vesselAutocomplete')

	React.useEffect(() => {
		if (selected != null) {
			const copyVessel = [...vesselList]

			selected.forEach((value) => {
				//Add value to selected list
				setVesselListPreviewSelected((prevState) => [...prevState, value])
				//Delete value from options list
				copyVessel.splice(
					copyVessel.findIndex((item) => item.vesselApiId === value.vesselApiId),
					1
				)
			})

			setVesselList(copyVessel)
		}
	}, [selected])

	React.useEffect(() => {
		setValue('vessels', vesselListPreviewSelected)
	}, [vesselListPreviewSelected])

	React.useEffect(() => {
		setDisableAddButton(watchVesselAutocomplete ? false : true)
	}, [watchVesselAutocomplete])

	const onAddVessel = () => {
		if (watchVesselAutocomplete) {
			//Se añade a la lista
			errors.vesselAutocomplete = null
			setValue('vesselAutocomplete', null)
			setVesselListPreviewSelected((prevState) => [...prevState, watchVesselAutocomplete])

			//Se elimina del autocomplete
			const copyVessel = [...vesselList]
			copyVessel.splice(
				copyVessel.findIndex((item) => item.vesselName === watchVesselAutocomplete.vesselName),
				1
			)

			setVesselList(copyVessel)
		}
	}

	const onDeleteVessel = (vessel) => {
		//Se elimina de la lista
		const copyVessel = [...vesselListPreviewSelected]
		copyVessel.splice(
			copyVessel.findIndex((item) => item.vesselName === vessel.vesselName),
			1
		)

		setVesselListPreviewSelected(copyVessel)
		//Se añade al autocomplete
		setVesselList((prevState) => [...prevState, vessel])
	}

	const checkEmptyVessels = () => {
		var x = getValues('vessels')
		return x ? x.length > 0 : false
	}

	return (
		<>
			<Box sx={{ display: 'flex' }}>
				<Box sx={{ width: '100%' }}>
					{vesselList && vesselList != null ? (
						<AutoCompleteComponent
							defaultValue={null}
							size='small'
							sx={{ minWidth: '100%' }}
							control={control}
							name='vesselAutocomplete'
							label={t('alert.config.form.vessels')}
							errors={errors?.vesselAutocomplete}
							optionsLabel={'vesselName'}
							keyProperty={'vesselApiId'}
							options={vesselList}
							evalMethod={(opt, val) => {
								return opt.vesselApiId === val.vesselApiId
							}}
							rules={{
								validate: () => checkEmptyVessels() || t('programs.validations.vessel-field'),
							}}
						/>
					) : null}
				</Box>
				<Box sx={{ display: 'flex', width: '10%', justifyContent: 'flex-end' }}>
					<FormControl variant='standard' size='small' sx={{ width: '40px', alignSelf: 'flex-start' }}>
						<Tooltip title={t('programs.form.vessel.add-button')}>
							<span>
								<IconButton color='primary' disabled={disableAddButton} onClick={() => onAddVessel()}>
									<AddCircleIcon />
								</IconButton>
							</span>
						</Tooltip>
					</FormControl>
				</Box>
			</Box>
			<Box sx={{ width: '100%', maxHeight: '25vh', overflow: 'auto' }}>
				<TableContainer component={Paper}>
					<Table {...register('vessels')} sx={{ minWidth: 650 }} aria-label='simple table'>
						<TableBody>
							{vesselListPreviewSelected.map((row) => (
								<TableRow
									key={row.vesselApiId}
									sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									<TableCell component='th' scope='row'>
										{row.vesselName}
									</TableCell>
									<TableCell component='th' scope='row' align='right'>
										<Tooltip title={t('comun.actions.delete')}>
											<span>
												<IconButton color='error' onClick={() => onDeleteVessel(row)}>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>
		</>
	)
}
