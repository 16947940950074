import Box from '@mui/material/Box'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../comun/BasicDialog'
import LabelValueComponent from '../comun/LabelValueComponent'
import SelectComponent from '../comun/form_fields/SelectComponent'

export default function ChangeStatusForm({ project, onClose, open, onSave }) {
	const { control, reset, handleSubmit, watch } = useForm()
	const [disableField, setDisableField] = React.useState(true)

	const optionSelected = watch('status')

	React.useEffect(() => {
		reset(project)
	}, [project])

	React.useEffect(() => {
		setDisableField(optionSelected ? false : true)
	}, [optionSelected])

	const [t] = useTranslation('common')

	React.useEffect(() => {
		reset()
	}, [onClose])

	return (
		<Box component='form' onSubmit={handleSubmit(onSave)}>
			<BasicDialog
				disabled={disableField}
				title={t('project.change-status.title')}
				onOpen={open}
				onClose={onClose}
				saveTxt={t('comun.actions.save')}>
				<Box sx={{ display: 'flex', flexDirection: 'column', minWidth: '80vh' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
						{project ? (
							<LabelValueComponent label={project.description} horizontal></LabelValueComponent>
						) : null}
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', m: 1 }}>
						<SelectComponent
							sx={{ width: '92%', mr: 2 }}
							label={t('programs.filter.status')}
							name='status'
							control={control}
							size='small'
							defaultValue={null}
							options={project ? project.nextStatusList : null}
							optionsLabel={'description'}
							optionsValue={'id'}
						/>
					</Box>
				</Box>
			</BasicDialog>
		</Box>
	)
}
