import { rolesConstants } from "../constants"

export const hasAdminRole = (roles) => {
    return roles.includes(rolesConstants.ADMIN)
}

export const hasFleetAdminRole = (roles) => {
    return roles.includes(rolesConstants.FLEET_ADMIN)
}

export const hasFleetStaffRole = (roles) => {
    return roles.includes(rolesConstants.FLEET_STAFF)
}

export const hasEMStaffRole = (roles) => {
    return roles.includes(rolesConstants.EM_STAFF)
}


export const hasEMAdminRole = (roles) => {
    return roles.includes(rolesConstants.EM_ADMIN)
}