import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import AlertZonesContainer from '../components/alerts/alert_zones/AlertZonesContainer'

const AlertZonesPage = () => {
	const history = useHistory()

	return (
		<ErrorBoundary history={history}>
			<AlertZonesContainer />
		</ErrorBoundary>
	)
}

export default AlertZonesPage
