import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../../services/ApiClientProvider'

export const useProjectItemCommentInsert = () => {
	const [dataProjectItemCommentInsert, setDataProjectItemCommentInsert] = useState(null)
	const [errorProjectItemCommentInsert, setErrorProjectItemCommentInsert] = useState(null)
	const [isLoadingProjectItemCommentInsert, setIsLoadingProjectItemCommentInsert] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemCommentInsert = useCallback(
		(props) => {
			client
				.post(baseUrl + 'horus/f3/project/item/comment', props)
				.then((result) => {
					setIsLoadingProjectItemCommentInsert(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemCommentInsert(result.data.data)
					} else {
						setErrorProjectItemCommentInsert({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemCommentInsert(error.response)
					setIsLoadingProjectItemCommentInsert(false)
				})

			setIsLoadingProjectItemCommentInsert(true)
			setDataProjectItemCommentInsert(null)
			setErrorProjectItemCommentInsert(null)
		},
		[client]
	)

	return {
		projectItemCommentInsert,
		dataProjectItemCommentInsert,
		errorProjectItemCommentInsert,
		isLoadingProjectItemCommentInsert,
	}
}
