import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export default function useLogoutRequest() {
	const [dataLogOut, setDataLogOut] = useState(null)
	const [errorLogOut, setErrorLogOut] = useState(null)
	const [isLoadingLogOut, setIsLoadingLogOut] = useState(false)

	const { client } = React.useContext(ApiClientContext)

	const logOut = useCallback(() => {
		client
			.post(baseUrl + 'horus/f3/logout')
			.then((result) => {
				setIsLoadingLogOut(false)
				if (result.data.success) {
					setDataLogOut(result.data.success)
				} else {
					setErrorLogOut({ statusText: result.data.errorMsg })
				}
			})
			.catch((err) => {
				setIsLoadingLogOut(false)
				setErrorLogOut(err.response)
			})

		setDataLogOut(null)
		setErrorLogOut(null)
		setIsLoadingLogOut(true)
	}, [])

	return {
		logOut,
		dataLogOut,
		errorLogOut,
		isLoadingLogOut,
	}
}
