import { Typography } from '@mui/material'
import { Box } from '@mui/material'
import React from 'react'

export default function LabelValueComponent({ label, value, sx, vertical }) {
	return vertical ? (
		<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', ...sx }}>
			<Typography variant='h6' sx={{ fontWeight: 600 }} color='primary'>
				{label}
			</Typography>
			<Typography variant='body1' sx={{ justifySelf: 'end' }} color='primary'>
				{value}
			</Typography>
		</Box>
	) : (
		<Box sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
			<Typography sx={{ fontWeight: 600, fontSize: '1.15rem' }} color='primary'>
				{label + ': '}
			</Typography>
			<Typography sx={{ ml: 1 }} color='primary'>
				{value}
			</Typography>
		</Box>
	)
}
