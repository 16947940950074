import { useCallback, useState, useContext } from 'react'

import { ApiClientContext } from '../../../../ApiClientProvider'
import { baseUrl } from '../../../../../constants'

export const useVideoAsignedConsolidation = () => {
	const [videoAsignedConsolidationData, setVideoAsignedConsolidationData] = useState(null)
	const [isLoadingVideoAsignedConsolidation, setIsLoadingVideoAsignedConsolidation] = useState(false)
	const [errorVideoAsignedConsolidation, setErrorVideoAsignedConsolidation] = useState(null)

	const { client } = useContext(ApiClientContext)

	const videoAsignedConsolidation = useCallback(
		(params, cams) => {
			let url = 'horus/f3/project/preview/videodaterange/consolidation?projectId=' + params.projectId
			url = url + '&from=' + params.from + '&to=' + params.to
			url = url + cams.map((c) => '&cam=' + c)
			url = url.replaceAll(',', '')
			client
				.post(baseUrl + url)
				.then((result) => {
					setIsLoadingVideoAsignedConsolidation(false)
					if (result.data.success) {
						setVideoAsignedConsolidationData(result.data.data)
					} else {
						setErrorVideoAsignedConsolidation({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingVideoAsignedConsolidation(false)
					setErrorVideoAsignedConsolidation(err.response)
				})
			setIsLoadingVideoAsignedConsolidation(true)
			setVideoAsignedConsolidationData(null)
			setErrorVideoAsignedConsolidation(null)
		},
		[client]
	)

	return {
		videoAsignedConsolidation,
		videoAsignedConsolidationData,
		isLoadingVideoAsignedConsolidation,
		errorVideoAsignedConsolidation,
	}
}
