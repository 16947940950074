import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Slide from '@mui/material/Slide'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow, { tableRowClasses } from '@mui/material/TableRow'
import { Tooltip } from '@mui/material'
import * as React from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const StyledBadge = styled(Badge)(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: -3,
		top: -1,
		border: `2px solid ${theme.palette.background.paper}`,
		padding: '0 4px',
		color: 'white',
	},
}))

/***********  ACCORDEON ********************/

export const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
	({ theme }) => ({
		border: `1px solid ${theme.palette.divider}`,
		'&:not(:last-child)': {
			borderBottom: 0,
		},
		'&:before': {
			display: 'none',
		},
	})
)

export const AccordionSummary = styled((props) => (
	<MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
	backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
	flexDirection: 'row-reverse',
	'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
		transform: 'rotate(90deg)',
	},
	'& .MuiAccordionSummary-content': {
		marginLeft: theme.spacing(1),
	},
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
	padding: theme.spacing(2),
	borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

/**** TRANSICION */
export const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction='up' ref={ref} {...props} />
})

/************** LISTAS */

export const StyledList = styled(List)({
	// selected and (selected + hover) states
	'&& .Mui-selected, && .Mui-selected:hover': {
		backgroundColor: 'aliceblue ',
		'&, & .MuiListItemIcon-root': {
			color: 'black',
		},
	},
	// hover states
	'& .MuiListItemButton-root:hover': {
		backgroundColor: 'aliceblue ',
		'&, & .MuiListItemIcon-root': {
			color: 'black',
		},
	},
})

/****  TABLAS */
export const StyledTableHeadRow = styled(TableRow)(({ theme }) => ({
	[`&.${tableRowClasses.head}`]: {
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.primary.main,
	},
}))

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
	'&&.Mui-selected': {
		backgroundColor: '#1976d257',
	},
}))

export const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.cell}`]: {
		textAlign: 'center',
	},
}))

/******  MODALES */

export const CustomDialog = styled(Dialog)(({ theme }) => ({
	'& .MuiDialogContent-root': {
		padding: theme.spacing(2),
		borderBottom: 'none',
		borderTop: '1px solid',
		borderBottomColor: theme.palette.primary.main,
		borderTopColor: theme.palette.primary.main,
	},
	'& .MuiDialogActions-root': {
		padding: theme.spacing(1),
	},
}))

export const CustomDialogTitle = (props) => {
	const { children, onClose, onCloseText, ...other } = props

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose == true ? (
				<Tooltip title={onCloseText}>
					<IconButton
						aria-label={onCloseText}
						onClick={onClose}
						sx={{
							position: 'absolute',
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}>
						<CloseIcon />
					</IconButton>
				</Tooltip>
			) : null}
		</DialogTitle>
	)
}

CustomDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
	onCloseText: PropTypes.string,
}
