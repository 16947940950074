import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import NewDashboardContainer from '../components/new_dashboard/NewDashboardContainer'

const DashboardPage = () => {
	const history = useHistory()
	return (
		<ErrorBoundary history={history}>
			<NewDashboardContainer />
		</ErrorBoundary>
	)
}

export default DashboardPage
