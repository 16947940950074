import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton, TableFooter, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from '../../constants'
import '../../index.css'
import { datetimeUTCtoString } from '../comun/DateUtils'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import { EnhancedTableHead, getComparator, stableSort } from '../comun/TableUtils'

export default function ProgramTable({ programList, handleFormPrograms, filter, onFilter }) {
	const [t] = useTranslation('common')

	//This block should be the same in every table component ---------------------------------- COPY THIS BOCK IN EVERY TABLE WITH SORTING SYSTEM
	const [order, setOrder] = useState(filter.orderDirection)
	const [orderBy, setOrderBy] = useState(filter.orderField)
	const [page, setPage] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)

	useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	useEffect(() => {
		if (programList) {
			setPage(programList.pageNo - 1)
			setTotalCount(programList.totalCount)
		}
	}, [programList])

	const handleAddAction = () => {
		handleFormPrograms(null, modeTypesCRUD.INSERT_MODE)
	}

	const columnas = [
		{
			id: 'start_date',
			orderBy: false,
			label: t('programs.table.header.star-date'),
		},
		{
			id: 'description',
			orderBy: false,
			label: t('programs.table.header.description'),
		},
		//{
		//	id: 'client',
		//	orderBy: false,
		//	label: t('programs.table.header.customer'),
		//},
		{
			id: 'end_date',
			orderBy: false,
			label: t('programs.table.header.end-date'),
		},
		{
			id: 'actions',
			label: '',
			excluirExcel: true,
		},
	]

	return (
		<TableContainer>
			<Table
				id='table-programs-list'
				aria-labelledby='tableTitle'
				size='small'
				sx={{ minWidth: 750 }}
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					addAction={handleAddAction}
				/>
				<TableBody>
					{programList &&
						stableSort(programList.data, getComparator(order, orderBy)).map((row, index) => {
							return (
								<StyledTableRow key={row.id}>
									<TableCell align='center'>{datetimeUTCtoString(row.startDate)}</TableCell>
									<TableCell align='center'>{row.description}</TableCell>
									<TableCell align='center'>{datetimeUTCtoString(row.endDate)}</TableCell>

									<TableCell align='center'>
										<Tooltip title={t('programs.table.content.update')}>
											<span>
												<IconButton
													color='primary'
													onClick={() => handleFormPrograms(row, modeTypesCRUD.UPDATE_MODE)}>
													<EditIcon />
												</IconButton>
											</span>
										</Tooltip>
										<Tooltip title={t('programs.table.content.delete')}>
											<span>
												<IconButton
													color='error'
													onClick={() => handleFormPrograms(row, modeTypesCRUD.DELETE_MODE)}>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									</TableCell>
								</StyledTableRow>
							)
						})}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePagination
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
