import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ReplayIcon from '@mui/icons-material/Replay'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { IconButton, TableFooter, Tooltip, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { modeTypesCRUD } from '../../constants'
import '../../index.css'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import TablePaginationComponent from '../comun/TablePaginationComponent'
import { EnhancedTableHead, getComparator, stableSort } from '../comun/TableUtils'

export default function UsersTable({ userList, filter, onFilter, handleFormUsers }) {
	const [t] = useTranslation('common')

	//Tabla
	const [order, setOrder] = useState(filter.orderDirection)
	const [orderBy, setOrderBy] = useState(filter.orderField)
	const [page, setPage] = React.useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	React.useEffect(() => {
		if (userList) {
			setPage(userList.pageNo - 1)
			setTotalCount(userList.totalCount)
		}
	}, [userList])

	const handleAddAction = () => {
		handleFormUsers(null, modeTypesCRUD.INSERT_MODE)
	}

	const columnas = [
		{
			id: 'username',
			align: 'left',
			orderBy: true,
			label: t('users.table.header.username'),
		},
		{
			id: 'name',
			align: 'left',
			orderBy: true,
			label: t('users.table.header.name'),
		},
		{
			id: 'surname',
			align: 'left',
			orderBy: true,
			label: t('users.table.header.surname'),
		},
		{
			id: 'email',
			align: 'left',
			orderBy: true,
			label: t('users.table.header.email'),
		},
		{
			id: 'roles',
			align: 'left',
			orderBy: false,
			label: t('users.table.header.roles'),
		},
		{
			id: 'enabled',
			align: 'left',
			orderBy: false,
			label: t('users.table.header.status'),
		},
		{
			id: 'actions',
			align: 'center',
			label: '',
			excluirExcel: true,
		},
	]

	const getRoles = (roles) => {
		return (
			<>
				{roles &&
					roles.map((rol, idx) => {
						return (
							<Box key={idx} sx={{ display: 'table' }}>
								<Typography sx={{ pl: 1 }} variant='body'>
									{rol.description}
								</Typography>
							</Box>
						)
					})}
			</>
		)
	}

	return (
		<TableContainer>
			<Table
				id='table-users-list'
				aria-labelledby='tableTitle'
				size='small'
				sx={{ minWidth: 750 }}
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					addAction={handleAddAction}
				/>
				<TableBody>
					{userList
						? stableSort(userList.data, getComparator(order, orderBy)).map((row, index) => {
								return (
									<StyledTableRow key={index}>
										<TableCell align='left'>{row.userName}</TableCell>
										<TableCell align='left'>{row.name}</TableCell>
										<TableCell align='left'>{row.surname}</TableCell>
										<TableCell align='left'>{row.email}</TableCell>
										<TableCell align='left'>{getRoles(row.roles)}</TableCell>
										<TableCell align='left'>
											{row.isEnabled ? (
												<Box sx={{ display: 'flex' }}>
													<CheckRoundedIcon fontSize='small' />
													<Typography sx={{ pl: 1 }} variant='body'>
														{t('users.table.content.enabled')}
													</Typography>
												</Box>
											) : (
												<Box sx={{ display: 'flex' }}>
													<CloseRoundedIcon fontSize='small' />
													<Typography sx={{ pl: 1 }} variant='body'>
														{t('users.table.content.disabled')}
													</Typography>
												</Box>
											)}
										</TableCell>
										<TableCell align='center'>
											<Tooltip title={t('users.table.content.update')}>
												<IconButton
													color='primary'
													onClick={() => handleFormUsers(row, modeTypesCRUD.UPDATE_MODE)}>
													<EditIcon />
												</IconButton>
											</Tooltip>
											<Tooltip title={t('users.table.content.clear')}>
													<IconButton
														color='secondary'
														onClick={() => handleFormUsers(row, modeTypesCRUD.CLEAR_MODE)}>
														<CleaningServicesIcon />
													</IconButton>
											</Tooltip>
											{row.isEnabled ? (
												<Tooltip title={t('users.table.content.delete')}>
													<IconButton
														color='error'
														onClick={() => handleFormUsers(row, modeTypesCRUD.DELETE_MODE)}>
														<DeleteIcon />
													</IconButton>
												</Tooltip>
											) : (
												<Tooltip title={t('users.table.content.enable')}>
													<IconButton
														color='primary'
														onClick={() => handleFormUsers(row, modeTypesCRUD.ENABLE_MODE)}>
														<ReplayIcon />
													</IconButton>
												</Tooltip>
											)}
										</TableCell>
									</StyledTableRow>
								)
						})
						: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
