import { Box, Button, TextField, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import SavedFiltersComponent from './SavedFiltersComponent'
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'
import SaveIcon from '@mui/icons-material/Save'
import SearchIcon from '@mui/icons-material/Search'
import { useTranslation } from 'react-i18next'
import BasicDialog from './BasicDialog'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import GradeIcon from '@mui/icons-material/Grade'
import { useEffect } from 'react'

export default function FilterFunctions({
	filters,
	selectedFilter,
	onDeleteSavedFilter,
	onClickSavedFilter,
	handleClearClick,
	saveFilter,
	resetFilter,
	threeLines,
}) {
	const [t] = useTranslation('common')

	const [open, setOpen] = useState(false)
	const [name, setName] = useState('')
	const [favSelected, setFavSelected] = useState(false)

	const handleChangeName = (event) => {
		setName(event.target.value)
	}

	const handleClear = () => {
		setFavSelected(false)
		handleClearClick()
		resetFilter()
	}

	const handleClick = (selectedFilter) => {
		setFavSelected(true)
		onClickSavedFilter(selectedFilter)
	}

	const onClose = (success) => {
		if (success) {
			saveFilter(name)
		}

		setOpen(false)
	}

	return (
		<div>
			<Box sx={{ display: 'flex', flexDirection: 'row', m: 1, width: '100%' }}>
				<SavedFiltersComponent
					filters={filters}
					filterSelected={selectedFilter}
					onClick={handleClick}
					onDelete={onDeleteSavedFilter}
					setOpen={setOpen}
					favSelected={favSelected}
				/>
			</Box>
			{threeLines && <Box sx={{ height: 51, m: 1 }}></Box>}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					m: 1,
					width: '100%',
					mt: '19px',
				}}>
				<Tooltip title={t('comun.actions.reset-filter')}>
					<Button onClick={handleClear} variant='outlined' sx={{ height: '40px', mr: 1, width: '33%' }}>
						<BackspaceOutlinedIcon />
					</Button>
				</Tooltip>
				<Tooltip title={t('comun.actions.search')}>
					<Button type='submit' variant='outlined' sx={{ height: '40px', width: '33%' }}>
						<SearchIcon />
					</Button>
				</Tooltip>
			</Box>
			<BasicDialog
				onOpen={open}
				saveTxt={t('comun.actions.save')}
				onClose={() => onClose(false)}
				onSave={() => onClose(true)}
				title={t('comun.filter.save-filter-title')}>
				<Box sx={{ display: 'flex', flexDirection: 'row', mr: 3, minWidth: '20vw' }}>
					<TextField
						size='small'
						sx={{ width: '100%' }}
						label={t('comun.filter.filter-name')}
						value={name}
						onChange={handleChangeName}
					/>
				</Box>
			</BasicDialog>
		</div>
	)
}
