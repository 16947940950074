import SearchIcon from '@mui/icons-material/Search'
import {
	Box,
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	TextField,
	Typography,
} from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { getDateLocale } from '../../../../App'

export default function ProjectVideosForm({ from, setFrom, to, setTo, checked, setChecked, onFilter }) {
	const [t, i18n] = useTranslation('common')
	const [errorMessage, setErrorMessage] = useState(null)

	useEffect(() => {
		if (checked) {
			let cams = checked.map((c, idx) => (c ? idx + 1 : null)).filter((c) => c != null)
			if (cams.length == 0) {
				setErrorMessage(t('video-request.error-cams'))
			} else {
				setErrorMessage(null)
			}
		}
	}, [checked])

	const handleChangeAll = (event) => {
		let aux = [...checked]
		setChecked(
			aux.map(() => {
				return event.target.checked
			})
		)
	}

	const handleChange = (event, idx) => {
		let aux = [...checked]
		aux[idx] = event.target.checked
		setChecked(aux)
	}

	return (
		<Box sx={{ display: 'flex', width: '100%' }}>
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<Box sx={{ display: 'flex', flexDirection: 'row' }}>
					<FormControl sx={{ width: '50%', m: 1 }} error={from == null}>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateLocale(i18n.language)}>
							<DateTimePicker
								clearable
								minTime={0}
								onChange={setFrom}
								label={t('video-request.from')}
								value={from}
								inputFormat='dd/MM/yyyy HH:mm'
								renderInput={(params) => <TextField {...params} size='small' error={from == null} />}
							/>
							{from == null && <FormHelperText>{t('video-request.error-from')}</FormHelperText>}
						</LocalizationProvider>
					</FormControl>
					<FormControl sx={{ width: '50%', m: 1 }} error={to == null}>
						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateLocale(i18n.language)}>
							<DateTimePicker
								clearable
								minTime={0}
								onChange={setTo}
								label={t('video-request.to')}
								value={to}
								inputFormat='dd/MM/yyyy HH:mm'
								renderInput={(params) => <TextField {...params} size='small' error={to == null} />}
							/>
						</LocalizationProvider>
						{to == null && <FormHelperText>{t('video-request.error-to')}</FormHelperText>}
					</FormControl>
				</Box>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', ml: 1 }}>
					<Typography sx={{ mt: 2 }}>{t('video-request.cameras')}:</Typography>
					<Box sx={{ display: 'flex', alignContent: 'center', width: '80%' }}>
						<FormControlLabel
							sx={{ ml: 1 }}
							label={t('video-request.all')}
							control={<Checkbox checked={checked.every((c) => c === true)} onChange={handleChangeAll} />}
						/>
						{checked.map((item, idx) => {
							return (
								<FormControlLabel
									key={idx}
									label={idx + 1}
									control={<Checkbox checked={item} onChange={(e) => handleChange(e, idx)} />}
								/>
							)
						})}
					</Box>
					<Button sx={{ m: 1, height: '40px', width: '8%' }} variant='outlined' onClick={onFilter}>
						<SearchIcon />
					</Button>
				</Box>
				{errorMessage != null && (
					<Typography sx={{ m: 1 }} color='error'>
						{errorMessage}
					</Typography>
				)}
			</Box>
		</Box>
	)
}
