import { useCallback, useContext, useState } from 'react'
import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const usePreviewVideoDelete = () => {
	const [dataVideoDelete, setDataVideoDelete] = useState(null)
	const [isLoadingVideoDelete, setIsLoadingVideoDelete] = useState(false)
	const [errorVideoDelete, setErrorVideoDelete] = useState(null)

	const { client } = useContext(ApiClientContext)

	const videoRequestDelete = useCallback(
		(vvReqId, type) => {
			const config = {
				params: {
					vvReqId: vvReqId,
				},
			}
			let url =
				type == 'project' ? 'horus/f3/project/preview/videorequest' : 'horus/f3/dashboard/preview/videorequest'
			client
				.delete(baseUrl + url, config)
				.then((result) => {
					setIsLoadingVideoDelete(false)
					if (result.status === 200 && result.data.success) {
						setDataVideoDelete(result.data)
					} else {
						setErrorVideoDelete({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingVideoDelete(false)
					setErrorVideoDelete(err.response)
				})
			setIsLoadingVideoDelete(true)
			setDataVideoDelete(null)
			setErrorVideoDelete(null)
		},
		[client]
	)

	return {
		videoRequestDelete,
		dataVideoDelete,
		isLoadingVideoDelete,
		errorVideoDelete,
	}
}
