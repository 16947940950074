import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useReportDelete = () => {
	const [reportDataDelete, setReportDataDelete] = useState(null)
	const [errorReportDelete, setErrorReportDelete] = useState(null)
	const [isLoadingReporDelete, setIsLoadingReporDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const reportDelete = useCallback(
		(props) => {
			const config = {
				data: {
					id: props.id,
					projectId: props.projectId,
				},
			}

			client
				.delete(baseUrl + 'horus/f3/project/export', config)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setReportDataDelete({ data: result.data })
					} else {
						setErrorReportDelete({ statusText: result.data.errorMsg })
					}
					setIsLoadingReporDelete(false)
				})
				.catch((error) => {
					setErrorReportDelete(error.response)
					setIsLoadingReporDelete(false)
				})

			setReportDataDelete(null)
			setErrorReportDelete(null)
			setIsLoadingReporDelete(true)
		},
		[client]
	)

	return {
		reportDelete,
		isLoadingReporDelete,
		reportDataDelete,
		errorReportDelete,
	}
}
