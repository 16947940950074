import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useReportDownload = () => {
	const [reportDataDownload, setReportDataDownload] = useState(null)
	const [errorReportDownload, setErrorReportDownload] = useState(null)
	const [isLoadingDownload, setIsLoadingDownload] = useState(false)

	const { client } = useContext(ApiClientContext)
	const reportDownload = useCallback(
		(props) => {
			client
				.get(baseUrl + 'horus/f3/project/export/download?' + prepareParams(props), { responseType: 'blob' })
				.then((result) => {
					if (result.status === 200) {
						setReportDataDownload({ data: result })
					} else {
						setErrorReportDownload({ statusText: result.data.errorMsg })
					}
					setIsLoadingDownload(false)
				})
				.catch((error) => {
					setErrorReportDownload(error.response)
					setIsLoadingDownload(false)
				})

			setReportDataDownload(null)
			setErrorReportDownload(null)
			setIsLoadingDownload(true)
		},
		[client]
	)

	return {
		reportDownload,
		isLoadingDownload,
		reportDataDownload,
		errorReportDownload,
	}
}
