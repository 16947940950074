import Typography from '@mui/material/Typography'
import React from 'react'

export default function Version({ tag }) {
	return (
		<Typography
			component='span'
			variant='body2'
			color='text.secondary'
			align='center'
			sx={{ p: 2, fontStyle: 'italic' }}>
			{tag}
		</Typography>
	)
}
