import axios from 'axios'
import { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'

export default function useUserSetPassword() {
	const [data, setData] = useState(null)
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	const userSetPassword = useCallback((code, password) => {
		const headers = {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			password: password,
			code: code,
		}

		axios
			.put(baseUrl + 'horus/f3/usersetpassword', headers)
			.then((result) => {
				setIsLoading(false)
				if (result.data.success) {
					setData(result.data)
				} else {
					/*Error*/
					setError({ statusText: result.data.errorMsg })
				}
			})
			.catch((err) => {
				setIsLoading(false)
				setError(err.response)
			})

		setData(null)
		setError(null)
		setIsLoading(true)
	}, [])

	return {
		userSetPassword,
		isLoading,
		data,
		error,
	}
}
