import React, { useMemo } from 'react'
import { Polyline } from 'react-leaflet'

const redTH = 10.8
const yellowTH = 11.2

export default function DashboardMapPolylinesLayer({ trackList, moving }) {
	const getColor = (p) => {
		if (p.speed < redTH) {
			return 'red'
		} else if (p.speed >= redTH && p.speed <= yellowTH) {
			return 'yellow'
		} else if (p.speed > yellowTH) {
			return 'green'
		} else return 'blue'
	}

	const trackListWithColor = useMemo(() => trackList?.map((p) => ({ ...p, color: getColor(p) })), [trackList])
	return (
		<>
			{trackListWithColor &&
				!moving &&
				trackListWithColor.length != 0 &&
				trackListWithColor
					?.map((latlng, index) =>
						index < trackListWithColor.length - 1 ? (
							<Polyline
								key={index}
								positions={[
									[latlng.lat, latlng.lon],
									[trackListWithColor[index + 1].lat, trackListWithColor[index + 1].lon],
								]}
								color={latlng.color}
								weight={0.5}></Polyline>
						) : null
					)
					.filter(Boolean)}
		</>
	)
}
