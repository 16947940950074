import { TableFooter } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import TablePaginationComponent from '../comun/TablePaginationComponent'
import { EnhancedTableHead, getComparator, stableSort, TimerTableCell } from '../comun/TableUtils'
import { datetimeUTCtoString } from '../comun/DateUtils'

export default function TimerUsersTable({
    dataTimerUsers, 
    filter, 
    onFilter,
	handleFormTimerUsers,
	handleRowSelected,
	rowSelected
}) {
	const [t] = useTranslation('common')

	//This block should be the same in every table component ----------------------------------
	const [order, setOrder] = React.useState(filter.orderDirection)
	const [orderBy, setOrderBy] = React.useState(filter.orderField)
	const [page, setPage] = React.useState(0)
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(filter.pageSize)

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------
	
	const handleCursor = (row) => {
		return ((row.time.days || row.time.hours || row.time.minutes || row.time.seconds) ? 'pointer' : 'auto')
	}

	React.useEffect(() => {
		if (dataTimerUsers) {
			setPage(dataTimerUsers.pageNo - 1)
			setTotalCount(dataTimerUsers.totalCount)
		}
	}, [dataTimerUsers])

	const columnas = [
		{
			id: 'userName',
			orderBy: true,
			label: t('timer.table.header.user'),
		},
		{
			id: 'date',
			orderBy: true,
			label: t('timer.table.header.date'),
		},
		{
			id: 'total',
			orderBy: true,
			label: t('timer.table.header.time'),
			excluirExcel: true,
		}
	]

	return (
		<TableContainer>
			<Table
				id='table-timer-users'
				sx={{ minWidth: 750 }}
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
				/>
				<TableBody>
					{dataTimerUsers	? 
						stableSort(dataTimerUsers.data, getComparator(order, orderBy)).map((row, index) => {
							return (
								<StyledTableRow 
									key={ index } 
									sx={{ cursor: handleCursor(row), background: handleRowSelected(row, rowSelected)}}
									onClick={() => handleFormTimerUsers(row)}
								>
									<TableCell align='center'>{row.userName}</TableCell>
									<TableCell align='center'>{datetimeUTCtoString(row.date)}</TableCell>
									<TimerTableCell	timerType={row.time}/>
								</StyledTableRow>
							)
						})
					: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
