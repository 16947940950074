import ChatIcon from '@mui/icons-material/Chat'
import CircleIcon from '@mui/icons-material/Circle'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import LiveTvIcon from '@mui/icons-material/LiveTv'
import NotificationsIcon from '@mui/icons-material/Notifications'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'
import WifiIcon from '@mui/icons-material/Wifi'
import WifiOffIcon from '@mui/icons-material/WifiOff'
import { Badge, Box, Collapse, IconButton, TableRow, Tooltip, Typography } from '@mui/material'
import Chip from '@mui/material/Chip'
import { green, red, yellow } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import TableCell from '@mui/material/TableCell'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { datetimeUTCtoString } from '../../comun/DateUtils'

export default function DashboardRow({
	row,
	handleRowClick,
	vesselSelected,
	goToMessenger,
	goToNotifications,
	goToPreview,
}) {
	const [t] = useTranslation('common')
	const [open, setOpen] = useState(false)
	//status 1 rojo 2 amarillo 3 verde
	const getChipContent = (cam) => {
		return (
			<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
				{cam.conn == 1 ? (
					<WifiOffIcon color='error' />
				) : cam.conn == 2 ? (
					<WifiOffIcon color='warning' />
				) : (
					<WifiIcon color='success' />
				)}
				<Typography sx={{ mx: 0.5 }}>C{cam.camNo}</Typography>
				{cam.rec == 1 ? (
					<VideocamOffIcon color='error' />
				) : cam.rec == 2 ? (
					<VideocamOffIcon color='warning' />
				) : (
					<VideocamIcon color='success' />
				)}
			</Box>
		)
	}
	const StyledBadge = styled(Badge)(({ theme }) => ({
		'& .MuiBadge-badge': {
			right: -2,
			top: 1,
			border: `2px solid ${theme.palette.background.paper}`,
			padding: '0 3px',
		},
	}))
	useEffect(() => {
		if (vesselSelected) {
			if (vesselSelected.vesselId == row.vesselId) {
				setOpen(true)
			} else {
				setOpen(false)
			}
		} else {
			setOpen(false)
		}
	}, [vesselSelected])

	return (
		<React.Fragment>
			<TableRow selected={row.selected} onClick={() => handleRowClick(row)}>
				<TableCell valign='center'>
					<Box sx={{ display: 'flex', alignItems: 'center' }}>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						<Typography sx={{ ml: 4 }}>{row.vesselName}</Typography>
					</Box>
				</TableCell>
				<TableCell align='center'>
					<Tooltip title={datetimeUTCtoString(row.date)}>
						<Typography variant='body2'>{row.dateDifDesc}</Typography>
					</Tooltip>
				</TableCell>
				<TableCell align='center'>
					<CircleIcon
						sx={{
							fill: row.seatubeStatus == 1 ? red[500] : row.seatubeStatus == 2 ? yellow[500] : green[500],
						}}
					/>
				</TableCell>
				<TableCell align='center' className='excluir-excel'>
					<Tooltip title={t('dashboard.go-to-messenger')}>
						<IconButton color='primary' sx={{ mx: 1 }} onClick={(e) => goToMessenger(row, e)}>
							<StyledBadge badgeContent={row.unreadChats} color='error' overlap='circular'>
								<ChatIcon />
							</StyledBadge>
						</IconButton>
					</Tooltip>
					<Tooltip title={t('dashboard.go-to-notifications')}>
						<IconButton color='primary' sx={{ mx: 1 }} onClick={(e) => goToNotifications(row, e)}>
							<StyledBadge badgeContent={row.unreadNotifications} color='error' overlap='circular'>
								<NotificationsIcon />
							</StyledBadge>
						</IconButton>
					</Tooltip>
					<Tooltip title={t('dashboard.go-to-preview')}>
						<IconButton color='primary' sx={{ mx: 1 }} onClick={(e) => goToPreview(row, e)}>
							<LiveTvIcon />
						</IconButton>
					</Tooltip>
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout='auto' unmountOnExit sx={{ display: 'flex', flexDirection: 'column' }}>
						<Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
							{row.camStatusList.map((cam) => {
								return (
									<Chip
										sx={{ mx: 0.5, my: 1 }}
										key={cam.camNo}
										variant='outlined'
										label={getChipContent(cam)}
									/>
								)
							})}
						</Box>
						<Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
							{row.serviceStatusList.map((item) => {
								return (
									<Chip
										sx={{ m: 1 }}
										key={item.name}
										label={item.name}
										variant='outlined'
										color={item.status == 1 ? 'error' : item.status == 2 ? 'warning' : 'success'}
									/>
								)
							})}
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	)
}
