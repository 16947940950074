import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { baseFilter, codesActivityTimer, messageDialogTypes, modeTypesCRUD } from '../../constants'
import { useProgramDelete } from '../../services/programs/useProgramDelete'
import { useProgramRequest } from '../../services/programs/useProgramRequest'
import { useUserLog } from '../../services/timer/useUserLog'
import { getGeneralActivity } from '../../utils/TimerDataUtils'

import MessageSnackBar from '../comun/MessageSnackBar'
import ConfirmationDialog from './../comun/ConfirmationDialog'
import FilterPrograms from './ProgramFilter'
import ProgramForm from './ProgramForm'
import ProgramTable from './ProgramTable'

import { useProgramInsert } from '../../services/programs/useProgramInsert'
import { useProgramUpdate } from '../../services/programs/useProgramUpdate'
import { datetimeGMTToLocaleInISOFormat } from '../comun/DateUtils'

import { useProgramFilters } from '../../services/programs/useProgramFilters'
import { useProgramRequestById } from '../../services/programs/useProgramRequestById'
import BackdropComponent from '../comun/utils/BackdropComponent'
import { convertAnalysis, convertUser, convertExports } from './ProgramsFunctions'

//Set de column to sort and the direction for the first search, baseFilter contains the rowsPerPage and pageNo
const initialFilter = { ...baseFilter, orderDirection: 'desc', orderField: 'startDate' }

export default function ProgramsContainer() {
	const [t] = useTranslation('common')
	const [programList, setProgramList] = useState(null)
	const [clients, setClients] = useState([])
	const [roles, setRoles] = useState([])
	const [fishingProfiles, setFishingProfiles] = useState([])
	const [users, setUsers] = useState([])
	const [vessels, setVessels] = useState([])
	const [exports, setExports] = useState([])

	const [filterProject, setFilterProject] = useState('')

	const defaultRow = {
		description: '',
		startDate: '',
		endDate: '',
		client: null,
		vessels: [],
		typeAnalysis: [],
		users: [],
		exports: [],
	}
	const [programSelected, setProgramSelected] = useState(defaultRow)

	const { userLogRequest } = useUserLog()

	//PlaningFilter : Obtiene valores para todos los combos
	const { programFilters, isLoadingPlanning, planningData, errorPlanningData } = useProgramFilters()

	//PlaningFilterProgram : Filtro para obtener Listado de Programas
	const { requestProgram, isLoadingPlanningProgram, planningProgramData, errorPlanningProgramData } =
		useProgramRequest()

	//Get de programa
	const { requestProgramById, isLoadingProgram, programData, errorProgramData } = useProgramRequestById()

	//Delete Programa
	const { programDelete, isLoadingProgramDelete, programDataDelete, errorProgramDelete } = useProgramDelete()

	const { programUpdate, isLoadingUpdate, programDataUpdate, errorProgramUpdate } = useProgramUpdate()
	const { programInsert, isLoadingInsert, programDataInsert, errorProgramInsert } = useProgramInsert()

	//Control de errores
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeMessage, setTypeMessage] = useState(null)

	//Modales
	const [open, setOpen] = useState(false)
	const [mode, setMode] = useState(modeTypesCRUD.INSERT_MODE)

	//Filtro
	const [filter, setFilter] = useState(initialFilter)
	const [openDelete, setOpenDelete] = useState(false)

	const [favouriteFilters, setFavourites] = useState(null)
	const [statusOptions, setStatusOptions] = useState(null)

	const onFilter = (data) => {
		setFilter(data)
		requestProgram(data)
	}

	useEffect(() => {
		userLogRequest(getGeneralActivity(codesActivityTimer.PLANNING_PAGE, 'PLANNING_PAGE'))
	}, [])

	useEffect(() => {
		programFilters()
	}, [])

	useEffect(() => {
		if (errorPlanningData) {
			setOpenError(true)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setErrorMsg(errorPlanningData)
		}
	}, [errorPlanningData])

	useEffect(() => {
		if (planningData != null) {
			setFilterProject(planningData.filterProject)
			setClients(planningData.clients)
			setRoles(planningData.rolEMStaff)
			setUsers(planningData.users)
			setVessels(planningData.vessels)
			setFishingProfiles(planningData.fishingprofiles)
			let aux = planningData.refProjectExports
			aux = aux.map((exp) => ({ ...exp, projectExportId: exp.exportId, programAnalysisTypeId: null }))
			setExports(aux)
			setProgramList(planningData.resultFilter)
			setFavourites(planningData.favouritesDic)
			setStatusOptions(planningData.statusOptions)
		}
	}, [planningData])

	useEffect(() => {
		if (errorPlanningProgramData) {
			setOpenError(true)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setErrorMsg(errorPlanningProgramData)
		} else if (planningProgramData != null) {
			setProgramList(planningProgramData)
		}
	}, [planningProgramData, errorPlanningProgramData])

	useEffect(() => {
		if (errorProgramData) {
			setOpenError(true)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setErrorMsg(errorProgramData)
		} else if (errorProgramDelete) {
			setOpenError(true)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setErrorMsg(errorProgramDelete)
		} else if (errorProgramInsert) {
			setOpenError(true)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setErrorMsg(errorProgramInsert)
		} else if (errorProgramUpdate) {
			setOpenError(true)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setErrorMsg(errorProgramUpdate)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorProgramData, errorProgramDelete, errorProgramInsert, errorProgramUpdate])

	useEffect(() => {
		if (programData) {
			setProgramSelected(programData)
			setOpen(true)
		}
	}, [programData])

	useEffect(() => {
		if (programDataInsert || programDataUpdate || programDataDelete) {
			onClose()
			requestProgram(filter)
		}
	}, [programDataInsert, programDataUpdate, programDataDelete])

	const handleCloseError = () => {
		setOpenError(false)
		requestProgram(filter)
	}

	const onClose = () => {
		setOpen(false)
	}

	/**************** LISTADO RESULTADOS ****************/
	const handleFormPrograms = (row, mode) => {
		setMode(mode)
		if (modeTypesCRUD.UPDATE_MODE === mode) {
			requestProgramById(row.id)
		} else if (modeTypesCRUD.DELETE_MODE === mode) {
			setOpenDelete(true)
			setProgramSelected(row)
		} else if (modeTypesCRUD.INSERT_MODE === mode) {
			setProgramSelected(defaultRow)
			setOpen(true)
		}
	}

	/******************** EVENTOS MODAL************/

	const onCloseDelete = (confirmed) => {
		setOpenDelete(false)
		if (confirmed) {
			programDelete(programSelected.id)
		}
	}

	const parseData = (data) => {
		let program = {}
		program.description = data.description
		program.client = data.clients
		var startDateISO = datetimeGMTToLocaleInISOFormat(data.startDate)
		program.startDate = startDateISO
		program.endDate = data.endDate == '' ? null : datetimeGMTToLocaleInISOFormat(data.endDate)
		program.vessels = data.vessels
		program.users = convertUser(data.selected_users)
		program.typeAnalysis = convertAnalysis(data.selected_analysis)

		return program
	}

	const onSave = (data) => {
		let program = parseData(data)

		if (modeTypesCRUD.UPDATE_MODE === mode) {
			program.id = data.id
			programUpdate(program)
		} else if (modeTypesCRUD.INSERT_MODE === mode) {
			programInsert(program)
		}
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<BackdropComponent
				open={
					isLoadingProgram ||
					isLoadingPlanningProgram ||
					isLoadingPlanning ||
					isLoadingProgramDelete ||
					isLoadingUpdate ||
					isLoadingInsert
				}
			/>

			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={typeMessage}
				handleCloseError={handleCloseError}
			/>
			<Box sx={{ width: '100%' }}>
				{users ? (
					<FilterPrograms
						users={users}
						clients={clients}
						vessels={vessels}
						onFilter={onFilter}
						filter={filter}
						filterProject={filterProject}
						favouriteFilters={favouriteFilters}
						statusOptions={statusOptions}></FilterPrograms>
				) : null}
			</Box>

			<Divider sx={{ my: 2 }} variant='middle' />

			<Box>
				<ProgramTable
					filter={filter}
					onFilter={onFilter}
					programList={programList}
					handleFormPrograms={handleFormPrograms}></ProgramTable>
			</Box>

			{open ? (
				<ProgramForm
					programSelected={programSelected}
					users={users}
					clients={clients}
					vessels={vessels}
					roles={roles}
					fishingProfiles={fishingProfiles}
					exports={exports}
					mode={mode}
					onclose={onClose}
					open={open}
					onSave={onSave}></ProgramForm>
			) : null}

			<ConfirmationDialog
				onOpen={openDelete}
				onClose={onCloseDelete}
				dialogTitle={t('programs.form.title-delete')}
				dialogContent={t('programs.form.delete-content')}
				dialogSaveButton={t('comun.actions.delete')}
				dialogCancelButton={t('comun.actions.close')}
			/>
		</Box>
	)
}
