import * as React from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useProjectStatusUpdate = () => {
	const [projectDataStatusUpdate, setProjectDataStatusUpdate] = React.useState(null)
	const [errorProjectStatusUpdate, setErrorProjectStatusUpdate] = React.useState(null)
	const [isLoadingStatusUpdate, setIsLoadingStatusUpdate] = React.useState(false)

	const { client } = React.useContext(ApiClientContext)

	const projectStatusUpdate = React.useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/project/status?' + prepareParams(props))
				.then((result) => {
					setIsLoadingStatusUpdate(false)
					if (result.status === 200 && result.data.success) {
						setProjectDataStatusUpdate({ data: result.data })
					} else {
						setErrorProjectStatusUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectStatusUpdate(error.response)
					setIsLoadingStatusUpdate(false)
				})

			setIsLoadingStatusUpdate(true)
			setProjectDataStatusUpdate(null)
			setErrorProjectStatusUpdate(null)
		},
		[client]
	)

	return {
		projectStatusUpdate,
		isLoadingStatusUpdate,
		projectDataStatusUpdate,
		errorProjectStatusUpdate,
	}
}
