import { useCallback, useState } from 'react'
import { useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useDashboardInfo = () => {
	const [dashboardData, setDashboardData] = useState(null)
	const [refreshInterval, setRefreshInterval] = useState(null)
	const [error, setError] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { client } = useContext(ApiClientContext)

	const dashboardInfo = useCallback(() => {
		client
			.get(baseUrl + 'horus/f3/dashboard')
			.then((result) => {
				setIsLoading(false)
				if (result.data.success) {
					//let x = result.data.data.forEach((item) => (item.selected = false))
					setDashboardData(result.data.data)
					setRefreshInterval(result.data.refreshIntSec * 1000)
				} else {
					setError({ statusText: result.data.errorMsg })
				}
			})
			.catch((err) => {
				setIsLoading(false)
				setError(err.response)
			})
		setDashboardData(null)
		setError(null)
		setIsLoading(true)
	}, [client])
	return {
		refreshInterval,
		dashboardInfo,
		isLoading,
		dashboardData,
		error,
		setError,
	}
}
