import { Box, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
	baseFilter,
	codesActivityTimer,
	currentProjectCookie,
	messageDialogTypes,
	previousState,
} from '../../constants'
import { useProjectDeclarationFilterRequest } from '../../services/projects/useProjectDeclarationFilterRequest'
import { useProjectDeclarationRequest } from '../../services/projects/useProjectDeclarationRequest'
import { useProjectFilterRequest } from '../../services/projects/useProjectFilterRequest'
import { useProjectInsert } from '../../services/projects/useProjectInsert'
import { useProjectRequest } from '../../services/projects/useProjectRequest'
import { useProjectStatusUpdate } from '../../services/projects/useProjectStatusUpdate'
import { useProjectUpdate } from '../../services/projects/useProjectUpdate'
import { useUserLog } from '../../services/timer/useUserLog'
import { getGeneralActivity } from '../../utils/TimerDataUtils'
import { removeLocalStorageItem, setCookie } from '../../utils/cookie'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import ChangeStatusForm from './ChangeStatusForm'
import ListNotesContainer from './ListNotesContainer'
import ProjectFilter from './ProjectFilter'
import ProjectForm from './ProjectForm'
import ProjectTable from './ProjectTable'

const initialFilter = {
	...baseFilter,
	orderDirection: 'asc',
	orderField: 'description',
	StartDate: null,
	EndDate: null,
	Status: null,
	auxStatus: null,
}

const actionsProject = {
	CHANGE_STATUS: 1,
	INSERT_PROJECT: 2,
	UPDATE_PROJECT: 3,
	PREVIEW: 4,
	LIST_NOTES: 5,
	EXPORT_PROJECT: 6,
}

export default function ProjectsContainer({ prevState }) {
	const history = useHistory()

	//Services
	const { projectFilterRequest, isLoadingProjectFilter, projectFilter, errorProjectFilter } =
		useProjectFilterRequest()
	const { projectRequest, isLoadingProject, dataProject, errorProject } = useProjectRequest()
	const { projectInsert, isLoadingInsert, projectDataInsert, errorProjectInsert } = useProjectInsert()
	const { projectUpdate, isLoadingUpdate, projectDataUpdate, errorProjectUpdate } = useProjectUpdate()
	const { projectStatusUpdate, isLoadingStatusUpdate, projectDataStatusUpdate, errorProjectStatusUpdate } =
		useProjectStatusUpdate()
	const {
		projectDeclarationFilterRequest,
		isLoadingProjectDeclarationFilter,
		projectDeclarationFilter,
		errorProjectDeclarationFilter,
	} = useProjectDeclarationFilterRequest()
	const { projectDeclarationRequest, isLoadingProjectDeclaration, projectDeclarationData, errorProjectDeclaration } =
		useProjectDeclarationRequest()

	const [filterReviews, setFilterReviews] = useState(null)
	const [projectList, setProjectList] = useState(null)
	const [vesselOptions, setVesselOptions] = useState([])
	const [programOptions, setProgramOptions] = useState([])
	const [listNotesOptions, setListNotesOptions] = useState([])
	const [filteredListNotesData, setFilteredListNotesData] = useState([])

	const { userLogRequest } = useUserLog()

	//Filter
	const [filter, setFilter] = useState(initialFilter)
	const [filterListNotes, setFilterListNotes] = useState(null)

	const [favouriteFilters, setFavourites] = React.useState(null)
	const [statusOptions, setStatusOptions] = React.useState(null)

	//Errors control
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeMessage, setTypeMessage] = useState(null)

	//Modals
	const [openForm, setOpenForm] = useState(false)
	const [openStatusForm, setOpenStatusForm] = useState(false)
	const [openListNotes, setOpenListNotes] = useState(false)
	const [mode, setMode] = useState(false)
	const [projectSelected, setProjectSelected] = useState(null)

	useEffect(() => {
		projectFilterRequest()
	}, [])

	useEffect(() => {
		if (prevState) {
			if (prevState.url == '/project') {
				onFilter(prevState.filter)
				setFilterListNotes(prevState.filterNotes)
				handleFormProject(prevState.project, prevState.action)
			}
			removeLocalStorageItem(previousState)
		}
	}, [prevState])

	useEffect(() => {
		if (dataProject) {
			setProjectList(dataProject)
		}
	}, [dataProject])

	useEffect(() => {
		if (projectFilter) {
			setVesselOptions(projectFilter.vesselList)
			setProgramOptions(projectFilter.programList)
			setFilterReviews(projectFilter.filterReviews)
			setProjectList(projectFilter.resultFilter)
			setFavourites(projectFilter.favouritesDic)
			setStatusOptions(projectFilter.statusOptions)
		}
	}, [projectFilter])

	useEffect(() => {
		if (projectDeclarationData) {
			if (filterListNotes) {
				onFilterListNotes(filterListNotes)
			} else {
				setFilteredListNotesData(projectDeclarationData)
			}
		}
	}, [projectDeclarationData])

	useEffect(() => {
		if (projectDeclarationFilter) {
			setListNotesOptions(projectDeclarationFilter)
		}
	}, [projectDeclarationFilter])

	useEffect(() => {
		if (projectDeclarationFilter && projectDeclarationData) {
			setOpenListNotes(true)
		}
	}, [projectDeclarationFilter, projectDeclarationData])

	useEffect(() => {
		if (projectDataInsert || projectDataUpdate || projectDataStatusUpdate) {
			onClose()
			onCloseChangeStatus()
			projectRequest(filter)
		}
	}, [projectDataInsert, projectDataUpdate, projectDataStatusUpdate])

	useEffect(() => {
		if (
			errorProjectFilter ||
			errorProject ||
			errorProjectInsert ||
			errorProjectUpdate ||
			errorProjectStatusUpdate ||
			errorProjectDeclarationFilter ||
			errorProjectDeclaration
		) {
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			setOpenError(true)
			if (errorProjectFilter) {
				setErrorMsg(errorProjectFilter)
			} else if (errorProject) {
				setErrorMsg(errorProject)
			} else if (errorProjectInsert) {
				setErrorMsg(errorProjectInsert)
			} else if (errorProjectUpdate) {
				setErrorMsg(errorProjectUpdate)
			} else if (errorProjectStatusUpdate) {
				setErrorMsg(errorProjectStatusUpdate)
			} else if (errorProjectDeclarationFilter) {
				setErrorMsg(errorProjectDeclarationFilter)
			} else if (errorProjectDeclaration) {
				setErrorMsg(errorProjectDeclaration)
			}
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [
		errorProjectFilter,
		errorProject,
		errorProjectInsert,
		errorProjectUpdate,
		errorProjectStatusUpdate,
		errorProjectDeclaration,
		errorProjectDeclarationFilter,
	])

	const onFilter = (data) => {
		setFilter(data)
		projectRequest(data)
	}

	const isSubstringInArray = (searching, array) => {
		const matches = array.filter((row) => row.includes(searching))

		return matches.length > 0
	}

	const onFilterListNotes = (data) => {
		setFilterListNotes(data)
		let filteredListNotesData = projectDeclarationData.filter((row) => {
			if (data.cameras.length && data.cameras.indexOf(row.camera) === -1) {
				return false
			}
			if (data.types.length) {
				let matches = data.types.filter((item) => {
					return item.description === row.itemTypeDesc
				})
				if (!matches.length) {
					return false
				}
			}
			if (data.description.length) {
				if (
					!(
						row.itemTypeCode.indexOf(data.description.trim()) !== -1 ||
						row.itemTypeDesc.indexOf(data.description.trim()) !== -1 ||
						isSubstringInArray(data.description.trim(), row.comments) ||
						isSubstringInArray(data.description.trim(), row.computedData) ||
						isSubstringInArray(data.description.trim(), row.notes)
					)
				) {
					return false
				}
			}
			return true
		})
		setFilteredListNotesData(filteredListNotesData)
	}

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const onClose = () => {
		setOpenForm(false)
	}

	const onCloseChangeStatus = () => {
		setOpenStatusForm(false)
	}

	const onCloseListNotes = () => {
		setFilterListNotes(null)
		setOpenListNotes(false)
		userLogRequest(getGeneralActivity(codesActivityTimer.PROJECT_GET, 'PROJECT_GET'))
	}

	const parseData = (data) => {
		let config = {}

		if (actionsProject.INSERT_PROJECT == mode) {
			config.programId = data.programId?.programId
			config.typeAnalysisId = data.typeAnalysisId?.typeAnalysisId
			config.vesselApiId = data.vesselApiId?.vesselApiId
			config.projectStatusId = data.projectStatusId?.value
		} else if (actionsProject.UPDATE_PROJECT == mode) {
			config.projectId = projectSelected.projectId
			config.programName = projectSelected.programName
			config.programId = projectSelected.programId
			config.typeAnalysisId = projectSelected.typeAnalysisId
			config.vesselApiId = projectSelected.vesselApiId
			config.projectStatusId = projectSelected.projectId
		}
		config.editedBy = window.localStorage.getItem('userProfileName').replace(/['"]+/g, '')
		config.description = data.description
		config.projectRef = data.projectRef
		config.rfmo = data.rfmo

		return config
	}

	const onSave = (data) => {
		let config = parseData(data)

		if (actionsProject.INSERT_PROJECT == mode) {
			projectInsert(config)
		} else if (actionsProject.UPDATE_PROJECT == mode) {
			projectUpdate(config)
		} else if (actionsProject.CHANGE_STATUS == mode) {
			config = {}
			config.projectId = data.projectId
			config.newProjectStatusId = data.status.id
			projectStatusUpdate(config)
		}
	}

	const handleGoToReports = (row) => {
		let project = {
			projectDescription: row.description,
			projectId: row.projectId,
		}
		setCookie(currentProjectCookie, JSON.stringify(project))
		history.push(`project/${row.projectId}/reports`)
	}

	const handleFormProject = (row, action) => {
		setMode(action)
		if (actionsProject.UPDATE_PROJECT == action) {
			setProjectSelected(row)
			setOpenForm(true)
		} else if (actionsProject.INSERT_PROJECT == action) {
			setOpenForm(true)
		} else if (actionsProject.CHANGE_STATUS == action) {
			setProjectSelected(row)
			setOpenStatusForm(true)
		} else if (actionsProject.LIST_NOTES == action) {
			setProjectSelected(row)
			projectDeclarationFilterRequest(row.projectId)
			projectDeclarationRequest(row.projectId)
		} else if (actionsProject.EXPORT_PROJECT === action) {
			handleGoToReports(row)
		}
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<Box sx={{ width: '100%' }}>
				<ProjectFilter
					vessels={vesselOptions}
					programs={programOptions}
					statusOptions={statusOptions}
					onFilter={onFilter}
					filter={filter}
					filterReviews={filterReviews}
					setFilterReviews={setFilterReviews}
					favouriteFilters={favouriteFilters}
				/>
			</Box>

			<Divider sx={{ my: 2 }} variant='middle' />

			<Box>
				<ProjectTable
					projectList={projectList}
					actions={actionsProject}
					filter={filter}
					onFilter={onFilter}
					handleFormProject={handleFormProject}
				/>
			</Box>

			<BackdropComponent
				open={
					isLoadingProjectFilter ||
					isLoadingProject ||
					isLoadingInsert ||
					isLoadingUpdate ||
					isLoadingStatusUpdate ||
					isLoadingProjectDeclarationFilter ||
					isLoadingProjectDeclaration
				}
			/>

			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				handleCloseError={handleCloseError}
				typeMsg={typeMessage}
			/>
			{openForm && (
				<ProjectForm
					programs={programOptions}
					status={statusOptions}
					project={projectSelected}
					actions={actionsProject}
					action={mode}
					open={openForm}
					onSave={onSave}
					onClose={onClose}
				/>
			)}

			<ChangeStatusForm
				project={projectSelected}
				open={openStatusForm}
				onSave={onSave}
				onClose={onCloseChangeStatus}
			/>

			<ListNotesContainer
				filterListNotes={filterListNotes}
				actionsProject={actionsProject}
				listNotesOptions={listNotesOptions}
				project={projectSelected}
				open={openListNotes}
				filter={filter}
				onClose={onCloseListNotes}
				onFilter={onFilterListNotes}
				filteredListNotesData={filteredListNotesData}
			/>
		</Box>
	)
}
