import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo'
import DesignServicesIcon from '@mui/icons-material/DesignServices'
import EditIcon from '@mui/icons-material/Edit'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import NoteIcon from '@mui/icons-material/Note'
import VideoSettingsIcon from '@mui/icons-material/VideoSettings'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, TableFooter, Tooltip } from '@mui/material'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { datetimeUTCtoString } from '../comun/DateUtils'
import { StyledTableRow } from '../comun/StyleComponentUtils'
import TablePaginationComponent from '../comun/TablePaginationComponent'
import { EnhancedTableHead, getComparator, stableSort } from '../comun/TableUtils'

export default function ProjectTable({ projectList, actions, handleFormProject, filter, onFilter }) {
	const [t] = useTranslation('common')
	const history = useHistory()

	//This block should be the same in every table component ----------------------------------
	const [order, setOrder] = React.useState(filter.orderDirection)
	const [orderBy, setOrderBy] = React.useState(filter.orderField)
	const [page, setPage] = React.useState(0)
	const [totalCount, setTotalCount] = React.useState(0)
	const [rowsPerPage, setRowsPerPage] = React.useState(filter.pageSize)

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------

	React.useEffect(() => {
		if (projectList) {
			setPage(projectList.pageNo - 1)
			setTotalCount(projectList.totalCount)
		}
	}, [projectList])

	const handleAddAction = () => {
		handleFormProject(null, actions.INSERT_PROJECT)
	}

	const handleGoToPreview = (e, row) => {
		e.stopPropagation()
		history.push({ pathname: '/preview', state: { vessel: null, project: row } })
	}

	const columnas = [
		{
			id: 'collapse',
			label: '',
			excluirExcel: true,
		},
		{
			id: 'calibration',
			label: '',
			excluirExcel: true,
		},
		{
			id: 'description',
			orderBy: true,
			label: t('project.table.header.description'),
		},
		// {
		// 	id: 'project-ref',
		// 	orderBy: false,
		// 	label: t('project.table.header.project-ref'),
		// },
		{
			id: 'program',
			orderBy: false,
			label: t('project.table.header.program'),
		},

		{
			id: 'analysis-type',
			orderBy: false,
			label: t('project.table.header.analysis-type'),
		},
		{
			id: 'fishing',
			orderBy: false,
			label: t('project.table.header.fishing'),
		},
		{
			id: 'vessel',
			orderBy: false,
			label: t('project.table.header.vessel'),
		},
		{
			id: 'status',
			orderBy: false,
			label: t('project.table.header.status'),
		},
		{
			id: 'actions',
			label: '',
			excluirExcel: true,
		},
	]

	function Row(props) {
		const { row } = props
		const [open, setOpen] = React.useState(false)

		return (
			<>
				<StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
					<TableCell width={50}>
						<IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
							{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					</TableCell>
					<TableCell align='center'>
						{row.vesselHasCalibrations ? (
							<Tooltip title={t('project.table.content.has-calibration')}>
								<DesignServicesIcon color='primary' />
							</Tooltip>
						) : (
							<Tooltip title={t('project.table.content.no-calibration')}>
								<WarningIcon color='primary' />
							</Tooltip>
						)}
					</TableCell>
					<TableCell align='center'>{row.description}</TableCell>
					{/* <TableCell align='center'>{row.projectRef}</TableCell> */}
					<TableCell align='center'>{row.programName}</TableCell>
					<TableCell align='center'>{row.typeAnalysisName}</TableCell>
					<TableCell align='center'>{row.fishingProfileName}</TableCell>
					<TableCell align='center'>{row.vesselName}</TableCell>
					<TableCell align='center'>{row.projectStatusName}</TableCell>
					<TableCell align='center'>
						{row.projectStatusId !== 6 ? (
							<Tooltip title={t('project.table.content.change-status')}>
								<IconButton
									size='small'
									color='primary'
									onClick={() => handleFormProject(row, actions.CHANGE_STATUS)}>
									<CompareArrowsIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title={t('project.table.content-disabled.change-status')}>
								<IconButton sx={{ cursor: 'auto' }} size='small'>
									<CompareArrowsIcon disabled />
								</IconButton>
							</Tooltip>
						)}

						{row.projectStatusId !== 6 ? (
							<Tooltip title={t('project.table.content.update')} size='small'>
								<IconButton
									size='small'
									color='primary'
									onClick={() => handleFormProject(row, actions.UPDATE_PROJECT)}>
									<EditIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title={t('project.table.content-disabled.update')}>
								<IconButton sx={{ cursor: 'auto' }} size='small'>
									<EditIcon disabled />
								</IconButton>
							</Tooltip>
						)}

						{row.projectStatusId !== 6 ? (
							<Tooltip title={t('project.table.content.preview')}>
								<span>
									<IconButton
										size='small'
										disabled={!row.canGoToPreview}
										color='primary'
										onClick={(e) => {
											handleGoToPreview(e, row)
										}}>
										<VideoSettingsIcon />
									</IconButton>
								</span>
							</Tooltip>
						) : (
							<Tooltip title={t('project.table.content-disabled.preview')}>
								<IconButton sx={{ cursor: 'auto' }} size='small'>
									<VideoSettingsIcon disabled />
								</IconButton>
							</Tooltip>
						)}

						{row.projectStatusId !== 6 ? (
							<Tooltip title={t('project.table.content.show-list-notes')}>
								<IconButton
									color='primary'
									onClick={() => handleFormProject(row, actions.LIST_NOTES)}
									size='small'>
									<NoteIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title={t('project.table.content-disabled.show-list-notes')}>
								<IconButton sx={{ cursor: 'auto' }} size='small'>
									<NoteIcon disabled />
								</IconButton>
							</Tooltip>
						)}

						{row.projectStatusId !== 6 ? (
							<Tooltip title={t('project.table.content.export-window')}>
								<IconButton
									size='small'
									color='primary'
									onClick={() => handleFormProject(row, actions.EXPORT_PROJECT)}>
									<ContentPasteGoIcon />
								</IconButton>
							</Tooltip>
						) : (
							<Tooltip title={t('project.table.content-disabled.export-window')}>
								<IconButton sx={{ cursor: 'auto' }} size='small'>
									<ContentPasteGoIcon disabled />
								</IconButton>
							</Tooltip>
						)}
					</TableCell>
				</StyledTableRow>

				<StyledTableRow>
					<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
						<Collapse in={open} timeout='auto' unmountOnExit>
							<Box>
								<Table size='small' aria-label='purchases'>
									<TableHead>
										<TableRow>
											<TableCell className={'table-header-sort-label'} width={50}></TableCell>
											<TableCell className={'table-header-sort-label'} align='center'>
												{t('project.table.header.edited')}
											</TableCell>
											{/* <TableCell className={'table-header-sort-label'} align='center'>
												{t('project.table.header.rfmo')}
											</TableCell> */}
											<TableCell className={'table-header-sort-label'} align='center'>
												{t('project.table.header.start-date')}
											</TableCell>
											<TableCell className={'table-header-sort-label'} align='center'>
												{t('project.table.header.end-date')}
											</TableCell>
											<TableCell className={'table-header-sort-label'} align='center'>
												{t('project.table.header.last-note-count')}
											</TableCell>
											<TableCell className={'table-header-sort-label'} align='center'>
												{t('project.table.header.last-calibration-date')}
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell></TableCell>
											<TableCell align='center'>{row.editedBy}</TableCell>
											{/* <TableCell align='center'>{row.rfmo}</TableCell> */}
											<TableCell align='center'>
												{datetimeUTCtoString(row.sequenceStartDate)}
											</TableCell>
											<TableCell align='center'>
												{datetimeUTCtoString(row.sequenceEndDate)}
											</TableCell>
											<TableCell align='center'>{row.lastNoteCount}</TableCell>
											<TableCell align='center'>
												{datetimeUTCtoString(row.vesselLastCalibration)}
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</Box>
						</Collapse>
					</TableCell>
				</StyledTableRow>
			</>
		)
	}

	return (
		<TableContainer>
			<Table
				id='table-project-list'
				sx={{ minWidth: 750, border: '1.5px solid rgba(224, 224, 224, 1)', borderRadius: '5px' }}
				aria-labelledby='tableTitle'
				size='small'>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
					addAction={handleAddAction}
				/>
				<TableBody>
					{projectList &&
						stableSort(projectList.data, getComparator(order, orderBy)).map((row, index) => (
							<Row key={index} row={row} />
						))}
				</TableBody>
				<TableFooter>
					<TableRow>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
