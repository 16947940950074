import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { Box, Button, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../../comun/BasicDialog'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'

export default function AlertZonesForm({ mode, onClose, open, title, onSave }) {
	const [t] = useTranslation('common')
	const {
		control,
		reset,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({ mode: 'onChange' })
	const [file, setFile] = useState(null)

	const onCloseForm = () => {
		setFile(null)
		reset(null)
		onClose()
	}

	const handleUpload = (event) => {
		setValue('fileNameKML', event.target.files[0].name, {
			shouldValidate: true,
			shouldDirty: true,
		})
		setFile(event.target.files[0])
	}

	const onSaveForm = (data) => {
		onSave(data, file)
	}
	const UploadButton = () => {
		return (
			<label htmlFor='contained-button-file'>
				<input
					accept='.kml'
					id='contained-button-file'
					type='file'
					onChange={handleUpload}
					style={{ display: 'none' }}
				/>
				<Tooltip title={t('comun.actions.upload')}>
					<Button
						variant='outlined'
						color={errors?.fileNameKML ? 'error' : 'primary'}
						component='span'
						sx={{ height: '40px' }}
						focusRipple={errors?.fileNameKML}>
						<DriveFolderUploadIcon />
					</Button>
				</Tooltip>
			</label>
		)
	}

	return (
		<Box component='form' onSubmit={handleSubmit(onSaveForm)}>
			<BasicDialog
				title={title}
				onOpen={open}
				onClose={onCloseForm}
				mode={mode}
				saveTxt={t('comun.actions.save')}>
				<Box sx={{ display: 'flex', flexDirection: 'column', mr: 3, minWidth: '600px' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', m: 1, my: 2 }}>
						<TextFieldComponent
							required
							sx={{ width: '100%', mr: 1 }}
							control={control}
							defaultValue={''}
							size='small'
							label={t('alert.zones.form.name')}
							name='name'
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								},
								maxLength: {
									value: 250,
									message: t('alert.zones.form.errors.name-max-lenght'),
								},
							}}
							errors={errors?.name}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', m: 1, my: 2 }}>
						<TextFieldComponent
							required
							sx={{ width: '100%', mr: 1 }}
							control={control}
							defaultValue={''}
							size='small'
							label={t('alert.zones.form.kml-file')}
							name='fileNameKML'
							rules={{
								required: {
									value: true,
									message: t('comun.form-errors.required'),
								},
							}}
							InputProps={{
								disabled: true,
							}}
							errors={errors?.fileNameKML}
						/>
						<UploadButton></UploadButton>
					</Box>
				</Box>
			</BasicDialog>
		</Box>
	)
}
