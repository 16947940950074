import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import IconButton from '@mui/material/IconButton'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { jwtCookieExpiryName, jwtCookieName, messageDialogTypes } from '../../constants'
import { CustomDialog, CustomDialogTitle, Transition } from './StyleComponentUtils'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { setCookie } from '../../utils/cookie'
import { ApiClientContext } from '../../services/ApiClientProvider'

export default function MessageSnackBar({ openError, errorMsg, typeMsg, handleCloseError }) {
	const [t] = useTranslation('common')
	const history = useHistory()
	const { setTokenValue } = useContext(ApiClientContext)

	const [reloadPage, setReloadPage] = useState(false)
	React.useEffect(() => {
		if (errorMsg && errorMsg.status) {
			let code = errorMsg.status
			if (code === 401) {
				setReloadPage(true)
			} else {
				setReloadPage(false)
			}
		}
		return () => {
			setReloadPage(false)
		}
	}, [errorMsg])

	const handleCloseErrorInside = () => {
		if (reloadPage) {
			setCookie(jwtCookieName, '')
			setCookie(jwtCookieExpiryName, '')
			window.localStorage.removeItem('userProfileName')
			setTokenValue(null)
			history.push({ pathname: 'login' })
		}
		handleCloseError()
	}

	const getIcon = () => {
		switch (typeMsg) {
			case messageDialogTypes.WARNING_MESSAGE:
				return <WarningRoundedIcon color='warning' fontSize='large' sx={{ mr: 2 }} />
			case messageDialogTypes.INFO_MESSAGE:
				return <InfoRoundedIcon color='primary' fontSize='large' sx={{ mr: 2 }} />
			case messageDialogTypes.ERROR_MESSAGE:
				return <ErrorRoundedIcon color='error' fontSize='large' sx={{ mr: 2 }} />
			case messageDialogTypes.SUCCESS_MESSAGE:
				return <CheckCircleRoundedIcon color='success' fontSize='large' sx={{ mr: 2 }} />
			default:
				return <ErrorRoundedIcon color='error' fontSize='large' sx={{ mr: 2 }} />
		}
	}

	const getTitleMessage = () => {
		switch (typeMsg) {
			case messageDialogTypes.WARNING_MESSAGE:
				return <span>{t('comun.message-snackbar-title.warning')}</span>
			case messageDialogTypes.INFO_MESSAGE:
				return <span>{t('comun.message-snackbar-title.info')}</span>
			case messageDialogTypes.ERROR_MESSAGE:
				return <span>{t('comun.message-snackbar-title.error')}</span>
			case messageDialogTypes.SUCCESS_MESSAGE:
				return <span>{t('comun.message-snackbar-title.success')}</span>
			default:
				return <span>{t('comun.message-snackbar-title.info')}</span>
		}
	}

	return (
		<CustomDialog
			open={openError}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			TransitionComponent={Transition}>
			<CustomDialogTitle
				id='alert-dialog-title'
				onClose={handleCloseErrorInside}
				onCloseText='Close'
				sx={{ minWidth: '400px', height: '60px', display: 'flex' }}>
				{getIcon()}
				{getTitleMessage()}
				<IconButton
					aria-label='close'
					onClick={handleCloseErrorInside}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
					}}>
					<CloseIcon />
				</IconButton>
			</CustomDialogTitle>
			<Box sx={{ ml: 3, mr: 3, mb: 1.5 }}>
				<DialogContent dividers sx={{ minWidth: '400px', minHeight: '100px' }}>
					<DialogContentText
						id='alert-dialog-description'
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							mt: 2,
						}}>
						{errorMsg ? errorMsg.statusText : ''}
						{errorMsg && errorMsg.status ? ' (' + errorMsg.status + ')' : ''}
						{errorMsg?.status === 401 ? (
							<p>
								<strong>{t('comun.errors.redirect-to-login')}</strong>
							</p>
						) : null}
					</DialogContentText>
				</DialogContent>
			</Box>
		</CustomDialog>
	)
}
