import { Box, Divider, Paper } from '@mui/material'
import Grid from '@mui/material/Grid'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { baseFilter, messageDialogTypes, modeTypesCRUD, codesActivityTimer } from '../../constants'
import { useUserFilterRequest } from '../../services/users/useUserFilterRequest'
import { useUserActivate } from '../../services/users/useUserActivate'
import { useUserDelete } from '../../services/users/useUserDelete'
import { useUserInsert } from '../../services/users/useUserInsert'
import { useUserRequest } from '../../services/users/useUserRequest'
import { useUserUpdate } from '../../services/users/useUserUpdate'
import { useUserLog } from '../../services/timer/useUserLog'
import { getGeneralActivity } from '../../utils/TimerDataUtils'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import ConfirmationDialog from './../comun/ConfirmationDialog'
import UsersFilter from './UsersFilter'
import UsersForm from './UsersForm'
import UsersTable from './UsersTable'
import { useUserClearCache } from '../../services/users/useUserClearCache'

const initialFilter = { ...baseFilter, orderDirection: 'desc', orderField: 'username' }

export default function UsersContainer() {
	const [t] = useTranslation('common')

	//Services
	const { userFilterRequest, isLoadingUserFilter, userFilter, errorUserFilter } = useUserFilterRequest()
	const { userLogRequest } = useUserLog()
	const { userRequest, isLoading, userData, errorUserData } = useUserRequest()
	const { userDelete, isLoadingDelete, errorUserDelete, userDataDelete } = useUserDelete()
	const { userClearCache, isLoadingUserClear, errorUserClear, userDataClear } = useUserClearCache()
	const { userActivate, isLoadingUserActivate, userDataActivate, errorUserActivate } = useUserActivate()
	const { userUpdate, userDataUpdate, errorUserUpdate } = useUserUpdate()
	const { userInsert, userDataInsert, errorUserInsert } = useUserInsert()

	const [filterUser, setFilterUser] = useState(null)
	const [userList, setUserList] = useState(null)
	const [rolesList, setRolesList] = useState(null)

	//Error control
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const [typeMessage, setTypeMessage] = useState(null)
	const [selectedUser, setSelectedUser] = useState(null)

	//Filter
	const [filter, setFilter] = useState(initialFilter)
	const [favouriteFilters, setFavourites] = React.useState(null)

	//Modals
	const [open, setOpen] = useState(false)
	const [mode, setMode] = React.useState(modeTypesCRUD.INSERT_MODE)
	const [openDelete, setOpenDelete] = useState(false)
	const [openClear, setOpenClear] = useState(false)
	const [openReactivate, setOpenReactivate] = useState(false)

	useEffect(() => {
		userFilterRequest()
	}, [])

	useEffect(() => {
		userLogRequest(getGeneralActivity(codesActivityTimer.USERS_PAGE, 'USERS_PAGE'))
	}, [])

	useEffect(() => {
		if (userFilter) {
			setFilterUser(userFilter.filterUser)
			setUserList(userFilter.resultFilter)
			setFavourites(userFilter.favouritesDic)

			setRolesList(
				userFilter.statusRoles.map((rol) => {
					return { ...rol, value: Number(rol.value) }
				})
			)
		}
	}, [userFilter])

	useEffect(() => {
		if (userData) {
			setUserList(userData)
		}
	}, [userData])

	useEffect(() => {
		if (
			errorUserFilter ||
			errorUserData ||
			errorUserDelete ||
			errorUserActivate ||
			errorUserUpdate ||
			errorUserInsert ||
			errorUserClear
		) {
			setOpenError(true)
			setTypeMessage(messageDialogTypes.ERROR_MESSAGE)
			if (errorUserFilter) {
				setErrorMsg(errorUserFilter)
			} else if (errorUserData) {
				setErrorMsg(errorUserData)
			} else if (errorUserDelete) {
				setErrorMsg(errorUserDelete)
			} else if (errorUserActivate) {
				setErrorMsg(errorUserActivate)
			} else if (errorUserUpdate) {
				setErrorMsg(errorUserUpdate)
			} else if (errorUserInsert) {
				setErrorMsg(errorUserInsert)
			}
		} else {
			setOpenError(false)
		}
	}, [errorUserFilter, errorUserData, errorUserDelete, errorUserActivate, errorUserUpdate, errorUserInsert])

	useEffect(() => {
		if (userDataDelete || userDataActivate || userDataUpdate || userDataInsert || userDataClear) {
			userRequest(filter)
		}
		setOpen(false)
	}, [userDataDelete, userDataActivate, userDataUpdate, userDataInsert])

	const handleCloseError = () => {
		setOpenError(false)
	}

	/***************** FILTRO ***************************/
	const onFilter = (filter) => {
		setFilter(filter)
		userRequest(filter)
	}

	/**************** LISTADO RESULTADOS ****************/
	const handleOpenForm = (row) => {
		debugger
		setSelectedUser(row)
		setOpen(true)
	}

	const handleFormUsers = (row, mode) => {
		setMode(mode)
		if (modeTypesCRUD.UPDATE_MODE === mode) {
			handleOpenForm(row)
		} else if (modeTypesCRUD.DELETE_MODE === mode) {
			setOpenDelete(true)
			setSelectedUser(row)
		} else if (modeTypesCRUD.INSERT_MODE === mode) {
			handleOpenForm(defaultRow)
		} else if (modeTypesCRUD.CLEAR_MODE === mode) {
			setOpenClear(true)
			setSelectedUser(row)
		} else if (modeTypesCRUD.ENABLE_MODE === mode) {
			setOpenReactivate(true)
			setSelectedUser(row)
		}
	}

	/******************** EVENTOS MODAL************/

	const onClose = () => {
		setOpen(false)
	}
	//Accion de insert/update sobre un usuario
	const onSave = (data) => {
		// setOpen(false)
		if (modeTypesCRUD.UPDATE_MODE === mode) {
			userUpdate(data)
		} else if (modeTypesCRUD.INSERT_MODE === mode) {
			userInsert(data)
		}
	}

	const onCloseDelete = (confirmed) => {
		setOpenDelete(false)
		if (confirmed) {
			userDelete(selectedUser.userName)
			userRequest(filter)
		}
	}

	const onCloseClear = (confirmed) => {
		setOpenClear(false)
		if (confirmed) {
			userClearCache(selectedUser.userName)
			userRequest(filter)
		}
	}

	const onCloseReactivate = (confirmed) => {
		setOpenReactivate(false)
		if (confirmed) {
			userActivate(selectedUser.userName)
		}
	}

	const defaultRow = {
		userName: '',
		name: '',
		surname: '',
		email: '',
		roles: [],
	}

	return (
		<Box sx={{ my: 1, display: 'flex', flexDirection: 'column', mx: 3 }}>
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={typeMessage}
				handleCloseError={handleCloseError}
			/>

			<BackdropComponent open={isLoadingUserFilter || isLoading || isLoadingDelete || isLoadingUserActivate || isLoadingUserClear} />
			<Box sx={{ width: '100%' }}>
				<Grid>
					<UsersFilter
						filterUser={filterUser}
						onFilter={onFilter}
						filter={filter}
						favouriteFilters={favouriteFilters}></UsersFilter>
					<Divider sx={{ m: 2 }} variant='middle' />
					<Paper sx={{ width: '100%' }}>
						<UsersTable
							userList={userList}
							filter={filter}
							onFilter={onFilter}
							handleFormUsers={handleFormUsers}></UsersTable>
					</Paper>
				</Grid>

				<UsersForm
					selectedUser={selectedUser}
					onClose={onClose}
					mode={mode}
					open={open}
					onSave={onSave}
					rolesList={rolesList}
				/>

				<ConfirmationDialog
					onOpen={openDelete}
					onClose={onCloseDelete}
					dialogTitle={t('users.form.title-delete')}
					dialogContent={t('users.form.delete-content')}
					dialogSaveButton={t('comun.actions.delete')}
					dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>

				<ConfirmationDialog
					onOpen={openClear}
					onClose={onCloseClear}
					dialogTitle={t('users.form.title-clear')}
					dialogContent={t('users.form.clear-content')}
					dialogSaveButton={t('comun.actions.clear-cache')}
					dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>

				<ConfirmationDialog
					onOpen={openReactivate}
					onClose={onCloseReactivate}
					dialogTitle={t('users.form.title-reactivate')}
					dialogContent={t('users.form.reactivate-content')}
					dialogSaveButton={t('comun.actions.enable')}
					dialogCancelButton={t('comun.actions.close')}></ConfirmationDialog>
			</Box>
		</Box>
	)
}
