import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined'
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { MapContainer, Polygon, TileLayer } from 'react-leaflet'
import Control from 'react-leaflet-custom-control'
import { mapSpeedThresholds } from '../../../constants'
import { useShowKMLDashboard } from '../../../services/dashboard/useShowKMLDashboard'
import MessageSnackBar from '../../comun/MessageSnackBar'
import './DashboardMap.css'
import DashboardMapDetailsLayer from './DashboardMapDetailsLayer'
import DashboardMapMarkersLayer from './DashboardMapMarkersLayer'
import DashboardMapPolylinesLayer from './DashboardMapPolylinesLayer'

export default function DashboardMap({ rows, vesselSelected, dataTrack, handleRowClick }) {
	const [moving, setMoving] = useState(false)
	const [showKML, setShowKML] = useState(false)
	const [showLegend, setShowLegend] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const { kmlZones, errorKmlZones, showKMLDashboard } = useShowKMLDashboard()
	const handleChangeKML = (e) => {
		if (e.target.checked) {
			showKMLDashboard()
		}
		setShowKML(e.target.checked)
	}

	useEffect(() => {
		if (errorKmlZones) {
			setOpenError(true)
			setErrorMsg(errorKmlZones)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorKmlZones])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}
	return (
		<MapContainer
			center={[-5.505, -0.09]}
			zoom={2}
			minZoom={2}
			scrollWheelZoom={true}
			id='dashboard-map'
			attributionControl={false}>
			<TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' attribution='' />
			<Control position='bottomright' prepend>
				<Box
					sx={{ backgroundColor: 'white', opacity: 0.8, display: 'flex', flexDirection: 'column' }}
					onMouseEnter={() => setShowLegend(true)}
					onMouseLeave={() => setShowLegend(false)}
					className='legend'>
					{!showLegend ? (
						<LayersOutlinedIcon />
					) : (
						<>
							<FormControlLabel
								sx={{ mt: 0, mb: 0.5, p: 0 }}
								label={'Show kml'}
								labelPlacement='end'
								control={
									<Checkbox
										sx={{ p: 0.5, pl: 1 }}
										checked={showKML}
										value={showKML}
										onChange={handleChangeKML}
									/>
								}
							/>
							<Typography sx={{ mt: 0, mb: 0.5 }}>Speed legend</Typography>
							<Box>
								<i style={{ background: 'green' }}></i> <span>&gt; {mapSpeedThresholds.green}kn</span>
							</Box>
							<Box>
								<i style={{ background: 'yellow' }}></i>{' '}
								<span>
									{' '}
									{mapSpeedThresholds.red}kn &gt; {mapSpeedThresholds.green}kn
								</span>
							</Box>
							<Box>
								<i style={{ background: 'red' }}></i> <span>&gt; {mapSpeedThresholds.red}kn</span>
							</Box>
						</>
					)}
				</Box>
			</Control>
			{showKML &&
				kmlZones?.map((zone) => {
					return zone.polygons.map((pos) => (
						<Polygon key={pos.polygonId} positions={pos.coordinates}></Polygon>
					))
				})}
			<DashboardMapMarkersLayer
				rows={rows}
				vesselSelected={vesselSelected}
				trackList={dataTrack}
				setMoving={setMoving}
				handleRowClick={handleRowClick}
			/>
			<DashboardMapDetailsLayer
				trackList={dataTrack}
				moving={moving}
				setMoving={setMoving}
				vesselSelected={vesselSelected}
			/>
			<DashboardMapPolylinesLayer trackList={dataTrack} moving={moving} setMoving={setMoving} />
			<MessageSnackBar openError={openError} errorMsg={errorMsg} handleCloseError={handleCloseError} />
		</MapContainer>
	)
}
