import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'

export const useTimerUsersDetails = () => {
	const [timerUsersDetails, setTimerUsersDetails] = useState(null)
	const [errorTimerUsersDetails, setErrorTimerUsersDetails] = useState(null)
	const [isLoadingTimerUsersDetails, setIsLoadingTimerUsersDetails] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerUsersDetailsRequest = useCallback(
		(props) => {
			client
				.get(baseUrl + 'horus/f3/timer/users/n3?' + prepareParams(props))
				.then((result) => {
					setIsLoadingTimerUsersDetails(false)
					setTimerUsersDetails(result.data)
					if (result.data.success) {
						setTimerUsersDetails(result.data)
					} else {
						setErrorTimerUsersDetails({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingTimerUsersDetails(false)
					setErrorTimerUsersDetails(err.response)
				})

			setTimerUsersDetails(null)
			setErrorTimerUsersDetails(null)
			setIsLoadingTimerUsersDetails(true)
		},
		[client]
	)
	return {
		timerUsersDetailsRequest,
		isLoadingTimerUsersDetails,
		timerUsersDetails,
		errorTimerUsersDetails,
	}
}
