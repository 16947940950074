import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserUpdate = () => {
	const [userDataUpdate, setUserDataUpdate] = useState(null)
	const [errorUserUpdate, setErrorUserUpdate] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const userUpdate = useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/user', props)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setUserDataUpdate({ data: result.data })
					} else {
						setErrorUserUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorUserUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setUserDataUpdate(null)
			setErrorUserUpdate(null)
		},
		[client]
	)

	return {
		userUpdate,
		isLoadingUpdate,
		userDataUpdate,
		setUserDataUpdate,
		errorUserUpdate,
		setErrorUserUpdate,
	}
}
