import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useReportRequestFilter = () => {
	const [reportFilterData, setReportFilterData] = useState(null)
	const [errorFilterReport, setErrorFilter] = useState(null)
	const [isLoadingFilter, setIsLoadinFilter] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const reportFilterRequest = useCallback(
		(id) => {
			const config = {
				params: {
					projectId: id,
				},
			}

			client
				.get(baseUrl + 'horus/f3/project/export/filter', config)
				.then((result) => {
					setIsLoadinFilter(false)
					if (result.data.success) {
						setReportFilterData(result.data.data)
					} else {
						setErrorFilter({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadinFilter(false)
					setErrorFilter(err.response)
				})

			setReportFilterData(null)
			setErrorFilter(null)
			setIsLoadinFilter(true)
		},
		[client]
	)
	return {
		reportFilterRequest,
		isLoadingFilter,
		reportFilterData,
		errorFilterReport,
	}
}
