import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import ProjectsContainer from '../components/projects/ProjectsContainer'
import { previousState } from '../constants'
import { readLocalStorageItem } from '../utils/cookie'

const ProjectsPage = () => {
	const history = useHistory()

	let previousStateAux = readLocalStorageItem(previousState)
	let prevState = previousStateAux ? JSON.parse(previousStateAux) : null

	return (
		<ErrorBoundary history={history}>
			<ProjectsContainer prevState={prevState} />
		</ErrorBoundary>
	)
}

export default ProjectsPage
