import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { prepareParams } from '../ServicesUtils'
export const useTimerProjectRequest = () => {
	const [dataTimerProject, setDataTimerProject] = useState(null)
	const [errorTimerProject, setErrorTimerProject] = useState(null)
	const [isLoadingTimerProject, setIsLoadingTimerProject] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerProjectRequest = useCallback(
		(filter) => {
			client
				.get(baseUrl + 'horus/f3/timer/projects/n1?' + prepareParams(filter))
				.then((result) => {
					setIsLoadingTimerProject(false)
					setDataTimerProject(result.data)
					if (result.data.success) {
						setDataTimerProject(result.data)
					} else {
						setErrorTimerProject({ statusText: result.data.error })
					}
				})
				.catch((err) => {
					setIsLoadingTimerProject(false)
					setErrorTimerProject(err.response)
				})

			setDataTimerProject(null)
			setErrorTimerProject(null)
			setIsLoadingTimerProject(true)
		},
		[client]
	)
	return {
		timerProjectRequest,
		isLoadingTimerProject,
		dataTimerProject,
		errorTimerProject,
	}
}
