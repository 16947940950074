import { Typography } from '@mui/material'
import { Icon } from 'leaflet'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Marker, Popup } from 'react-leaflet'

import arrow from '../../../assets/up-arrow.svg'
import { datetimeUTCtoString } from '../../comun/DateUtils'

import 'leaflet-rotatedmarker'
import { mapMarkerSize } from '../../../constants'
import MapInfoTooltip from '../../new_preview/secondary_panel/map_tab/MapInfoTooltip'
import { useMapInfoPosition } from '../../../services/preview/useMapInfoPosition'
import MessageSnackBar from '../../comun/MessageSnackBar'

const arrowBlackIcon = new Icon({
	iconUrl: arrow,
	iconSize: [mapMarkerSize, mapMarkerSize],
})

export default function DashboardMapDetailsLayer({ trackList, moving, vesselSelected }) {
	const [t] = useTranslation('common')

	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const { getMapInfoPosition, dataMapInfo, errorMapInfo, isLoadingMapInfo } = useMapInfoPosition(null, null, true)
	const handleClick = (p, nextPos) => {
		getMapInfoPosition(vesselSelected?.vesselId, p.date, nextPos.date)
	}
	useEffect(() => {
		if (errorMapInfo) {
			setOpenError(true)
			setErrorMsg(errorMapInfo)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorMapInfo])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	return (
		<>
			<MessageSnackBar openError={openError} errorMsg={errorMsg} handleCloseError={handleCloseError} />
			{!moving &&
				trackList?.map((p, index) => {
					let nextPos = index == trackList.length - 1 ? p : trackList[index + 1]
					return (
						<Marker
							eventHandlers={{
								click: () => handleClick(p, nextPos),
							}}
							key={p.date}
							className='rotatedMarker'
							position={[p.lat, p.lon]}
							alt='Marker'
							rotationOrigin='center'
							rotationAngle={p.course}
							icon={arrowBlackIcon}>
							<MapInfoTooltip
								fromDashboard
								isLoadingMapInfo={isLoadingMapInfo}
								dataMapInfo={dataMapInfo}
								p={p}
							/>
						</Marker>
					)
				})}
		</>
	)
}
