import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'
import { datetimeGMTToLocaleInISOFormat } from '../../components/comun/DateUtils'

export const useChangeFootageCam = (vesselApiId, projectId) => {
	const [footageData, setFootageData] = useState(null)
	const [errorfootage, setErrorFootage] = useState(null)
	const [isLoadingFootage, setIsLoadingFootage] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const changeFootageCam = useCallback(
		(url, cam, currentTime) => {
			let auxUrl = vesselApiId ? 'horus/f3/dashboard/preview/view/' : 'horus/f3/project/preview/view/'
			let argument = vesselApiId ? '?vesselApiId=' + vesselApiId : '?projectId=' + projectId
			client
				.put(
					baseUrl +
						auxUrl +
						url +
						argument +
						'&camNo=' +
						cam +
						'&currentTime=' +
						datetimeGMTToLocaleInISOFormat(currentTime)
				)
				.then((result) => {
					setIsLoadingFootage(false)
					if (result.data.success) {
						setFootageData(result.data.vesselVideoPlayInfo)
					} else {
						setErrorFootage({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingFootage(false)
					setErrorFootage(err.response)
				})

			setFootageData(null)
			setErrorFootage(null)
			setIsLoadingFootage(true)
		},
		[client]
	)
	return {
		changeFootageCam,
		footageData,
		errorfootage,
		isLoadingFootage,
		setFootageData,
		setErrorFootage,
	}
}
