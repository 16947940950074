import React, { useEffect, useRef, useState } from 'react'
import { trackTransforms } from '../measurement/CanvasFunctions'

const hardColor = '#ff2626'
const softColor = '#eb27278a'

var dragStart, dragged, lastX, lastY

export default function CalibrationCanvas({ posCounter, setPosCounter, sqrSelected, setSqrSelected, sqrList, image }) {
	const canvas = useRef(null)
	const [updated, setUpdated] = useState(false)
	const [ctx, setCtx] = useState(null)

	useEffect(() => {
		if (updated) {
			redraw()
			setUpdated(false)
		}
	}, [updated])

	useEffect(() => {
		if (sqrList) {
			setUpdated(true)
		}
	}, [sqrList])

	useEffect(() => {
		setUpdated(true)
	}, [sqrSelected])

	useEffect(() => {
		setUpdated(true)
	}, [image])

	useEffect(() => {
		if (image.complete) {
			// La imagen ya está cargada, dibuja en el canvas
			redraw()
		} else {
			// Escucha el evento onload de la imagen para dibujar en el canvas una vez que esté cargada
			image.onload = () => {
				redraw()
			}
		}
	}, [image, ctx])

	useEffect(() => {
		if (canvas.current) {
			canvas.current.width = canvas.current.offsetWidth
			canvas.current.height = canvas.current.offsetHeight
			lastX = canvas.current.width / 2
			lastY = canvas.current.height / 2
			if (ctx == null) {
				let ctxAux = canvas.current.getContext('2d')
				trackTransforms(ctxAux)
				setCtx(ctxAux)
				setUpdated(true)
			}
		}
	}, [canvas?.current])

	const redraw = () => {
		redrawImage()
		redrawCalibrations()
		redrawCalibrationSelected()
	}

	const redrawImage = () => {
		if (ctx == null) return
		ctx.save()
		ctx.setTransform(1, 0, 0, 1, 0, 0)
		ctx.clearRect(0, 0, canvas.current.width, canvas.current.height)
		ctx.restore()
		ctx.drawImage(image, 0, 0)
	}

	const redrawCalibrations = () => {
		if (ctx == null) return
		sqrList.forEach((sqr) => {
			if (sqrSelected?.name != sqr.name) {
				sqr.positions.forEach((pos, idx2) => {
					drawPoint(pos, softColor, idx2, false)
					if (idx2 != 0) {
						if (idx2 == 3) {
							joinPoints(pos, sqr.positions[0], softColor)
							drawDistance(pos, sqr.positions[0], sqr.distance, softColor)
						}
						joinPoints(pos, sqr.positions[idx2 - 1], softColor)
					}
				})
			}
		})
	}

	const redrawCalibrationSelected = () => {
		if (ctx == null) return
		sqrSelected?.positions.forEach((pos, idx) => {
			drawPoint(pos, hardColor, idx, true)
			if (idx != 0) {
				if (idx == 3) {
					joinPoints(pos, sqrSelected.positions[0], hardColor)
					drawDistance(pos, sqrSelected.positions[0], sqrSelected.distance, hardColor)
				}
				joinPoints(pos, sqrSelected.positions[idx - 1], hardColor)
			}
		})
	}

	const joinPoints = (a, b, color) => {
		ctx.fillStyle = color
		ctx.strokeStyle = color
		ctx.lineWidth = 1
		ctx.beginPath()
		ctx.moveTo(a.x, a.y)
		ctx.lineTo(b.x, b.y)
		ctx.closePath()
		ctx.stroke()
	}

	const drawPoint = (aux, color, idx, hard) => {
		let name = idx == 0 ? 'A' : idx == 1 ? 'B' : idx == 2 ? 'C' : idx == 3 ? 'D' : null
		ctx.fillStyle = color
		ctx.strokeStyle = color
		ctx.beginPath()
		ctx.font = '20px Arial'
		if (hard) ctx.fillText(name, aux.x + 10, aux.y - 10)
		ctx.arc(aux.x, aux.y, 1.5, 0, Math.PI * 2)
		ctx.fill()
	}

	const drawDistance = (a, b, distance, color) => {
		let dx = b.x - a.x
		let dy = b.y - a.y
		let midX = a.x + dx * 0.5
		let midY = a.y + dy * 0.5
		ctx.fillStyle = color
		ctx.strokeStyle = color
		ctx.beginPath()
		ctx.font = '24px Arial'
		ctx.fillText(distance, midX - 20, midY - 20)
		ctx.fill()
	}

	const handleMouseDown = (evt) => {
		lastX = evt.offsetX || evt.pageX - canvas.current.offsetLeft
		lastY = evt.offsetY || evt.pageY - canvas.current.offsetTop
		dragStart = ctx.transformedPoint(lastX, lastY)
		dragged = false
	}

	const handleMouseMove = (evt) => {
		lastX = evt.offsetX || evt.pageX - canvas.current.offsetLeft
		lastY = evt.offsetY || evt.pageY - canvas.current.offsetTop
		dragged = true
		if (dragStart) {
			var pt = ctx.transformedPoint(lastX, lastY)
			ctx.translate(pt.x - dragStart.x, pt.y - dragStart.y)
			setUpdated(true)
		}
	}

	const handleScroll = (evt) => {
		//evt.preventDefault()
		var delta = evt.nativeEvent.wheelDelta ? evt.nativeEvent.wheelDelta / 40 : evt.detail ? -evt.detail : 0
		if (delta && delta != 0) handleZoom(delta)
		return false
	}

	const handleZoom = (clicks, auxX, auxY) => {
		if (ctx == null) return
		var scaleFactor = 1.05
		var pt
		if (auxX && auxY) {
			pt = ctx.transformedPoint(auxX, auxY)
		} else {
			pt = ctx.transformedPoint(lastX, lastY)
		}
		ctx.translate(pt.x, pt.y)
		var factor = Math.pow(scaleFactor, clicks)
		ctx.scale(factor, factor)
		ctx.translate(-pt.x, -pt.y)
		setUpdated(true)
	}

	const handleMouseUp = (evt) => {
		dragStart = null
		if (!dragged) {
			handleSetPoint(evt)
		}
	}

	const handleSetPoint = (evt) => {
		if (sqrSelected) {
			var x = evt.pageX - canvas.current.offsetLeft
			var y = evt.pageY - canvas.current.offsetTop
			let point = null
			switch (posCounter) {
				case 0:
					point = ctx.transformedPoint(x, y)
					break
				case 1:
					point = ctx.transformedPoint(x, y)
					break
				case 2:
					point = ctx.transformedPoint(x, y)
					break
				case 3:
					point = ctx.transformedPoint(x, y)
					break
				default:
					return
			}
			let auxPos = [...sqrSelected.positions]
			auxPos[posCounter] = point
			setSqrSelected((prevState) => ({
				...prevState,
				positions: auxPos,
			}))
			setPosCounter(posCounter + 1)
		}
	}

	return (
		<canvas
			id='canvas'
			ref={canvas}
			style={{
				width: '100%',
				height: '100%',
				cursor: 'crosshair',
				border: '1px solid black',
				backgroundColor: '#00000054',
			}}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onWheel={handleScroll}
			onMouseMove={handleMouseMove}></canvas>
	)
}
