import { Box, Typography } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../comun/BasicDialog'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'

export default function ChangePasswordForm({ onSave, open, onClose }) {
	const [t] = useTranslation('common')
	const {
		control,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm()

	React.useEffect(() => {
		reset()
	}, [onClose])

	return (
		<Box component='form' onSubmit={handleSubmit(onSave)}>
			<BasicDialog
				title={t('change-password.title')}
				onOpen={open}
				onClose={onClose}
				saveTxt={t('change-password.confirm-button')}>
				<Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-evenly',
							width: '100%',
							my: 1.5,
						}}>
						<Typography variant='body1'>{t('change-password.description')}</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-evenly',
							width: '100%',
							my: 1.5,
						}}>
						<TextFieldComponent
							sx={{ mr: 1, width: '80%' }}
							size='small'
							label={t('change-password.username')}
							control={control}
							rules={{
								required: t('comun.form-errors.required'),
							}}
							name='username'
							errors={errors?.username}
						/>
					</Box>
				</Box>
			</BasicDialog>
		</Box>
	)
}
