import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useGetTimeRangeOptions = (vesselApiId) => {
	const [dataTimeRange, setDataTimeRange] = useState(null)
	const [errorTimeRange, setErrorTimeRange] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const { client } = React.useContext(ApiClientContext)
	const url = vesselApiId
		? 'horus/f3/dashboard/preview/time/range/config'
		: 'horus/f3/project/preview/time/range/config'

	const getTimeRangeOptions = useCallback(() => {
		client
			.get(baseUrl + url)
			.then((result) => {
				setIsLoading(false)
				setDataTimeRange(result.data)
			})
			.catch((err) => {
				setIsLoading(false)
				setErrorTimeRange(err.response)
			})

		setDataTimeRange(null)
		setErrorTimeRange(null)
		setIsLoading(true)
	}, [client])
	return {
		getTimeRangeOptions,
		dataTimeRange,
		errorTimeRange,
		isLoading,
	}
}
