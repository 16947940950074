import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import UsersContainer from '../components/users/UsersContainer'

const UsersManagementPage = () => {
	const history = useHistory()

	return (
		<ErrorBoundary history={history}>
			<UsersContainer></UsersContainer>
		</ErrorBoundary>
	)
}

export default UsersManagementPage
