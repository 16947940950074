import React, { useEffect, useState } from 'react'

import Box from '@mui/material/Box'

import FormControl from '@mui/material/FormControl'

import { useTranslation } from 'react-i18next'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { IconButton, InputLabel, MenuItem, OutlinedInput, Paper, Select, Tooltip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import FieldsetComponent from '../comun/FieldsetComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'
import AutoCompleteComponent from '../comun/form_fields/AutoCompleteComponent'

export default function AnalysisTypeProgramForm({ methodsForm, typeAnalysisList, fishingProfiles, exports }) {
	const {
		control,
		register,
		setValue,
		getValues,
		watch,
		formState: { errors },
	} = methodsForm

	const [t] = useTranslation('common')

	const [dataSelected, setDataSelected] = useState([])

	const [disableAddButton, setDisableAddButton] = useState(true)

	useEffect(() => {
		console.log({ dataSelected })
		console.log({ exports })
	}, [dataSelected])

	useEffect(() => {
		if (typeAnalysisList) {
			typeAnalysisList.forEach((value) => {
				const filterFishing = fishingProfiles.find((fprof) => value.idFishingProfiles == fprof.fishingProfileId)

				var newRow = {
					description: value.description,
					fishing: {
						fishingProfileId: filterFishing.fishingProfileId,
						description: filterFishing.description,
					},
					programAnalysisTypeExports: value.programAnalysisTypeExports,
				}
				setDataSelected((prevState) => [...prevState, newRow])
			})
		}
	}, [typeAnalysisList])

	useEffect(() => {
		setValue('selected_analysis', dataSelected)
	}, [dataSelected])

	useEffect(() => {
		const subscription = watch((value) => {
			setDisableAddButton(!value.fishing || !value.description_analysis)
		})
		return () => subscription.unsubscribe()
	}, [watch])

	const addRowTable = () => {
		let fishing = getValues('fishing')
		let description = getValues('description_analysis')
		let exportTypes = []
		let existeAnalisis = false
		const copyVessel = [...dataSelected]

		copyVessel.forEach((element) => {
			if (
				element != null &&
				element.description == description &&
				element.fishing.fishingProfileId == fishing.fishingProfileId
			) {
				existeAnalisis = true
			}
		})

		if (!existeAnalisis) {
			let ediExport = {
				programAnalysisTypeId: 1,
				projectExportId: 1,
				description: 'Edi File',
			}
			let kmlExport = {
				programAnalysisTypeId: 4,
				projectExportId: 4,
				description: 'KML File',
			}

			let newRow = {
				description: description,
				fishing: { fishingProfileId: fishing.fishingProfileId, description: fishing.description },
				programAnalysisTypeExports: [ediExport, kmlExport],
			}
			errors.description_analysis = null
			errors.fishing = null
			setValue('fishing', null)
			setValue('description_analysis', '')
			setDataSelected((prevState) => [...prevState, newRow])
		} else {
			errors.description_analysis = { message: t('programs.form.analysis.error-analysis') }
		}
	}

	const onDeleteAnalysis = (row) => {
		const auxAnalysis = [...dataSelected]
		const indx = auxAnalysis.findIndex(
			(item) =>
				item.description === row.description &&
				item.fishing.fishingProfileId === row.fishing.fishingProfileId
		)
		auxAnalysis.splice(indx,1)

		setDataSelected(auxAnalysis)
	}

	const checkEmptyAnalysis = () => {
		//var typeAnalysisList = getValues('description_analysis')
		return dataSelected ? dataSelected.length > 0 : false
	}
	return (
		<Box
			sx={{
				width: '100%',
				height: '100%',
			}}>
			<FieldsetComponent title={t('programs.form.analysis.title')}>
				<Box
					sx={{
						width: '100%',
						height: '100%',
						m: 1,
					}}>
					<Box sx={{ width: '100%' }}>
						<Box sx={{ display: 'flex' }}>
							<Box sx={{ width: '45%' }}>
								<TextFieldComponent
									defaultValue={''}
									sx={{ minWidth: '100%', pr: 1 }}
									control={control}
									label={t('programs.form.analysis.description')}
									name='description_analysis'
									size='small'
									rules={{
										validate: (v) =>
											checkEmptyAnalysis() || t('programs.validations.analysis-type-field'),
									}}
									errors={errors?.description_analysis}
								/>
							</Box>
							<Box sx={{ width: '45%' }}>
								<AutoCompleteComponent
									size='small'
									sx={{ minWidth: '100%' }}
									control={control}
									defaultValue={null}
									name='fishing'
									label={t('programs.form.analysis.fishing-profile')}
									errors={errors?.fishing}
									optionsLabel={'description'}
									keyProperty={'fishingProfileId'}
									options={fishingProfiles}
									evalMethod={(opt, val) => {
										return opt.fishingProfileId === val.fishingProfileId
									}}
									rules={{
										validate: (v) =>
											checkEmptyAnalysis() || t('programs.validations.analysis-type-field'),
									}}
								/>
							</Box>

							<Box sx={{ display: 'flex', width: '10%', justifyContent: 'flex-end' }}>
								<FormControl
									variant='standard'
									size='small'
									sx={{ width: '40px', alignSelf: 'flex-start' }}>
									<Tooltip title={t('programs.form.vessel.add-button')}>
										<span>
											<IconButton
												color='primary'
												disabled={disableAddButton}
												onClick={() => addRowTable()}>
												<AddCircleIcon />
											</IconButton>
										</span>
									</Tooltip>
								</FormControl>
							</Box>
						</Box>
						<Box>
							<FormControl sx={{ width: '100%' }} error={!!errors?.selected_analysis} size='small'>
								<TableContainer component={Paper}>
									<Table {...register('selected_analysis')} sx={{ minWidth: 300 }} aria-label='simple table'>
										<TableBody>
											{dataSelected.map((row, idx) => (
												<TableRow
													key={row.fishing.fishingProfileId}
													sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
													<TableCell component='th' scope='row'>
														{row.description}
													</TableCell>
													<TableCell component='th' scope='row'>
														{row.fishing.description}
													</TableCell>
													{/*<TableCell component='th' scope='row'>
														<FormControl size='small' sx={{ width: '16vw' }}>
															<InputLabel id='export'>
																{t('programs.filter.exports')}
															</InputLabel>
															<Select
																multiple
																labelId='export'
																value={row.programAnalysisTypeExports}
																onChange={(e) => handleChangedExport(e, idx)}
																input={
																	<OutlinedInput
																		size='small'
																		label={t('programs.filter.exports')}
																	/>
																}>
																{exports &&
																	exports.map((opt, idx) => (
																		<MenuItem key={idx} value={opt}>
																			{opt.description}
																		</MenuItem>
																	))}
															</Select>
														</FormControl>
													</TableCell>*/}
													{/* <SelectComponent
														options={exports}
														defaultValue={[]}
														optionsLabel={'description'}
														optionsValue={'exportId'}
														sx={{ width: '100%', mt: 0 }}
														rules={{ required: t('programs.validations.required-field') }}
														control={control}
														label={t('programs.filter.exports')}
														name='exports'
														size='small'
														errors={errors?.exports}
														multiple
														chip
													/> */}

													<TableCell component='th' scope='row' align='right'>
														<Tooltip title={t('programs.form.analysis.delete-button')}>
															<span>
																<IconButton
																	color='error'
																	onClick={() => onDeleteAnalysis(row)}>
																	<DeleteIcon />
																</IconButton>
															</span>
														</Tooltip>
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</FormControl>
						</Box>
					</Box>
				</Box>
			</FieldsetComponent>
		</Box>
	)
}
