import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useCalibrationUpdate = () => {
	const [calibrationDataUpdate, setCalibrationDataUpdate] = useState(null)
	const [errorCalibrationUpdate, setErrorCalibrationUpdate] = useState(null)
	const [isLoadingUpdate, setIsLoadingUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const calibrationUpdate = useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/vessel/camera/calibrations', props)
				.then((result) => {
					setIsLoadingUpdate(false)
					if (result.status === 200 && result.data.success) {
						setCalibrationDataUpdate(result.data.data)
					} else {
						setErrorCalibrationUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorCalibrationUpdate(error.response)
					setIsLoadingUpdate(false)
				})

			setIsLoadingUpdate(true)
			setCalibrationDataUpdate(null)
			setErrorCalibrationUpdate(null)
		},
		[client]
	)

	return {
		calibrationUpdate,
		isLoadingUpdate,
		calibrationDataUpdate,
		errorCalibrationUpdate,
		setCalibrationDataUpdate,
	}
}
