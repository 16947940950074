import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useProgramDelete = () => {
	const [programDataDelete, setProgramDataDelete] = useState(null)
	const [errorProgramDelete, setErrorProgramDelete] = useState(null)
	const [isLoadingProgramDelete, setIsLoadingProgramDelete] = useState(false)

	const { client } = useContext(ApiClientContext)

	const programDelete = useCallback(
		(id) => {
			const config = {
				params: {
					IdProgram: id,
				},
			}

			client
				.delete(baseUrl + 'horus/f3/program', config)
				.then((result) => {
					if (result.status === 200 && result.data.success) {
						setProgramDataDelete({ data: result.data })
					} else {
						setErrorProgramDelete({ statusText: result.data.errorMsg })
					}
					setIsLoadingProgramDelete(false)
				})
				.catch((error) => {
					setErrorProgramDelete(error.response)
					setIsLoadingProgramDelete(false)
				})

			setProgramDataDelete(null)
			setErrorProgramDelete(null)
			setIsLoadingProgramDelete(true)
		},
		[client]
	)

	return {
		programDelete,
		isLoadingProgramDelete,
		programDataDelete,
		setProgramDataDelete,
		errorProgramDelete,
		setErrorProgramDelete,
	}
}
