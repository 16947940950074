import { useCallback, useState } from 'react'
import { useContext } from 'react'

import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useGetAllChats = () => {
	const [chatList, setChatList] = useState(null)
	const [refreshInterval, setRefreshInterval] = useState(null)
	const [errorGetAllChats, setErrorGetAllChats] = useState(null)
	const [isLoadingGetAllChats, setIsLoadingGetAllChats] = useState(false)
	const { client } = useContext(ApiClientContext)

	const getAllChats = useCallback(() => {
		client
			.get(baseUrl + 'horus/f3/chat/all')
			.then((result) => {
				setIsLoadingGetAllChats(false)
				if (result.data.success) {
					setChatList(result.data.data)
					setRefreshInterval(result.data.refreshIntSec * 1000)
				} else {
					setErrorGetAllChats({ statusText: result.data.errorMsg })
				}
			})
			.catch((err) => {
				setIsLoadingGetAllChats(false)
				setErrorGetAllChats(err.response)
			})
		setChatList(null)
		setErrorGetAllChats(null)
		setIsLoadingGetAllChats(true)
	}, [client])
	return {
		refreshInterval,
		getAllChats,
		isLoadingGetAllChats,
		chatList,
		errorGetAllChats,
		setErrorGetAllChats,
	}
}
