import { Box, Button, TextField, Tooltip } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import React, { useState } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import BackspaceOutlinedIcon from '@mui/icons-material/BackspaceOutlined'

export default function NotificationFilter({ onFilter, t }) {
	const [name, setName] = useState('')
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)

	const handleChangeName = (e) => {
		setName(e.target.value)
	}
	const handleChangeStart = (newValue) => {
		setStartDate(newValue)
	}
	const handleChangeEnd = (newValue) => {
		setEndDate(newValue)
	}

	const handleClearFilter = () => {
		setName('')
		setStartDate(null)
		setEndDate(null)
		onFilter('', null, null)
	}

	return (
		<Box sx={{ width: '100%', p: 0.5 }}>
			<TextField
				value={name}
				size='small'
				onChange={handleChangeName}
				autoComplete='off'
				label={t('preview.notification.name')}
				placeholder={t('preview.notification.name')}
				sx={{ width: '24%', fontSize: '14px' }}
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DateTimePicker
					clearable
					minTime={0}
					disableFuture={true}
					onChange={handleChangeStart}
					label={t('preview.notification.start-date')}
					value={startDate}
					inputFormat='dd/MM/yyyy HH:mm'
					renderInput={(params) => <TextField size='small' {...params} sx={{ width: '29.5%', mx: 0.5 }} />}
				/>
			</LocalizationProvider>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DateTimePicker
					clearable
					minTime={0}
					disableFuture={true}
					onChange={handleChangeEnd}
					label={t('preview.notification.end-date')}
					value={endDate}
					inputFormat='dd/MM/yyyy HH:mm'
					renderInput={(params) => <TextField size='small' {...params} sx={{ width: '29.5%', mr: 0.5 }} />}
				/>
			</LocalizationProvider>
			<Tooltip title={t('comun.actions.clear')} placement='top'>
				<Button
					color='secondary'
					variant='outlined'
					sx={{ height: '40px', maxWidth: '7%', minWidth: '0px', m: 0, mr: 0.5 }}
					onClick={handleClearFilter}>
					<BackspaceOutlinedIcon />
				</Button>
			</Tooltip>
			<Tooltip title={t('comun.actions.search')} placement='top'>
				<Button
					variant='outlined'
					sx={{ height: '40px', maxWidth: '7%', minWidth: '0px', m: 0 }}
					onClick={() => onFilter(name, startDate, endDate)}>
					<SearchIcon />
				</Button>
			</Tooltip>
		</Box>
	)
}
