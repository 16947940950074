import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useCalibration = () => {
	const [calibrationData, setCalibrationData] = useState(null)
	const [calibrationError, setCalibrationError] = useState(null)
	const [isLoadingCalibration, setIsLoadingCalibration] = useState(false)

	const { client } = useContext(ApiClientContext)

	const calibrationRequest = useCallback(
		(vesselApiId, camNo) => {
			client
				.get(baseUrl + 'horus/f3/vessel/camera/calibrations?vesselApiId=' + vesselApiId + '&camNo=' + camNo)
				.then((result) => {
					setIsLoadingCalibration(false)
					if (result.data.success) {
						setCalibrationData(result.data.data)
					} else {
						setCalibrationError({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setIsLoadingCalibration(false)
					setCalibrationError(error.response)
				})
			setIsLoadingCalibration(true)
			setCalibrationData(null)
			setCalibrationError(null)
		},
		[client]
	)

	return {
		isLoadingCalibration,
		calibrationRequest,
		calibrationData,
		calibrationError,
	}
}
