import CloseIcon from '@mui/icons-material/Close'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import React, { useRef } from 'react'
import Draggable from 'react-draggable'
import { useTranslation } from 'react-i18next'
import '../../index.css'
import { CustomDialog, CustomDialogTitle } from './StyleComponentUtils'

export default function BasicDialog({
	title,
	onOpen,
	onClose,
	onSave,
	children,
	closeTxt,
	saveTxt,
	noactions,
	fullScreen,
	disabled,
	hideBackdrop,
	paperProps,
	annotationModal,
	closeColor,
}) {
	const [t] = useTranslation('common')
	return (
		<CustomDialog
			PaperProps={paperProps}
			hideBackdrop={hideBackdrop}
			fullScreen={fullScreen}
			open={onOpen}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			maxWidth={false}
			PaperComponent={fullScreen ? Paper : PaperComponent}>
			<CustomDialogTitle
				id='alert-dialog-title'
				onClose={onClose}
				onCloseText='Close'
				sx={{ py: '10px', px: '16px' }}>
				{title}
				<IconButton aria-label='close' onClick={onClose} sx={{ position: 'absolute', right: 8, top: 8 }}>
					<CloseIcon />
				</IconButton>
			</CustomDialogTitle>
			<Box
				sx={{ ml: annotationModal ? 0 : 3, mr: annotationModal ? 0 : 3, mb: 1.5 }}
				component={saveTxt ? 'form' : 'div'}>
				<DialogContent dividers>
					<Box sx={{ width: '100%' }} className='content'>
						{children}
					</Box>
				</DialogContent>
				{!noactions && (
					<DialogActions sx={{ mt: 1, pb: 0 }}>
						{saveTxt && (
							<Button
								disabled={disabled}
								autoFocus
								onClick={onSave ? onSave : null}
								variant='outlined'
								type={onSave ? 'button' : 'submit'}>
								{saveTxt ? saveTxt : t('basic-dialog.save')}
							</Button>
						)}
						<Button variant='outlined' onClick={onClose} color={closeColor ? closeColor : 'secondary'}>
							{closeTxt ? closeTxt : t('basic-dialog.close')}
						</Button>
					</DialogActions>
				)}
			</Box>
		</CustomDialog>
	)
}

function PaperComponent(props) {
	const nodeRef = useRef(null)
	return (
		<Draggable nodeRef={nodeRef} handle='#alert-dialog-title' cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper ref={nodeRef} {...props} />
		</Draggable>
	)
}
