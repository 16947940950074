import DesignServicesIcon from '@mui/icons-material/DesignServices'
import { IconButton, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useShallow } from 'zustand/shallow'
import { useVideoScreenshotInfo } from '../../../services/videos/useVideoScreenshotInfo'
import CalibrationContainer from '../../calibration/CalibrationContainer'
import { dateDiffInSeconds } from '../../comun/DateUtils'
import MessageSnackBar from '../../comun/MessageSnackBar'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import useStore from '../PreviewStore'

const img = new Image()
export default function CalibrationButton() {
	const [t] = useTranslation('common')
	const [openCalibration, setOpenCalibration] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)

	const { play, pause, project, getCurrentTime, mainVideo } = useStore(
		useShallow((state) => ({
			play: state.play,
			pause: state.pause,
			project: state.project,
			getCurrentTime: state.getCurrentTime,
			mainVideo: state.mainVideo,
		}))
	)
	const { getScreenshotInfo, screenshotInfoData, isLoadingScreenshotInfo, screenshotInfoError } =
		useVideoScreenshotInfo()

	const handleOpenCalibration = () => {
		let params = {
			VideoId: mainVideo.videoId,
			Offset: dateDiffInSeconds(getCurrentTime(), mainVideo.startDate).toFixed(0),
			Opt: 'CAL',
		}
		pause()
		getScreenshotInfo(params)
	}

	useEffect(() => {
		if (screenshotInfoError) {
			setOpenError(true)
			setErrorMsg(screenshotInfoError)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [screenshotInfoError])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	useEffect(() => {
		if (screenshotInfoData) {
			img.src = screenshotInfoData.url
			setOpenCalibration(true)
		}
	}, [screenshotInfoData])

	const handleCloseCalibration = () => {
		setOpenCalibration(false)
		play()
	}
	return (
		<>
			<MessageSnackBar openError={openError} errorMsg={errorMsg} handleCloseError={handleCloseError} />
			<BackdropComponent open={isLoadingScreenshotInfo} />
			<Tooltip placement='top' title={t('videos.main-section.calibration')}>
				<span>
					<IconButton
						disabled={mainVideo?.videoId == '0'}
						color='primary'
						variant='outlined'
						size='small'
						sx={{ mx: 0.5, height: '90%' }}
						onClick={handleOpenCalibration}>
						<DesignServicesIcon sx={{ height: '220%' }} />
					</IconButton>
				</span>
			</Tooltip>
			{openCalibration && (
				<CalibrationContainer
					onOpen={openCalibration}
					onClose={handleCloseCalibration}
					image={img}
					vesselApiId={project.vesselApiId}
					cam={mainVideo.cam}
					imageId={screenshotInfoData?.imageId}
				/>
			)}
		</>
	)
}
