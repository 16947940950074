import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useUpdateLayerConfig } from '../../services/chart/useUpdateLayerConfig'
import VerticalTimeline from '../new_preview/bottom_panel/VerticalTimeline'
import ChartGraph from './ChartGraph'
import ChartLayerConfig from './ChartLayerConfig'

function ChartContainer({
	dataPreview: {
		graphicData: { layers: initialLayers, dataNews },
		timeData,
		visualizationData: { isLiveViewSelected },
	},
	updateCurrentTime,
	projectId,
	vesselApiId,
}) {
	const [data, setData] = useState(dataNews)
	const [layers, setLayers] = useState(initialLayers)

	const { dataUpdateLayer, updateLayersConfig } = useUpdateLayerConfig(vesselApiId, projectId)

	useEffect(() => {
		if (dataUpdateLayer) {
			setData(dataUpdateLayer)
		}
	}, [dataUpdateLayer])

	useEffect(() => {
		if (dataNews) {
			setData(dataNews)
		}
	}, [dataNews])

	const handleChangeHidden = (layer) => {
		let auxLayers = [...layers]
		let layersShownLenght = auxLayers.filter((l) => l.isHidden == false).length
		if (layersShownLenght > 1 || layer.isHidden == true) {
			auxLayers[auxLayers.findIndex((l) => l.layer == layer.layer)].isHidden =
				!auxLayers[auxLayers.findIndex((l) => l.layer == layer.layer)].isHidden
			setLayers(auxLayers)
			updateLayersConfig(layer)
		}
	}

	return (
		<Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '5%', height: '91%' }}>
				{layers &&
					layers?.map((layer, layerIdx) => {
						return (
							<ChartLayerConfig
								layerIdx={layerIdx}
								setLayers={setLayers}
								layers={layers}
								key={layer.layer}
								layer={layer}
								handleChangeHidden={handleChangeHidden}
								updateLayersConfig={updateLayersConfig}
								isLiveViewSelected={isLiveViewSelected}
							/>
						)
					})}
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '95%',
					height: '100%',
					position: 'relative',
					boxShadow:
						'1px 1px 2px 1px rgb(25 118 210 / 85%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
				}}>
				<ChartGraph
					layers={layers}
					data={data}
					updateCurrentTime={updateCurrentTime}
					isLiveViewSelected={isLiveViewSelected}
				/>

				<VerticalTimeline timeData={timeData} />
			</Box>
		</Box>
	)
}

export default React.memo(ChartContainer)
