import { Visibility, VisibilityOff } from '@mui/icons-material'
import { IconButton, InputAdornment } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import axios from 'axios'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import logoImg from '../../assets/horus-azul.png'
import backgroundImg from '../../assets/login.png'
import {
	acceptCookieName,
	jwtCookieExpiryName,
	jwtCookieName,
	jwtRefreshToken,
	languageCookieName,
	messageDialogTypes,
	rolesCookieName,
} from '../../constants'
import { ApiClientContext } from '../../services/ApiClientProvider'
import useLoginRequest from '../../services/horus/useLoginRequest'
import usePasswordChangeRequest from '../../services/horus/usePasswordChangeRequest'
import { readCookie, setCookie } from '../../utils/cookie'
import BasicDialog from '../comun/BasicDialog'
import CookieDialog from '../comun/CookieDialog'
import SelectComponent from '../comun/form_fields/SelectComponent'
import TextFieldComponent from '../comun/form_fields/TextFieldComponent'
import MessageSnackBar from '../comun/MessageSnackBar'
import BackdropComponent from '../comun/utils/BackdropComponent'
import Copyright from '../Copyright'
import ErrorBoundary from '../ErrorBoundary'
import ChangePasswordForm from '../password_recovery/ChangePasswordForm'
import './Login.css'

const theme = createTheme()

const languages = [
	{ label: 'English', value: 'en' },
	{ label: 'Español', value: 'es' },
]

export default function Login() {
	const {
		watch,
		control,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm({})

	const { login, isLoading, dataLogin, error } = useLoginRequest()
	const { passwordChangeRequest, isLoadingPasswordChange, dataPasswordChange, errorPasswordChange } =
		usePasswordChangeRequest()

	const { setTokenValue } = useContext(ApiClientContext)
	const history = useHistory()
	const [t, i18n] = useTranslation('common')
	const cookieLanguage = readCookie(languageCookieName)
	const [showPassword, setShowPassword] = useState(false)
	const cookieAccept = readCookie(acceptCookieName)
	const [openModal, setOpenModal] = useState(false)
	const [openModalLegal, setOpenModalLegal] = useState(false)
	const [openModalPrivacy, setOpenModalPrivacy] = useState(false)
	const [openModalCookie, setOpenModalCookie] = useState(false)
	const [openModalCookieAccept, setOpenModalCookieAccept] = useState(true)
	const [openError, setOpenError] = useState(false)
	const [message, setMessage] = useState({ statusTxt: '' })
	const [msgType, setMsgType] = useState(messageDialogTypes.INFO_MESSAGE)
	const languageSelectValue = watch('language')

	useEffect(() => {
		if (cookieLanguage != null && cookieLanguage != '') {
			if (cookieLanguage == 'es') {
				setValue('language', languages[1])
			} else {
				setValue('language', languages[0])
			}
		} else {
			setValue('language', languages[0])
		}
		if (cookieAccept != null && cookieAccept != '' && cookieAccept.toString() == 'true') {
			setOpenModalCookieAccept(!cookieAccept)
		} else {
			setOpenModalCookieAccept(true)
		}
	}, [cookieLanguage, cookieAccept])

	const changeLanguage = (event) => {
		if (event.target.value == 'es') {
			setValue('language', languages[1])
		} else {
			setValue('language', languages[0])
		}
	}

	useEffect(() => {
		if (languageSelectValue) {
			i18n.changeLanguage(languageSelectValue.value)
		}
	}, [languageSelectValue])

	const onSubmit = (data) => {
		setCookie(languageCookieName, data.language.value, 'Fri, 31 Dec 2038 00:00:00 GMT')
		login(data.email, data.password, languageSelectValue)
	}

	useEffect(() => {
		if (dataLogin) {
			setTokenValue(dataLogin.token)
			setCookie(jwtCookieName, dataLogin.token, dataLogin.expiration)
			setCookie(jwtCookieExpiryName, dataLogin.expiration, dataLogin.expiration)
			setCookie(jwtRefreshToken, dataLogin.refreshToken, null)
			if (dataLogin.token) {
				var rolesToken = parseJwt(dataLogin.token).HorusRoles
				var arrayRoles = parseRoles(rolesToken)
				setCookie(rolesCookieName, arrayRoles, dataLogin.expiration)
			}
		}
	}, [dataLogin])

	useEffect(() => {
		if (error) {
			setMsgType(messageDialogTypes.ERROR_MESSAGE)
			setMessage(error)
			setOpenError(true)
			axios.defaults.headers.Authorization = undefined
		} else {
			setMessage(null)
			setOpenError(false)
		}
	}, [error])

	useEffect(() => {
		if (dataPasswordChange) {
			setMsgType(messageDialogTypes.SUCCESS_MESSAGE)
			setMessage({ statusText: t('change-password.change-request-success') })
			setOpenError(true)
		}
		if (errorPasswordChange) {
			setMsgType(messageDialogTypes.ERROR_MESSAGE)
			setMessage(errorPasswordChange)
			setOpenError(true)
		}
	}, [dataPasswordChange, errorPasswordChange])

	function parseJwt(token) {
		var base64Url = token.split('.')[1]
		var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
		var jsonPayload = decodeURIComponent(
			atob(base64)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
				})
				.join('')
		)
		return JSON.parse(jsonPayload)
	}

	function parseRoles(roles) {
		return roles.replace('[', '').replace(']', '').replace(/['"]+/g, '').split(',')
	}

	const onClose = () => {
		setOpenModal(false)
	}

	const onCloseLegal = () => {
		setOpenModalLegal(false)
	}

	const onClosePrivacy = () => {
		setOpenModalPrivacy(false)
	}

	const onCloseCookie = () => {
		setOpenModalCookie(false)
	}

	const onCloseCookieAccept = () => {
		//setCookie(languageCookieName, language.value, 'Fri, 31 Dec 2038 00:00:00 GMT')
		setCookie(acceptCookieName, openModalCookieAccept, 'Fri, 31 Dec 2038 00:00:00 GMT')
		setOpenModalCookieAccept(false)
	}

	const onCloseError = () => {
		setOpenError(false)
	}

	const onPasswordChangeRequest = (data) => {
		setOpenModal(false)
		passwordChangeRequest(data.username)
	}

	return (
		<ErrorBoundary history={history}>
			<MessageSnackBar
				openError={openError}
				errorMsg={message}
				handleCloseError={onCloseError}
				typeMsg={msgType}
			/>
			<BackdropComponent open={isLoadingPasswordChange || isLoading} />
			<ThemeProvider theme={theme}>
				<ChangePasswordForm open={openModal} onClose={onClose} onSave={onPasswordChangeRequest} />

				<BasicDialog title={t('legal-warning.title')} onOpen={openModalLegal} onClose={onCloseLegal}>
					<Box sx={{ width: '100%', my: 1.5, maxHeight: '600px' }}>
						<Typography
							variant='body1'
							dangerouslySetInnerHTML={{ __html: t('legal-warning.description') }}
						/>
					</Box>
				</BasicDialog>

				<BasicDialog title={t('privacy-policy.title')} onOpen={openModalPrivacy} onClose={onClosePrivacy}>
					<Box sx={{ width: '100%', my: 1.5, maxHeight: '600px' }}>
						<Typography
							variant='body1'
							dangerouslySetInnerHTML={{ __html: t('privacy-policy.description') }}
						/>
					</Box>
				</BasicDialog>

				<BasicDialog title={t('cookie-policy.title')} onOpen={openModalCookie} onClose={onCloseCookie}>
					<Box sx={{ width: '100%', my: 1.5, maxHeight: '600px' }}>
						<Typography
							variant='body1'
							dangerouslySetInnerHTML={{ __html: t('cookie-policy.description') }}
						/>
					</Box>
				</BasicDialog>

				<CookieDialog
					onOpen={openModalCookieAccept}
					onLink={setOpenModalCookie}
					onSave={onCloseCookieAccept}
					language={languageSelectValue ? languageSelectValue.value : languages[0].value}
					changeLanguage={changeLanguage}
					saveTxt={t('change-password.confirm-button')}></CookieDialog>
				{/* <div style={{ height: "100vh", width: "100%", margin: 0 }}> */}
					<Grid container  sx={{ width: '100%',height: '100vh' }}>
						<CssBaseline />
						<Grid
							item
							xs={false}
							sm={12}
							md={12}
							sx={{
								backgroundImage: `url(${backgroundImg})`,
								backgroundRepeat: 'cover',
								backgroundColor: (t) =>
									t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}
						>
							<Box mr={{ xs: 3 } } ml={{ xs: 4, sm: 13 }} my={{ xs: 0, sm: 3 }}>
								<img className='logo' src={logoImg} />
								<br />
								<Typography variant="h6" color="primary" sx={{ my: 1, fontFamily: 'sans-serif'  }}>
								Bringing fisheries management to the next level
								</Typography>
							</Box>
							<Grid container
								spacing={0}
								direction="column"
								alignItems="center"
								justifyContent="center" item>
								<Grid item component={Paper}  xs={10} sm={4} md={1} elevation={3} borderRadius={5} sx={{ my: 3 }}>
									<Box
										sx={{
											mx: 4,
											display: 'flex',
											flexDirection: 'column',
											alignItems: 'center',
										}}>
											<Box  sx={{ fontWeight: 'bold' }}>
												<Typography variant="h5" color="primary" sx={{ my: 3, fontFamily: 'sans-serif'  }}>							
												{t('login.welcome')}
												</Typography>
											</Box>
										<Box
											component='form'
											noValidate
											onSubmit={handleSubmit(onSubmit)}
											>
											<TextFieldComponent
												sx={{ my: 1, width: '100%' }}
												control={control}
												name='email'
												autoFocus
												autoComplete='email'
												required
												rules={{
													required: t('comun.form-errors.required'),
												}}
												label={t('login.user')}
												errors={errors?.email}
											/>
											<TextFieldComponent
												sx={{ my: 1, width: '100%' }}
												control={control}
												type={showPassword ? 'text' : 'password'}
												name='password'
												required
												rules={{
													required: t('comun.form-errors.required'),
												}}
												autoComplete='current-password'
												label={t('login.password')}
												errors={errors?.password}
												InputProps={{
													endAdornment: (
														<InputAdornment position='end'>
															<IconButton
																aria-label='toggle password visibility'
																onClick={() => setShowPassword(!showPassword)}
																edge='end'>
																{showPassword ? <VisibilityOff /> : <Visibility />}
															</IconButton>
														</InputAdornment>
													),
												}}
											/>

											<SelectComponent
												sx={{ my: 1, width: '100%' }}
												control={control}
												name='language'
												label={t('login.language')}
												options={languages}
												optionsLabel='label'
												optionsValue='value'
												defaultValue={[]}
											/>

											<Button
												type='submit'
												variant='contained'
												sx={{ mt: 3, mb: 2, width: '100%' }}
												disabled={isLoading}>
												{t('login.login')}
											</Button>
											<Grid container>
												<Grid item xs>
													<Button sx={{ textTransform: 'none' }} onClick={() => setOpenModal(true)}>
														{t('login.forgot-password')}
													</Button>
												</Grid>
											</Grid>
											<Box
												sx={{
													mt: 2,
													width: '100%',
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
												}}>
												<Copyright open={true} />
											</Box>
										</Box>
									</Box>
								</Grid>
							</Grid>
							<Grid container
								direction="column"
								alignItems="center"
								justifyContent="center" >
								<Grid item xs>
									<Button sx={{ textTransform: 'none', color: 'text.disabled'  }} onClick={() => setOpenModalLegal(true)}>
										{t('legal-warning.button')}
									</Button>
									<Button
										sx={{ textTransform: 'none',  color: 'text.disabled' }}
										onClick={() => setOpenModalPrivacy(true)}>
										{t('privacy-policy.button')}
									</Button>
									<Button sx={{ textTransform: 'none',  color: 'text.disabled' }} onClick={() => setOpenModalCookie(true)}>
										{t('cookie-policy.button')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				{/* </div> */}
			</ThemeProvider>
		</ErrorBoundary>
	)
}
