import { useCallback, useState, useContext } from 'react'

import { ApiClientContext } from '../../../../ApiClientProvider'
import { baseUrl } from '../../../../../constants'

export const useVideoAsignedDelete = () => {
	const [videoAsignedDeleteData, setVideoAsignedDeleteData] = useState(null)
	const [isLoadingVideoAsignedDelete, setIsLoadingVideoAsignedDelete] = useState(false)
	const [errorVideoAsignedDelete, setErrorVideoAsignedDelete] = useState(null)

	const { client } = useContext(ApiClientContext)

	const videoAsignedDelete = useCallback(
		(row) => {
			let url = 'horus/f3/project/preview/videodaterange?projectId=' + row.projectId
			url = url + '&from=' + row.from + '&to=' + row.to
			url = url + row.cams.map((c) => '&cam=' + c)
			url = url.replaceAll(',', '')
			client
				.delete(baseUrl + url)
				.then((result) => {
					setIsLoadingVideoAsignedDelete(false)
					if (result.data.success) {
						setVideoAsignedDeleteData(result.data.success)
					} else {
						setErrorVideoAsignedDelete({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingVideoAsignedDelete(false)
					setErrorVideoAsignedDelete(err.response)
				})
			setIsLoadingVideoAsignedDelete(true)
			setVideoAsignedDeleteData(null)
			setErrorVideoAsignedDelete(null)
		},
		[client]
	)

	return {
		videoAsignedDelete,
		videoAsignedDeleteData,
		isLoadingVideoAsignedDelete,
		errorVideoAsignedDelete,
	}
}
