import { Badge } from '@mui/material'
import { Icon } from 'leaflet'
import React, { useEffect, useState } from 'react'
import { Marker } from 'react-leaflet'

import { styled } from '@mui/material/styles'
import 'leaflet-rotatedmarker'
import { useShallow } from 'zustand/shallow'
import arrow from '../../../../assets/up-arrow.svg'
import { mapMarkerSize } from '../../../../constants'
import { useMapInfoPosition } from '../../../../services/preview/useMapInfoPosition'
import MessageSnackBar from '../../../comun/MessageSnackBar'
import useStore from '../../PreviewStore'
import MapInfoTooltip from './MapInfoTooltip'
import './MapTab.css'
const arrowBlackIcon = new Icon({
	iconUrl: arrow,
	iconSize: [mapMarkerSize, mapMarkerSize],
})
export default function CustomMarker({ p, updateCurrentTime, nextPos }) {
	const { vessel, project } = useStore(
		useShallow((state) => ({
			vessel: state.vessel,
			project: state.project,
		}))
	)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const { getMapInfoPosition, dataMapInfo, errorMapInfo, isLoadingMapInfo } = useMapInfoPosition(
		vessel?.vesselId,
		project?.projectId,
		false
	)

	useEffect(() => {
		if (errorMapInfo) {
			setOpenError(true)
			setErrorMsg(errorMapInfo)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorMapInfo])

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}

	const handleGoToMoment = (position) => {
		updateCurrentTime(position.date)
	}
	const handleClick = () => {
		getMapInfoPosition(null, p.date, nextPos.date)
	}
	return (
		<Marker
			position={[p.lat, p.lon]}
			alt='Marker'
			rotationOrigin='center'
			eventHandlers={{
				click: handleClick,
			}}
			zIndexOffset={100}
			rotationAngle={p.course}
			icon={arrowBlackIcon}>
			<MapInfoTooltip
				handleGoToMoment={handleGoToMoment}
				isLoadingMapInfo={isLoadingMapInfo}
				dataMapInfo={dataMapInfo}
				p={p}
			/>
			<MessageSnackBar openError={openError} errorMsg={errorMsg} handleCloseError={handleCloseError} />
			{/* <Typography sx={{ fontSize: { fontSize } }}>
							<strong>{`${t('track.map.date')}: `}</strong>
							{datetimeUTCtoString(p.date)}
						</Typography>

						<Typography sx={{ fontSize: { fontSize } }}>
							<strong>{`${t('track.map.latitude')}: `}</strong>
							{p.lat}
						</Typography>
						<Typography sx={{ fontSize: { fontSize } }}>
							<strong>{`${t('track.map.longitude')}: `}</strong>
							{p.lon}
						</Typography>
						<Typography sx={{ fontSize: { fontSize } }}>
							<strong>{`${t('track.map.speed')}: `}</strong>
							{p.speed + ' kn'}
						</Typography>
						<Typography sx={{ fontSize: { fontSize } }}>
							<strong>{`${t('track.map.course')}: `} </strong>
							{p.course}
						</Typography>
						<Tooltip title={t('preview.annotation.go-to-moment')}>
							<Button
								onClick={() => handleGoToMoment(p)}
								variant='outlined'
								sx={{ minWidth: '25px !important', height: '25px' }}>
								<RedoIcon sx={{ heigth: '20px', width: '20px' }} />
							</Button>
						</Tooltip> */}
		</Marker>
	)
}
