import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useTimerProjectFilterRequest = () => {
	const [timerProjectFilter, setTimerProjectFilter] = useState(null)
	const [errorTimerProjectFilter, setErrorTimerProjectFilter] = useState(null)
	const [isLoadingTimerProjectFilter, setIsLoadingTimerProjectFilter] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerProjectFilterRequest = useCallback(() => {
		client
			.get(baseUrl + 'horus/f3/timer/projects/filter')
			.then((result) => {
				setIsLoadingTimerProjectFilter(false)
				setTimerProjectFilter(result.data.data)
				if (result.data.success) {
					setTimerProjectFilter(result.data.data)
				} else {
					setErrorTimerProjectFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingTimerProjectFilter(false)
				setErrorTimerProjectFilter(err.response)
			})

		setTimerProjectFilter(null)
		setErrorTimerProjectFilter(null)
		setIsLoadingTimerProjectFilter(true)
	}, [client])
	return {
		timerProjectFilterRequest,
		isLoadingTimerProjectFilter,
		timerProjectFilter,
		errorTimerProjectFilter,
	}
}
