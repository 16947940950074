import { Typography } from '@mui/material'
import { Icon } from 'leaflet'
import React, { useEffect, useRef } from 'react'
import { Marker, Popup, useMap } from 'react-leaflet'
import blackVesselIcon from '../../../assets/black-vessel-icon.png'
import greenVesselIcon from '../../../assets/green-vessel-icon-border.png'
import yellowVesselIcon from '../../../assets/orange-vessel-icon-border.png'
import redVesselIcon from '../../../assets/red-vessel-icon-border.png'
import { datetimeUTCtoString } from '../../comun/DateUtils'
import { useTranslation } from 'react-i18next'
import { getBounds } from '../../../utils/customFunctions'

export default function DashboardMapMarkersLayer({ rows, vesselSelected, trackList, setMoving, handleRowClick }) {
	const [t] = useTranslation('common')
	const refs = useRef([])
	const map = useMap()

	useEffect(() => {
		if (vesselSelected && map && trackList && trackList?.length > 0) {
			map.flyToBounds(getBounds(trackList))
			setMoving(true)
			map.once('moveend', () => moveEnded(true))
		} else {
			map.flyTo([-5.505, -0.09], 2)
		}
	}, [vesselSelected, trackList])

	const moveEnded = (auxMoving) => {
		if (auxMoving) {
			setMoving(false)
		}
	}
	const getVesselIcon = (status) => {
		switch (status) {
			case 1: {
				return new Icon({
					iconUrl: redVesselIcon,
					iconSize: [25, 25],
				})
			}
			case 2: {
				return new Icon({
					iconUrl: yellowVesselIcon,
					iconSize: [25, 25],
				})
			}
			case 3: {
				return new Icon({
					iconUrl: greenVesselIcon,
					iconSize: [25, 25],
				})
			}
			default: {
				return new Icon({
					iconUrl: blackVesselIcon,
					iconSize: [25, 25],
				})
			}
		}
	}

	return (
		<>
			{rows &&
				rows.map((vessel, idx) => {
					return (
						<Marker
							eventHandlers={{
								click: (e) => {
									handleRowClick(vessel)
								},
							}}
							key={idx + vessel.vesselName}
							position={[vessel.lat, vessel.lon]}
							icon={getVesselIcon(vessel.seatubeStatus)}
							title={vessel.vesselName}>
							<Popup ref={(popup) => (refs.current[idx] = popup)}>
								<Typography>
									<strong>{t('vessel.table.header.vessel-name')}:</strong> {vessel.vesselName}
								</Typography>
								<Typography>
									<strong>{t('vessel.table.header.last-conection')} </strong>
									{datetimeUTCtoString(vessel.date)}
								</Typography>
							</Popup>
						</Marker>
					)
				})}
		</>
	)
}
