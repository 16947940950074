import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'
import error from '../assets/error.png'
import { appRoutes, errorTypes } from '../constants'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError() {
		// Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
		return { hasError: true }
	}

	componentDidCatch(error) {
		if (process.env.NODE_ENV !== 'production') {
			console.log('Caught unexpected error' + error)
		}
		if (error.message === errorTypes.WRONG_JWT) {
			this.props.history.push(appRoutes.LOGIN)
		}
	}

	render() {
		if (this.state.hasError) {
			// Puedes renderizar cualquier interfaz de repuesto
			return (
				<Box
					maxWidth='false'
					sx={{
						mt: 4,
						mb: 4,
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						alignItems: 'center',
					}}>
					<Box component='div' sx={{ display: 'flex', m: 5 }}>
						<img src={error} />
					</Box>
					<Box component='div'>
						<Typography gutterBottom variant='h5' component='div'>
							Error inesperado
						</Typography>
						<Typography gutterBottom variant='span' component='div'>
							Ha ocurrido un error en la aplicación y tu página no se ha podido mostrar. Póngase en
							contacto con el administrador
						</Typography>
					</Box>
				</Box>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary
