import { Box } from '@mui/material'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import BasicDialog from '../../comun/BasicDialog'
import LabelValueComponent from './LabelValueComponent'
import FieldsetComponent from '../../comun/FieldsetComponent'
import TextFieldComponent from '../../comun/form_fields/TextFieldComponent'
import { useForm } from 'react-hook-form'

export default function AlertViewForm({ alert, open, onClose, onReport }) {
	const [t] = useTranslation('common')

	const { control, reset } = useForm()

	useEffect(() => {
		reset(alert)
	}, [alert])

	const onOffIcon = (onOff) => {
		return onOff ? <CheckRoundedIcon /> : <CloseRoundedIcon fontSize='small' />
	}
	return (
		<BasicDialog
			title={t('alert.view.form.title')}
			onOpen={open}
			onClose={onClose}
			saveTxt={alert?.status == 0 ? t('alert.view.table.content.report') : null}
			onSave={onReport}>
			<Box sx={{ display: 'flex', flexDirection: 'row', mr: 3, minWidth: '800px' }}>
				<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<TextFieldComponent
							control={control}
							size='small'
							name='description'
							label={t('alert.view.form.config-name')}
							sx={{ m: 1, width: '50%' }}
							InputProps={{ readOnly: true }}
						/>
						<TextFieldComponent
							InputProps={{ readOnly: true }}
							control={control}
							size='small'
							name='vesselName'
							label={t('alert.view.form.vessel')}
							sx={{ m: 1, width: '50%' }}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<TextFieldComponent
							InputProps={{ readOnly: true }}
							control={control}
							size='small'
							name='startDate'
							label={t('alert.view.form.start-date')}
							sx={{ m: 1, width: '50%' }}
						/>
						<TextFieldComponent
							InputProps={{ readOnly: true }}
							control={control}
							size='small'
							name='endDate'
							label={t('alert.view.form.end-date')}
							sx={{ m: 1, width: '50%' }}
							defaultValue={alert ? alert.endDate : 'N/A'}
						/>
					</Box>
					<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
						<TextFieldComponent
							InputProps={{ readOnly: true }}
							control={control}
							size='small'
							name='latlon'
							label={t('alert.view.form.latlon')}
							sx={{ m: 1, width: '24%' }}
						/>
						<TextFieldComponent
							InputProps={{ readOnly: true }}
							control={control}
							size='small'
							name='speedValue'
							label={t('alert.view.form.speed')}
							sx={{ m: 1, width: '24%' }}
						/>
						<TextFieldComponent
							InputProps={{ readOnly: true }}
							control={control}
							size='small'
							name='auxStatus'
							label={t('alert.view.form.status')}
							sx={{ m: 1, width: '50%' }}
						/>
					</Box>
					{alert?.commentary && (
						<Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
							<TextFieldComponent
								InputProps={{ readOnly: true }}
								control={control}
								size='small'
								name='commentary'
								label={t('alert.view.form.commentary')}
								sx={{ m: 1, width: '100%' }}
							/>
						</Box>
					)}
				</Box>
				{alert?.sensors.length !== 0 && (
					<Box sx={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
						<FieldsetComponent title={t('alert.view.form.sensors')}>
							<Box sx={{ display: 'flex', flexDirection: 'column' }}>
								{alert?.sensors.map((sensor, idx) => {
									return (
										<LabelValueComponent
											vertical
											key={sensor.sensorId + idx}
											sx={{ m: 1, width: '100%' }}
											label={sensor.description}
											value={
												sensor.value
													? sensor.value + ' ' + sensor.unit
													: onOffIcon(sensor.onOff)
											}
										/>
									)
								})}
							</Box>
						</FieldsetComponent>
					</Box>
				)}
			</Box>
		</BasicDialog>
	)
}
