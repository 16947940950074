import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useTimerUsersFilterRequest = () => {
	const [timerUsersFilter, setTimerUsersFilter] = useState(null)
	const [errorTimerUsersFilter, setErrorTimerUsersFilter] = useState(null)
	const [isLoadingTimerUsersFilter, setIsLoadingTimerUsersFilter] = useState(false)
	const { client } = React.useContext(ApiClientContext)

	const timerProjectUsersRequest = useCallback(() => {
		client
			.get(baseUrl + 'horus/f3/timer/users/filter')
			.then((result) => {
				setIsLoadingTimerUsersFilter(false)
				setTimerUsersFilter(result.data.data)
				if (result.data.success) {
					setTimerUsersFilter(result.data.data)
				} else {
					setErrorTimerUsersFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingTimerUsersFilter(false)
				setErrorTimerUsersFilter(err.response)
			})

		setTimerUsersFilter(null)
		setErrorTimerUsersFilter(null)
		setIsLoadingTimerUsersFilter(true)
	}, [client])
	return {
		timerProjectUsersRequest,
		isLoadingTimerUsersFilter,
		timerUsersFilter,
		errorTimerUsersFilter,
	}
}
