import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import AlertConfigContainer from '../components/alerts/alert_config/AlertConfigContainer'

const AlertsConfigPage = () => {
	const history = useHistory()

	return (
		<ErrorBoundary history={history}>
			<AlertConfigContainer />
		</ErrorBoundary>
	)
}

export default AlertsConfigPage
