import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useZoneDesactivate = () => {
	const [dataZoneDesactivate, setDataZoneDesactivate] = useState(null)
	const [errorZoneDesactivate, setErrorZoneDesactivate] = useState(null)
	const [isLoadingZoneDesactivate, setIsLoadingZoneDesactivate] = useState(false)
	const { client } = useContext(ApiClientContext)

	const zoneDesactivate = useCallback(
		(props) => {
			client
				.delete(baseUrl + 'horus/f3/alert/zone/act?ZoneId=' + props)
				.then((result) => {
					setIsLoadingZoneDesactivate(false)
					if (result.status === 200 && result.data.success) {
						setDataZoneDesactivate({ data: result.data })
					} else {
						setErrorZoneDesactivate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorZoneDesactivate(error.response)
					setIsLoadingZoneDesactivate(false)
				})

			setIsLoadingZoneDesactivate(true)
			setDataZoneDesactivate(null)
			setErrorZoneDesactivate(null)
		},
		[client]
	)

	return {
		dataZoneDesactivate,
		errorZoneDesactivate,
		isLoadingZoneDesactivate,
		zoneDesactivate,
	}
}
