import MapIcon from '@mui/icons-material/Map'
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton, TableFooter, Tooltip } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { datetimeUTCtoString } from '../../comun/DateUtils'
import { StyledTableRow } from '../../comun/StyleComponentUtils'
import { EnhancedTableHead, getComparator, stableSort } from '../../comun/TableUtils'
import TablePaginationComponent from '../../comun/TablePaginationComponent'

export default function AlertViewTable({
	alertList,
	handleOpenConfirmation,
	handleOpenForm,
	filter,
	onFilter,
	handleShowPreview,
	statusOptions,
}) {
	const [t] = useTranslation('common')

	//This block should be the same in every table component ---------------------------------- COPY THIS BOCK IN EVERY TABLE WITH SORTING SYSTEM
	const [order, setOrder] = useState(filter.orderDirection)
	const [orderBy, setOrderBy] = useState(filter.orderField)
	const [page, setPage] = useState(0)
	const [totalCount, setTotalCount] = useState(0)
	const [rowsPerPage, setRowsPerPage] = useState(filter.pageSize)

	React.useEffect(() => {
		if (filter) {
			setRowsPerPage(filter.pageSize)
			setOrder(filter.orderDirection)
			setOrderBy(filter.orderField)
		}
	}, [filter])

	const handleChangePage = (event, newPage) => {
		let newFilter = { ...filter }
		newFilter.pageNo = newPage + 1
		onFilter(newFilter)
	}

	const handleChangeRowsPerPage = (event) => {
		let newFilter = { ...filter }
		newFilter.pageSize = event.target.value
		newFilter.pageNo = 1
		onFilter(newFilter)
	}

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc'
		let newFilter = { ...filter }
		newFilter.orderDirection = isAsc ? 'desc' : 'asc'
		newFilter.orderField = property
		newFilter.pageNo = 1
		onFilter(newFilter)
	}
	//Until here it should be the same in every table component ------------------------------

	//This useEffect should be the same but changing the "alertList" with the name of the data that we receive from Container
	React.useEffect(() => {
		if (alertList) {
			setPage(alertList.pageNo - 1)
			setTotalCount(alertList.totalCount)
		}
	}, [alertList])

	//IMPORTANT: columns id should match the order options that SATLINK provides. orderBy: true only in the columns that SATLINK allow us to sort by
	const columnas = [
		{
			id: 'startDate',
			disablePadding: true,
			orderBy: true,
			label: t('alert.view.table.header.start-date'),
		},
		{
			id: 'endDate',
			orderBy: true,
			label: t('alert.view.table.header.end-date'),
		},
		{
			id: 'configId',
			orderBy: false,
			label: t('alert.view.table.header.config-name'),
		},
		{
			id: 'vesselname',
			orderBy: true,
			label: t('alert.view.table.header.vessel'),
		},
		{
			id: 'status',
			orderBy: false,
			label: t('alert.view.table.header.status'),
		},
		{
			id: 'actions',
			label: t('alert.view.table.header.options'),
			excluirExcel: true,
		},
	]

	const getStatus = (value) => {
		return statusOptions.find((s) => s.value == value)?.description
	}

	return (
		<TableContainer>
			<Table
				id='table-alert-list'
				sx={{ minWidth: 750 }}
				aria-labelledby='tableTitle'
				size='small'
				style={{ border: '1.5px solid rgba(224, 224, 224, 1)' }}>
				<EnhancedTableHead
					order={order}
					orderBy={orderBy}
					onRequestSort={handleRequestSort}
					headCells={columnas}
				/>
				<TableBody>
					{alertList
						? stableSort(alertList.data, getComparator(order, orderBy)).map((row, index) => {
								return (
									<StyledTableRow key={index}>
										<TableCell align='center'>{datetimeUTCtoString(row.startDate)}</TableCell>
										<TableCell align='center'>{datetimeUTCtoString(row.endDate)}</TableCell>
										<TableCell align='center'>{row.description}</TableCell>
										<TableCell align='center'>{row.vesselName}</TableCell>
										<TableCell align='center'>{getStatus(row.status)}</TableCell>
										<TableCell align='center' className='excluir-excel'>
											{row.status == 0 && (
												<Tooltip title={t('alert.view.table.content.report')}>
													<IconButton
														color='primary'
														onClick={() => handleOpenConfirmation(row)}>
														<NotificationsPausedIcon />
													</IconButton>
												</Tooltip>
											)}
											<Tooltip title={t('alert.view.table.content.details')}>
												<IconButton color='primary' onClick={() => handleOpenForm(row)}>
													<VisibilityIcon />
												</IconButton>
											</Tooltip>

											<Tooltip title={t('alert.view.table.content.show-preview')}>
												<IconButton color='primary' onClick={() => handleShowPreview(row)}>
													<MapIcon />
												</IconButton>
											</Tooltip>
										</TableCell>
									</StyledTableRow>
								)
						  })
						: null}
				</TableBody>
				<TableFooter className='excluir-excel'>
					<TableRow style={{ height: 52 }}>
						<TablePaginationComponent
							rowsPerPageOptions={[5, 10, 25]}
							count={totalCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
							customActions={true}
						/>
					</TableRow>
				</TableFooter>
			</Table>
		</TableContainer>
	)
}
