import { useCallback, useState, useContext } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUserFilterRequest = () => {
	const { client } = useContext(ApiClientContext)
	const [userFilter, setUserFilter] = useState(null)
	const [errorUserFilter, setErrorUserFilter] = useState(null)
	const [isLoadingUserFilter, setIsLoadingUserFilter] = useState(false)
	

	const userFilterRequest = useCallback(() => {
		setIsLoadingUserFilter(true);
    	setUserFilter(null);
    	setErrorUserFilter(null);

		client
			.get(baseUrl + 'horus/f3/user/filter')
			.then((result) => {
				setIsLoadingUserFilter(false)

				if (result.data.success) {
					setUserFilter(result.data.data)
				} else {
					setErrorUserFilter({ statusText: result.data.error })
				}
			})
			.catch((err) => {
				setIsLoadingUserFilter(false)
				setErrorUserFilter(err.response)
			})
	}, [client])
	return {
		userFilterRequest,
		isLoadingUserFilter,
		userFilter,
		errorUserFilter,
	}
}