import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import PhotoCamera from '@mui/icons-material/PhotoCamera'
import VideoCallIcon from '@mui/icons-material/VideoCall'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import { Box, IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { messageDialogTypes } from '../../../constants'
import { useInformChangePreview } from '../../../services/preview/useInformChangePreview'
import MessageSnackBar from '../../comun/MessageSnackBar'
import AnnotationButtons from './AnnotationButtons'
import CalibrationButton from './CalibrationButton'
import useStore from '../PreviewStore'
import { useShallow } from 'zustand/shallow'
import { dateDiffInSeconds, datetimeUTCtoString } from '../../comun/DateUtils'
import { useVideoScreenshotInfo } from '../../../services/videos/useVideoScreenshotInfo'
import BackdropComponent from '../../comun/utils/BackdropComponent'
import ProjectVideosModal from './project_videos/ProjectVideosModal'
import VideoRequestModal from './video_request/VideoRequestModal'
import { hasFleetStaffRole } from '../../../utils/roles'
import { rolesCookieName } from '../../../constants'
import { readCookie } from '../../../utils/cookie'

const speedOptions = [
	{ label: 'x0.25', value: 0.25 },
	{ label: 'x0.5', value: 0.5 },
	{ label: 'x1', value: 1 },
	{ label: 'x2', value: 2 },
	{ label: 'x5', value: 5 },
	{ label: 'x8', value: 8 },
	{ label: 'x10', value: 10 },
]

const timeRangeOptions = [
	{ label: '30 mins', value: '30min' },
	{ label: '1 hour', value: '1hour' },
	{ label: '1 day', value: '1day' },
	{ label: '1 week', value: '1week' },
	{ label: 'Other', value: 0 },
]

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
	'& .MuiToggleButtonGroup-grouped': {
		margin: 1,
		border: '1px solid #1976d2',
		borderRadius: 5,

		'&.Mui-selected': {
			color: 'white',
			fontWeight: 700,
			backgroundColor: '#1976d2',
			textTransform: 'unset !important',
		},
		'&': {
			color: '#1976d2',
			fontWeight: 700,
			backgroundColor: 'white',
			textTransform: 'unset !important',
		},
		'&.Mui-disabled': {
			color: '#bdbdbd',
			border: '1px solid #bdbdbd',
			'&.Mui-selected': {
				color: 'white !important',
				backgroundColor: '#bdbdbd !important',
			},
		},
	},
}))

function Controls({
	dataPreview: {
		controlsData: {
			isZooming,
			isZoomEnabled,
			isVideoRequestEnabled,
			isVideoAsignVisible,
			isVideoAsignEnabled,
			isPlaySpeedEnabled,
			isAnnotationShortcutVisible,
			isAnnotationShortcutEnabled,
			fishingProfile,
			isCalibrationVisible,
		},
		visualizationData,
		timeData: { currentRangeStart, currentRangeEnd },
	},
	updateTimeRange,
	handleZoomChanged,
	vesselApiId,
	projectId,
}) {
	const [t] = useTranslation('common')

	const [zoom, setZoom] = useState(isZooming)
	const [timeRange, setTimeRange] = useState(0)
	const [openRequest, setOpenRequest] = useState(false)
	const [openVideoProject, setOpenVideoProject] = useState(false)
	const [openError, setOpenError] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const {
		play,
		pause,
		vessel,
		project,
		speed,
		setSpeed,
		enableRangeConfigEnabled,
		disableRangeConfigEnabled,
		getCurrentTime,
		mainVideo,
	} = useStore(
		useShallow((state) => ({
			play: state.play,
			pause: state.pause,
			vessel: state.vessel,
			speed: state.speed,
			project: state.project,
			setSpeed: state.setSpeed,
			enableRangeConfigEnabled: state.enableRangeConfigEnabled,
			disableRangeConfigEnabled: state.disableRangeConfigEnabled,
			getCurrentTime: state.getCurrentTime,
			mainVideo: state.mainVideo,
		}))
	)

	const { informChange, errorInformChange, setErrorInformChange } = useInformChangePreview(vesselApiId, projectId)
	const { getScreenshotInfo, screenshotInfoData, isLoadingScreenshotInfo, screenshotInfoError } =
		useVideoScreenshotInfo()

	const handleChangeSpeed = (event, newSpeed) => {
		if (newSpeed != null) {
			informChange('speed', newSpeed)
			setSpeed(newSpeed)
		}
	}

	const [roles, setRoles] = useState([])
	const cookieRoles = readCookie(rolesCookieName)

	React.useEffect(() => {
		setRoles(cookieRoles.split(","));
	}, [cookieRoles])

	useEffect(() => {
		if (errorInformChange) {
			setOpenError(true)
			setErrorMsg(errorInformChange)
		} else if (screenshotInfoError) {
			setOpenError(true)
			setErrorMsg(screenshotInfoError)
		} else {
			setOpenError(false)
			setErrorMsg(null)
		}
	}, [errorInformChange, screenshotInfoError])

	const handleChangeTimeRange = (event, newTimeRange) => {
		if (newTimeRange != null) {
			if (newTimeRange != 0) {
				disableRangeConfigEnabled()
				updateTimeRange(null, null, null, newTimeRange)
			} else {
				enableRangeConfigEnabled()
			}
			setTimeRange(newTimeRange)
		}
	}

	const handleChangeZoom = () => {
		if (zoom) {
			handleZoomChanged()
			informChange('zoom/disable')
		} else {
			handleZoomChanged()
			informChange('zoom/enable')
		}
		setZoom(!zoom)
	}

	const handleOpenVideoRequest = () => {
		pause()
		setOpenRequest(true)
	}

	const handleOpenVideoProject = () => {
		pause()
		setOpenVideoProject(true)
	}

	const onCloseRequest = () => {
		play()
		setOpenRequest(false)
	}

	const onCloseVideoProject = () => {
		play()
		setOpenVideoProject(false)
	}

	const handleCloseError = () => {
		setOpenError(false)
		setErrorMsg(null)
	}
	useEffect(() => {
		if (screenshotInfoData) {
			var link = document.createElement('a')
			link.href = screenshotInfoData.url
			link.click()
		}
	}, [screenshotInfoData])

	const handleScreenshot = () => {
		let params = {
			VideoId: mainVideo.videoId,
			Offset: dateDiffInSeconds(getCurrentTime(), mainVideo.startDate).toFixed(0),
			Opt: 'PIC',
		}
		pause()
		getScreenshotInfo(params)
	}

	return (
		<Box
			sx={{
				width: '100%',
				display: 'flex',
				alignItems: 'center',
				height: '100%',
			}}>
			<BackdropComponent open={isLoadingScreenshotInfo} />
			<MessageSnackBar
				openError={openError}
				errorMsg={errorMsg}
				typeMsg={messageDialogTypes.ERROR_MESSAGE}
				handleCloseError={handleCloseError}
			/>
			<Box sx={{ width: '60%', display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center' }}>
				<StyledToggleButtonGroup
					disabled={!isPlaySpeedEnabled}
					color='primary'
					size='small'
					exclusive
					sx={{ mx: 1, height: '90%' }}
					value={speed != null ? speed : 1}
					onChange={handleChangeSpeed}>
					{speedOptions.map((opt, idx) => {
						return (
							<ToggleButton color='primary' key={idx} variant='outlined' value={opt.value}>
								{opt.label}
							</ToggleButton>
						)
					})}
				</StyledToggleButtonGroup>

				<Tooltip placement='top' title={t('videos.main-section.screenshot')}>
					<span>
						<IconButton
							color='primary'
							variant='outlined'
							size='small'
							sx={{ mx: 0.5, height: '80%' }}
							disabled={visualizationData.isLiveViewSelected || mainVideo?.videoId == '0'}
							onClick={handleScreenshot}>
							<PhotoCamera />
						</IconButton>
					</span>
				</Tooltip>
				<StyledToggleButtonGroup
					disabled={!isZoomEnabled || visualizationData.isLiveViewSelected}
					color='primary'
					size='small'
					exclusive
					sx={{ mx: 0.5, height: '90%' }}
					value={zoom != null ? zoom : false}
					onChange={handleChangeZoom}>
					<ToggleButton color='primary' variant='outlined' selected={zoom} value={true}>
						<ZoomInIcon sx={{ height: '220%' }} />
					</ToggleButton>
				</StyledToggleButtonGroup>
				{isCalibrationVisible && <CalibrationButton />}

				<Tooltip placement='top' title={t('track.actions.video-request')}>
					<span>
						<IconButton
							color='primary'
							variant='outlined'
							size='small'
							sx={{ mx: 0.5, height: '80%' }}
							disabled={!isVideoRequestEnabled}
							onClick={handleOpenVideoRequest}>
							<CloudUploadIcon />
						</IconButton>
					</span>
				</Tooltip>
				{isVideoAsignVisible && (
					<Tooltip placement='top' title={t('track.actions.video-project')}>
						<span>
							<IconButton
								color='primary'
								variant='outlined'
								size='small'
								sx={{ mx: 0.5, height: '80%' }}
								disabled={!isVideoAsignEnabled}
								onClick={handleOpenVideoProject}>
								<VideoCallIcon />
							</IconButton>
						</span>
					</Tooltip>
				)}
			</Box>
			<Box
				sx={{
					width: '40%',
					display: 'flex',
					alignItems: 'center',
					height: '100%',
					justifyContent: 'space-between',
					mr: 1,
					ml: 1,
				}}>
				<AnnotationButtons
					fishingProfile={fishingProfile}
					isAnnotationShortcutEnabled={isAnnotationShortcutEnabled}
					isAnnotationShortcutVisible={isAnnotationShortcutVisible}
				/>
				<StyledToggleButtonGroup
					color='primary'
					size='small'
					exclusive
					disabled={visualizationData.isLiveViewSelected}
					sx={{ mx: 0.5, height: '90%', justifySelf: 'flex-end' }}
					value={timeRange != null ? timeRange : 0}
					onChange={handleChangeTimeRange}>
					{timeRangeOptions.map((opt, idx) => {
						return (
							<ToggleButton color='primary' key={idx} variant='outlined' value={opt.value}>
								{opt.label}
							</ToggleButton>
						)
					})}
				</StyledToggleButtonGroup>
			</Box>
			{openRequest && (
				<VideoRequestModal
					open={openRequest}
					onClose={onCloseRequest}
					vessel={vessel}
					projectId={projectId}
					vesselApiId={vesselApiId}
					vesselName={vessel ? vessel.vesselName : project.vesselName}
					defaultFrom={currentRangeStart}
					defaultTo={currentRangeEnd}
					numCameras={visualizationData?.cameraEnum?.length}
				/>
			)}
			{openVideoProject && (
				<ProjectVideosModal
					open={openVideoProject}
					onClose={onCloseVideoProject}
					projectId={projectId}
					vesselApiId={vesselApiId}
					projectName={project.description}
					defaultFrom={currentRangeStart}
					defaultTo={currentRangeEnd}
					numCameras={visualizationData?.cameraEnum?.length}
				/>
			)}
		</Box>
	)
}

export default React.memo(Controls)
