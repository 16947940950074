import React, { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'
import { ApiClientContext } from '../ApiClientProvider'

export const useUpdatePreview = (vesselApiId, projectId) => {
	const [visualizationData, setVisualizationData] = useState(null)
	const [errorUpdatePreview, setErrorUpdatePreview] = useState(null)
	const [isLoadingUpdatePreview, setIsLoadingUpdatePreview] = useState(false)
	const { client } = React.useContext(ApiClientContext)
	const computedUrl = vesselApiId ? 'horus/f3/dashboard/preview/view/' : 'horus/f3/project/preview/view/'
	const computedArgument = vesselApiId ? '?vesselApiId=' + vesselApiId : '?projectId=' + projectId
	const updatePreview = useCallback(
		(url) => {
			let isLiveView = url.includes('liveview')
			client
				.put(baseUrl + computedUrl + url + computedArgument)
				.then((result) => {
					setIsLoadingUpdatePreview(false)
					if (result.data.success) {
						setVisualizationData(isLiveView ? result.data : result.data.data)
					} else {
						setErrorUpdatePreview({ statusText: result.data.errorMsg })
					}
				})
				.catch((err) => {
					setIsLoadingUpdatePreview(false)
					setErrorUpdatePreview(err.response)
				})

			setVisualizationData(null)
			setErrorUpdatePreview(null)
			setIsLoadingUpdatePreview(true)
		},
		[client]
	)
	return {
		updatePreview,
		visualizationData,
		errorUpdatePreview,
		isLoadingUpdatePreview,
	}
}
