import axios from 'axios'
import { useCallback, useState } from 'react'
import { baseUrl } from '../../constants'

export default function usePasswordChangeRequest() {
	const [dataPasswordChange, setDataPasswordChange] = useState(null)
	const [errorPasswordChange, setErrorPasswordChange] = useState(null)
	const [isLoadingPasswordChange, setIsLoadingPasswordChange] = useState(false)

	const passwordChangeRequest = useCallback((username) => {
		axios
			.put(baseUrl + 'horus/f3/userpasswordrequest?userName=' + username)
			.then((result) => {
				setIsLoadingPasswordChange(false)
				if (result.data.success) {
					setDataPasswordChange(result.data)
				} else {
					setErrorPasswordChange({ statusText: result.data.errorMsg })
				}
			})
			.catch((err) => {
				setIsLoadingPasswordChange(false)
				setErrorPasswordChange(err.response)
			})

		setDataPasswordChange(null)
		setErrorPasswordChange(null)
		setIsLoadingPasswordChange(true)
	}, [])

	return {
		passwordChangeRequest,
		isLoadingPasswordChange,
		dataPasswordChange,
		errorPasswordChange,
	}
}
