import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../ApiClientProvider'

export const useVesselAlertReport = () => {
	const [alertReportData, setAlertReportData] = useState(null)
	const [errorAlertReport, setErrorAlertReport] = useState(null)
	const [isLoadingAlertReport, setIsLoadingAlertReport] = useState(false)

	const { client } = useContext(ApiClientContext)

	const vesselAlertReport = useCallback(
		(idAlert, comment) => {
			client
				.put(baseUrl + 'horus/f3/alert/vessel/report?Id=' + idAlert + '&commentary=' + comment)
				.then((result) => {
					setIsLoadingAlertReport(false)
					if (result.status === 200 && result.data.success) {
						setAlertReportData({ data: result.data })
					} else {
						setErrorAlertReport({ statusText: result.data.errorMsg })
					}
					setIsLoadingAlertReport(false)
				})
				.catch((error) => {
					setErrorAlertReport(error.response)
					setIsLoadingAlertReport(false)
				})

			setAlertReportData(null)
			setErrorAlertReport(null)
			setIsLoadingAlertReport(true)
		},
		[client]
	)

	return {
		vesselAlertReport,
		alertReportData,
		errorAlertReport,
		isLoadingAlertReport,
	}
}
