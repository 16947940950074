import React from 'react'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import ReportsContainer from '../components/reports/ReportsContainer'

const ReportsPage = () => {
	const history = useHistory()

	let { projectId } = useParams()

	return (
		<ErrorBoundary history={history}>
			<ReportsContainer projectId={projectId} />
		</ErrorBoundary>
	)
}

export default ReportsPage
