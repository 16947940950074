import React from 'react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import ErrorBoundary from '../components/ErrorBoundary'
import TimerUsersContainer from '../components/timer/TimerUsersContainer'

const TimerUsersPage = () => {
	const history = useHistory()

	return (
		<ErrorBoundary history={history}>
			<TimerUsersContainer></TimerUsersContainer>
		</ErrorBoundary>
	)
}

export default TimerUsersPage
