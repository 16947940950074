import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { FormHelperText } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getDateLocale } from '../../../App'

export default function DatePickerComponent({
	rules,
	control,
	defaultValue,
	name,
	label,
	errors,
	sx,
	size,
	disableFuture,
}) {
	const [, i18n] = useTranslation('common')

	return (
		<FormControl sx={sx} error={!!errors} size={size}>
			<Controller
				rules={rules}
				defaultValue={defaultValue}
				name={name}
				control={control}
				render={({ field: { onChange, value, onBlur } }) => (
					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={getDateLocale(i18n.language)}>
						<DateTimePicker
							clearable
							minTime={0}
							disableFuture={disableFuture}
							onChange={onChange}
							label={label}
							value={value}
							inputFormat='dd/MM/yyyy HH:mm'
							renderInput={(params) => (
								<TextField onBlur={onBlur} size={size} {...params} error={!!errors} />
							)}
						/>
					</LocalizationProvider>
				)}
			/>
			<FormHelperText>{errors?.message}</FormHelperText>
		</FormControl>
	)
}
