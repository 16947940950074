import { useCallback, useState, useContext } from 'react'

import { baseUrl } from '../../../constants'
import { ApiClientContext } from '../../../services/ApiClientProvider'

export const useProjectItemCommentUpdate = () => {
	const [dataProjectItemCommentUpdate, setDataProjectItemCommentUpdate] = useState(null)
	const [errorProjectItemCommentUpdate, setErrorProjectItemCommentUpdate] = useState(null)
	const [isLoadingProjectItemCommentUpdate, setIsLoadingProjectItemCommentUpdate] = useState(false)

	const { client } = useContext(ApiClientContext)

	const projectItemCommentUpdate = useCallback(
		(props) => {
			client
				.put(baseUrl + 'horus/f3/project/item/comment', props)
				.then((result) => {
					setIsLoadingProjectItemCommentUpdate(false)
					if (result.status === 200 && result.data.success) {
						setDataProjectItemCommentUpdate(result.data.data)
					} else {
						setErrorProjectItemCommentUpdate({ statusText: result.data.errorMsg })
					}
				})
				.catch((error) => {
					setErrorProjectItemCommentUpdate(error.response)
					setIsLoadingProjectItemCommentUpdate(false)
				})

			setIsLoadingProjectItemCommentUpdate(true)
			setDataProjectItemCommentUpdate(null)
			setErrorProjectItemCommentUpdate(null)
		},
		[client]
	)

	return {
		projectItemCommentUpdate,
		dataProjectItemCommentUpdate,
		errorProjectItemCommentUpdate,
		isLoadingProjectItemCommentUpdate,
	}
}
